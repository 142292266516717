import { apiPost, apiPatch } from 'modules/Core/actions';

export enum RecordSubstrateLaminationActions {
  CALL = 'pilot/batch-records/RECORD_SUBSTRATE_LAMINATION',
  REQUEST = 'pilot/batch-records/RECORD_SUBSTRATE_LAMINATION_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_SUBSTRATE_LAMINATION_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_SUBSTRATE_LAMINATION_FAILURE',
}

type LaminationPayload = {
  splits: number[];
  speed: number;
  passes: number;
};

type AmendLaminationPayload = { subRecordId?: string; comment?: string } & LaminationPayload;

export const recordSubstrateLamination = (recordId: string, payload: LaminationPayload) =>
  apiPost(
    `/pilot/batch-records/${recordId}/preparation/lamination`,
    RecordSubstrateLaminationActions.CALL,
    payload
  );

export enum AmendSubstrateLaminationActions {
  CALL = 'pilot/batch-records/AMEND_SUBSTRATE_LAMINATION',
  REQUEST = 'pilot/batch-records/AMEND_SUBSTRATE_LAMINATION_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_SUBSTRATE_LAMINATION_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_SUBSTRATE_LAMINATION_FAILURE',
}

export const amendSubstrateLamination = (recordId: string, payload: AmendLaminationPayload) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/preparation/lamination`,
    AmendSubstrateLaminationActions.CALL,
    payload
  );

export enum RecordFinalLaminiationActions {
  CALL = 'pilot/batch-records/RECORD_FINAL_LAMINIATION',
  REQUEST = 'pilot/batch-records/RECORD_FINAL_LAMINIATION_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_FINAL_LAMINIATION_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_FINAL_LAMINIATION_FAILURE',
}

export const recordFinalLaminiation = (recordId: string, payload: LaminationPayload) =>
  apiPost(
    `/pilot/batch-records/${recordId}/post-drying/lamination`,
    RecordFinalLaminiationActions.CALL,
    payload
  );

export enum AmendFinalLaminationActions {
  CALL = 'pilot/batch-records/AMEND_FINAL_LAMINATION',
  REQUEST = 'pilot/batch-records/AMEND_FINAL_LAMINATION_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_FINAL_LAMINATION_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_FINAL_LAMINATION_FAILURE',
}

export const amendFinalLamination = (recordId: string, payload: AmendLaminationPayload) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/post-drying/lamination`,
    AmendFinalLaminationActions.CALL,
    payload
  );
