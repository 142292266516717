import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListTable } from 'modules/Common';
import createRepairRequestList from 'modules/Instruments/slices/Management/data/createRepairRequestList';
import styles from './InstrumentRepairs.styles';

const InstrumentRepairs = ({ isActive, repairRequests, handleRepairRequestSelected }) => {
  const classes = makeStyles(styles)();
  const tableData = createRepairRequestList(repairRequests);

  return (
    <div className={classes.root}>
      <CardContent hidden={!isActive}>
        <Grid container className={classes.stackedList}>
          <Grid item xs={12}>
            <ListTable tableData={tableData} noPaper onRowClicked={handleRepairRequestSelected} />
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
};

InstrumentRepairs.propTypes = {
  isActive: PropTypes.bool.isRequired,
  repairRequests: PropTypes.array.isRequired,
  handleRepairRequestSelected: PropTypes.func.isRequired,
};

export default InstrumentRepairs;
