import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './TestResultRadioGroup.styles';

const TestResultRadioGroup = ({ name, fieldName, value, onChange }) => {
  const classes = makeStyles(styles)();

  return (
    <FormControl className={classes.radioGroup} component="fieldset">
      <FormLabel component="legend">{name}</FormLabel>
      <RadioGroup name={fieldName} value={value} onChange={onChange} row required>
        <FormControlLabel
          value="1"
          control={<Radio color="primary" />}
          label="Pass"
          labelPlacement="end"
        />
        <FormControlLabel
          value="0"
          control={<Radio color="primary" />}
          label="Fail"
          labelPlacement="end"
        />
        <FormControlLabel
          value="2"
          control={<Radio color="primary" />}
          label="N/A"
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  );
};

TestResultRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TestResultRadioGroup;
