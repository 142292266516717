/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import React from 'react';
import produce from 'immer';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { JSONSchema7, JSONSchema7Definition } from 'json-schema';

import { Form } from '@ldx-dmp/Common/components';
import useFormSubmitting from '@ldx-dmp/Common/useFormSubmitting';

export type AddConfigurationOptionToTestSessionProps<T> = {
  items?: T[];
  current?: T[];
  form: any;
  select: (schema: JSONSchema7['properties']) => JSONSchema7Definition;
  formatName?: (data: T) => string;
} & any;

function AddConfigurationOptionToTestSession<T>({
  items,
  current,
  form,
  select,
  formatName,
  initialValues = undefined,
  ...values
}: AddConfigurationOptionToTestSessionProps<T>) {
  const { isSubmitting, handleSubmit } = useFormSubmitting(values?.onFormSubmit);

  const { schema } = form;
  const filterIds = current?.map(x => x.id);

  const newSchema = React.useMemo(
    () =>
      produce(sch => {
        const selectable = items?.filter(x => !filterIds.includes(x.id));
        const prop = select(sch.properties);
        prop.enum = selectable.map(x => x.id);
        prop.enumNames = selectable.map(x => (formatName ? formatName(x) : x.name));

        return sch;
      }, schema)(),
    [filterIds, formatName, items, schema, select]
  );

  return (
    <Dialog open={!!values?.formOpen} onClose={values?.onFormClose} fullWidth>
      {newSchema.modalTitle && <DialogTitle>{newSchema.modalTitle}</DialogTitle>}
      <DialogContent>
        <Form
          {...form}
          isSubmitting={isSubmitting}
          schema={newSchema}
          initialValues={initialValues || form?.empty || {}}
          onSubmit={handleSubmit}
          onClose={values?.onFormClose || (() => {})}
        />
      </DialogContent>
    </Dialog>
  );
}

export default AddConfigurationOptionToTestSession;
