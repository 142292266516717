import React, { ReactNode } from 'react';
import { Card, Grid } from '@material-ui/core';
import useStyles from './SummaryPanel.styles';

type SummaryPanelProps = {
  withCard?: boolean;
  children: ReactNode;
};

const SummaryPanel = ({ withCard = false, children }: SummaryPanelProps) => {
  const classes = useStyles();
  return (
    <>
      {withCard && (
        <Card className={classes.root}>
          <Grid container>{children}</Grid>
        </Card>
      )}
      {!withCard && (
        <Grid container className={classes.root}>
          {children}
        </Grid>
      )}
    </>
  );
};

export default SummaryPanel;
