import React from 'react';
import axios from 'axios';
import { buildUrl } from '@ldx-dmp/Core/components/DataTable/dataTableSlice';
import { options, columns } from 'modules/InstrumentsQualityControl/context';

import {
  selectItems,
  selectLoading,
  setData,
  startLoading,
  stopLoading,
} from '../testSessionsSlice';

export default function(state, apiUrl, dispatch, navigate) {
  const data = selectItems(state);
  const isReloading = selectLoading(state) === 'RELOADING';
  const isLoading = selectLoading(state) === 'LOADING' || selectLoading(state) === 'NONE';

  const fetchAll = React.useCallback(
    function fetchAll(tableState) {
      dispatch(startLoading());
      const url = buildUrl(`${apiUrl}/instruments/quality-control/test-sessions`, tableState);
      axios.get(url).then(response => {
        dispatch(setData(response.data));
        dispatch(stopLoading());
      });
    },
    [apiUrl, dispatch]
  );

  const onRowClick = React.useCallback(
    row => {
      navigate(`/instruments-quality-control/${row[0]}`);
    },
    [navigate]
  );

  return React.useMemo(
    () => ({
      isReloading: isReloading || isLoading,
      fetchAll,
      columns,
      data: data.items?.map(x => [
        x.id,
        x.testSessionId,
        `${Object.keys(x.instruments || {}).length}/${x.maxAllowedInstruments}`,
        x.status,
      ]),
      totalCount: data.totalCount,
      options: {
        ...options,
        onRowClick,
      },
    }),
    [data.items, data.totalCount, fetchAll, isLoading, isReloading, onRowClick]
  );
}
