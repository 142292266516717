import { apiPost, apiPatch } from 'modules/Core/actions';

export enum AddLaserSubrecordActions {
  CALL = 'pilot/batch-records/ADD_LASER_SUBRECORD',
  REQUEST = 'pilot/batch-records/ADD_LASER_SUBRECORD_REQUEST',
  SUCCESS = 'pilot/batch-records/ADD_LASER_SUBRECORD_SUCCESS',
  FAILURE = 'pilot/batch-records/ADD_LASER_SUBRECORD_FAILURE',
}

export enum AddAssemblyToLaserSubrecordActions {
  CALL = 'pilot/batch-records/ADD_ASSEMBLY_TO_LASER',
  REQUEST = 'pilot/batch-records/ADD_ASSEMBLY_TO_LASER_REQUEST',
  SUCCESS = 'pilot/batch-records/ADD_ASSEMBLY_TO_LASER_SUCCESS',
  FAILURE = 'pilot/batch-records/ADD_ASSEMBLY_TO_LASER_FAILURE',
}

type AddLaserSubrecord = {
  splits: number[];
  laserFile: string;
  laserNumbers: number[];
};

type AddAssemblyToLaserSubrecord = {
  laserNumbers: number[];
};

export const addLaserSubrecord = (recordId: string, payload: AddLaserSubrecord) =>
  apiPost(
    `/pilot/batch-records/${recordId}/preparation/laser-assembly`,
    AddLaserSubrecordActions.CALL,
    {
      ...payload,
    }
  );

export const addAssemblyToLaserSubrecord = (
  recordId: string,
  subrecordId: string,
  payload: AddAssemblyToLaserSubrecord
) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/preparation/laser-assembly/${subrecordId}`,
    AddAssemblyToLaserSubrecordActions.CALL,
    {
      ...payload,
    }
  );
