import React from 'react';
import { SectionComment, Sections } from 'modules/Pilot/BatchRecord/domain';
import { Grid } from '@material-ui/core';
import CommentsTable from '../../CommentsTable';

type BatchCommentsProps = {
  recordId: string;
  comments: SectionComment[];
  readOnly?: boolean;
};

const BatchComments = ({ recordId, comments, readOnly = false }: BatchCommentsProps) => {
  const sections = [
    { key: Sections.SignOff, title: 'General Batch Comments' },
    { key: Sections.Preparation, title: 'Preparation Comments' },
    { key: Sections.Deposition, title: 'Deposition Comments' },
    { key: Sections.Drying, title: 'Drying Comments' },
    { key: Sections.PostDrying, title: 'Post Drying Comments' },
    { key: Sections.Inspection, title: 'Inspection Comments' },
  ];
  return (
    <>
      <Grid item xs={12}>
        {sections.map(section => (
          <CommentsTable
            key={section.key}
            recordId={recordId}
            section={section.key}
            comments={comments}
            title={section.title}
            readOnly={readOnly}
          />
        ))}
      </Grid>
    </>
  );
};

export default BatchComments;
