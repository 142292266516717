import React from 'react';
import { useEffectOnce } from 'react-use';
import { Field } from 'formik';
import { Chip, FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import { InstrumentDto } from 'modules/Instruments/models';
import useInstrumentSubsystemsSelectList from 'modules/Global/instrumentSubsystems/useInstrumentSubsystemsSelectList';
import useStyles from './AffectedSubsystemsSelect.styles';

type AffectedSubsystemsSelectProps = {
  name: string;
  instrument?: InstrumentDto;
};

const AffectedSubsystemsSelect = ({ name, instrument }: AffectedSubsystemsSelectProps) => {
  const classes = useStyles();
  const { items, fetch } = useInstrumentSubsystemsSelectList(instrument?.version?.platform);

  useEffectOnce(() => {
    fetch();
  });

  return (
    <>
      <FormControl className={classes.root} variant="filled">
        <InputLabel htmlFor={name}>Affected Subsystems</InputLabel>
        <Field name={name}>
          {({ field, meta }) => (
            <Select
              variant="filled"
              id={name}
              multiple
              error={meta.touched && meta.error}
              {...field}
              input={<Input id={`${name}-chips`} />}
              renderValue={(selected: string[]) => (
                <div className={classes.chips}>
                  {selected.map((subsys: string) => (
                    <Chip
                      key={subsys}
                      label={items.find(sys => sys.value === subsys)?.label}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
            >
              {items.map(subsys => (
                <MenuItem key={subsys.value} value={subsys.value}>
                  {subsys.label}
                </MenuItem>
              ))}
            </Select>
          )}
        </Field>
      </FormControl>
    </>
  );
};

export default AffectedSubsystemsSelect;
