import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',

    sectionHeader: {
      margin: theme.spacing(2),
    },

    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },

    '& .MuiCardContent-root': {
      whiteSpace: 'pre-line',
    },
  },
  stackedList: {
    width: theme.breakpoints.width('lg'),
  },
}));
