import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { InstrumentsQualityControlListRouter } from './features/testSessions';
import { MaterialRouter } from './features/materials';
import { EquipmentRouter } from './features/equipment';

const InstrumentRouter = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/instruments-quality-control/equipment/:id?"
          component={EquipmentRouter}
        />
        <Route
          exact
          path="/instruments-quality-control/materials/:id?"
          component={MaterialRouter}
        />
        <Route
          exact
          path="/instruments-quality-control/:id?"
          component={InstrumentsQualityControlListRouter}
        />
      </Switch>
    </>
  );
};

export default InstrumentRouter;
