/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import AddMaterialToVisualInspectionInput from 'modules/InstrumentsQualityControl/models/AddMaterialToVisualInspectionInput';
import IActionResult from 'modules/InstrumentsQualityControl/models/IActionResult';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.properties.materialId.title = 'Material';

  return sch;
};

export const uiSchema = ui => {
  ui.expiryDate = { 'ui:widget': 'date' };

  return ui;
};

export const mapToEdit = () => (current: IActionResult): AddMaterialToVisualInspectionInput => ({
  ...current,
});

export const fields = noop;

export const widgets = noop;

export const validation = () =>
  yup.object({
    lotNumber: yup.string().required(),
  });

export const empty = def => ({
  ...def,
  expiryDate: null,
});
