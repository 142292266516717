import React from 'react';
import { Button, TableCell } from '@material-ui/core';
import { SignOffDialog } from 'modules/Pilot/BatchRecord/SignOff/components';
import { useDispatch } from 'react-redux';
import { openDialog } from 'modules/Core/actions/dialogs';
import { ValueField, SignOffField, VerificationAction } from './TableData';

type DataTableFieldProps = {
  field: ValueField | SignOffField;
  verification?: VerificationAction;
  readOnly: boolean;
};

export default ({ field, verification, readOnly = false }: DataTableFieldProps) => {
  const dispatch = useDispatch();

  if (field === undefined) return <></>;

  const isSignOff = (fld: SignOffField | ValueField): fld is SignOffField =>
    (fld as SignOffField).date !== undefined;

  if (isSignOff(field)) {
    if (!readOnly && field.user === null && verification !== undefined)
      return (
        <TableCell colSpan={2} align="center">
          <Button
            size="small"
            color="primary"
            onClick={() => dispatch(openDialog(verification.dialogName))}
            variant="contained"
          >
            Verify
          </Button>
          <SignOffDialog
            title={verification.title}
            action={verification.action}
            api={verification.api}
            dialogName={verification.dialogName}
            maxWidth={verification.maxWidth}
            review={verification.review}
            reviewText={verification.reviewText}
          />
        </TableCell>
      );
    return (
      <>
        <TableCell>{field.user}</TableCell>
        <TableCell>{field.date}</TableCell>
      </>
    );
  }

  return <TableCell>{field.value}</TableCell>;
};
