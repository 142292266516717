import React from 'react';
import { useEffectOnce } from 'react-use';
import { PagedResultDto } from 'modules/Core';
import { PilotLineDto } from '@ldx-dmp/Pilot/models';
import useServer from '@ldx-dmp/Pilot/Lines/useServer';
import { FormikSelectField } from '@ldx-dmp/Common';
import { FormikSelectFieldProps } from 'modules/Common/components/FormikSelectField/FormikSelectField';

export default ({ ...props }: FormikSelectFieldProps) => {
  const [values, setValues] = React.useState<{ value: string | number; label: string }[]>([]);
  const server = useServer();

  useEffectOnce(() => {
    server.getListAsync<PagedResultDto<PilotLineDto>>(0, 1000, 'name').then(response =>
      setValues(
        response?.data?.items?.map(x => ({
          value: String(x.id),
          label: `${String(x.name)} (${String(x.code)})`,
        })) || []
      )
    );
  });

  return <FormikSelectField {...props} options={values || []} />;
};
