export const LDX_INSTRUMENT_REPAIR_REQUESTS = 'LDX.Instruments.RepairRequests';
export const LDX_INSTRUMENT_REPAIR_VIEW = 'LDX.Instruments.RepairRequests.View';
export const LDX_INSTRUMENT_REPAIR_CREATE = 'LDX.Instruments.RepairRequests.Create';
export const LDX_INSTRUMENT_REPAIR_UPDATE = 'LDX.Instruments.RepairRequests.Update';
export const LDX_INSTRUMENT_REPAIR_TEST = 'LDX.Instruments.RepairRequests.RecordPostRepairTesting';
export const LDX_INSTRUMENT_REPAIR_RESOLVE = 'LDX.Instruments.RepairRequests.Resolve';
export const LDX_INSTRUMENT_REPAIR_ADMIN = 'LDX.Instruments.RepairRequests.Admin';

export const LDX_PILOTLINE_BATCH = 'LDX.PilotLine.BatchRecords';
export const LDX_PILOTLINE_BATCH_VIEW = 'LDX.PilotLine.BatchRecords.View';
export const LDX_PILOTLINE_BATCH_CREATE = 'LDX.PilotLine.BatchRecords.Create';
export const LDX_PILOTLINE_BATCH_RECORD = 'LDX.PilotLine.BatchRecords.Record';
export const LDX_PILOTLINE_BATCH_AMEND = 'LDX.PilotLine.BatchRecords.Amend';
export const LDX_PILOTLINE_BATCH_OGP = 'LDX.PilotLine.BatchRecords.OgpInspection';
export const LDX_PILOTLINE_BATCH_SIGN_SECTION = 'LDX.PilotLine.BatchRecords.SignOffSection';
export const LDX_PILOTLINE_BATCH_FORCE_SIGN_SECTION =
  'LDX.PilotLine.BatchRecords.ForceSignOffSection';
export const LDX_PILOTLINE_BATCH_MODIFY_LOCKED = 'LDX.PilotLine.BatchRecords.ModifyLocked';
export const LDX_PILOTLINE_BATCH_LINE_CLEARANCE = 'LDX.PilotLine.BatchRecords.GiveLineClearance';
export const LDX_PILOTLINE_BATCH_MFC_SIGN_OFF = 'LDX.PilotLine.BatchRecords.ManufacturingSignOff';
export const LDX_PILOTLINE_BATCH_SIGN_OFF = 'LDX.PilotLine.BatchRecords.BatchSignOff';

export const LDX_INSTRUMENT_QUALITYCONTROL_ADMIN = 'LDX.Instruments.QualityControl.View';

export const LDX_PILOTLINE_LINES_MODIFY = 'LDX.PilotLine.BatchRecords.ModifyPilotLines';
