export default function(instruments, onRowClick = null) {
  return {
    options: {
      selectableRows: 'none',
      download: false,
      print: false,
      filterType: 'multiselect',
      elevation: 1,
      viewColumns: false,
      onRowClick,
    },
    columns: [
      { name: 'serial', label: 'Instrument Serial', options: { filter: false, sort: true } },
      { name: 'family', label: 'Family', options: { filter: true, sort: true } },
      { name: 'type', label: 'Instrument Type', options: { filter: true, sort: true } },
      { name: 'revision', label: 'Revision', options: { filter: true, sort: true } },
      { name: 'wirelessId', label: 'Wireless ID', options: { filter: true, sort: true } },
    ],
    data: instruments.map(instrument => [
      instrument?.serial,
      instrument?.version?.platform,
      instrument?.version?.platformVersion,
      instrument?.version?.revision,
      instrument?.wirelessId,
    ]),
  };
}
