import React from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField, TextFieldProps } from '@material-ui/core';
import WarningField from '../WarningField';

export type FormikTextFieldProps = TextFieldProps & {
  name: string;
  label: string;
  step?: number | undefined;
  meta?: {
    error?: string;
    touched: boolean;
    initialTouched: boolean;
    initialError?: string;
  };
  warning?: {
    check: (value: unknown, values: unknown) => boolean;
    message?: string;
  };
};

const FormikTextField = ({
  name,
  onChange,
  onBlur,
  id,
  onFocus,
  warning,
  ...rest
}: FormikTextFieldProps) => {
  const { values } = useFormikContext();
  return (
    <Field name={name || id}>
      {({ field, meta }) => (
        <>
          <TextField
            variant="filled"
            fullWidth
            error={meta.touched && !!meta.error}
            name={name || id}
            helperText={
              warning && warning.check(field.value, values) && <WarningField {...warning} />
            }
            {...rest}
            {...field}
          />
        </>
      )}
    </Field>
  );
};

export default FormikTextField;
