/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import React from 'react';
import startCase from 'lodash/startCase';
import WashingMachine from 'mdi-material-ui/WashingMachine';
import TimeAgo from 'react-timeago';
import { Typography, Paper } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineOppositeContent,
} from '@material-ui/lab';
import { formatShortDateTime } from 'modules/Common/utilities';
import ActivityLogEntryDto from 'modules/Instruments/models/ActivityLogEntryDto';
import useStyles from './ActivityPanel.styles';

type ActivityPanelProps = {
  events?: ActivityLogEntryDto[] | undefined;
};

const ActivityTimelineItem = ({
  action: { eventType, timestamp, source },
  skipConnector = false,
}: {
  action: ActivityLogEntryDto;
  skipConnector: boolean;
}) => {
  const classes = useStyles();

  return (
    <TimelineItem>
      <TimelineOppositeContent className={classes.oppositeContent}>
        <Typography variant="body2" color="textSecondary">
          <TimeAgo date={timestamp} title={formatShortDateTime(timestamp)} />
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color="primary">
          <WashingMachine />
        </TimelineDot>
        {!skipConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h6" component="h1">
            {startCase(eventType)}
          </Typography>
          <Typography>
            {source?.system} - {source?.name}
          </Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ({ events }: ActivityPanelProps) => {
  const classes = useStyles();

  return (
    <>
      {events && (
        <Timeline align="left" className={classes.root}>
          {events.map((e, index) => (
            <ActivityTimelineItem
              key={`${e.eventType}-${e.timestamp}`}
              action={e}
              skipConnector={index >= events.length - 1}
            />
          ))}
        </Timeline>
      )}
    </>
  );
};
