import { apiPatch, apiPost } from 'modules/Core/actions';

export enum RecordFoilPouchLotNumberActions {
  CALL = 'pilot/batch-records/RECORD_FOIL_POUCH_LOT_NUMBER',
  REQUEST = 'pilot/batch-records/RECORD_FOIL_POUCH_LOT_NUMBER_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_FOIL_POUCH_LOT_NUMBER_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_FOIL_POUCH_LOT_NUMBER_FAILURE',
}

export enum AmendFoilPouchLotNumberActions {
  CALL = 'pilot/batch-records/AMEND_FOIL_POUCH_LOT_NUMBER',
  REQUEST = 'pilot/batch-records/AMEND_FOIL_POUCH_LOT_NUMBER_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_FOIL_POUCH_LOT_NUMBER_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_FOIL_POUCH_LOT_NUMBER_FAILURE',
}

type RecordFoilPouchLotNumberPayload = {
  lotNumber: string;
};

type AmendFoilPouchLotNumberPayload = {
  subRecordId?: string;
  comment?: string;
} & RecordFoilPouchLotNumberPayload;

export const recordFoilPouchLotNumber = (
  recordId: string,
  payload: RecordFoilPouchLotNumberPayload
) =>
  apiPost(
    `/pilot/batch-records/${recordId}/deposition/pouches`,
    RecordFoilPouchLotNumberActions.CALL,
    { ...payload }
  );

export const amendFoilPouchLotNumber = (
  recordId: string,
  payload: AmendFoilPouchLotNumberPayload
) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/deposition/pouches`,
    AmendFoilPouchLotNumberActions.CALL,
    { ...payload }
  );
