/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import startCase from 'lodash/startCase';
import { FieldProps } from '@rjsf/core';

export default ({ schema, formData, value, uiSchema = {} }: FieldProps) => {
  const { format = startCase } = uiSchema;
  return (
    <Grid item xs={12}>
      <Typography variant="body1">{schema.title}</Typography>
      <Typography>{format(formData || value)}</Typography>
    </Grid>
  );
};
