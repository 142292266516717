import React from 'react';
import * as yup from 'yup';
import { FormDefinition } from 'modules/Common/components/FormDialog/FormDialog';
import { FormikValues } from 'formik';
import { addCommentToSection, AddCommentToSectionActions } from 'modules/Pilot/BatchRecord/actions';
import { FormikTextField } from 'modules/Common/components';

interface Values extends FormikValues {
  comment: string;
}

export default (recordId: string, section: string, dialogName: string): FormDefinition<Values> => {
  return {
    title: 'Add Comment',
    dialogName,
    apiAction: AddCommentToSectionActions.CALL,
    onSubmit: (values: Values, _, dispatch) => {
      dispatch(addCommentToSection(recordId, section, values.comment));
    },
    initialValues: {
      comment: '',
    },
    form: () => <FormikTextField name="comment" label="Comment" multiline />,
    validationSchema: (): yup.ObjectSchema<Values> =>
      yup
        .object()
        .shape({
          comment: yup
            .string()
            .defined()
            .required()
            .min(5),
        })
        .defined(),
  };
};
