import React, { useMemo, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { useParams } from 'react-router';
import { ContentHeader, TabManager, RecordCard, Spinner, utils } from 'modules/Common';
import { BatchRecord, BatchRequest } from 'modules/Pilot/BatchRecord/domain';
import PreparationPanel from 'modules/Pilot/BatchRecord/SingleView/components/PreparationPanel';
import DepositionPanel from 'modules/Pilot/BatchRecord/SingleView/components/DepositionPanel';
import DryingPanel from 'modules/Pilot/BatchRecord/SingleView/components/DryingPanel';
import PostDryingPanel from 'modules/Pilot/BatchRecord/SingleView/components/PostDryingPanel';
import SignOffPanel from 'modules/Pilot/BatchRecord/SingleView/components/SignOffPanel';
import InspectionPanel from 'modules/Pilot/BatchRecord/SingleView/components/InspectionPanel';
import { useSelector, useDispatch } from 'react-redux';
import {
  getCurrentBatchRecord,
  getCurrentBatchRecordId,
  isBatchRecordInitialising,
} from 'modules/Pilot/BatchRecord/reducers/selectors';
import {
  setCurrentBatchRecord,
  fetchBatchRecord,
  SignOffSectionActions,
  signOffSection,
  forceSignOffSection,
  UnlockSectionActions,
  unlockSection,
  batchRecordDialogClose,
  getDespositionAssets,
  getChemistryDefinitions,
} from 'modules/Pilot/BatchRecord/actions';
import { closeDialog } from 'modules/Core/actions/dialogs';
import { Button, Fade } from '@material-ui/core';
import { batchRecordFormDefinition } from 'modules/Pilot/BatchRecord/Form';
import { FormDialog } from 'modules/Common/components';
import { FormDialogContext } from '@ldx-dmp/Common/components/FormDialog';
import { RecordStatus, SectionTitle } from 'modules/RecordManagement';
import { appPermissions } from 'modules/Core/domain';
import { getOpenDialogsStartingWith, getUserProfile } from 'modules/Core/reducers/selectors';
import { useDiagnostics } from 'modules/Pilot/BatchRecord/diagnostics/useDiagnostics';
import ActivityPanel from '../ActivityPanel';
import BatchRecordActions from './BatchRecordActions';
import useRejectDefinition from './rejectRecordDefinition';
import useRealtimeUpdater from './useRealtimeUpdater';
import DiagnosticsPanel from '../../../diagnostics/components/DiagnosticsPanel';

function getChemistry(batchRequest: BatchRequest | undefined | null) {
  if (!batchRequest) return '';
  if (batchRequest.chemistry !== 'Other') return batchRequest.chemistry;

  return batchRequest.chemistryOther;
}

const createSummaryItems = (batchRecord?: BatchRecord) => [
  { key: 'lotNumber', primary: 'Lot Number', secondary: batchRecord?.lotNumber },
  {
    key: 'scheduled',
    primary: 'Scheduled For',
    secondary: utils.formatShortDate(batchRecord?.batchRequest.scheduledRunDate),
  },
  { key: 'chemistry', primary: 'Chemistry', secondary: getChemistry(batchRecord?.batchRequest) },
  {
    key: 'deposition',
    primary: 'Deposition',
    secondary: batchRecord?.batchRequest.depositionMethod,
  },
  { key: 'splits', primary: 'Splits', secondary: batchRecord?.batchRequest.numberOfSplits },
  { key: 'stripType', primary: 'Strip Type', secondary: batchRecord?.batchRequest.stripType },
  {
    key: 'cards',
    primary: 'Cards',
    secondary: batchRecord?.batchRequest.numberOfCards,
  },
  {
    key: 'strips',
    primary: 'Strips',
    secondary: batchRecord ? batchRecord.batchRequest.numberOfStrips : '-',
  },
];

const BatchRecordPage = () => {
  const openedDialogs = useSelector(getOpenDialogsStartingWith('pilot/batch-records/'));
  const [requiresUpdate, setRequiredUpdate] = React.useState(false);
  const user = useSelector(getUserProfile);

  const dispatch = useDispatch();
  const { batchRecordId } = useParams();
  const currentRecordId = useSelector(getCurrentBatchRecordId);
  const batchRecord = useSelector(getCurrentBatchRecord) as BatchRecord;
  const batchRequest = batchRecord?.batchRequest;
  const isLoading = useSelector(isBatchRecordInitialising);
  const isReadOnly =
    batchRecord?.status === RecordStatus.Complete || batchRecord?.status === RecordStatus.Rejected;
  const summaryItems = useMemo(() => createSummaryItems(batchRecord), [batchRecord]);
  const formDefinition = useMemo(() => batchRecordFormDefinition(batchRecordId, batchRequest), [
    batchRecordId,
    batchRequest,
  ]);
  const rejectDefinition = useRejectDefinition(batchRecordId);

  useEffect(() => {
    if (!currentRecordId || currentRecordId !== batchRecordId)
      dispatch(setCurrentBatchRecord(batchRecordId));
  }, [currentRecordId, batchRecordId, dispatch]);

  useEffectOnce(() => {
    // Hack to remove the need to call this when the Create success return to avoid a double load
    // This is tracked under DMP-207
    dispatch(batchRecordDialogClose());
    dispatch(fetchBatchRecord(batchRecordId));
  });

  useEffect(() => {
    if (batchRecord?.pilotLine?.id) {
      dispatch(getDespositionAssets(batchRecord?.pilotLine?.id));
      dispatch(getChemistryDefinitions(batchRecord?.pilotLine?.id));
    }
  }, [batchRecord?.pilotLine?.id, batchRecordId, dispatch]);

  const onUpdate = React.useCallback(() => {
    if (!openedDialogs.length) {
      dispatch(fetchBatchRecord(batchRecordId));
    } else {
      setRequiredUpdate(true);
    }
  }, [batchRecordId, dispatch, openedDialogs]);

  const reload = React.useCallback(() => {
    openedDialogs.forEach(element => {
      dispatch(closeDialog(element.replace('_DIALOG', '')));
    });
    dispatch(fetchBatchRecord(batchRecordId));
    setRequiredUpdate(false);
  }, [batchRecordId, dispatch, openedDialogs]);

  useRealtimeUpdater(batchRecordId, onUpdate);

  const [currentPanel, setCurrentPanel] = React.useState('');

  const diagnostics = useDiagnostics({
    batchRecord,
    enabled: user?.roles?.includes('admin'),
    fetch: currentPanel === 'diagnostics',
  });

  if (!batchRecord || !batchRecord.preparation || isLoading) return <Spinner />;

  const tabs = [
    {
      name: 'preparation',
      tabLabel: 'Preparation',
      panelHeadingComponent: (
        <SectionTitle
          label="Substrate Preparation"
          status={batchRecord.preparation.status}
          signOffApi={SignOffSectionActions.CALL}
          signOffAction={signOffSection(batchRecord.id, 'preparation')}
          signOffPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
          forceSignOffAction={forceSignOffSection(batchRecord.id, 'preparation')}
          forceSignOffPermission={appPermissions.LDX_PILOTLINE_BATCH_FORCE_SIGN_SECTION}
          unlockApi={UnlockSectionActions.CALL}
          unlockAction={comment => unlockSection(batchRecord.id, 'preparation', comment)}
          unlockPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
        />
      ),
      component: <PreparationPanel batchRecord={batchRecord} readOnly={isReadOnly} />,
    },
    {
      name: 'deposition',
      tabLabel: 'Deposition',
      panelHeadingComponent: (
        <SectionTitle
          label="Deposition"
          status={batchRecord.deposition.status}
          signOffApi={SignOffSectionActions.CALL}
          signOffAction={signOffSection(batchRecord.id, 'deposition')}
          signOffPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
          forceSignOffAction={forceSignOffSection(batchRecord.id, 'deposition')}
          forceSignOffPermission={appPermissions.LDX_PILOTLINE_BATCH_FORCE_SIGN_SECTION}
          unlockApi={UnlockSectionActions.CALL}
          unlockAction={comment => unlockSection(batchRecord.id, 'deposition', comment)}
          unlockPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
        />
      ),
      component: <DepositionPanel batchRecord={batchRecord} readOnly={isReadOnly} />,
    },
    {
      name: 'drying',
      tabLabel: 'Drying',
      panelHeadingComponent: (
        <SectionTitle
          label="Drying"
          status={batchRecord.drying.status}
          signOffApi={SignOffSectionActions.CALL}
          signOffAction={signOffSection(batchRecord.id, 'drying')}
          signOffPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
          forceSignOffAction={forceSignOffSection(batchRecord.id, 'drying')}
          forceSignOffPermission={appPermissions.LDX_PILOTLINE_BATCH_FORCE_SIGN_SECTION}
          unlockApi={UnlockSectionActions.CALL}
          unlockAction={comment => unlockSection(batchRecord.id, 'drying', comment)}
          unlockPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
        />
      ),
      component: <DryingPanel batchRecord={batchRecord} readOnly={isReadOnly} />,
    },
    {
      name: 'postDrying',
      tabLabel: 'Post Drying',
      panelHeadingComponent: (
        <SectionTitle
          label="Post Drying"
          status={batchRecord.postDrying.status}
          signOffApi={SignOffSectionActions.CALL}
          signOffAction={signOffSection(batchRecord.id, 'post-drying')}
          signOffPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
          forceSignOffAction={forceSignOffSection(batchRecord.id, 'post-drying')}
          forceSignOffPermission={appPermissions.LDX_PILOTLINE_BATCH_FORCE_SIGN_SECTION}
          unlockApi={UnlockSectionActions.CALL}
          unlockAction={comment => unlockSection(batchRecord.id, 'post-drying', comment)}
          unlockPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
        />
      ),
      component: <PostDryingPanel batchRecord={batchRecord} readOnly={isReadOnly} />,
    },
    {
      name: 'inspection',
      tabLabel: 'Inspection',
      panelHeadingComponent: (
        <SectionTitle
          label="Visual Inspection"
          status={batchRecord.inspection.status}
          signOffApi={SignOffSectionActions.CALL}
          signOffAction={signOffSection(batchRecord.id, 'inspection')}
          signOffPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
          forceSignOffAction={forceSignOffSection(batchRecord.id, 'inspection')}
          forceSignOffPermission={appPermissions.LDX_PILOTLINE_BATCH_FORCE_SIGN_SECTION}
          unlockApi={UnlockSectionActions.CALL}
          unlockAction={comment => unlockSection(batchRecord.id, 'inspection', comment)}
          unlockPermission={appPermissions.LDX_PILOTLINE_BATCH_SIGN_SECTION}
        />
      ),
      component: <InspectionPanel batchRecord={batchRecord} readOnly={isReadOnly} />,
    },
    {
      name: 'signOff',
      tabLabel: 'Sign Off',
      component: <SignOffPanel batchRecord={batchRecord} readOnly={isReadOnly} />,
      panelHeadingComponent: <SectionTitle label="Sign Off" status={batchRecord.signOff.status} />,
    },
    {
      name: 'activity',
      tabLabel: 'Activity',
      panelHeading: 'Activity',
      component: <ActivityPanel events={batchRecord.events} />,
    },
  ];

  if (diagnostics.enabled) {
    tabs.push({
      name: 'diagnostics',
      tabLabel: 'Diagnostics',
      panelHeading: 'Diagnostics',
      component: <DiagnosticsPanel {...diagnostics} />,
    });
  }

  return (
    <FormDialogContext.Provider
      value={{
        saveRestricted: requiresUpdate || diagnostics.confirmed,
        saveRestrictedMessage: (
          <div>
            {!diagnostics.confirmed && requiresUpdate ? (
              <>
                Batch record has been modified. Please
                <Button color="primary" type="button" onClick={reload}>
                  reload page.
                </Button>
              </>
            ) : (
              <>Diagnostics enabled, UI in readonly state</>
            )}
          </div>
        ),
      }}
    >
      <Fade in={!isLoading}>
        <div>
          <ContentHeader title="Batch Record for Prototype Strip">
            {!isReadOnly && <BatchRecordActions />}
          </ContentHeader>
          <RecordCard summary={summaryItems}>
            <TabManager onTabChanged={index => setCurrentPanel(tabs[index]?.name)}>
              {tabs}
            </TabManager>
          </RecordCard>
          <FormDialog definition={formDefinition} />
          <FormDialog definition={rejectDefinition} />
        </div>
      </Fade>
    </FormDialogContext.Provider>
  );
};

export default BatchRecordPage;
