/* eslint-disable react/prop-types */
import React from 'react';
import { FormikValues } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Typography } from '@material-ui/core';
import { FormDefinition } from '@ldx-dmp/Common/components/FormDialog/FormDialog';
import {
  amendDevelopmentBatchRequest,
  AmendDevelopmentBatchRequestActions,
  attachDevelopmentBatchRequest,
  AttachDevelopmentBatchRequestActions,
} from '@ldx-dmp/Pilot/BatchRecord/actions';
import { FormikTextField } from '@ldx-dmp/Common';

interface Values extends FormikValues {
  file?: File;
  comment?: string;
}

export default (recordId: string, dialogName: string, isEdit = false): FormDefinition<Values> => {
  const validation = () => {
    if (isEdit)
      return {
        comment: yup
          .string()
          .defined()
          .required()
          .min(5),
      };

    return { comment: yup.string().optional() };
  };

  return {
    title: 'Development Batch Request',
    dialogName,
    apiAction: isEdit
      ? AmendDevelopmentBatchRequestActions.CALL
      : AttachDevelopmentBatchRequestActions.CALL,
    onSubmit: (values: Values, _, dispatch) => {
      if (values.file !== undefined) {
        if (isEdit)
          dispatch(amendDevelopmentBatchRequest(recordId, values.file, values.comment ?? ''));
        else dispatch(attachDevelopmentBatchRequest(recordId, values.file));
      }
    },
    initialValues: {
      file: undefined,
      comment: '',
    },
    // eslint-disable-next-line react/prop-types
    form: ({ values, setFieldValue }) => (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            Please attach the Development Batch Request
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <label htmlFor="file">
            <input
              type="file"
              name="file"
              id="file"
              style={{ display: 'none' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!event.target.files) return;
                setFieldValue('file', event.target.files[0]);
              }}
              accept=".docx,.doc,.odt,.xls,.xlsx,.ods,.pdf"
            />
            <Button
              color="primary"
              variant="contained"
              component="span"
              style={{ marginRight: '10px' }}
            >
              Select File
            </Button>
            {values.file && <>{values.file.name}</>}
          </label>
        </Grid>
        <Grid item xs={12}>
          {isEdit && <FormikTextField name="comment" label="Reason for change" multiline />}
        </Grid>
      </Grid>
    ),
    validationSchema: (): yup.ObjectSchema<Values> =>
      yup
        .object()
        .shape(validation())
        .defined(),
  };
};
