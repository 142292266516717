import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { fetchBatchRecords } from 'modules/Pilot/BatchRecord/actions';
import {
  getBatchRecords,
  getTotalCount,
  isBatchRecordInitialising,
  isBatchRecordFetching,
} from 'modules/Pilot/BatchRecord/reducers/selectors';
import { createBatchRecordTableData, columns } from 'modules/Pilot/BatchRecord/data';
import { DataTable } from 'modules/Core/components';
import { BatchRecordsTableViewOptions } from 'modules/Pilot/BatchRecord/actions/fetchBatchRecords';

const BatchRecordsTable = ({ view = 'live' }: { view?: BatchRecordsTableViewOptions }) => {
  const [currentData, setData] = React.useState<
    Partial<ReturnType<typeof createBatchRecordTableData>>
  >({});
  const dispatch = useDispatch();

  const isLoading = useSelector(isBatchRecordInitialising);
  const isReloading = useSelector(isBatchRecordFetching);
  const batchRecords = useSelector(getBatchRecords);
  const totalCount = useSelector(getTotalCount);

  React.useEffect(() => {
    const data = createBatchRecordTableData(batchRecords, (_, rowMeta) => {
      const recordId = batchRecords[rowMeta.dataIndex].id;
      dispatch(push(`/pilot-line/batch-records/${recordId}`));
    });
    setData(data);
  }, [batchRecords, dispatch]);

  const fetchData = React.useCallback(
    tableState => {
      dispatch(fetchBatchRecords(view, tableState));
    },
    [dispatch, view]
  );

  return (
    <>
      <DataTable
        fetchData={fetchData}
        isFetching={isLoading || isReloading}
        totalCount={totalCount}
        showData={!isLoading}
        {...currentData}
        columns={columns}
      />
    </>
  );
};

export default BatchRecordsTable;
