import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    maxWidth: 300,

    '& .MuiCardHeader-root': {
      padding: '8px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& .MuiTypography-subtitle1': {
        fontFamily: 'ITCAvantGardeStd-Md',
      },
      '& .MuiTypography-body2': {
        color: theme.palette.primary.contrastText,
      },
    },

    '& .MuiCardContent-root': {
      padding: '8px',
    },

    '& .MuiAvatar-root': {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
