import { makeUserHasPermission } from 'modules/Core/reducers/selectors';
import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

type PermissionRequiredProps = {
  permission: string | string[];
  children: ReactNode;
};

export default ({ permission, children }: PermissionRequiredProps) => {
  const userHasPermission = useMemo(() => makeUserHasPermission(), []);
  let hasPermission = false;

  if (!Array.isArray(permission))
    hasPermission = useSelector(state => userHasPermission(state, permission));
  else {
    const missing = permission.find(perm => !useSelector(state => userHasPermission(state, perm)));
    hasPermission = missing === undefined;
  }

  return hasPermission ? <>{children}</> : <></>;
};
