import React from 'react';
import { camelCase } from 'lodash';
import { Grid } from '@material-ui/core';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { RecordStatus } from 'modules/RecordManagement';
import useStyles from './RecordProgress.styles';

export default ({ batchRecord }: { batchRecord: BatchRecord }) => {
  const classes = useStyles();
  const isReadOnly =
    batchRecord.status === RecordStatus.Complete || batchRecord.status === RecordStatus.Rejected;

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        <ul id="progress">
          <li className={camelCase(batchRecord.preparation.status)}>Preparation</li>
          <li className={camelCase(batchRecord.deposition.status)}>Deposition</li>
          <li className={camelCase(batchRecord.drying.status)}>Drying</li>
          <li className={camelCase(batchRecord.postDrying.status)}>Post Drying</li>
          <li className={camelCase(batchRecord.inspection.status)}>Inspection</li>
          <li className={isReadOnly ? 'readOnly' : 'incomplete'}>Sign Off</li>
        </ul>
      </div>
    </Grid>
  );
};
