import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '&.incomplete': {
      '& .MuiChip-root': {
        backgroundColor: '#ffd54f',
        color: '#000',
        '&.MuiChip-clickable:hover': {
          backgroundColor: '#ffe082',
        },
      },
    },
    '&.complete': {
      '& .MuiChip-root': {
        backgroundColor: '#81c784',
        color: '#000',
        '&.MuiChip-clickable:hover': {
          backgroundColor: '#a5d6a7',
        },
      },
    },
    '&.locked': {},
    '&.ignore': {
      '& .MuiChip-root': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&.MuiChip-clickable:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  },
}));
