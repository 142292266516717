import { createSelector } from 'reselect';
import { DepositionMethod } from '../domain';
import { LoadingState } from './batchRecordReducer';

export const getPilot = state => state.pilot;
export const getBatchReordsRoot = createSelector(getPilot, state => state.batchRecords);

export const isBatchRecordInitialising = createSelector(
  getBatchReordsRoot,
  state => state.loading === LoadingState.INIT
);
export const isBatchRecordFetching = createSelector(
  getBatchReordsRoot,
  state => state.loading === LoadingState.FETCHING
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isBatchRecordInitialised = _state => true;

export const getBatchRecords = createSelector(getBatchReordsRoot, state => state.items);
export const getBatchRecordsActivity = state => state.pilot.batchRecords.activity;
export const getCurrentBatchRecordId = state => state.pilot.batchRecords.current;

export const getCurrentBatchRecord = createSelector(
  getBatchRecords,
  getCurrentBatchRecordId,
  (records, currentId) => records.find(record => record.id === currentId)
);

export const getTotalCount = createSelector(getBatchReordsRoot, root => root.totalCount);

export const getDepositionAssets = (state, method: DepositionMethod) =>
  state.pilot.batchRecords.depositionAssets.filter(asset => asset.associatedMethod === method);

export const getChemistryDefinitions = state => state.pilot.batchRecords.chemistryDefinitions;

export const getCurrentBatchRecordActivity = createSelector(
  getBatchRecordsActivity,
  getCurrentBatchRecordId,
  (records, currentId) => records.find(record => record.id === currentId)
);
