import { apiPost, apiPatch } from 'modules/Core/actions';

export enum RecordAssemblyActions {
  CALL = 'pilot/batch-records/RECORD_ASSEMBLY',
  REQUEST = 'pilot/batch-records/RECORD_ASSEMBLY_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_ASSEMBLY_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_ASSEMBLY_FAILURE',
}

export enum AmendAssemblyActions {
  CALL = 'pilot/batch-records/AMEND_ASSEMBLY',
  REQUEST = 'pilot/batch-records/AMEND_ASSEMBLY_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_ASSEMBLY_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_ASSEMBLY_FAILURE',
}

export enum VerifyAssemblyActions {
  CALL = 'pilot/batch-records/VERIFY_ASSEMBLY',
  REQUEST = 'pilot/batch-records/VERIFY_ASSEMBLY_REQUEST',
  SUCCESS = 'pilot/batch-records/VERIFY_ASSEMBLY_SUCCESS',
  FAILURE = 'pilot/batch-records/VERIFY_ASSEMBLY_FAILURE',
}

export type AssemblyPayload = {
  splits: number[];
  lasers: number[];
};

export type AmendAssemblyPayload = { subRecordId?: string; comment?: string } & AssemblyPayload;

export const recordAssembly = (recordId: string, payload: AssemblyPayload) =>
  apiPost(`/pilot/batch-records/${recordId}/preparation/assembly`, RecordAssemblyActions.CALL, {
    ...payload,
  });

export const amendAssembly = (recordId: string, payload: AmendAssemblyPayload) =>
  apiPatch(`/pilot/batch-records/${recordId}/preparation/assembly`, AmendAssemblyActions.CALL, {
    ...payload,
  });

export const verifyAssembly = (recordId: string, subRecordId: string) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/preparation/assembly/verify`,
    VerifyAssemblyActions.CALL,
    {
      subRecordId,
    }
  );
