import React from 'react';
import { useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Counter } from 'mdi-material-ui';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { FormDialog } from 'modules/Common/components';
import { openDialog } from 'modules/Core/actions/dialogs';
import { PartStatus, SectionStatus } from 'modules/RecordManagement';
import useStyles from './SubAssemblyRejects.styles';
import useFormDefinition from './SubAssemblyRejects.form';
import SectionPart from '../../SectionPart';

type SubAssemblyRejectsProps = {
  batchRecord: BatchRecord;
};

export default ({ batchRecord }: SubAssemblyRejectsProps) => {
  const dispatch = useDispatch();
  const formDefintion = useFormDefinition(batchRecord);
  const { rejectsByReason, totalRejects } = batchRecord.preparation.subAssemblyRejects;
  const classes = useStyles();

  return (
    <SectionPart
      xs={6}
      title="Sub Assembly Rejects"
      status={
        batchRecord.preparation.status === SectionStatus.Locked
          ? PartStatus.Locked
          : PartStatus.Ignore
      }
      actionChip={{
        label: 'Update',
        onChipClicked: () => dispatch(openDialog(formDefintion.dialogName)),
        icon: <Counter />,
        visible:
          batchRecord.preparation.status !== SectionStatus.Locked &&
          batchRecord.preparation.status !== SectionStatus.ReadOnly,
      }}
    >
      <Table stickyHeader size="small" className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell>Reject Description</TableCell>
            <TableCell>Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rejectsByReason && (
            <>
              {rejectsByReason.map(rejects => (
                <TableRow key={rejects.id}>
                  <TableCell className="rowHeader" scope="row" variant="head" component="th">
                    {rejects.code}. {rejects.description}
                  </TableCell>
                  <TableCell>{rejects.count}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  align="right"
                  className="footerTotal"
                  scope="row"
                  variant="head"
                  component="th"
                >
                  TOTAL:
                </TableCell>
                <TableCell>{totalRejects}</TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
      <FormDialog definition={formDefintion} />
    </SectionPart>
  );
};
