import React from 'react';
import startCase from 'lodash/startCase';
import { Typography } from '@material-ui/core';
import {
  Check,
  Close,
  ProgressQuestion,
  ProgressWrench,
  AccountClockOutline,
  AccountCheck,
} from 'mdi-material-ui';

import PassFailUnableToTest from 'modules/InstrumentsQualityControl/models/PassFailUnableToTest';
import InstrumentTestStatus from 'modules/InstrumentsQualityControl/models/InstrumentTestStatus';
import InstrumentTestOutcome from 'modules/InstrumentsQualityControl/models/InstrumentTestOutcome';

import useStyles from './InstrumentResult.styles';

const InstrumentResult = ({
  value,
  hideTitle = false,
  children,
}: {
  value?: PassFailUnableToTest | InstrumentTestStatus | InstrumentTestOutcome | string;
  hideTitle?: boolean;
  children?: React.ReactNode;
}) => {
  const classes = useStyles();
  return (
    <Typography variant="body1" component="span" className={classes.result}>
      {((!value && value?.toString() !== 'false') ||
        value === InstrumentTestStatus.Pending ||
        value === 'Draft' ||
        value === 'AwaitingAnalysis') && (
        <span className="result-none">
          <ProgressQuestion style={{ verticalAlign: 'middle' }} />
          {!hideTitle && <> {startCase(value)}</>}
          {children}
        </span>
      )}
      {value === InstrumentTestStatus.InProgress && (
        <span className="result-in-progress">
          <ProgressWrench style={{ verticalAlign: 'middle' }} />
          {!hideTitle && <> In Progress</>}
          {children}
        </span>
      )}
      {(value === PassFailUnableToTest.Fail ||
        value === InstrumentTestOutcome.Fail ||
        value === PassFailUnableToTest.UnableToTest ||
        value === 'Quarantined' ||
        value === 'Rejected' ||
        value?.toString() === 'false') && (
        <span className="result-fail">
          <Close style={{ verticalAlign: 'middle' }} />
          {!hideTitle && <> {value}</>}
          {children}
        </span>
      )}
      {(value === PassFailUnableToTest.Pass ||
        value === InstrumentTestOutcome.Pass ||
        value === 'Approved' ||
        value?.toString() === 'true') && (
        <span className="result-pass">
          <Check style={{ verticalAlign: 'middle' }} />
          {!hideTitle && <> {value}</>}
          {children}
        </span>
      )}
      {value === InstrumentTestStatus.ReadyForReview && (
        <span className="result-ready-for-review">
          <AccountClockOutline style={{ verticalAlign: 'middle' }} />
          {!hideTitle && <> Ready For Review</>}
          {children}
        </span>
      )}
      {value === InstrumentTestStatus.SignedOff && (
        <span className="result-signed-off">
          <AccountCheck style={{ verticalAlign: 'middle' }} />
          {!hideTitle && <> Signed Off</>}
          {children}
        </span>
      )}
      {value === InstrumentTestStatus.CorrectionsRequired && (
        <span className="result-corrections-required">
          <ProgressQuestion style={{ verticalAlign: 'middle' }} />
          {!hideTitle && <> Corrections Required</>}
          {children}
        </span>
      )}
      {value === InstrumentTestStatus.Reviewed && (
        <span className="result-ready-for-review">
          <ProgressQuestion style={{ verticalAlign: 'middle' }} />
          {!hideTitle && <> Reviewed</>}
          {children}
        </span>
      )}
    </Typography>
  );
};

export default InstrumentResult;
