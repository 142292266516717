import { createAction } from 'redux-api-middleware';
import * as actionTypes from './actionTypes';

const resolveRepairRequest = (repairRequest, payload) => {
  return createAction({
    endpoint: `/instruments/repairs/${repairRequest.id}/resolve`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.RESOLVE_REPAIR_REQUEST,
      actionTypes.RESOLVE_REPAIR_SUCCESS,
      actionTypes.RESOLVE_REPAIR_FAILURE,
    ],
    body: JSON.stringify({
      resolutionOutcome: payload.resolutionOutcome,
      comments: payload.comments,
    }),
  });
};

export default resolveRepairRequest;
