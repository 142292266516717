import { Section, SubRecordPart, SigningAction, Part, SubRecord } from 'modules/RecordManagement';
import { DepositionMethod, ChemistryDefinition } from './common';

export type DepositionSection = Section & {
  foilPouches: SubRecordPart<PouchPreparation>;
  reagent: Reagent;
  blockAid: BlockAid;
  depositions: SubRecordPart<DepositionSubRecord>;
};

export type DepositionSubRecord = SubRecord & {
  depositionMethod: DepositionMethod;
  assetNumber: string;
  splits: number[];
  channels: number[];
  zone: '' | 'A' | 'B' | 'C';
  chemistry: ChemistryDefinition;
  volume: number;
  lidOrBase: LidOrBase;
  addedBy: SigningAction;
  verifiedBy: SigningAction;
};

export type Reagent = Part & {
  number?: string;
  recordedBy?: SigningAction;
};

export type BlockAid = Part & {
  number?: string;
  recordedBy?: SigningAction;
};

export type PouchPreparation = SubRecord & {
  lotNumber: string;
  preparedBy: SigningAction;
};

export enum LidOrBase {
  NA = 'NA',
  Lid = 'Lid',
  Base = 'Base',
}
