/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import { TestSessionDto, TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import UpdateVisualInspectionToInstrumentInput from 'modules/InstrumentsQualityControl/models/UpdateVisualInspectionToInstrumentInput';
import ReworkRequriedWidget from '../widgets/ReworkRequriedWidget';
import ConditionalField from '../widgets/ConditionalField';
import InstrumentResultWidget from '../widgets/InstrumentResultWidget';
import CaseField from '../widgets/CaseField';
import TextDisplayWidget from '../widgets/ReviewField';
import StringReviewField from '../widgets/StringReviewField';
import RubberFeetWidget from '../widgets/RubberFeetWidget';

import { formatBool } from '../utils';
import AlertWidget from '../widgets/AlertWidget';

function noop<T>(id: T) {
  return id;
}

function configureYesNoNotApplicableInput(item) {
  const { title } = item;
  delete item.title;

  item.properties.value.title = title;
  item.properties.notApplicableComment.title = `${title} Not Applicable Comment`;
}

export const schema = sch => {
  sch.properties.submitComment = {
    type: 'string',
    title: 'Comment',
  };
  sch.properties.flexReworkInstrument.type = 'string';
  sch.properties.flexReworkInstrument.type = 'string';
  sch.properties.qualityEventRequired.type = 'string';

  configureYesNoNotApplicableInput(sch.properties.softwareUploadRequired);
  configureYesNoNotApplicableInput(sch.properties.currentSoftwareNowInstalled);

  return sch;
};

function configureDoorUi() {
  const conditionalField = {
    'ui:field': ConditionalField,
    predicate: values =>
      values.door.doesTheDoorOpenSmoothlyWithoutObstructionAndDislocation?.toString() === 'false' ||
      values.door.doesTheDoorCloseSmoothlyWithoutObstructionAndDislocationAndCanSelfClose?.toString() ===
        'false',
  };
  return {
    'ui:grid:xs': 6,
    'ui:card': true,

    doesTheDoorOpenSmoothlyWithoutObstructionAndDislocation: {
      'ui:widget': TextDisplayWidget,
    },
    doesTheDoorCloseSmoothlyWithoutObstructionAndDislocationAndCanSelfClose: {
      'ui:widget': TextDisplayWidget,
    },
    reworkRequired: {
      'ui:widget': ReworkRequriedWidget,
      ...conditionalField,
    },
    reworkCarriedOutBy: {
      'ui:widget': TextDisplayWidget,
      'ui:options': {
        select: ({ all }) => all,
      },
      ...conditionalField,
    },
    verifiedBy: {
      'ui:widget': TextDisplayWidget,
      'ui:options': {
        select: ({ all }) => all,
      },
      ...conditionalField,
    },
    theDoorShouldOpenAndCloseSmoothlyWithoutDislocatingAndAcceptableGap: {
      'ui:widget': TextDisplayWidget,
    },
    theDoorShouldNotBeLooseOrDifficultToOpenClose: {
      'ui:widget': TextDisplayWidget,
    },
  };
}

function configureRubberFeetUi() {
  const conditionalField = {
    'ui:field': ConditionalField,
    predicate: values =>
      values.rubberFeet.areAllFourFeetFirmlyAttachedToTheInstrumentCasing?.toString() === 'false',
  };
  return {
    'ui:grid:xs': 6,
    'ui:card': true,
    areAllFourFeetFirmlyAttachedToTheInstrumentCasing: {
      'ui:widget': TextDisplayWidget,
    },
    ifFeetAreLoosePleaseIndicateWhichFeet: {
      'ui:widget': RubberFeetWidget,
      'ui:options': {
        readOnly: true,
      },
      ...conditionalField,
    },
    reworkRequired: {
      'ui:widget': TextDisplayWidget,
      ...conditionalField,
    },
    reworkCarriedOutBy: {
      'ui:widget': TextDisplayWidget,
      'ui:options': {
        select: ({ all }) => all,
      },
      ...conditionalField,
    },
    verifiedBy: {
      'ui:widget': TextDisplayWidget,
      'ui:options': {
        select: ({ all }) => all,
      },
      ...conditionalField,
    },
    allFourRubberFeetShouldBeFirmlyAttachedToTheInstrumentCasing: {
      'ui:widget': TextDisplayWidget,
    },
    theInstrumentShouldBeLevelWhenPlacedOnAStableLevelSurface: {
      'ui:widget': TextDisplayWidget,
    },
  };
}
export const uiSchema = ui => {
  ui['ui:order'] = [
    'submitComment',
    'serialNumberVerifiedAgainstLabel',
    'visualInspectionOutcome',
    'visualInspectionStatus',
    'goodsReceivedNumber',
    'flexReworkInstrument',
    'detailsVerifiedBy',
    'currentReleasedSoftware',
    'softwareVersionInstalledOnInstrument',
    'softwareUploadRequired',
    'currentSoftwareNowInstalled',
    'carriedOutBy',
    'case',
    'labels',
    'door',
    'screen',
    'rubberFeet',
    'stripDetectionAndNfcOperation',
    '*',
  ];

  ui.submitComment = {
    'ui:field': ConditionalField,
    predicate: data => !!data.submitComment,
    'ui:widget': AlertWidget,
    'ui:options': {
      severity: 'warning',
    },
  };

  ui.instrumentId = {
    'ui:widget': 'hidden',
  };
  ui.serialNumberVerifiedAgainstLabel = {
    'ui:widget': TextDisplayWidget,
    'ui:grid:xs': 6,
  };

  ui.visualInspectionStatus = {
    'ui:widget': 'hidden',
    'ui:field': InstrumentResultWidget,
    'ui:grid:xs': 6,
  };

  ui.goodsReceivedNumber = {
    'ui:widget': TextDisplayWidget,
    'ui:options': {
      select: (current: TestSessionDto) => current.instruments?.map(x => x.visualInspection),
    },
    'ui:grid:xs': 6,
    format: value => value,
  };

  ui.visualInspectionOutcome = {
    'ui:field': TextDisplayWidget,
    'ui:grid:xs': 6,
  };

  ui.flexReworkInstrument = {
    'ui:grid:xs': 6,
    format: formatBool,
    'ui:field': TextDisplayWidget,
  };

  ui.detailsVerifiedBy = {
    'ui:widget': TextDisplayWidget,
    'ui:options': {
      select: ({ all }) => all,
    },
  };
  ui.currentReleasedSoftware = {
    'ui:grid:xs': 6,
    'ui:widget': TextDisplayWidget,
    format: value => value,
  };
  ui.softwareVersionInstalledOnInstrument = {
    'ui:grid:xs': 6,
    'ui:widget': TextDisplayWidget,
    format: value => value,
  };
  ui.softwareUploadRequired = {
    'ui:title': 'Software Upload Required',
    format: value => value,
    'ui:collapse': true,
    value: { 'ui:widget': TextDisplayWidget, format: formatBool },
    notApplicableComment: {
      'ui:field': ConditionalField,
      'ui:widget': TextDisplayWidget,
      predicate: values => values.softwareUploadRequired.value === 'NotApplicable',
      format: value => value,
    },
  };
  ui.currentSoftwareNowInstalled = {
    'ui:field': ConditionalField,
    'ui:collapse': true,
    'ui:widget': TextDisplayWidget,
    predicate: values => values.softwareUploadRequired.value === 'Yes',
    value: {
      predicate: values => values.softwareUploadRequired.value === 'Yes',
      'ui:field': ConditionalField,
      'ui:widget': TextDisplayWidget,
      format: formatBool,
    },
    notApplicableComment: {
      'ui:field': ConditionalField,
      'ui:widget': TextDisplayWidget,
      predicate: values => values.currentSoftwareNowInstalled.value === 'NotApplicable',
    },
  };
  ui.carriedOutBy = {
    'ui:field': ConditionalField,
    predicate: values => values.softwareUploadRequired.value === 'Yes',
    'ui:widget': TextDisplayWidget,
    'ui:options': {
      select: ({ current }) => current,
    },
  };
  ui.case = {
    'ui:field': CaseField,
    'ui:grid:xs': 6,
    'ui:card': true,
    instrumentCasingShouldBeInspectedForMajorDefects: {
      'ui:widget': TextDisplayWidget,
    },
    theWhiteTopCasingShouldBeSecurelyAndEvenlyAttachedToTheBlackBaseUnit: {
      'ui:widget': TextDisplayWidget,
    },
  };

  const caseDisable = {
    'ui:disable': values =>
      values.case?.instrumentCasingShouldBeInspectedForMajorDefects?.toString() === 'false' ||
      values.case?.theWhiteTopCasingShouldBeSecurelyAndEvenlyAttachedToTheBlackBaseUnit?.toString() ===
        'false',
  };
  ui.door = {
    ...configureDoorUi(),
    ...caseDisable,
  };
  ui.screen = {
    ...caseDisable,
    'ui:grid:xs': 6,
    'ui:card': true,
    theScreenShouldNotBeCrackedScratchedOrChipped: {
      'ui:widget': TextDisplayWidget,
    },
    theScreenShouldFitFlushWithTheInstrumentCasing: {
      'ui:widget': TextDisplayWidget,
    },
    whenTheInstrumentIsPoweredOnTheScreenShouldProduceAColourDisplayWithNoObviousDefects: {
      'ui:widget': TextDisplayWidget,
    },
    whenTheInstrumentIsPoweredOnTheScreenShouldProvideTouchscreenFunctionality: {
      'ui:widget': TextDisplayWidget,
    },
  };

  ui.rubberFeet = {
    ...caseDisable,
    ...configureRubberFeetUi(),
  };
  ui.labels = {
    ...caseDisable,
    'ui:grid:xs': 6,
    'ui:card': true,
    serialNumberOnLabelMatchesSerialNumberInAboutScreen: {
      'ui:widget': TextDisplayWidget,
    },
    theLumiradxLogoShouldBeVisibleOnTheTopSideOfTheInstrumentBetweenTheScreenAndTheDoor: {
      'ui:widget': TextDisplayWidget,
    },
    theNfcIconShouldBeVisibleOnTheRightHandSideOfTheInstrument: {
      'ui:widget': TextDisplayWidget,
    },
    labelShouldBeFreeFromPrintDefectsAndFormatAndContentShouldMatchThatSpecifiedInTheCurrentRevision: {
      'ui:widget': TextDisplayWidget,
    },
  };
  ui.stripDetectionAndNfcOperation = {
    ...caseDisable,
    'ui:card': true,
    'ui:grid:xs': 6,
    instrumentDetectsStrips: {
      'ui:widget': TextDisplayWidget,
    },
    instrumentRecognisesRfidTag: {
      'ui:widget': TextDisplayWidget,
    },
    instrumentSuccessfullyUploadsLotCalibrationFiles: {
      'ui:widget': TextDisplayWidget,
    },
  };
  ui.plaqueCalibration = {
    ...caseDisable,
    'ui:card': true,
    calibrationValue: {
      'ui:grid:xs': 4,
      'ui:widget': TextDisplayWidget,
      format: value => value,
    },
    meanDifferenceValue: {
      'ui:grid:xs': 4,
      'ui:widget': TextDisplayWidget,
      format: value => value,
    },
    biasValue: {
      'ui:grid:xs': 4,
      'ui:widget': TextDisplayWidget,
      format: value => value,
    },
  };
  ui.qualityEventRequired = {
    ...caseDisable,
    format: formatBool,
    'ui:widget': TextDisplayWidget,
  };
  ui.qualityEventNumber = {
    ...caseDisable,
    'ui:widget': TextDisplayWidget,
    'ui:field': ConditionalField,
    predicate: values => values?.qualityEventRequired?.toString() === 'true',
    format: value => value,
  };

  ui.plannedDeviationNumbers = {
    'ui:field': ConditionalField,
    predicate: values => values?.plannedDeviationNumbers?.length,
    'ui:options': {
      addable: false,
      removable: false,
      orderable: false,
    },
    items: {
      'ui:widget': StringReviewField,
    },
  };
  return ui;
};

export const mapToEdit = () => (
  current: TestSessionInstrumentDto
): UpdateVisualInspectionToInstrumentInput => {
  const inspection = current?.visualInspection || {};
  return {
    ...inspection,
    instrumentId: current?.id,
    review: {},
  } as UpdateVisualInspectionToInstrumentInput;
};

export const fields = noop;

export const widgets = noop;

export const validation = () => yup.object({});

export const empty = noop;
