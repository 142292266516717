import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useParams } from 'react-router';
import { Card, CircularProgress, Fade } from '@material-ui/core';
import {
  InstrumentSummary,
  InstrumentTabs,
  InstrumentSubsystems,
  InstrumentRepairs,
  InstrumentEolTests,
  InstrumentDeliveryRecords,
  InstrumentRmas,
  InstrumentHistory,
} from 'modules/Instruments/slices/Management/components';
import { actionCreators as repairActionCreators } from 'modules/Instruments/slices/Repair/actions';
import { setCurrentInstrument } from 'modules/Instruments/slices/Management/actions';
import { selectors } from 'modules/Instruments/slices/Management/reducers';
import { getRepairRequestsForInstrument } from 'modules/Instruments/slices/Repair/reducers';
import { ContentHeader, Spinner } from 'modules/Common';
import { useEffectOnce } from 'react-use';

const InstrumentPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const { instrumentId } = useParams();

  useEffectOnce(() => {
    dispatch(setCurrentInstrument(instrumentId));
  });

  const instrument = useSelector(selectors.getCurrentInstrument);
  const isLoading = useSelector(selectors.getIsLoading);
  const repairRequests = useSelector(state => getRepairRequestsForInstrument(state, instrument));

  const handleTabChanged = (event, newTab) => {
    setSelectedTab(newTab);
  };

  const handleRepairRequestSelected = requestId => {
    dispatch(repairActionCreators.setCurrentRepairRequest(requestId));
    dispatch(push(`/instruments/repair/${requestId}`));
  };

  if ((!instrument && isLoading) || !instrument) return <Spinner />;

  return (
    <Fade in={instrument}>
      <div>
        <ContentHeader
          title={
            <>
              Instrument {instrument.serial}{' '}
              {isLoading ? <CircularProgress size={20} style={{ marginRight: '4px' }} /> : ''}
            </>
          }
        />
        <Card>
          <InstrumentSummary instrument={instrument} />
          <InstrumentTabs selectedTab={selectedTab} handleTabChanged={handleTabChanged} />
          {selectedTab === 0 && (
            <InstrumentSubsystems isLoading={isLoading} instrument={instrument} isActive />
          )}
          {selectedTab === 1 && (
            <InstrumentRepairs
              isActive
              repairRequests={repairRequests}
              handleRepairRequestSelected={handleRepairRequestSelected}
            />
          )}
          {selectedTab === 2 && (
            <InstrumentEolTests isActive isLoading={isLoading} instrument={instrument} />
          )}
          {selectedTab === 3 && (
            <InstrumentDeliveryRecords isActive isLoading={isLoading} instrument={instrument} />
          )}
          {selectedTab === 4 && (
            <InstrumentRmas isActive isLoading={isLoading} instrument={instrument} />
          )}
          {selectedTab === 5 && (
            <InstrumentHistory isActive isLoading={isLoading} instrument={instrument} />
          )}
        </Card>
      </div>
    </Fade>
  );
};

export default InstrumentPage;
