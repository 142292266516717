import React from 'react';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import AddConfigurationOptionToTestSession from '../AddConfigurationOptionToTestSession';
import { TestConfigurationOptionsContextType } from '../../TestConfigurationOptionsContextType';
import ContentHeader from './ContentHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
    actionButtons: {
      flex: '0 0 auto',
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& > :not(:first-child)': {
        marginLeft: '8px',
      },
    },
    contentHeader: {
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  })
);

export function TestSessionConfigurationOptions<T>({
  all,
  current,
  form,
  onSubmit,
  onClose,
  onOpen,
  formOpen,
  children,
  select,
  title,
  buttonText,
  formatName,
  readOnly,
  Component = AddConfigurationOptionToTestSession,
}: {
  children: (child: T[]) => React.ReactNode;
  title: string;
  buttonText: string;
  formatName?: (data: T) => string;
  readOnly: boolean;
  Component?: React.ReactElement;
} & TestConfigurationOptionsContextType<T>) {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.contentHeader}>
          <ContentHeader
            title={title}
            withNewItem={!readOnly}
            newItemButtonText={buttonText}
            newItemButtonOnClick={onOpen}
          />
        </div>
        {children(current)}
      </Paper>

      {formOpen && (
        <Component
          onFormSubmit={onSubmit}
          form={form}
          onFormClose={onClose}
          formOpen={formOpen}
          items={all}
          current={current}
          select={select}
          formatName={formatName}
        />
      )}
    </>
  );
}

export default TestSessionConfigurationOptions;
