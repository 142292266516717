import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .MuiTableCell-head': {
      fontFamily: 'ITCAvantGardeStd-Bk',
      fontWeight: 'bold',
    },
    '& .MuiTableRow-root:nth-of-type(2n+1):not(.MuiTableRow-footer)': {
      backgroundColor: 'inherit',
    },
    '& .MuiTableRow-root:hover:not(.MuiTableRow-footer)': {
      '&:hover': {
        backgroundColor: 'inherit',
        cursor: 'default',
      },
    },
    '& .rowHeader': {
      backgroundColor: theme.palette.background.default,
    },
    '& .footerTotal': {
      backgroundColor: theme.palette.secondary.dark,
      fontWeight: 'bold',
      color: theme.palette.primary.contrastText,
    },
  },
}));
