/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './addMaterialToVisualInspectionInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      materialId: {
        type: 'string',
        title: 'Material Id',
      },
      lotNumber: {
        type: 'string',
        title: 'Lot Number',
      },
      expiryDate: {
        type: 'string',
        title: 'Expiry Date',
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  materialId: '',
  lotNumber: '',
  expiryDate: '',
});
