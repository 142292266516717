import React from 'react';
import { SummaryPanel, SummaryPanelItem } from 'modules/Common';
import { SummaryPanelItemProps } from 'modules/Common/components/SummaryPanel/SummaryPanelItem/SummaryPanelItem';

const TestSessionSummary = ({ items }: { items?: SummaryPanelItemProps[] }) => {
  return (
    <SummaryPanel withCard>
      {items &&
        items.map(item => (
          <SummaryPanelItem key={item.key} primary={item.primary} secondary={item.secondary} />
        ))}
    </SummaryPanel>
  );
};

export default TestSessionSummary;
