import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { Clear, Check, FiberManualRecord } from '@material-ui/icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './FunctionalTestList.styles';

const FunctionalTestList = ({ functionalTests, onTestSelected }) => {
  const classes = makeStyles(styles)();

  const handleTestClick = (event, index) => {
    onTestSelected(index);
  };

  const pass = { label: 'Passed', icon: <Check />, class: classes.passed };
  const fail = { label: 'Failed', icon: <Clear />, class: classes.failed };
  const na = { label: 'N/A', icon: <FiberManualRecord />, class: classes.na };

  const getOverallResult = result => {
    if (result === 'Pass') return pass;
    if (result === 'Fail') return fail;

    return na;
  };

  return (
    <>
      <List className={classes.root} dense component="nav">
        {functionalTests.map((test, index) => (
          <Fragment key={test.id}>
            <ListItem
              button
              selected={test.selected}
              className={getOverallResult(test.overallResult).class}
              onClick={event => handleTestClick(event, index)}
            >
              <ListItemIcon className={classes.resultIcon}>
                {getOverallResult(test.overallResult).icon}
              </ListItemIcon>
              <ListItemText
                primary={getOverallResult(test.overallResult).label}
                secondary={moment(test.testingCompletedAt).format('DD/MM/YYYY, h:mm:ss a')}
              />
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </>
  );
};

FunctionalTestList.propTypes = {
  functionalTests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      overallResult: PropTypes.string.isRequired,
      testingCompletedAt: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onTestSelected: PropTypes.func.isRequired,
};

export default FunctionalTestList;
