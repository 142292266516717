import { createSelector } from 'reselect';
import { Instrument } from 'modules/Instruments/domain';
import { DefaultRootState } from 'react-redux';
import { createLoadingSelector } from 'modules/Core/reducers';
import { FetchRepairsForInstrumentActionTypes } from 'modules/Instruments/slices/Repair/actions/actionTypes';
import {
  FetchInstrumentActionTypes,
  FetchInstrumentsActionTypes,
  RegisterInstrumentActionTypes,
  UpdateInstrumentActionTypes,
  FetchInstrumentBySerialActionTypes,
} from '../actions/actionTypes';

export const getSubsystems = state => state.instruments.repairRequests.subsystems;

export const getTypeForInstrument = (state, instrument) =>
  state.instruments.repairRequests.types.find(
    type => type.definitionId === instrument.type?.definitionId
  );

export const getFamilyForInstrument = (state, instrument) =>
  state.instruments.repairRequests.families.find(
    family => family.definitionId === instrument.type?.platform
  );

export const getSubsystemsForInstrument = createSelector(
  getSubsystems,
  (_: DefaultRootState, instrument?: Instrument) => instrument,
  (subsystems, instrument) =>
    subsystems.filter(subsystem => subsystem.platform === instrument?.type?.platform)
);

export const getInstruments = state => state.instruments.management.items;

export const getInstrument = (state, instrumentId) => {
  const instrument = state.instruments.management.items.find(ins => ins.id === instrumentId);
  if (!instrument) return null;

  return {
    ...instrument,
    type: {
      ...instrument.type,
      family: getFamilyForInstrument(state, instrument),
      subsystems: getSubsystemsForInstrument(state, instrument),
    },
  };
};

export const getCurrentInstrument = state =>
  getInstrument(state, state.instruments.management.current);

export const getInstrumentBySerial = createSelector(
  getInstruments,
  (_: DefaultRootState, serialNumber: string | undefined) => serialNumber,
  (instruments, serialNumber) => instruments.find(ins => ins.serial === serialNumber)
);

export const getIsLoading = (state: DefaultRootState) =>
  createLoadingSelector([
    UpdateInstrumentActionTypes.UPDATE_INSTRUMENT,
    FetchInstrumentActionTypes.FETCH_INSTRUMENT,
    FetchInstrumentsActionTypes.FETCH_INSTRUMENTS,
    RegisterInstrumentActionTypes.REGISTER_INSTRUMENT,
    FetchInstrumentBySerialActionTypes.FETCH_INSTRUMENT_BY_SERIAL,
    FetchRepairsForInstrumentActionTypes.FETCH_REPAIRS_FOR_INSTRUMENT,
  ])(state);
