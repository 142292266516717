import registerNewInstrumentAsync from './registerNewInstrumentAsync';
import getInstrumentsAsync from './getInstrumentsAsync';
import getInstrumentAsync from './getInstrumentAsync';
import getInstrumentHistoryAsync from './getInstrumentHistoryAsync';
import getInstrumentBySerialAsync from './getInstrumentBySerialAsync';
import searchInstrumentBySerialAsync from './searchInstrumentBySerialAsync';
import searchInstrumentsAsync from './searchInstrumentsAsync';
import updateInstrumentAsync from './updateInstrumentAsync';
// imports

export default apiUrl => ({
  registerNewInstrumentAsync: registerNewInstrumentAsync(apiUrl),
  getInstrumentsAsync: getInstrumentsAsync(apiUrl),
  getInstrumentAsync: getInstrumentAsync(apiUrl),
  getInstrumentHistoryAsync: getInstrumentHistoryAsync(apiUrl),
  getInstrumentBySerialAsync: getInstrumentBySerialAsync(apiUrl),
  searchInstrumentBySerialAsync: searchInstrumentBySerialAsync(apiUrl),
  updateInstrumentAsync: updateInstrumentAsync(apiUrl),
  searchInstrumentsAsync: searchInstrumentsAsync(apiUrl),
  // exports
});
