import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Fade } from '@material-ui/core';
import { InstrumentSerial } from 'modules/Instruments/domain';
import { selectors } from 'modules/Instruments/slices/Management/reducers';
import { selectors as repairSelectors } from 'modules/Instruments/slices/Repair/reducers';
import InstrumentForm from 'modules/Instruments/slices/Management/components/InstrumentForm';
import { closeRequestForm } from 'modules/Instruments/slices/Repair/actions/actionCreators';
import RepairRequestForm from 'modules/Instruments/slices/Repair/components/RepairRequestForm';
import useSerialSearch from 'modules/Instruments/slices/Management/search/useSerialSearch';
import { RepairRequestState } from '../../reducers/types';

type RepairRequestDialogProps = {
  show: boolean;
};

const RepairRequestDialog = ({ show }: RepairRequestDialogProps) => {
  const { search, result } = useSerialSearch();

  const [serial, setSerial] = useState<InstrumentSerial>();
  const [instrumentSelected, setInstrumentSelected] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setInstrumentSelected(false);
    dispatch(closeRequestForm());
  };

  const handleSerialChanged = newSerial => {
    setSerial(newSerial);
    search(newSerial.full);
  };

  const handleInstrumentComplete = () => setInstrumentSelected(true);

  const editMode = useSelector(repairSelectors.isRequestFormInEditMode);
  const instrument = useSelector(state => selectors.getInstrumentBySerial(state, serial?.full));
  const repairRequest = useSelector(state =>
    repairSelectors.getCurrentRepairRequest(state as RepairRequestState)
  );
  const showRequestForm = editMode || instrumentSelected;
  if (editMode && !repairRequest) return <>Something went wrong</>;

  return (
    <>
      <Dialog open={show} onClose={handleClose} fullWidth>
        <DialogTitle>
          {editMode ? `Editing ${repairRequest?.irn}` : 'New Repair Request'}
        </DialogTitle>
        <DialogContent>
          <Fade in={!editMode && !instrumentSelected}>
            <div>
              {!editMode && !instrumentSelected && (
                <InstrumentForm
                  mode="select"
                  searchResult={result}
                  instrument={instrument}
                  onComplete={handleInstrumentComplete}
                  onCancel={handleClose}
                  onInstrumentSerialChanged={handleSerialChanged}
                />
              )}
            </div>
          </Fade>
          <Fade in={showRequestForm}>
            <div>
              {showRequestForm && (
                <RepairRequestForm
                  instrument={editMode ? repairRequest?.instrument : instrument}
                  repairRequest={repairRequest}
                  editMode={editMode}
                  onCancel={handleClose}
                />
              )}
            </div>
          </Fade>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RepairRequestDialog;
