/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import produce from 'immer';
import { FeedbackFieldTemplate } from '@ldx-dmp/Common/components';
import { TestSessionDto, TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import * as form from '../forms/VisualInspection/updateVisualInspectionToInstrumentInputFormSchema';
import * as gridForm from '../forms/VisualInspection/updateVisualInspectionToInstrumentInputFormSchema.grid.overrides';
import * as reviewForm from '../forms/VisualInspection/reviewVisualInspectionToInstrumentInputFormSchema.overrides';
import * as signedOffForm from '../forms/VisualInspection/signedOffVisualInspectionToInstrumentInputFormSchema.overrides';
import useInstrumentTest from './useInstrumentTest';
import usePermissionStates from './usePermissionStates';

export default function useVisualInspectionTest(
  current: TestSessionDto,
  setCurrent: any,
  fetchCurrent: any,
  initialInstrument?: TestSessionInstrumentDto
) {
  const [showSignOff, setShowSignOff] = React.useState(false);
  const [showCorrections, setShowCorrections] = React.useState(false);
  const [hideForm, setHideForm] = React.useState(() => {});
  const [setInstrument, setSetInstrument] = React.useState(() => {});
  const [test, setTest] = React.useState<TestSessionInstrumentDto | undefined>();

  const onReview = React.useCallback((t, hide, set) => {
    setHideForm(() => value => {
      hide(value);
    });
    setSetInstrument(() => (value, sess) => set(value, sess));
    setShowSignOff(true);
    setTest(t);
  }, []);

  const readyForReviewState = React.useMemo(
    () => ({
      form: () => ({
        ...form,
        schema: produce(form.schema, reviewForm.schema),
        validation: reviewForm.validation(),
        mapToEdit: reviewForm.mapToEdit(),
        uiSchema: reviewForm.uiSchema({}),
      }),
    }),
    []
  );

  const signedOffState = React.useMemo(
    () => ({
      form: () => ({
        ...form,
        schema: produce(form.schema, signedOffForm.schema),
        validation: reviewForm.validation(),
        mapToEdit: signedOffForm.mapToEdit(),
        uiSchema: signedOffForm.uiSchema({}),
      }),
    }),
    []
  );

  const inProgressState = React.useMemo(
    () => ({
      form: () => ({
        ...form,
        uiSchema: gridForm.uiSchema({}),
      }),
    }),
    []
  );

  const options = React.useMemo(
    () => ({
      showSignOff,
      setShowSignOff,
      showCorrections,
      setShowCorrections,
      toggleForm: hideForm,
      setHideForm,
      setInstrument,
      setSetInstrument,
      test,
      setTest,

      onReview,

      states: {
        Pending: {
          ...inProgressState,
          autoSaveEnabled: true,
        },
        InProgress: {
          ...inProgressState,
          autoSaveEnabled: true,
        },
        ReadyForReview: {
          ...readyForReviewState,
          autoSaveEnabled: false,
          buttonText: 'Submit Review',
        },
        SignedOff: {
          ...signedOffState,
          hideButton: true,
          autoSaveEnabled: false,
        },
        ReadOnly: {
          ...signedOffState,
          hideButton: true,
          autoSaveEnabled: false,
          name: 'ReadOnly',
        },
        CorrectionsRequired: {
          ...inProgressState,
          autoSaveEnabled: true,
          fieldTemplate: FeedbackFieldTemplate,
          onSubmit: (data, t, hide, set) => {
            setHideForm(() => value => {
              hide(value);
            });
            setSetInstrument(() => (value, sess) => set(value, sess));
            setTest({
              ...t,
              visualInspection: {
                ...t.visualInspection,
                ...data,
              },
            });
            setShowCorrections(true);
            return Promise.resolve();
          },
        },
      },
      getStatus: values => values?.visualInspection?.visualInspectionStatus,
      getOutcome: values => values?.visualInspection?.visualInspectionOutcome,
    }),
    [
      hideForm,
      inProgressState,
      onReview,
      readyForReviewState,
      setInstrument,
      showCorrections,
      showSignOff,
      signedOffState,
      test,
    ]
  );

  const currentId = '';

  const getSessionPermissions = usePermissionStates(current?.permissions);

  const { states, ...instrumentTest } = useInstrumentTest(
    current,
    setCurrent,
    'visual-inspection',
    fetchCurrent,
    options.getStatus,
    options.getOutcome,
    currentId,
    options.onReview,
    options.states,
    undefined,
    undefined,
    getSessionPermissions,
    i => i?.visualInspection?.permissions,
    initialInstrument
  );

  const results = React.useMemo(
    () => ({
      ...options,
      ...instrumentTest,
      submitReview: states.SubmitReview.onSubmit,
      states,
      getTest: i => i?.visualInspection,
    }),
    [instrumentTest, options, states]
  );

  return results;
}
