import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FieldProps } from '@rjsf/core';
import InstrumentResult from 'modules/InstrumentsQualityControl/features/instruments/components/InstrumentResult';

export default ({ schema, formData, value, id }: FieldProps) => {
  return (
    <Grid item xs={12} data-testid={id}>
      <Typography variant="body1">{schema.title}</Typography>
      <InstrumentResult value={formData || value} />
    </Grid>
  );
};
