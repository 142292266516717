import React from 'react';

import { VisualInspectionDetailsDto } from 'modules/InstrumentsQualityControl/models';

import * as addOperatorForm from '../../forms/VisualInspection/addOperatorToVisualInspectionInputFormSchema';
import * as addMaterialForm from '../../forms/VisualInspection/addMaterialToVisualInspectionInputFormSchema';
import * as addEquipmentForm from '../../forms/VisualInspection/addEquipmentToVisualInspectionInputFormSchema';
import TestsPane, { TestsPaneProps } from '../TestPane/TestPane';
import VisualInspectionContainer from './VisualInspectionContainer';

export default props => (
  <>
    <VisualInspectionContainer {...props}>
      {({ current, ...p }: TestsPaneProps<VisualInspectionDetailsDto>) => (
        <TestsPane
          {...p}
          current={current}
          configuration={current?.visualInspectionConfiguration}
          addOperatorForm={addOperatorForm}
          addMaterialForm={addMaterialForm}
          addEquipmentForm={addEquipmentForm}
        />
      )}
    </VisualInspectionContainer>
  </>
);
