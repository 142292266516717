import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import { selectors } from '../../../reducers';
import NavigationGroup from './NavigationGroup';
import styles from './MainNavigation.styles';

const MainNavigation = () => {
  const classes = makeStyles(styles)();
  const navItems = useSelector(state => selectors.getNavigationItems(state));

  return (
    <List component="nav" className={classes.root}>
      {navItems &&
        navItems.map(item => (
          <Fragment key={item.key}>
            <NavigationGroup item={item} />
          </Fragment>
        ))}
    </List>
  );
};

export default MainNavigation;
