import React from 'react';
import { useBoolean } from 'react-use';
import { Container, Fade, Button, CircularProgress } from '@material-ui/core';

import { ContentHeader, Spinner, TabManager } from '@ldx-dmp/Common/components';

import { TestSessionDto } from 'modules/InstrumentsQualityControl/models';
import QCContext from 'modules/InstrumentsQualityControl/context';

import TestSessionInstrumentPane from '../TestSessionInstrumentPane';
import TestSessionVisualInspectionPane from '../TestSessionVisualInspectionPane';
import TestSessionPerformanceTestPane from '../../instrumentTests/performanceTest/components/TestSessionPerformanceTestPane';
import TestSessionQualityEventsTestPane from '../TestSessionQualityEventsTestPane';
import TestSessionPlannedDeviationsTestPane from '../TestSessionPlannedDeviationsTestPane';
import ActivityPane from '../ActivityPanel';
import { useFormSubmitting } from '../../hooks';
import TestSessionDialog from '../TestSessionDialog';

function SubmitForReview({
  onClick,
  setCurrent,
  ...props
}: {
  onClick: (() => Promise<TestSessionDto>) | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>) {
  const { isSubmitting, handleSubmit } = useFormSubmitting(onClick);
  const [showDialog, toggle] = useBoolean(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={toggle}
        {...props}
      >
        {isSubmitting && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
        Submit For Review
      </Button>
      <TestSessionDialog
        title="Submit Test Session For QA Review"
        open={showDialog}
        buttonText="Submit"
        setOpen={toggle}
        onSubmit={handleSubmit}
        setCurrent={setCurrent}
      />
    </>
  );
}

const Details = () => {
  return (
    <QCContext.Consumer>
      {({ current, isLoading, fetchCurrent, submitForReview, setCurrent, ...options }) => {
        if (!current && isLoading) {
          return <Spinner />;
        }

        return (
          <Fade in>
            <>
              <ContentHeader title={`Test Session - ${current?.testSessionId}`}>
                {current?.canSubmitForReview && (
                  <SubmitForReview onClick={submitForReview} setCurrent={setCurrent} />
                )}
              </ContentHeader>

              <TabManager onTabChanged={() => fetchCurrent && fetchCurrent()}>
                {[
                  {
                    name: 'instruments',
                    tabLabel: 'Instruments',
                    component: (
                      <Container maxWidth="xl">
                        <TestSessionInstrumentPane />
                      </Container>
                    ),
                  },
                  {
                    name: 'visualInspection',
                    tabLabel: 'Visual Inspection',
                    component: (
                      <Container maxWidth="xl">
                        <TestSessionVisualInspectionPane
                          current={current}
                          fetchCurrent={fetchCurrent}
                          isLoading={isLoading}
                          setCurrent={setCurrent}
                          {...options}
                        />
                      </Container>
                    ),
                  },
                  {
                    name: 'performanceTest',
                    tabLabel: 'Performance Test',
                    component: (
                      <Container maxWidth="xl">
                        <TestSessionPerformanceTestPane
                          fetchCurrent={fetchCurrent}
                          current={current}
                          isLoading={isLoading}
                          setCurrent={setCurrent}
                          {...options}
                        />
                      </Container>
                    ),
                  },
                  {
                    name: 'qe',
                    tabLabel: 'Quality Events',
                    component: (
                      <Container maxWidth="xl">
                        <TestSessionQualityEventsTestPane
                          fetchCurrent={fetchCurrent}
                          current={current}
                          isLoading={isLoading}
                          setCurrent={setCurrent}
                          {...options}
                        />
                      </Container>
                    ),
                  },
                  {
                    name: 'pd',
                    tabLabel: 'Planned Deviations',
                    component: (
                      <Container maxWidth="xl">
                        <TestSessionPlannedDeviationsTestPane
                          fetchCurrent={fetchCurrent}
                          current={current}
                          isLoading={isLoading}
                          setCurrent={setCurrent}
                          {...options}
                        />
                      </Container>
                    ),
                  },
                  {
                    name: 'activity',
                    tabLabel: 'Activity',
                    component: (
                      <Container maxWidth="xl">
                        <ActivityPane current={current} {...options} />
                      </Container>
                    ),
                  },
                ]}
              </TabManager>
            </>
          </Fade>
        );
      }}
    </QCContext.Consumer>
  );
};

export default Details;
