import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { FileDocumentEditOutline } from 'mdi-material-ui';
import { DataTableData, RowClickedEvent, RowEditClickedEvent } from './TableData';
import useStyles from './SimpleDataTable.styles';
import DataTableHeading from './DataTableHeading';
import DataTableField from './DataTableField';

type DataTableProps = {
  tableData: DataTableData;
  onRowClicked?: RowClickedEvent;
  onRowEditClicked?: RowEditClickedEvent;
  editable?: boolean;
  readOnly?: boolean;
};

const DataTable = ({
  tableData: { columns, rows },
  onRowClicked,
  onRowEditClicked,
  editable = false,
  readOnly = false,
}: DataTableProps) => {
  const classes = useStyles();
  const handleClick = rowKey => {
    if (onRowClicked) onRowClicked(rowKey);
  };

  const handleEditClick = rowKey => {
    if (onRowEditClicked) onRowEditClicked(rowKey);
  };

  return (
    <div className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            {columns && columns.map(col => <DataTableHeading key={col.accessor} column={col} />)}
            {editable && (
              <TableCell>
                <FileDocumentEditOutline color="action" />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map(row => (
              <TableRow key={row.key} onClick={() => handleClick(row.key)}>
                {columns.map(col => (
                  <DataTableField
                    key={col.accessor}
                    field={row.fields[col.accessor]}
                    verification={row.verification}
                    readOnly={readOnly}
                  />
                ))}
                {editable && (
                  <TableCell>
                    <Tooltip title="Edit Row">
                      <IconButton size="small" onClick={() => handleEditClick(row.key)}>
                        <FileDocumentEditOutline color="primary" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataTable;
