import PropTypes from 'prop-types';

export const navigationItem = {
  key: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  route: PropTypes.string,
  icon: PropTypes.node,
};

export const groupNavigationItem = {
  ...navigationItem,
  children: PropTypes.arrayOf(PropTypes.shape(navigationItem)),
  open: PropTypes.bool,
};
