import { apiPost } from 'modules/Core/actions/apiActions';

export enum RejectBatchRecordActions {
  CALL = 'pilot/batch-records/REJECT_BATCH_RECORD',
  REQUEST = 'pilot/batch-records/REJECT_BATCH_RECORD_REQUEST',
  SUCCESS = 'pilot/batch-records/REJECT_BATCH_RECORD_SUCCESS',
  FAILURE = 'pilot/batch-records/REJECT_BATCH_RECORD_FAILURE',
}

export const rejectBatchRecord = (recordId: string, comment: string) =>
  apiPost(`/pilot/batch-records/${recordId}/reject`, RejectBatchRecordActions.CALL, { comment });
