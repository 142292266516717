import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  result: {
    textTransform: 'uppercase',
    '& .result-pass': {
      color: theme.palette.success.main,
    },
    '& .result-fail': {
      color: '#f44336',
    },
    '& .result-in-progress': {
      color: theme.palette.secondary.light,
    },
    '& .result-ready-for-review': {
      color: theme.palette.info.light,
    },
    '& .result-corrections-required': {
      color: theme.palette.warning.light,
    },
    '& .result-none': {
      color: theme.palette.grey[500],
    },
    '& .result-signed-off': {
      color: theme.palette.success.light,
    },
  },
}));
