/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid } from '@material-ui/core';
import { Spinner } from 'modules/Common';
import styles from './InstrumentHistory.styles';
import ActivityPanel from './ActivityPanel';
import { useInstrumentHistory } from '../../History';

const InstrumentHistory = ({ isActive, instrument }: Record<string, any>) => {
  const { fetch, status, items } = useInstrumentHistory(instrument?.id);

  const classes = styles();

  React.useEffect(() => {
    if (isActive && status === 'idle') {
      fetch();
    }
  }, [fetch, isActive, status]);

  if (!isActive) return <></>;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          className={classes.stackedList}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {status === 'loaded' && <ActivityPanel events={items} />}
          {status !== 'loaded' && <Spinner />}
        </Grid>
      </Grid>
    </div>
  );
};

export default InstrumentHistory;
