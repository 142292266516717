import React, { useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { compose } from 'redux';
import { createLoadingSelector } from 'modules/Core/reducers';
import { getConfig } from 'modules/Core/reducers/selectors';
import { actionCreators as repairRequestActionCreators } from 'modules/Instruments/slices/Repair/actions';
import {
  RepairRequestDetails,
  RepairRequestDialog,
} from 'modules/Instruments/slices/Repair/components';
import {
  getCurrentRepairRequest,
  getTechnicianUsers,
  getPostRepairTestSetup,
  getRepairRequestsForInstrument,
  selectors,
} from 'modules/Instruments/slices/Repair/reducers';
import {
  FetchRepairRequestActionTypes,
  FetchRepairsForInstrumentActionTypes,
} from 'modules/Instruments/slices/Repair/actions/actionTypes';
import { Spinner } from 'modules/Common';
import { Fade } from '@material-ui/core';
import * as instrumentActions from '../../Management/actions';
import { getInstrument } from '../../Management/reducers/selectors';

const RepairRequestPage = ({
  postRepairTestSetup,
  repairRequest,
  relatedRequests,
  technicianUsers,
  setCurrentRepairRequest,
  openRequestForm,
  showRequestForm,
  currentUserId,
  isLoading,
  config,
  fetchInstrument,
  instrument,
}) => {
  const { requestId } = useParams();
  const apiUrl = config.api.base_uri;

  const handleClickOpen = () => {
    openRequestForm('edit');
  };

  useEffect(() => {
    if (!repairRequest && !isLoading) {
      setCurrentRepairRequest(requestId);
    }
  }, [repairRequest, isLoading, requestId, setCurrentRepairRequest]);

  useEffect(() => {
    if (repairRequest && !instrument) {
      fetchInstrument(repairRequest?.instrument?.id);
    }
  }, [fetchInstrument, instrument, repairRequest]);

  const isLoaded = repairRequest !== undefined && repairRequest !== null;

  const handlePrintClicked = () => {
    axios
      .get(`${apiUrl}/instruments/repairs/${requestId}/print`, {
        responseType: 'blob',
      })
      .then(response => response.data)
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${repairRequest?.irn}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      });
  };

  if (!isLoaded) return <Spinner />;

  return (
    <Fade in={isLoaded}>
      <div>
        <RepairRequestDialog show={showRequestForm} />
        <RepairRequestDetails
          onEditClicked={handleClickOpen}
          onPrintClicked={handlePrintClicked}
          repairRequest={repairRequest}
          technicianUsers={technicianUsers}
          postRepairTestSetup={postRepairTestSetup}
          relatedRequests={relatedRequests}
          currentUserId={currentUserId}
          instrument={instrument}
        />
      </div>
    </Fade>
  );
};

RepairRequestPage.propTypes = {
  repairRequest: PropTypes.object,
  relatedRequests: PropTypes.array.isRequired,
  technicianUsers: PropTypes.array.isRequired,
  setCurrentRepairRequest: PropTypes.func.isRequired,
  openRequestForm: PropTypes.func.isRequired,
  fetchInstrument: PropTypes.func.isRequired,
  showRequestForm: PropTypes.bool.isRequired,
  postRepairTestSetup: PropTypes.object.isRequired,
  currentUserId: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  config: PropTypes.object.isRequired,
  instrument: PropTypes.object.isRequired,
};

RepairRequestPage.defaultProps = {
  repairRequest: null,
  currentUserId: null,
};

const mapStateToProps = state => {
  const request = getCurrentRepairRequest(state);
  const loadingSelector = createLoadingSelector([
    FetchRepairRequestActionTypes.FETCH_REPAIR_REQUEST,
    FetchRepairsForInstrumentActionTypes.FETCH_REPAIRS_FOR_INSTRUMENT,
  ]);

  return {
    instrument: getInstrument(state, request?.instrument?.id),
    repairRequest: request,
    relatedRequests: request ? getRepairRequestsForInstrument(state, request.instrument) : [],
    technicianUsers: getTechnicianUsers(state),
    postRepairTestSetup: getPostRepairTestSetup(state),
    currentUserId: state.oidc.user.profile.sub,
    showRequestForm: selectors.isRequestFormOpen(state),
    isLoading: !request && loadingSelector(state),
    config: getConfig(state),
  };
};

const actionCreators = {
  setCurrentRepairRequest: repairRequestActionCreators.setCurrentRepairRequest,
  openRequestForm: repairRequestActionCreators.openRequestForm,
  fetchInstrument: instrumentActions.fetchInstrument,
};

const enhance = compose(withRouter, connect(mapStateToProps, actionCreators));

export default enhance(RepairRequestPage);
