import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    '& .MuiTableCell-head': {
      minWidth: '275px',
    },
  },
  labelImage: {
    width: '100%',
    maxWidth: '400px',
  },
}));
