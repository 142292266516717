/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './updateVisualInspectionToInstrumentInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      instrumentId: {
        type: 'string',
        title: 'Instrument Id',
      },
      serialNumberVerifiedAgainstLabel: {
        type: 'boolean',
        title: 'Serial Number Verified Against Label',
      },
      goodsReceivedNumber: {
        type: 'string',
        title: 'Goods Received Number',
      },
      flexReworkInstrument: {
        type: 'boolean',
        title: 'Flex Rework Instrument',
      },
      detailsVerifiedBy: {
        type: 'string',
        title: 'Details Verified By',
      },
      currentReleasedSoftware: {
        type: 'string',
        title: 'Current Released Software',
      },
      softwareVersionInstalledOnInstrument: {
        type: 'string',
        title: 'Software Version Installed On Instrument',
      },
      softwareUploadRequired: {
        title: 'Software Upload Required',
        type: 'object',
        properties: {
          value: {
            title: 'Value',
            type: 'string',
            enum: ['Yes', 'No', 'NotApplicable'],
            enumNames: ['Yes', 'No', 'Not Applicable'],
          },
          notApplicableComment: {
            type: 'string',
            title: 'Not Applicable Comment',
          },
        },
      },
      currentSoftwareNowInstalled: {
        title: 'Current Software Now Installed',
        type: 'object',
        properties: {
          value: {
            title: 'Value',
            type: 'string',
            enum: ['Yes', 'No', 'NotApplicable'],
            enumNames: ['Yes', 'No', 'Not Applicable'],
          },
          notApplicableComment: {
            type: 'string',
            title: 'Not Applicable Comment',
          },
        },
      },
      carriedOutBy: {
        type: 'string',
        title: 'Carried Out By',
      },
      case: {
        title: 'Case',
        type: 'object',
        properties: {
          instrumentCasingShouldBeInspectedForMajorDefects: {
            type: 'boolean',
            title: 'Instrument Casing Should Be Inspected For Major Defects',
          },
          theWhiteTopCasingShouldBeSecurelyAndEvenlyAttachedToTheBlackBaseUnit: {
            type: 'boolean',
            title:
              'The White Top Casing Should Be Securely And Evenly Attached To The Black Base Unit',
          },
        },
      },
      door: {
        title: 'Door',
        type: 'object',
        properties: {
          doesTheDoorOpenSmoothlyWithoutObstructionAndDislocation: {
            type: 'boolean',
            title: 'Does The Door Open Smoothly Without Obstruction And Dislocation',
          },
          doesTheDoorCloseSmoothlyWithoutObstructionAndDislocationAndCanSelfClose: {
            type: 'boolean',
            title:
              'Does The Door Close Smoothly Without Obstruction And Dislocation And Can Self Close',
          },
          reworkRequired: {
            type: 'boolean',
            title: 'Rework Required',
          },
          reworkCarriedOutBy: {
            type: 'string',
            title: 'Rework Carried Out By',
          },
          verifiedBy: {
            type: 'string',
            title: 'Verified By',
          },
          theDoorShouldOpenAndCloseSmoothlyWithoutDislocatingAndAcceptableGap: {
            type: 'boolean',
            title: 'The Door Should Open And Close Smoothly Without Dislocating And Acceptable Gap',
          },
          theDoorShouldNotBeLooseOrDifficultToOpenClose: {
            type: 'boolean',
            title: 'The Door Should Not Be Loose Or Difficult To Open Close',
          },
        },
      },
      screen: {
        title: 'Screen',
        type: 'object',
        properties: {
          theScreenShouldNotBeCrackedScratchedOrChipped: {
            type: 'boolean',
            title: 'The Screen Should Not Be Cracked Scratched Or Chipped',
          },
          theScreenShouldFitFlushWithTheInstrumentCasing: {
            type: 'boolean',
            title: 'The Screen Should Fit Flush With The Instrument Casing',
          },
          whenTheInstrumentIsPoweredOnTheScreenShouldProduceAColourDisplayWithNoObviousDefects: {
            title:
              'When The Instrument Is Powered On The Screen Should Produce A Colour Display With No Obvious Defects',
            type: 'string',
            enum: ['Pass', 'Fail', 'UnableToTest'],
            enumNames: ['Pass', 'Fail', 'Unable To Test'],
          },
          whenTheInstrumentIsPoweredOnTheScreenShouldProvideTouchscreenFunctionality: {
            title:
              'When The Instrument Is Powered On The Screen Should Provide Touchscreen Functionality',
            type: 'string',
            enum: ['Pass', 'Fail', 'UnableToTest'],
            enumNames: ['Pass', 'Fail', 'Unable To Test'],
          },
        },
      },
      rubberFeet: {
        title: 'Rubber Feet',
        type: 'object',
        properties: {
          areAllFourFeetFirmlyAttachedToTheInstrumentCasing: {
            type: 'boolean',
            title: 'Are All Four Feet Firmly Attached To The Instrument Casing',
          },
          ifFeetAreLoosePleaseIndicateWhichFeet: {
            title: 'If Feet Are Loose Please Indicate Which Feet',
            type: 'array',
            items: {
              type: 'string',
              title: 'IfFeetAreLoosePleaseIndicateWhichFeet',
            },
          },
          reworkRequired: {
            title: 'Rework Required',
            type: 'string',
            enum: ['Pass', 'Fail', 'UnableToTest'],
            enumNames: ['Pass', 'Fail', 'Unable To Test'],
          },
          reworkCarriedOutBy: {
            type: 'string',
            title: 'Rework Carried Out By',
          },
          verifiedBy: {
            type: 'string',
            title: 'Verified By',
          },
          allFourRubberFeetShouldBeFirmlyAttachedToTheInstrumentCasing: {
            type: 'boolean',
            title: 'All Four Rubber Feet Should Be Firmly Attached To The Instrument Casing',
          },
          theInstrumentShouldBeLevelWhenPlacedOnAStableLevelSurface: {
            type: 'boolean',
            title: 'The Instrument Should Be Level When Placed On A Stable Level Surface',
          },
        },
      },
      labels: {
        title: 'Labels',
        type: 'object',
        properties: {
          serialNumberOnLabelMatchesSerialNumberInAboutScreen: {
            title: 'Serial Number On Label Matches Serial Number In About Screen',
            type: 'string',
            enum: ['Pass', 'Fail', 'UnableToTest'],
            enumNames: ['Pass', 'Fail', 'Unable To Test'],
          },
          theLumiradxLogoShouldBeVisibleOnTheTopSideOfTheInstrumentBetweenTheScreenAndTheDoor: {
            type: 'boolean',
            title:
              'The Lumiradx Logo Should Be Visible On The Top Side Of The Instrument Between The Screen And The Door',
          },
          theNfcIconShouldBeVisibleOnTheRightHandSideOfTheInstrument: {
            type: 'boolean',
            title: 'The Nfc Icon Should Be Visible On The Right Hand Side Of The Instrument',
          },
          labelShouldBeFreeFromPrintDefectsAndFormatAndContentShouldMatchThatSpecifiedInTheCurrentRevision: {
            type: 'boolean',
            title:
              'Label Should Be Free From Print Defects And Format And Content Should Match That Specified In The Current Revision',
          },
        },
      },
      stripDetectionAndNfcOperation: {
        title: 'Strip Detection And Nfc Operation',
        type: 'object',
        properties: {
          instrumentDetectsStrips: {
            title: 'Instrument Detects Strips',
            type: 'string',
            enum: ['Pass', 'Fail', 'UnableToTest'],
            enumNames: ['Pass', 'Fail', 'Unable To Test'],
          },
          instrumentRecognisesRfidTag: {
            title: 'Instrument Recognises Rfid Tag',
            type: 'string',
            enum: ['Pass', 'Fail', 'UnableToTest'],
            enumNames: ['Pass', 'Fail', 'Unable To Test'],
          },
          instrumentSuccessfullyUploadsLotCalibrationFiles: {
            title: 'Instrument Successfully Uploads Lot Calibration Files',
            type: 'string',
            enum: ['Pass', 'Fail', 'UnableToTest'],
            enumNames: ['Pass', 'Fail', 'Unable To Test'],
          },
        },
      },
      qualityEventRequired: {
        type: 'boolean',
        title: 'Quality Event Required',
      },
      qualityEventNumber: {
        type: 'string',
        title: 'Quality Event Number',
      },
      visualInspectionStatus: {
        title: 'Visual Inspection Status',
        type: 'string',
        enum: [
          'Pending',
          'InProgress',
          'ReadyForReview',
          'CorrectionsRequired',
          'Reviewed',
          'SignedOff',
        ],
        enumNames: [
          'Pending',
          'In Progress',
          'Ready For Review',
          'Corrections Required',
          'Reviewed',
          'Signed Off',
        ],
      },
      visualInspectionOutcome: {
        title: 'Visual Inspection Outcome',
        type: 'string',
        enum: ['Pass', 'Fail'],
        enumNames: ['Pass', 'Fail'],
      },
      plannedDeviationNumbers: {
        title: 'Planned Deviation Numbers',
        type: 'array',
        items: {
          type: 'string',
          title: 'PlannedDeviationNumber',
        },
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  instrumentId: '',
  serialNumberVerifiedAgainstLabel: false,
  goodsReceivedNumber: '',
  flexReworkInstrument: false,
  detailsVerifiedBy: '',
  currentReleasedSoftware: '',
  softwareVersionInstalledOnInstrument: '',
  softwareUploadRequired: {},
  currentSoftwareNowInstalled: {},
  carriedOutBy: '',
  case: {},
  door: {},
  screen: {},
  rubberFeet: {},
  labels: {},
  stripDetectionAndNfcOperation: {},
  qualityEventRequired: false,
  qualityEventNumber: '',
  visualInspectionStatus: '',
  visualInspectionOutcome: '',
  plannedDeviationNumbers: [],
});
