import { apiPost } from 'modules/Core/actions';

export enum RecordBatchCompletionActions {
  CALL = 'pilot/batch-records/RECORD_BATCH_COMPLETION',
  REQUEST = 'pilot/batch-records/RECORD_BATCH_COMPLETION_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_BATCH_COMPLETION_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_BATCH_COMPLETION_FAILURE',
}

export const recordBatchCompletion = (recordId: string) =>
  apiPost(
    `/pilot/batch-records/${recordId}/sign-off/batch-completion`,
    RecordBatchCompletionActions.CALL,
    {}
  );
