import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getConfig } from 'modules/Core/reducers/selectors';

const VersionInfo = () => {
  const config = useSelector(getConfig);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="subtitle2" color="textSecondary">
        <strong>Confidential</strong> - &copy; 2021 LumiraDx. All rights reserved, worldwide.
        <br />
        {config.app.environment} - {config.app.version}&nbsp;
      </Typography>
    </div>
  );
};

export default VersionInfo;
