import React from 'react';
import { Button, ButtonGroup, Fade } from '@material-ui/core';

import { ContentHeader, NewItemButton } from '@ldx-dmp/Common/components';

import QCContext from 'modules/InstrumentsQualityControl/context';

import TestSessionsRecordsTable from '../TestSessionsDataTable';

const List = () => (
  <QCContext.Consumer>
    {values => (
      <Fade in>
        <>
          <ContentHeader title="Instrument Quality Control">
            <ButtonGroup variant="contained" color="primary">
              <NewItemButton text="New Test Session" onClick={values.onNewItemClick} />
              <Button onClick={values.moveToMaterials}>Manage Materials</Button>
              <Button onClick={values.moveToEquipment}>Manage Equipment</Button>
            </ButtonGroup>
          </ContentHeader>
          <TestSessionsRecordsTable />
        </>
      </Fade>
    )}
  </QCContext.Consumer>
);

export default List;
