/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSaga, Mode } from '@ldx-dmp/Redux';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions,
  selectIsSyncing,
  selectShowSyncSuccess,
  selectOutState,
  autoSavePause,
  autoSaveStart,
  apiCallRegister,
  flushApiCalls,
} from './autoSaveSlice';

import saga from './saga';

export function useAutoSave<T>() {
  useSaga({
    key: 'autoSave',
    saga,
    mode: Mode.DAEMON,
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(autoSaveStart());
    return () => {
      dispatch(flushApiCalls());
      dispatch(autoSavePause());
    };
  }, [dispatch]);

  const { hasChanges } = useSelector(selectOutState);
  const showSyncSuccess = useSelector(selectShowSyncSuccess);
  const syncing = useSelector(selectIsSyncing);

  const clearSyncSuccess = React.useCallback(() => {
    dispatch(actions.clearSyncSuccess());
  }, [dispatch]);

  const add = React.useCallback(
    (key, tag, func, flush = false) => {
      dispatch(apiCallRegister(key, tag, func, flush));
    },
    [dispatch]
  );

  const flush = React.useCallback(
    key => {
      return new Promise<void>(resolve => {
        dispatch(flushApiCalls(key, () => resolve()));
      });
    },
    [dispatch]
  );

  return React.useMemo(
    () => ({
      hasChanges,
      syncing,
      showSyncSuccess,
      clearSyncSuccess,
      add,
      clear: clearSyncSuccess,
      flush,
    }),
    [hasChanges, syncing, showSyncSuccess, clearSyncSuccess, add, flush]
  );
}
