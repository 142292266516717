/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './addMaterialInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      name: {
        type: 'string',
        title: 'Name',
      },
      specification: {
        type: 'string',
        title: 'Specification',
      },
      revision: {
        type: 'string',
        title: 'Revision',
      },
      usage: {
        title: 'Usage',
        type: 'string',
        enum: ['VisualInspection', 'PerformanceTest'],
        enumNames: ['Visual Inspection', 'Performance Test'],
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  name: '',
  specification: '',
  revision: '',
  usage: '',
});
