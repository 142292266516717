/* eslint-disable prettier/prettier */
import React from 'react';
import { startCase } from 'lodash';
import { ActivityEvent } from 'modules/Pilot/BatchRecord/domain';
import { Typography, Paper, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineOppositeContent,
} from '@material-ui/lab';
import { formatDate } from 'modules/Common/utilities';
import useStyles from './ActivityPanel.styles';

type ActivityPanelProps = {
  events: ActivityEvent[];
};

const getActionText = (actionName: string) => {
  const casedName = startCase(actionName);
  const words = casedName.match(/\b(\w+)\b/g);
  const verb = words.pop();

  return `${verb} ${words.join(' ').replace('Ogp', 'OGP')}`;
};

const getContents = (content: string) => {
  const parsedContent = JSON.parse(content);
  const keys = Object.keys(parsedContent);

  const things: { field: string; value: string }[] = [];
  keys.forEach(key => {
    if (
      key === 'PartStatus' ||
      key === 'SubAssemblyRejects' ||
      key === 'StripRejects' ||
      key === 'RejectCounts'
    )
      return;

    // TODO: Fix this hacky mess (DMP-92)
    if (key === 'Splits' || key === 'Lasers' || key === 'Channels') {
      if (parsedContent[key].Selected)
        things.push({ field: key, value: parsedContent[key].Selected.join(', ') });
      else things.push({ field: key, value: parsedContent[key].join(', ') });
    } else if ((key === 'LotNumber' || key === 'Number') && typeof parsedContent[key] === 'object')
      things.push({ field: startCase(key), value: parsedContent[key].Number });
    else if (key === 'Section') things.push({ field: 'Section', value: parsedContent[key].Name });
    else if (key === 'Asset') things.push({ field: 'Asset', value: parsedContent[key].Full });
    else if (key === 'Chemistry')
      things.push({
        field: 'Chemistry',
        value: parsedContent[key].Name,
      });
    else if (key === 'Attachments') {
      parsedContent[key].forEach((attachment, index) =>
        things.push({ field: `Attachment ${index + 1}`, value: `${attachment.FileName}` })
      );
    } else if (key === 'Attachment') {
      things.push({ field: `Attachment`, value: `${parsedContent[key].FileName}` });
    } else if (key === 'IsForced') {
      things.push({
        field: `Forced?`,
        value: `${parsedContent[key] === true ? 'True' : 'False'}`,
      });
    } else things.push({ field: startCase(key), value: parsedContent[key] });
  });

  return things;
};

const ActivityTimelineItem = ({
  action: { name, timestamp, user, content },
  skipConnector = false,
}: {
  action: ActivityEvent;
  skipConnector: boolean;
}) => (
  <TimelineItem>
    <TimelineOppositeContent>
      <Typography variant="subtitle1" color="textSecondary">
        {formatDate(timestamp)}
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineDot color="primary" />
      {!skipConnector && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent>
      <Paper elevation={3} variant="outlined">
        <div className="eventHeader">
          <Typography variant="h6">
            {user.name} {getActionText(name)}
          </Typography>
        </div>
        <Table>
          <TableBody>
            {content &&
              getContents(content).map(fieldValue => (
                <TableRow key={fieldValue.field}>
                  <TableCell style={{ width: '50%' }}>
                    <strong>{fieldValue.field}</strong>
                  </TableCell>
                  <TableCell>{fieldValue.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </TimelineContent>
  </TimelineItem>
);

export default ({ events }: ActivityPanelProps) => {
  const classes = useStyles();
  return (
    <>
      {events && (
        <Timeline align="left" className={classes.root}>
          {events.map((e, index) => (
            <ActivityTimelineItem
              key={`${e.name}-${e.timestamp}`}
              action={e}
              skipConnector={index >= events.length - 1}
            />
          ))}
        </Timeline>
      )}
    </>
  );
};
