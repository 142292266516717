import React from 'react';
import { useServer } from 'modules/Global/locations';
import { PagedResultDto } from 'modules/Core';
import { LocationDto } from 'modules/Global/models';

export default () => {
  const server = useServer();
  const [items, setItems] = React.useState<{ value: string | number; label: string }[]>([]);

  return React.useMemo(
    () => ({
      items,
      fetch: () =>
        server.getListAsync<PagedResultDto<LocationDto>>(0, 1000, 'name').then(response =>
          setItems(
            response?.data?.items?.map(x => ({
              value: String(x.id),
              label: String(x.name),
            })) || []
          )
        ),
    }),
    [server, items]
  );
};
