import { apiPost, apiPatch } from 'modules/Core/actions';

export enum RecordLaserUsageActions {
  CALL = 'pilot/batch-records/RECORD_LASER_USAGE',
  REQUEST = 'pilot/batch-records/RECORD_LASER_USAGE_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_LASER_USAGE_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_LASER_USAGE_FAILURE',
}

export enum VerifyLaserUsageActions {
  CALL = 'pilot/batch-records/VERIFY_LASER_USAGE',
  REQUEST = 'pilot/batch-records/VERIFY_LASER_USAGE_REQUEST',
  SUCCESS = 'pilot/batch-records/VERIFY_LASER_USAGE_SUCCESS',
  FAILURE = 'pilot/batch-records/VERIFY_LASER_USAGE_FAILURE',
}

export enum AmendLaserUsageActions {
  CALL = 'pilot/batch-records/AMEND_LASER_USAGE',
  REQUEST = 'pilot/batch-records/AMEND_LASER_USAGE_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_LASER_USAGE_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_LASER_USAGE_FAILURE',
}

export type LaserUsagePayload = {
  splits: number[];
  laserFile: string;
  lasers: number[];
};

export type AmendLaserUsagePayload = { subRecordId?: string; comment?: string } & LaserUsagePayload;

export const recordLaserUsage = (recordId: string, payload: LaserUsagePayload) =>
  apiPost(`/pilot/batch-records/${recordId}/preparation/laser`, RecordLaserUsageActions.CALL, {
    ...payload,
  });

export const verifyLaserUsage = (recordId: string, subRecordId: string) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/preparation/laser/verify`,
    VerifyLaserUsageActions.CALL,
    {
      subRecordId,
    }
  );

export const amendLaserUsage = (recordId: string, payload: AmendLaserUsagePayload) =>
  apiPatch(`/pilot/batch-records/${recordId}/preparation/laser`, AmendLaserUsageActions.CALL, {
    ...payload,
  });
