import React from 'react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { initReactI18next } from 'react-i18next';
import { Provider } from 'react-redux';
import { OidcProvider, loadUser } from 'redux-oidc';
import { ConnectedRouter } from 'connected-react-router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import MomentUtils from '@date-io/moment/';
import { Log } from 'oidc-client';
import { AppRouter, LumiraDxTheme } from '@ldx-dmp/Core';
import { authManager } from '@ldx-dmp/Auth';
import configureAxiosAuth from '@ldx-dmp/Core/http';
import { i18next, IntlProvider } from '@ldx-dmp/Localization';
import configureStore, { history } from './configureStore';
import appConfig from './config';

if (appConfig.logging.oidc.enabled) {
  Log.logger = console;
  Log.level = appConfig.logging.oidc.level;
}

const store = configureStore(appConfig);

authManager.init(appConfig.oidc);
const userManager = authManager.getUserManager();
loadUser(store, userManager);

configureAxiosAuth(store);

i18next.use(initReactI18next).init(i18next.options);

export default function() {
  return (
    <Provider store={store}>
      <IntlProvider>
        <OidcProvider store={store} userManager={userManager}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={LumiraDxTheme}>
              <div className="App">
                <CssBaseline />
                <ConnectedRouter history={history}>
                  <AppRouter />
                </ConnectedRouter>
              </div>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </OidcProvider>
      </IntlProvider>
    </Provider>
  );
}
