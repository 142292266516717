import { apiPost } from 'modules/Core/actions';

export enum RecordPackagingAndSealingCarriedOutActions {
  CALL = 'pilot/batch-records/RECORD_PACKAGING_SEALING',
  REQUEST = 'pilot/batch-records/RECORD_PACKAGING_SEALING_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_PACKAGING_SEALING_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_PACKAGING_SEALING_FAILURE',
}

export const recordPackagingSealingCarriedOut = (recordId: string) =>
  apiPost(
    `/pilot/batch-records/${recordId}/inspection/packaging-sealing`,
    RecordPackagingAndSealingCarriedOutActions.CALL,
    {}
  );
