import React from 'react';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { FirmwareVersionDto } from 'modules/Instruments/models';

export default function useProcesedFirmwares({ items }: { items?: FirmwareVersionDto[] }) {
  const grouped = groupBy(
    sortBy(
      items?.filter(x => x.version),
      x => x.timestamp
    ).reverse(),
    x => x.type
  );

  const latest = React.useMemo(() => {
    function process(g: Record<string, FirmwareVersionDto[]>) {
      return Object.entries(g).map(([, value]) => {
        if (!value.length) return {};

        return {
          ...value[0],
          others: value.filter(x => x.timestamp !== value[0].timestamp),
        };
      });
    }

    return process(grouped);
  }, [grouped]);

  return React.useMemo(
    () => ({
      items: items || [],
      grouped: grouped || {},
      latest,
    }),
    [grouped, items, latest]
  ) as {
    items: FirmwareVersionDto[];
    grouped: Record<string, FirmwareVersionDto[]>;
    latest: FirmwareVersionDto & { others: FirmwareVersionDto[] }[];
  };
}
