/* eslint-disable no-param-reassign */
import React from 'react';
import MUIDataTable from 'mui-datatables';
import produce from 'immer';
import { useBoolean } from 'react-use';

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { makeUserHasPermission } from 'modules/Core/reducers/selectors';
import { Form } from '@ldx-dmp/Common/components';
import { useFormSubmitting } from '@ldx-dmp/InstrumentsQualityControl/features/testSessions/hooks';
import useTestTypeServer from '@ldx-dmp/Global/testTypeDefinition/useServer';
import useSampleTypeServer from '@ldx-dmp/Global/sampleTypeDefinition/useServer';

import {
  SampleTypeDefinitionDto,
  TestTypeDefinitionDto,
} from '@ldx-dmp/InstrumentsQualityControl/models';
import { TestSessionConfigurationOptions } from '@ldx-dmp/InstrumentsQualityControl/features/testSessions/components/TestSessionTestConfigurationTables/TestSessionConfigurationOptions';
import { AddConfigurationOptionToTestSessionProps } from 'modules/InstrumentsQualityControl/features/testSessions/components/AddConfigurationOptionToTestSession';

import useServer from 'modules/InstrumentsQualityControl/features/testSessions/hooks/useServer';

import * as addTestAndSampleTypeInputFormSchema from 'modules/InstrumentsQualityControl/features/testSessions/forms/PerformanceTest/addTestAndSampleTypeInputFormSchema';
import QCContext, {
  InstrumentsQualityControlContextType,
} from 'modules/InstrumentsQualityControl/context';
import { useSelector } from 'react-redux';
import { PagedResultDto } from 'modules/Core';

function OptionsForm<T>({
  form,
  initialValues,
  current,
  items,
  ...values
}: AddConfigurationOptionToTestSessionProps<T>) {
  const { isSubmitting, handleSubmit } = useFormSubmitting(values?.onFormSubmit);

  const { schema } = form;

  const newSchema = React.useMemo(
    () =>
      produce(sch => {
        sch.properties.testType.enum = items?.testTypes?.map(x => x.id) || [undefined];
        sch.properties.testType.enumNames = items?.testTypes?.map(
          x => `${x.code} - ${x.softwareDisplayText}`
        ) || ['Loading...'];

        sch.properties.sampleType.enum = items?.sampleTypes?.map(x => x.id) || [undefined];
        sch.properties.sampleType.enumNames = items?.sampleTypes?.map(
          x => `${x.code} - ${x.softwareDisplayText}`
        ) || ['Loading...'];

        return sch;
      }, schema)(),
    [items, schema]
  );

  return (
    <Dialog open={!!values?.formOpen} onClose={values?.onFormClose} fullWidth>
      <DialogTitle>Add Test Configuration</DialogTitle>
      <DialogContent>
        <Form
          {...form}
          schema={newSchema}
          isSubmitting={isSubmitting}
          initialValues={initialValues || form?.empty || {}}
          onSubmit={handleSubmit}
          onClose={values?.onFormClose || (() => {})}
        />
      </DialogContent>
    </Dialog>
  );
}

const userHasPermission = makeUserHasPermission();

export function PerformanceTestAdditionalConfig({ readOnly }: { readOnly: boolean }) {
  const hasPermission = useSelector(state =>
    userHasPermission(state, 'LDX.Instruments.QualityControl.ModifyTestConfiguration')
  );
  const [formOpen, toggle] = useBoolean(false);
  const [testTypes, setTestTypes] = React.useState<TestTypeDefinitionDto[] | undefined>();
  const [sampleTypes, setSampleTypes] = React.useState<SampleTypeDefinitionDto[] | undefined>();
  const { current: testSession, setCurrent } = React.useContext(
    QCContext
  ) as InstrumentsQualityControlContextType;

  const testTypeServer = useTestTypeServer();
  const sampleTypeServer = useSampleTypeServer();
  const server = useServer();

  const options = {
    title: 'Test Configuration',
    buttonText: 'Add',
    onOpen: toggle,
    onClose: toggle,
    formOpen,
    all: {
      sampleTypes,
      testTypes,
    },
    form: addTestAndSampleTypeInputFormSchema,
    onSubmit: async data => {
      const response = await server.patchPerformanceTestConfiguration(testSession?.id, data);

      setCurrent(response.data);
      toggle();
    },
    current: testSession?.performanceTestConfiguration?.performanceTestTypeAndSamples?.map(x => ({
      testType: `${x.testType?.definition?.code} - ${x.testType?.definition?.softwareDisplayText}`,
      sampleType: `${x.sampleType?.definition?.code} - ${x.sampleType?.definition?.softwareDisplayText}`,
    })),
  };

  React.useEffect(() => {
    if (formOpen && !testTypes) {
      testTypeServer
        .getListAsync<PagedResultDto<TestTypeDefinitionDto>>('', 0, 1000)
        .then(response => {
          setTestTypes(response.data.items);
        });
    }

    if (!formOpen && testTypes) {
      setTestTypes(undefined);
    }
  }, [formOpen, testTypeServer, testTypes]);

  React.useEffect(() => {
    if (formOpen && !sampleTypes) {
      sampleTypeServer
        .getListAsync<PagedResultDto<SampleTypeDefinitionDto>>('', 0, 1000)
        .then(response => {
          setSampleTypes(response.data.items);
        });
    }

    if (!formOpen && sampleTypes) {
      setSampleTypes(undefined);
    }
  }, [formOpen, sampleTypeServer, sampleTypes, testTypeServer, testTypes]);

  return (
    <TestSessionConfigurationOptions
      readOnly={
        readOnly || testSession?.performanceTestConfiguration?.lineClearance || !hasPermission
      }
      Component={OptionsForm}
      {...options}
    >
      {(current: TestTypeDefinitionDto[]) => (
        <>
          <MUIDataTable
            data={current}
            columns={[
              { name: 'testType', label: 'Test Type' },
              { name: 'sampleType', label: 'Sample Type' },
            ]}
            options={{
              elevation: 0,
              download: false,
              search: false,
              sort: false,
              filter: false,
              pagination: false,
              print: false,
              viewColumns: false,
              selectableRows: 'none',
            }}
          />
        </>
      )}
    </TestSessionConfigurationOptions>
  );
}

export default PerformanceTestAdditionalConfig;
