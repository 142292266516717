import React from 'react';
import { useEffectOnce } from 'react-use';
import { FormikSelectField } from '@ldx-dmp/Common';
import { FormikSelectFieldProps } from '@ldx-dmp/Common/components/FormikSelectField/FormikSelectField';
import useRepairLocationsSelectList from '../useRepairLocationsSelectList';

export default ({ ...props }: FormikSelectFieldProps) => {
  const { items, fetch } = useRepairLocationsSelectList();

  useEffectOnce(() => {
    fetch();
  });

  return <FormikSelectField {...props} options={items || []} />;
};
