import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .MuiDivider-root': {
      marginTop: theme.spacing(1),
    },
    '& .MuiChip-root': {
      marginRight: theme.spacing(1),
    },
    '& .section-action': {
      '&.MuiChip-root': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&.MuiChip-clickable:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  },
}));
