/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './updatePerformanceToInstrumentInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      flexReworkInstrument: {
        type: 'boolean',
        title: 'Flex Rework Instrument',
      },
      piezoMagnetCheckOutcome: {
        title: 'Piezo Magnet Check Outcome',
        type: 'object',
        properties: {
          qualityEventRequired: {
            type: 'boolean',
            title: 'Quality Event Required',
          },
          qualityEventNumber: {
            type: 'string',
            title: 'Quality Event Number',
          },
          outcome: {
            type: 'boolean',
            title: 'Outcome',
          },
          hasQualityEvents: {
            type: 'boolean',
            title: 'Has Quality Events',
          },
        },
      },
      tests: {
        title: 'Tests',
        type: 'array',
        items: {
          type: 'object',
          properties: {
            anyErrorsDuringInstrumentBootUp: {
              type: 'boolean',
              title: 'Any Errors During Instrument Boot Up',
            },
            errorCodes: {
              title: 'Error Codes',
              type: 'array',
              items: {
                type: 'string',
                title: 'ErrorCode',
              },
            },
            comments: {
              type: 'string',
              title: 'Comments',
            },
            replicates: {
              title: 'Replicates',
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  testReplicateId: {
                    type: 'string',
                    title: 'Test Replicate Id',
                  },
                  sampleType: {
                    type: 'string',
                    title: 'Sample Type',
                  },
                  testType: {
                    type: 'string',
                    title: 'Test Type',
                  },
                  number: {
                    type: 'number',
                    title: 'Number',
                  },
                },
              },
            },
            status: {
              title: 'Status',
              type: 'string',
              enum: ['AwaitingAnalysis'],
              enumNames: ['Awaiting Analysis'],
            },
          },
        },
      },
      performanceTestOutcome: {
        title: 'Performance Test Outcome',
        type: 'object',
        properties: {
          qualityEventRequired: {
            type: 'boolean',
            title: 'Quality Event Required',
          },
          qualityEventNumber: {
            type: 'string',
            title: 'Quality Event Number',
          },
          comments: {
            type: 'string',
            title: 'Comments',
          },
          plannedDeviationNumbers: {
            title: 'Planned Deviation Numbers',
            type: 'array',
            items: {
              type: 'string',
              title: 'PlannedDeviationNumber',
            },
          },
          hasQualityEvents: {
            type: 'boolean',
            title: 'Has Quality Events',
          },
        },
      },
      auditLogOutcome: {
        title: 'Audit Log Outcome',
        type: 'object',
        properties: {
          firstMagnetSelfTestAdcValue: {
            type: 'number',
            title: 'First Magnet Self Test Adc Value',
          },
          lastMagnetSelfTestAdcValue: {
            type: 'number',
            title: 'Last Magnet Self Test Adc Value',
          },
          difference: {
            type: 'number',
            title: 'Difference',
          },
          outcome: {
            type: 'boolean',
            title: 'Outcome',
          },
          qualityEventRequired: {
            type: 'boolean',
            title: 'Quality Event Required',
          },
          qualityEventNumber: {
            type: 'string',
            title: 'Quality Event Number',
          },
          comments: {
            type: 'string',
            title: 'Comments',
          },
          plannedDeviationNumbers: {
            title: 'Planned Deviation Numbers',
            type: 'array',
            items: {
              type: 'string',
              title: 'PlannedDeviationNumber',
            },
          },
        },
      },
      releasePreparation: {
        title: 'Release Preparation',
        type: 'object',
        properties: {
          factoryResetDate: {
            type: 'string',
            title: 'Factory Reset Date',
          },
          anyErrorsDuringInstrumentBootUpAfterFactoryReset: {
            type: 'boolean',
            title: 'Any Errors During Instrument Boot Up After Factory Reset',
          },
          errorCodes: {
            title: 'Error Codes',
            type: 'array',
            items: {
              type: 'string',
              title: 'ErrorCode',
            },
          },
          comments: {
            type: 'string',
            title: 'Comments',
          },
          decontaminationDate: {
            type: 'string',
            title: 'Decontamination Date',
          },
          cleanDate: {
            type: 'string',
            title: 'Clean Date',
          },
          carriedOutBy: {
            title: 'Carried Out By',
            type: 'object',
            properties: {
              user: {
                title: 'User',
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name',
                  },
                },
              },
              date: {
                type: 'string',
                title: 'Date',
              },
            },
          },
          verifiedBy: {
            title: 'Verified By',
            type: 'object',
            properties: {
              user: {
                title: 'User',
                type: 'object',
                properties: {
                  name: {
                    type: 'string',
                    title: 'Name',
                  },
                },
              },
              date: {
                type: 'string',
                title: 'Date',
              },
            },
          },
        },
      },
      logFile: {
        title: 'Log File',
        type: 'object',
        properties: {
          instrumentId: {
            type: 'string',
            title: 'Instrument Id',
          },
          filename: {
            type: 'string',
            title: 'Filename',
          },
          contentType: {
            type: 'string',
            title: 'Content Type',
          },
          contentLength: {
            type: 'number',
            title: 'Content Length',
          },
          status: {
            title: 'Status',
            type: 'string',
            enum: ['Uploaded', 'Processed'],
            enumNames: ['Uploaded', 'Processed'],
          },
        },
      },
      plaqueCalibration: {
        title: 'Plaque Calibration',
        type: 'object',
        properties: {
          calibrationValue: {
            type: 'number',
            title: 'Calibration Value',
          },
          meanDifferenceValue: {
            type: 'number',
            title: 'Mean Difference Value',
          },
          biasValue: {
            type: 'number',
            title: 'Bias Value',
          },
        },
      },
      instrumentId: {
        type: 'string',
        title: 'Instrument Id',
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  flexReworkInstrument: false,
  piezoMagnetCheckOutcome: {},
  tests: [],
  performanceTestOutcome: {},
  auditLogOutcome: {},
  releasePreparation: {},
  logFile: {},
  plaqueCalibration: {},
  instrumentId: '',
});
