import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

const WarningField = ({ message }: { message?: string }) => (
  <Alert severity="warning">
    <AlertTitle>{message}</AlertTitle>
  </Alert>
);

export default WarningField;
