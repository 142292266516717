import { apiPut, apiPatch } from 'modules/Core/actions';
import { LidOrBase, DepositionMethod } from '../domain';

export enum RecordDepositionItemActions {
  CALL = 'pilot/batch-records/RECORD_DEPOSITION_ITEM',
  REQUEST = 'pilot/batch-records/RECORD_DEPOSITION_ITEM_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_DEPOSITION_ITEM_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_DEPOSITION_ITEM_FAILURE',
}

export enum VerifyDepositionItemActions {
  CALL = 'pilot/batch-records/VERIFY_DEPOSITION_ITEM',
  REQUEST = 'pilot/batch-records/VERIFY_DEPOSITION_ITEM_REQUEST',
  SUCCESS = 'pilot/batch-records/VERIFY_DEPOSITION_ITEM_SUCCESS',
  FAILURE = 'pilot/batch-records/VERIFY_DEPOSITION_ITEM_FAILURE',
}

export enum AmendDepositionItemActions {
  CALL = 'pilot/batch-records/AMEND_DEPOSITION_ITEM',
  REQUEST = 'pilot/batch-records/AMEND_DEPOSITION_ITEM_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_DEPOSITION_ITEM_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_DEPOSITION_ITEM_FAILURE',
}

export type RecordDepositionItemPayload = {
  depositionMethod: DepositionMethod;
  assetId: string;
  splits: number[];
  channels: number[];
  zone: '' | 'A' | 'B' | 'C';
  chemistry: string;
  chemistryOther?: string;
  volume: number;
  lidOrBase: LidOrBase | null;
};

export type AmendDepositionItemPayload = RecordDepositionItemPayload & {
  subRecordId?: string;
  comment?: string;
};

export const recordDepositionItem = (recordId: string, payload: RecordDepositionItemPayload) =>
  apiPut(
    `/pilot/batch-records/${recordId}/deposition/depositions`,
    RecordDepositionItemActions.CALL,
    {
      ...payload,
    }
  );

export const verifyDepositionItem = (recordId: string, subRecordId: string) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/deposition/depositions/verify`,
    VerifyDepositionItemActions.CALL,
    {
      subRecordId,
    }
  );

export const amendDepositionItem = (recordId: string, payload: AmendDepositionItemPayload) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/deposition/depositions`,
    AmendDepositionItemActions.CALL,
    {
      ...payload,
    }
  );
