/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@material-ui/core';
import useStyles from '@ldx-dmp/Common/components/FormDialog/FormDialog.styles';

import { JsonFormProps } from './JsonFormProps';
import { useJsonForms } from './useJsonForms';

import ObjectField from './ObjectFieldTemplate';
import TemplateField from './FieldTemplate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NormalFormProps<T = any> = {
  initialValues: T;
  onClose: () => void;
  isSubmitting?: boolean;
  children?: React.ReactNode;
  isValid?: boolean;
  showErrorList?: boolean;
  topButtons?: boolean;
} & Omit<JsonFormProps<T>, 'component'>;

export function NormalForm<T>({
  title,
  schema,
  uiSchema,
  initialValues,
  onSubmit,
  onClose,
  isSubmitting,
  disabled,
  validation,
  showErrorList = false,
  children,
  onSave,
  fieldTemplate = TemplateField,
  ...props
}: NormalFormProps<T>) {
  const classes = useStyles();
  const { t } = { t: str => str };

  const { JsonForm } = useJsonForms<T>();

  return (
    <>
      <JsonForm
        title={title}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        formData={initialValues}
        schema={schema}
        uiSchema={uiSchema}
        ObjectFieldTemplate={ObjectField}
        FieldTemplate={fieldTemplate}
        widgets={{}}
        showErrorList={showErrorList}
        validationSchema={validation}
        onSave={onSave}
        {...props}
      >
        {children || (
          <div className={classes.actionButtons}>
            <Button variant="contained" onClick={onClose} disabled={isSubmitting || disabled}>
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting || disabled}
            >
              {isSubmitting && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
              {t('Save')}
            </Button>
          </div>
        )}
      </JsonForm>
    </>
  );
}

export default NormalForm;
