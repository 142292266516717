import React from 'react';
import { useDispatch } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { FormDialog } from 'modules/Common/components';
import { openDialog } from 'modules/Core/actions/dialogs';
import SectionPart from 'modules/Pilot/BatchRecord/SingleView/components/SectionPart';
import { PartStatus } from 'modules/RecordManagement';
import { Counter } from 'mdi-material-ui';
import useStyles from './StripRejects.styles';
import useFormDefinition from './StripRejects.form';

type StripRejectsProps = {
  batchRecord: BatchRecord;
};

export default ({ batchRecord }: StripRejectsProps) => {
  const dispatch = useDispatch();
  const { stripRejects } = batchRecord.inspection;
  const formDefintion = useFormDefinition(batchRecord);
  const classes = useStyles();

  return (
    <SectionPart
      xs={12}
      title="Strip Rejects"
      status={batchRecord.inspection.stripRejects.status}
      actionChip={{
        label: 'Update',
        onChipClicked: () => dispatch(openDialog(formDefintion.dialogName)),
        icon: <Counter />,
        visible: batchRecord.inspection.stripRejects.status === PartStatus.Ignore,
      }}
    >
      <Table stickyHeader size="small" className={classes.root}>
        <TableHead>
          <TableRow>
            <TableCell>Reject Description</TableCell>
            <TableCell>Count</TableCell>
            <TableCell>%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stripRejects && (
            <>
              {stripRejects.rejectsByReason.map(rejects => (
                <TableRow key={rejects.id}>
                  <TableCell className="rowHeader" scope="row" variant="head" component="th">
                    {rejects.code}. {rejects.description}
                  </TableCell>
                  <TableCell>{rejects.count}</TableCell>
                  <TableCell>{rejects.percentageOfTotal}%</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell
                  align="right"
                  className="footerTotal"
                  scope="row"
                  variant="head"
                  component="th"
                >
                  TOTAL:
                </TableCell>
                <TableCell>{stripRejects.totalRejects}</TableCell>
                <TableCell>{stripRejects.rejectedPercentage}%</TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
      <FormDialog definition={formDefintion} />
    </SectionPart>
  );
};
