import {
  BatchRecord,
  DepositionAsset,
  ChemistryDefinition,
  BatchRecordActivity,
} from 'modules/Pilot/BatchRecord/domain';
import {
  CreateBatchRecordActions,
  AmendBatchRecordActions,
  AddLidPrintActions,
  FetchBatchRecordsActions,
  FetchBatchRecordActions,
  setCurrentBatchRecord,
  AddBasePrintActions,
  AddAdhesiveActions,
  RecordSubstrateLaminationActions,
  AmendSubstrateLaminationActions,
  UpdateSubAssemblyRejectCountsActions,
  RecordFoilPouchLotNumberActions,
  RecordReagentNumbersActions,
  AmendReagentNumbersActions,
  RecordBlockAidNumbersActions,
  AmendBlockAidNumbersActions,
  SignOffActions,
  RecordLaserUsageActions,
  VerifyLaserUsageActions,
  RecordAssemblyActions,
  RecordBoxOvenUsageActions,
  RecordFinalLaminiationActions,
  AmendFinalLaminationActions,
  BatchRecordLoadingActions,
  GetChemistryDefinitionsActions,
  GetDepositionAssetsActions,
  RecordDepositionItemActions,
  AmendLidPrintActions,
  FetchBatchRecordActivityActions,
  AmendBasePrintActions,
  AmendAdhesiveActions,
  AmendLaserUsageActions,
  AmendAssemblyActions,
  VerifyAssemblyActions,
  RecordSubstrateOgpInspectionActions,
  RecordFirstLabelPrepared,
  VerifyFirstLabelPrepared,
  RecordRemainingLabelsPreparedActions,
  SignOffSectionActions,
  UnlockSectionActions,
  AmendFoilPouchLotNumberActions,
  GiveLineClearanceActions,
  RecordBaseAssemblyActions,
  RecordSingulationActions,
  AmendSingulationActions,
  RecordOvenManagementCardFeed,
  AmendBoxOvenUsageActions,
  ReviseStripRejectCountsActions,
  RecordVisualInspectionCarriedOutActions,
  RecordPackagingAndSealingCarriedOutActions,
  RecordFinalOgpInspectionActions,
  AttachDevelopmentBatchRequestActions,
  AddCommentToSectionActions,
  SignOffManufacturingActions,
  RejectBatchRecordActions,
  AmendFinalOgpInspectionActions,
  AmendSubstrateOgpInspectionActions,
  AmendFirstLabelImageActions,
  AmendDevelopmentBatchRequestActions,
} from 'modules/Pilot/BatchRecord/actions';
import { VerifyDepositionItemActions, AmendDepositionItemActions } from '../actions/deposition';
import {
  AmendTunnelDryerUsageActions,
  RecordTunnelDryerUsageActions,
} from '../actions/tunnelDryer';
import { RecordBatchCompletionActions } from '../actions/batchCompletion';

export enum LoadingState {
  IDLE,
  INIT,
  FETCHING,
  LOADED,
}

export type BatchRecordState = {
  items: BatchRecord[];
  totalCount: number;

  activity: BatchRecordActivity[];
  depositionAssets: DepositionAsset[];
  chemistryDefinitions: ChemistryDefinition[];
  loading: LoadingState;
};

const initialState = {
  items: [],
  totalCount: 0,
  activity: [],
  depositionAssets: [],
  chemistryDefinitions: [],
  loading: LoadingState.IDLE,
};

export default (state: BatchRecordState = initialState, action) => {
  switch (action.type) {
    case FetchBatchRecordsActions.CLEAR:
      return {
        ...state,
        items: [],
        totalCount: 0,
        loading: LoadingState.IDLE,
      };
    case FetchBatchRecordsActions.REQUEST:
      // eslint-disable-next-line no-case-declarations
      const loading =
        state.loading === LoadingState.IDLE ? LoadingState.INIT : LoadingState.FETCHING;
      return {
        ...state,
        loading,
      };
    case BatchRecordLoadingActions.INITIALISED:
      return {
        ...state,
        loading: LoadingState.LOADED,
      };
    case GetChemistryDefinitionsActions.SUCCESS:
      return {
        ...state,
        chemistryDefinitions: action.payload,
      };
    case GetDepositionAssetsActions.SUCCESS: {
      return {
        ...state,
        depositionAssets: action.payload,
      };
    }
    case CreateBatchRecordActions.SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload],
        totalCount: state.totalCount + 1,
      };
    case FetchBatchRecordsActions.SUCCESS:
      return {
        ...state,
        loading: LoadingState.LOADED,
        ...action.payload,
      };
    case AmendBatchRecordActions.SUCCESS:
    case AddLidPrintActions.SUCCESS:
    case AmendLidPrintActions.SUCCESS:
    case AddBasePrintActions.SUCCESS:
    case AmendBasePrintActions.SUCCESS:
    case AddAdhesiveActions.SUCCESS:
    case AmendAdhesiveActions.SUCCESS:
    case RecordLaserUsageActions.SUCCESS:
    case VerifyLaserUsageActions.SUCCESS:
    case AmendLaserUsageActions.SUCCESS:
    case RecordAssemblyActions.SUCCESS:
    case AmendAssemblyActions.SUCCESS:
    case VerifyAssemblyActions.SUCCESS:
    case RecordSubstrateLaminationActions.SUCCESS:
    case AmendSubstrateLaminationActions.SUCCESS:
    case RecordSubstrateOgpInspectionActions.SUCCESS:
    case AmendSubstrateOgpInspectionActions.SUCCESS:
    case UpdateSubAssemblyRejectCountsActions.SUCCESS:
    case RecordFirstLabelPrepared.SUCCESS:
    case AmendFirstLabelImageActions.SUCCESS:
    case VerifyFirstLabelPrepared.SUCCESS:
    case RecordRemainingLabelsPreparedActions.SUCCESS:
    case SignOffActions.SUCCESS:
    case FetchBatchRecordActions.SUCCESS:
    case RecordFoilPouchLotNumberActions.SUCCESS:
    case AmendFoilPouchLotNumberActions.SUCCESS:
    case RecordReagentNumbersActions.SUCCESS:
    case AmendReagentNumbersActions.SUCCESS:
    case RecordBlockAidNumbersActions.SUCCESS:
    case AmendBlockAidNumbersActions.SUCCESS:
    case RecordFinalLaminiationActions.SUCCESS:
    case AmendFinalLaminationActions.SUCCESS:
    case ReviseStripRejectCountsActions.SUCCESS:
    case RecordDepositionItemActions.SUCCESS:
    case VerifyDepositionItemActions.SUCCESS:
    case AmendDepositionItemActions.SUCCESS:
    case RecordOvenManagementCardFeed.SUCCESS:
    case RecordBoxOvenUsageActions.SUCCESS:
    case AmendBoxOvenUsageActions.SUCCESS:
    case RecordTunnelDryerUsageActions.SUCCESS:
    case AmendTunnelDryerUsageActions.SUCCESS:
    case RecordBaseAssemblyActions.SUCCESS:
    case RecordSingulationActions.SUCCESS:
    case AmendSingulationActions.SUCCESS:
    case SignOffSectionActions.SUCCESS:
    case UnlockSectionActions.SUCCESS:
    case GiveLineClearanceActions.SUCCESS:
    case RecordVisualInspectionCarriedOutActions.SUCCESS:
    case RecordPackagingAndSealingCarriedOutActions.SUCCESS:
    case RecordFinalOgpInspectionActions.SUCCESS:
    case AmendFinalOgpInspectionActions.SUCCESS:
    case AddCommentToSectionActions.SUCCESS:
    case RecordBatchCompletionActions.SUCCESS:
    case AttachDevelopmentBatchRequestActions.SUCCESS:
    case AmendDevelopmentBatchRequestActions.SUCCESS:
    case SignOffManufacturingActions.SUCCESS:
    case RejectBatchRecordActions.SUCCESS: {
      if (state.items.length === 0) {
        return {
          ...state,
          items: [action.payload],
        };
      }

      const items = state.items.filter(x => x.id !== action.payload.id);

      return {
        ...state,
        items: [...items, action.payload],
      };
    }
    case FetchBatchRecordActivityActions.SUCCESS:
      return {
        ...state,
        activity:
          state.activity.length === 0
            ? [action.payload]
            : state.activity.map(record =>
                record.id === action.payload.id ? action.payload : record
              ),
      };
    case setCurrentBatchRecord.type:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
};
