/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PagedResultDto } from '@ldx-dmp/Core/models';

import { TestSessionDto } from 'modules/InstrumentsQualityControl/models';

export type TestSessionsState = {
  items: PagedResultDto<TestSessionDto>;
  current?: TestSessionDto | null;
  formOpen: boolean;
  loading: 'NONE' | 'IDLE' | 'LOADING' | 'RELOADING';
  summaryType: 'CARD' | 'TABLE';
  addInstrumentsOpen: boolean;
  addControlInstrumentsOpen: boolean;
};

export const initialState: TestSessionsState = {
  loading: 'NONE',
  formOpen: false,
  items: new PagedResultDto<TestSessionDto>(),
  summaryType: 'TABLE',
  addInstrumentsOpen: false,
  addControlInstrumentsOpen: false,
};

export const testSessionsSlice = createSlice({
  name: 'test-sessions',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<PagedResultDto<TestSessionDto>>) => {
      state.items = action.payload;
    },
    openForm: state => {
      state.formOpen = true;
    },
    closeForm: state => {
      state.formOpen = false;
    },
    setCurrent: (state, action: PayloadAction<TestSessionDto | null>) => {
      state.current = action.payload;
    },
    startLoading: state => {
      state.loading = state.loading === 'NONE' ? 'LOADING' : 'RELOADING';
    },
    stopLoading: state => {
      state.loading = state.loading === 'NONE' ? 'NONE' : 'IDLE';
    },
    setSummaryType: (state, action: PayloadAction<'CARD' | 'TABLE'>) => {
      state.summaryType = action.payload;
    },
    setAddInstruments: (state, action: PayloadAction<boolean>) => {
      state.addInstrumentsOpen = action.payload;
    },
    setAddControlInstruments: (state, action: PayloadAction<boolean>) => {
      state.addControlInstrumentsOpen = action.payload;
    },
  },
});

export const {
  setData,
  openForm,
  closeForm,
  startLoading,
  stopLoading,
  setCurrent,
  setSummaryType,
  setAddInstruments,
  setAddControlInstruments,
} = testSessionsSlice.actions;

export const selectFormOpen = (state: TestSessionsState) => state.formOpen;
export const selectItems = (state: TestSessionsState) => state.items;
export const selectLoading = (state: TestSessionsState) => state.loading;
export const selectCurrent = (state: TestSessionsState) => state.current;
export const selectSummaryType = (state: TestSessionsState) => state.summaryType;
export const selectAddInstrumentsOpen = (state: TestSessionsState) => state.addInstrumentsOpen;
export const selectAddControlInstrumentsOpen = (state: TestSessionsState) =>
  state.addControlInstrumentsOpen;

export default testSessionsSlice.reducer;
