import React from 'react';
import VisualInspectionSignOffDialog from '../VisualInspectionSignOffDialog';
import useVisualInspectionTest from '../../hooks/useVisualInspectionTest';

export default function VisualInspectionContainer({
  current,
  setCurrent,
  onCorrectionsSubmit,
  fetchCurrent,
  children,
  initialInstrument,
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Record<string, any>) {
  const usage = 'VisualInspection';

  const {
    showSignOff,
    test,
    setShowSignOff,
    setTest,
    toggleForm: hideForm,
    setInstrument,
    showCorrections,
    setShowCorrections,
    submitReview,
    ...rest
  } = useVisualInspectionTest(current, setCurrent, fetchCurrent, initialInstrument);

  return (
    <>
      {children({
        ...props,
        ...rest,
        setCurrent,
        current,
        usage,
      })}

      <VisualInspectionSignOffDialog
        open={showSignOff && !!test}
        setShowSignOff={setShowSignOff}
        setTest={setTest}
        setShowTests={hideForm}
        onSubmit={submitReview}
        test={test}
        setCurrent={setCurrent}
        setInstrument={setInstrument}
      />
      <VisualInspectionSignOffDialog
        title="Submit Corrections"
        open={showCorrections && !!test}
        setShowSignOff={setShowCorrections}
        setTest={setTest}
        setShowTests={hideForm}
        onSubmit={onCorrectionsSubmit}
        test={test}
        initialValues={{
          ...test?.visualInspection,
        }}
        setCurrent={setCurrent}
        setInstrument={setInstrument}
        canEditOutcome={false}
      />
    </>
  );
}
