import React, { ReactNode, ReactElement } from 'react';
import { startCase, lowerCase } from 'lodash';
import { Chip, Divider, Grid, Typography } from '@material-ui/core';
import { CheckDecagram, PlusCircle } from 'mdi-material-ui';
import clsx from 'clsx';
import { PartStatus } from 'modules/RecordManagement';
import { BreakpointStep } from 'modules/Common/types';
import useStyles from './SectionPart.styles';

type ActionChip = {
  onChipClicked: () => {};
  label: string;
  icon: ReactElement;
  visible: boolean;
};

type SectionPartProps = {
  title: string;
  xs?: BreakpointStep;
  actionChip?: ActionChip;
  status?: PartStatus;
  children: ReactNode;
};

export const addRowChipAction = (onChipClicked: () => {}, visible: boolean) => ({
  onChipClicked,
  label: 'Add',
  icon: <PlusCircle />,
  visible,
});

export const signChipAction = (onChipClicked: () => {}, visible: boolean) => ({
  onChipClicked,
  label: 'Sign',
  icon: <CheckDecagram />,
  visible,
});

export default ({ title, actionChip, xs, status, children }: SectionPartProps) => {
  const classes = useStyles();
  const showStatus = status && status !== PartStatus.Ignore && status !== PartStatus.ReadOnly;

  return (
    <Grid item xs={xs} id={title}>
      <div className={clsx(classes.root, lowerCase(status))}>
        <Grid item container className="recordTableHeader">
          <Grid item>
            <Typography variant="h6" style={{ flex: 1 }}>
              {title}
            </Typography>
          </Grid>
          <Grid container item xs justify="flex-end" spacing={1}>
            {showStatus && (
              <Grid item>
                <Chip label={startCase(status)} />
              </Grid>
            )}
            {actionChip && actionChip.visible && (
              <Grid item>
                <Chip
                  label={actionChip.label}
                  icon={actionChip.icon}
                  onClick={actionChip.onChipClicked}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider />
        {children}
      </div>
    </Grid>
  );
};
