/* eslint-disable @typescript-eslint/no-explicit-any */
import useServer from 'modules/Instruments/useServer';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  recieveSearch,
  SerialSearchResult,
  startSearch,
} from '../reducers/instrumentSearchReducer';

export default () => {
  const server = useServer();
  const dispatch = useDispatch();

  const result = useSelector<any>(
    state => state?.instruments?.search?.result
  ) as SerialSearchResult;

  const search = React.useCallback(
    async serial => {
      dispatch(startSearch());
      const response = await server.searchInstrumentBySerialAsync<SerialSearchResult>(serial);
      dispatch(recieveSearch(response.data));
      return response.data;
    },
    [dispatch, server]
  );

  return React.useMemo(() => ({ search, result }), [result, search]);
};
