import { SubRecord, SigningAction, Part } from 'modules/RecordManagement';

export type LaminationItem = SubRecord & {
  splits: number[];
  passes: number;
  speed: number;
  laminatedBy: SigningAction;
};

export type RejectSummary = Part & {
  rejectsByReason: RejectCount[];
  totalRejects: number;
};

export type RejectCount = {
  id: string;
  code: string;
  description: string;
  count: number;
};

export type OgpInspection = Part & {
  attachments: FileAttachment[];
  inspectedBy: SigningAction;
};

export type DepositionAsset = {
  id: string;
  assetNumber: string;
  associatedMethod: DepositionMethod;
};

export type ChemistryDefinition = {
  id: string;
  name: string;
};

export enum DepositionMethod {
  Hamilton = 'Hamilton',
  Fisnar = 'Fisnar',
  Both = 'Both',
}

export type FileAttachment = {
  key: string;
  fileName: string;
  contentType: string;
  contentLength: number;
};

export enum Sections {
  Preparation = 'Preparation',
  Deposition = 'Deposition',
  Drying = 'Drying',
  PostDrying = 'PostDrying',
  Inspection = 'Inspection',
  SignOff = 'SignOff',
}
