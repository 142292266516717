/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import merge from 'lodash/merge';
import useStyles from '@ldx-dmp/Common/components/FormDialog/FormDialog.styles';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import InstrumentResult from 'modules/InstrumentsQualityControl/features/instruments/components/InstrumentResult';
import { Form, Spinner } from '@ldx-dmp/Common/components';
import useFormSubmitting from '@ldx-dmp/Common/useFormSubmitting';
import AddCorrectionsUser from './AddCorrectionsUser';

export type TestSessionInstrumentTestFormProps<T> = {
  instrument: TestSessionInstrumentDto;
  form: any;
  onSave?: (data: Partial<T>) => Promise<unknown>;
  onSubmit: (data: Partial<T>) => Promise<unknown>;
  status?: string;
  type: string;
  buttonText?: string;
  hideButton?: boolean;
  hideForm?: boolean;
} & Record<string, any>;

export function TestSessionInstrumentTestForm<T>({
  instrument,
  form,
  onSave,
  onSubmit,
  status,
  type,
  buttonText = 'Complete',
  hideForm = false,
  hideButton = false,
  testEnabled = true,
  getStatus,
  autoSaveEnabled,
  getTestPermissions,
  getTest,
  addCorrectionsUser,
  configuration,
  ...props
}: TestSessionInstrumentTestFormProps<T>) {
  const { isSubmitting, handleSubmit } = useFormSubmitting(onSubmit);
  const { isSubmitting: isSaving, handleSubmit: handleSave } = useFormSubmitting(onSave);
  const isReviewed = status === 'SignedOff';
  const classes = useStyles();

  if (!form || !form?.mapToEdit) {
    return <Spinner />;
  }

  const title = instrument ? (
    <Grid container>
      <Grid item xs>
        {instrument?.instrument?.serial}
      </Grid>
      <Grid item lg>
        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          <Grid item>
            <AddCorrectionsUser
              instrument={instrument}
              status={status}
              permissions={getTestPermissions(instrument)}
              test={getTest(instrument)}
              addCorrectionsUser={addCorrectionsUser}
            />
            <InstrumentResult value={status || getStatus(instrument)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    'Untitled'
  );

  return (
    <>
      {!testEnabled && (
        <Alert severity="warning">
          <AlertTitle>Test is currently not available</AlertTitle>
        </Alert>
      )}
      {!hideForm && testEnabled && (
        <>
          <Form
            key={instrument?.id}
            {...form}
            schema={{
              ...form.schema,
              title,
            }}
            isSubmitting={isSubmitting}
            disabled={isSaving}
            enableReinitialize
            onSave={autoSaveEnabled && handleSave}
            initialValues={
              merge({}, form?.empty, form.mapToEdit(instrument, configuration)) || form?.empty || {}
            }
            onSubmit={handleSubmit}
            getStatus={getStatus}
            topButtons={!hideButton}
            autoSaveId={instrument?.id}
            autoSaveTag={`${type}-update`}
            {...props}
          >
            <div
              className={classes.actionButtons}
              style={{
                marginRight: 0,
                paddingRight: 0,
              }}
            >
              {!hideButton && (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || isSaving || isReviewed}
                >
                  {isSubmitting && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
                  {buttonText}
                </Button>
              )}
            </div>
          </Form>
        </>
      )}
    </>
  );
}
