import React from 'react';
import { PagedResultDto } from 'modules/Core';
import { InstrumentSubsystemDto } from '../models';
import useServer from './useServer';

export default platform => {
  const server = useServer();
  const [items, setItems] = React.useState<{ value: string | number; label: string }[]>([]);

  return React.useMemo(
    () => ({
      items,
      fetch: () =>
        server
          .getListAsync<PagedResultDto<InstrumentSubsystemDto>>(0, 1000, 'name')
          .then(response =>
            setItems(
              response?.data?.items
                ?.filter(x => x.platform === platform)
                ?.map(x => ({
                  value: String(x.id),
                  label: String(x.name),
                })) || []
            )
          ),
    }),
    [items, server, platform]
  );
};
