/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

export type FormValues = {
  repairLocation?: string;
  requestDate: ParsableDate;
  isWipedDown?: 'yes' | 'no' | 'na' | '';
  nameOfMeterUser?: string;
  meterLocation?: string;
  affectedSubsystems?: string[];
  requestDescription?: string;
  workCarriedOut?: string;
};

export default (editMode): yup.ObjectSchema<FormValues> => {
  let obj = {
    requestDate: yup.date().defined(),
    isWipedDown: yup
      .string()
      .defined()
      .oneOf(['yes', 'no', 'na'] as const),
    nameOfMeterUser: yup.string().defined(),
    meterLocation: yup.string().defined(),
    requestDescription: yup.string().defined(),
    affectedSubsystems: yup
      .array()
      .of(yup.string().defined())
      .defined(),
    workCarriedOut: yup.string().optional(),
  };

  if (!editMode) {
    obj = {
      ...obj,
      repairLocation: yup.string().defined(),
    } as any;
  }

  return yup.object(obj).defined();
};
