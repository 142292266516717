/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import { TestSessionDto, TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import UpdateVisualInspectionToInstrumentInput from 'modules/InstrumentsQualityControl/models/UpdateVisualInspectionToInstrumentInput';
import OperatorsWidget from '../widgets/OperatorsWidget';
import PassFailWidget from '../widgets/PassFailWidget';
import ReworkRequriedWidget from '../widgets/ReworkRequriedWidget';
import ConditionalField from '../widgets/ConditionalField';
import RubberFeetWidget from '../widgets/RubberFeetWidget';
import InstrumentResultWidget from '../widgets/InstrumentResultWidget';
import GoodsReceivedNumberWidget from '../widgets/GoodsReceivedNumberWidget';
import CaseField from '../widgets/CaseField';

function noop<T>(id: T) {
  return id;
}

function configureYesNoNotApplicableInput(item) {
  const { title } = item;
  delete item.title;

  item.properties.value.title = title;
  item.properties.notApplicableComment.title = `${title} Not Applicable Comment`;
}

export const schema = sch => {
  configureYesNoNotApplicableInput(sch.properties.softwareUploadRequired);
  configureYesNoNotApplicableInput(sch.properties.currentSoftwareNowInstalled);

  sch.properties.flexReworkInstrument.type = 'string';

  return sch;
};

function configureDoorUi() {
  const conditionalField = {
    'ui:field': ConditionalField,
    predicate: values =>
      values.door.doesTheDoorOpenSmoothlyWithoutObstructionAndDislocation?.toString() === 'false' ||
      values.door.doesTheDoorCloseSmoothlyWithoutObstructionAndDislocationAndCanSelfClose?.toString() ===
        'false',
  };
  return {
    'ui:grid:xs': 6,
    'ui:card': true,

    doesTheDoorOpenSmoothlyWithoutObstructionAndDislocation: {
      'ui:widget': 'radio',
    },
    doesTheDoorCloseSmoothlyWithoutObstructionAndDislocationAndCanSelfClose: {
      'ui:widget': 'radio',
    },
    reworkRequired: {
      'ui:widget': ReworkRequriedWidget,
      ...conditionalField,
    },
    reworkCarriedOutBy: {
      'ui:widget': OperatorsWidget,
      'ui:options': {
        select: ({ all }) => all,
      },
      ...conditionalField,
    },
    verifiedBy: {
      'ui:widget': OperatorsWidget,
      'ui:options': {
        select: ({ all }) => all,
      },
      ...conditionalField,
    },
    theDoorShouldOpenAndCloseSmoothlyWithoutDislocatingAndAcceptableGap: {
      'ui:widget': PassFailWidget,
    },
    theDoorShouldNotBeLooseOrDifficultToOpenClose: {
      'ui:widget': PassFailWidget,
    },
  };
}

function configureRubberFeetUi() {
  const conditionalField = {
    'ui:field': ConditionalField,
    predicate: values =>
      values.rubberFeet.areAllFourFeetFirmlyAttachedToTheInstrumentCasing?.toString() === 'false',
  };
  return {
    'ui:grid:xs': 6,
    'ui:card': true,
    areAllFourFeetFirmlyAttachedToTheInstrumentCasing: {
      'ui:widget': 'radio',
    },
    ifFeetAreLoosePleaseIndicateWhichFeet: {
      'ui:widget': RubberFeetWidget,
      ...conditionalField,
    },
    reworkRequired: {
      'ui:widget': ReworkRequriedWidget,
      ...conditionalField,
    },
    reworkCarriedOutBy: {
      'ui:widget': OperatorsWidget,
      'ui:options': {
        select: ({ all }) => all,
      },
      ...conditionalField,
    },
    verifiedBy: {
      'ui:widget': OperatorsWidget,
      'ui:options': {
        select: ({ all }) => all,
      },
      ...conditionalField,
    },
    allFourRubberFeetShouldBeFirmlyAttachedToTheInstrumentCasing: {
      'ui:widget': PassFailWidget,
    },
    theInstrumentShouldBeLevelWhenPlacedOnAStableLevelSurface: {
      'ui:widget': PassFailWidget,
    },
  };
}

export const uiSchema = ui => {
  ui.qualityEventRequired = {
    'ui:widget': 'radio',
  };
  ui.qualityEventNumber = {
    'ui:field': ConditionalField,
    predicate: values => values?.qualityEventRequired?.toString() === 'true',
  };

  ui['ui:disable'] = values => values.visualInspectionStatus === 'ReadyForReview';

  ui['ui:order'] = [
    'serialNumberVerifiedAgainstLabel',
    'visualInspectionOutcome',
    'visualInspectionStatus',
    'goodsReceivedNumber',
    'flexReworkInstrument',
    'detailsVerifiedBy',
    'currentReleasedSoftware',
    'softwareVersionInstalledOnInstrument',
    'softwareUploadRequired',
    'currentSoftwareNowInstalled',
    'carriedOutBy',
    'case',
    'labels',
    'door',
    'screen',
    'rubberFeet',
    'stripDetectionAndNfcOperation',
    '*',
  ];

  ui.instrumentId = {
    'ui:widget': 'hidden',
  };
  ui.serialNumberVerifiedAgainstLabel = {
    'ui:widget': PassFailWidget,
    'ui:grid:xs': 6,
  };

  ui.visualInspectionStatus = {
    'ui:widget': 'hidden',
    'ui:field': InstrumentResultWidget,
    'ui:grid:xs': 6,
  };

  ui.goodsReceivedNumber = {
    'ui:widget': GoodsReceivedNumberWidget,
    'ui:options': {
      select: (current: TestSessionDto) => current.instruments?.map(x => x.visualInspection),
    },
    'ui:grid:xs': 6,
  };

  ui.visualInspectionOutcome = {
    'ui:field': InstrumentResultWidget,
    'ui:grid:xs': 6,
  };

  ui.flexReworkInstrument = {
    'ui:grid:xs': 6,
    'ui:widget': 'radio',
  };

  ui.detailsVerifiedBy = {
    'ui:widget': OperatorsWidget,
    'ui:options': {
      select: ({ all }) => all,
    },
  };
  ui.currentReleasedSoftware = {
    'ui:grid:xs': 6,
  };
  ui.softwareVersionInstalledOnInstrument = {
    'ui:grid:xs': 6,
  };
  ui.softwareUploadRequired = {
    'ui:hideTitle': true,
    'ui:collapse': true,
    value: { 'ui:widget': 'radio' },
    notApplicableComment: {
      'ui:field': ConditionalField,
      predicate: values => values.softwareUploadRequired.value === 'NotApplicable',
    },
  };
  ui.currentSoftwareNowInstalled = {
    'ui:hideTitle': true,
    'ui:collapse': true,
    'ui:field': ConditionalField,
    predicate: values => values.softwareUploadRequired.value === 'Yes',
    value: { 'ui:widget': 'radio' },
    notApplicableComment: {
      'ui:field': ConditionalField,
      predicate: values => values.currentSoftwareNowInstalled.value === 'NotApplicable',
    },
  };
  ui.carriedOutBy = {
    'ui:field': ConditionalField,
    predicate: values => values.softwareUploadRequired.value === 'Yes',
    'ui:widget': OperatorsWidget,
    'ui:options': {
      select: ({ current }) => current,
    },
  };
  ui.case = {
    'ui:field': CaseField,
    'ui:grid:xs': 6,
    'ui:card': true,
    instrumentCasingShouldBeInspectedForMajorDefects: {
      'ui:widget': PassFailWidget,
    },
    theWhiteTopCasingShouldBeSecurelyAndEvenlyAttachedToTheBlackBaseUnit: {
      'ui:widget': PassFailWidget,
    },
  };

  const caseDisable = {
    'ui:disable': values =>
      values.case?.instrumentCasingShouldBeInspectedForMajorDefects?.toString() === 'false' ||
      values.case?.theWhiteTopCasingShouldBeSecurelyAndEvenlyAttachedToTheBlackBaseUnit?.toString() ===
        'false',
  };
  ui.door = {
    ...configureDoorUi(),
    ...caseDisable,
  };
  ui.screen = {
    ...caseDisable,
    'ui:grid:xs': 6,
    'ui:card': true,
    theScreenShouldNotBeCrackedScratchedOrChipped: {
      'ui:widget': PassFailWidget,
    },
    theScreenShouldFitFlushWithTheInstrumentCasing: {
      'ui:widget': PassFailWidget,
    },
    whenTheInstrumentIsPoweredOnTheScreenShouldProduceAColourDisplayWithNoObviousDefects: {
      'ui:widget': 'radio',
    },
    whenTheInstrumentIsPoweredOnTheScreenShouldProvideTouchscreenFunctionality: {
      'ui:widget': 'radio',
    },
  };

  ui.rubberFeet = {
    ...caseDisable,
    ...configureRubberFeetUi(),
  };
  ui.labels = {
    ...caseDisable,
    'ui:grid:xs': 6,
    'ui:card': true,
    serialNumberOnLabelMatchesSerialNumberInAboutScreen: {
      'ui:widget': 'radio',
    },
    theLumiradxLogoShouldBeVisibleOnTheTopSideOfTheInstrumentBetweenTheScreenAndTheDoor: {
      'ui:widget': PassFailWidget,
    },
    theNfcIconShouldBeVisibleOnTheRightHandSideOfTheInstrument: {
      'ui:widget': PassFailWidget,
    },
    labelShouldBeFreeFromPrintDefectsAndFormatAndContentShouldMatchThatSpecifiedInTheCurrentRevision: {
      'ui:widget': PassFailWidget,
    },
  };
  ui.stripDetectionAndNfcOperation = {
    ...caseDisable,
    'ui:card': true,
    'ui:grid:xs': 6,
    instrumentDetectsStrips: {
      'ui:widget': 'radio',
    },
    instrumentRecognisesRfidTag: {
      'ui:widget': 'radio',
    },
    instrumentSuccessfullyUploadsLotCalibrationFiles: {
      'ui:widget': 'radio',
    },
  };

  return ui;
};

export const mapToEdit = () => (
  current: TestSessionInstrumentDto
): UpdateVisualInspectionToInstrumentInput => {
  const inspection = current?.visualInspection || {};
  return {
    ...inspection,
    instrumentId: current?.id,
  } as UpdateVisualInspectionToInstrumentInput;
};

export const fields = noop;

export const widgets = noop;

const validateNotApplicableComment = {
  value: yup.mixed().required(),
  notApplicableComment: yup.string().when('value', {
    is: 'NotApplicable',
    then: () => yup.string().required(),
    otherwise: () =>
      yup
        .string()
        .nullable()
        .optional(),
  }),
};

const always = {
  serialNumberVerifiedAgainstLabel: yup.bool().required(),
  goodsReceivedNumber: yup.string().required(),
  flexReworkInstrument: yup.string().required(),
  detailsVerifiedBy: yup
    .string()
    .required()
    .notOneOf(['00000000-0000-0000-0000-000000000000']),
  softwareVersionInstalledOnInstrument: yup
    .string()
    .required()
    .min(4),
  softwareUploadRequired: yup.object(validateNotApplicableComment),
  currentSoftwareNowInstalled: yup.object().when('softwareUploadRequired', {
    is: softwareUploadRequired => softwareUploadRequired?.value?.toString() === 'Yes',
    then: yup.object(validateNotApplicableComment),
    otherwise: yup.object().optional(),
  }),
  carriedOutBy: yup.mixed().when('softwareUploadRequired', {
    is: softwareUploadRequired => softwareUploadRequired?.value?.toString() === 'Yes',
    then: yup
      .mixed()
      .required()
      .notOneOf(['00000000-0000-0000-0000-000000000000']),
    otherwise: yup.mixed().optional(),
  }),
  case: yup.object({
    instrumentCasingShouldBeInspectedForMajorDefects: yup.mixed().required(),
    theWhiteTopCasingShouldBeSecurelyAndEvenlyAttachedToTheBlackBaseUnit: yup.mixed().required(),
  }),
  qualityEventRequired: yup.mixed().required(),
  qualityEventNumber: yup.mixed().when('qualityEventRequired', {
    is: value => value?.toString() === 'true',
    then: yup.mixed().required(),
    otherwise: yup.mixed().optional(),
  }),
};

const full = {
  door: yup.object({
    doesTheDoorOpenSmoothlyWithoutObstructionAndDislocation: yup.mixed().required(),
    doesTheDoorCloseSmoothlyWithoutObstructionAndDislocationAndCanSelfClose: yup.mixed().required(),
    theDoorShouldOpenAndCloseSmoothlyWithoutDislocatingAndAcceptableGap: yup.mixed().required(),
    theDoorShouldNotBeLooseOrDifficultToOpenClose: yup.mixed().required(),
    reworkCarriedOutBy: yup
      .mixed()
      .when(
        [
          'doesTheDoorOpenSmoothlyWithoutObstructionAndDislocation',
          'doesTheDoorCloseSmoothlyWithoutObstructionAndDislocationAndCanSelfClose',
        ],
        {
          is: (first, second) => first?.toString() === 'false' || second?.toString() === 'false',
          then: yup
            .mixed()
            .required()
            .notOneOf(['00000000-0000-0000-0000-000000000000']),
          otherwise: yup.mixed().optional(),
        }
      ),
    verifiedBy: yup
      .mixed()
      .when(
        [
          'doesTheDoorOpenSmoothlyWithoutObstructionAndDislocation',
          'doesTheDoorCloseSmoothlyWithoutObstructionAndDislocationAndCanSelfClose',
        ],
        {
          is: (first, second) => first?.toString() === 'false' || second?.toString() === 'false',
          then: yup
            .mixed()
            .required()
            .notOneOf(['00000000-0000-0000-0000-000000000000']),
          otherwise: yup.mixed().optional(),
        }
      ),
  }),
  screen: yup.object({
    theScreenShouldNotBeCrackedScratchedOrChipped: yup.mixed().required(),
    theScreenShouldFitFlushWithTheInstrumentCasing: yup.mixed().required(),
    whenTheInstrumentIsPoweredOnTheScreenShouldProduceAColourDisplayWithNoObviousDefects: yup
      .mixed()
      .required(),
    whenTheInstrumentIsPoweredOnTheScreenShouldProvideTouchscreenFunctionality: yup
      .mixed()
      .required(),
  }),
  rubberFeet: yup.object({
    areAllFourFeetFirmlyAttachedToTheInstrumentCasing: yup.mixed().required(),
    // TODO: rubber feet selection - DMP-365
    allFourRubberFeetShouldBeFirmlyAttachedToTheInstrumentCasing: yup.mixed().required(),
    theInstrumentShouldBeLevelWhenPlacedOnAStableLevelSurface: yup.mixed().required(),
  }),
  labels: yup.object({
    serialNumberOnLabelMatchesSerialNumberInAboutScreen: yup.mixed().required(),
    theLumiradxLogoShouldBeVisibleOnTheTopSideOfTheInstrumentBetweenTheScreenAndTheDoor: yup
      .mixed()
      .required(),
    theNfcIconShouldBeVisibleOnTheRightHandSideOfTheInstrument: yup.mixed().required(),
    labelShouldBeFreeFromPrintDefectsAndFormatAndContentShouldMatchThatSpecifiedInTheCurrentRevision: yup
      .mixed()
      .required(),
  }),
  stripDetectionAndNfcOperation: yup.object({
    instrumentDetectsStrips: yup.mixed().required(),
    instrumentRecognisesRfidTag: yup.mixed().required(),
    instrumentSuccessfullyUploadsLotCalibrationFiles: yup.mixed().required(),
  }),
};

const caseValidation = val => ({
  is: value => {
    const result =
      value?.instrumentCasingShouldBeInspectedForMajorDefects?.toString() === 'false' ||
      value?.theWhiteTopCasingShouldBeSecurelyAndEvenlyAttachedToTheBlackBaseUnit?.toString() ===
        'false';
    return result;
  },

  otherwise: val,
  then: yup.object().optional(),
});

export const validation = () =>
  yup
    .object({
      ...always,
      door: yup.object().when('case', caseValidation(full.door)),
      screen: yup.object().when('case', caseValidation(full.screen)),
      rubberFeet: yup.object().when('case', caseValidation(full.rubberFeet)),
      labels: yup.object().when('case', caseValidation(full.labels)),
      stripDetectionAndNfcOperation: yup
        .object()
        .when('case', caseValidation(full.stripDetectionAndNfcOperation)),
    })

    .defined();

export const empty = noop;
