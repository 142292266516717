import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { AppConfig } from 'modules/Core/types';
import { getConfig } from 'modules/Core/reducers/selectors';
import { OperatorDto } from 'modules/InstrumentsQualityControl/models';

export default function(current, setCurrent, type, autoLoad = true) {
  const config = useSelector(getConfig) as AppConfig;

  const [fetching, setFetching] = React.useState(false);
  const [formOpen, setShowAddOperators] = React.useState(false);
  const [operators, setOperators] = React.useState<OperatorDto[] | undefined>();

  React.useEffect(() => {
    if (operators || !autoLoad || fetching) return;
    setFetching(true);
    axios
      .get(`${config.api.base_uri}/instruments/quality-control/operators`)
      .then(response => setOperators(response.data.items));
  }, [autoLoad, config.api.base_uri, fetching, operators]);

  const onSubmit = React.useCallback(
    data =>
      axios
        .patch(
          `${config.api.base_uri}/instruments/quality-control/test-sessions/${current?.id}/${type}/add-operators`,
          data
        )
        .then(response => {
          if (setCurrent) setCurrent(response.data);
          setShowAddOperators(false);
        }),
    [config.api.base_uri, current?.id, setCurrent, type]
  );

  return React.useMemo(
    () => ({
      all: operators || [],
      operators,
      formOpen,
      onOpen: () => setShowAddOperators(true),
      onSubmit,
      onClose: () => setShowAddOperators(false),
      title: 'Operators',
      buttonText: 'Add Operators',
      select: props => props.operators,
    }),
    [onSubmit, operators, formOpen]
  );
}
