import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthRouter } from '@ldx-dmp/Auth/';
import { InstrumentRouter } from '@ldx-dmp/Instruments';
import InstrumentRepairRouter from '@ldx-dmp/Instruments/Repair/routes';
import InstrumentCatalogueRouter from 'modules/Instruments/Catalogue/routes';
import ReagentsRouter from 'modules/Reagents/Home/routes';
import { PilotLineRouter } from '@ldx-dmp/Pilot';
import { InstrumentsQualityControlRouter } from '@ldx-dmp/InstrumentsQualityControl';
import { GlobalRouter } from '@ldx-dmp/Global';
import { ReleaseReviewRouter } from 'modules/ReleaseReview';
import { ElasticSearchRouter } from '@ldx-dmp/ElasticSearch';
import StripProductionRouter from '@ldx-dmp/StripProduction/StripLotRecord/routes';
import { AppLoader, SignedInLayout } from '../containers';

const Home = () => <></>;

const AppRouter = () => (
  <Switch>
    {/* <Route path="/error" component={ErrorPage} /> */}
    <Route path="/auth" component={AuthRouter} />
    <Route path="/">
      <AppLoader>
        <SignedInLayout>
          <Route exact path="/" component={Home} />
          <Route path="/instruments-quality-control" component={InstrumentsQualityControlRouter} />
          <Route path="/instruments" component={InstrumentRouter} />
          <Route path="/pilot-line" component={PilotLineRouter} />
          <Route path={GlobalRouter.path} component={GlobalRouter} />
          <Route path={ReleaseReviewRouter.path} component={ReleaseReviewRouter} />
          <Route path={ElasticSearchRouter.path} component={ElasticSearchRouter} />
          <Route path={InstrumentRepairRouter.path} component={InstrumentRepairRouter} />
          <Route path={InstrumentCatalogueRouter.path} component={InstrumentCatalogueRouter} />
          <Route path={ReagentsRouter.path} component={ReagentsRouter} />
          <Route path={StripProductionRouter.path} component={StripProductionRouter} />
        </SignedInLayout>
      </AppLoader>
    </Route>
  </Switch>
);

export default AppRouter;
