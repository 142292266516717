import { apiPost } from 'modules/Core/actions';

export enum RecordOvenManagementCardFeed {
  CALL = 'pilot/batch-records/RECORD_OVEN_CARD_FEED',
  REQUEST = 'pilot/batch-records/RECORD_OVEN_CARD_FEED_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_OVEN_CARD_FEED_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_OVEN_CARD_FEED_FAILURE',
}

export const recordOvenManagementCardFeed = (recordId: string) =>
  apiPost(
    `/pilot/batch-records/${recordId}/drying/oven-cardfeed`,
    RecordOvenManagementCardFeed.CALL,
    {}
  );
