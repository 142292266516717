import React from 'react';
import { WidgetProps, FieldProps } from '@rjsf/core';
import { OperatorDto } from 'modules/InstrumentsQualityControl/models';
import OperatorsContext from '../../operatorsContext';

export default ({ registry, uiSchema, ...props }: WidgetProps & FieldProps) => {
  const selectOptions = (uiSchema['ui:options']?.select || (({ all }) => all)) as (
    values
  ) => OperatorDto[] | undefined;
  const { SelectWidget } = registry.widgets;

  return (
    <OperatorsContext.Consumer>
      {values => (
        <SelectWidget
          {...props}
          uiSchema={uiSchema}
          options={{
            enumOptions:
              selectOptions(values)?.map(x => ({
                value: String(x.id),
                label: String(x.name),
              })) || [],
          }}
        />
      )}
    </OperatorsContext.Consumer>
  );
};
