/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React from 'react';
import { orderBy } from 'lodash';
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useStyles from '@ldx-dmp/Common/components/FormDialog/FormDialog.styles';

import DragIndicatorIcon from '@material-ui/icons/DragIndicatorOutlined';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';

const reorder = (list: TestSessionInstrumentDto[], startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const TestSessionIntrumentsList = ({
  instruments,
  onSave,
  onClose,
}: {
  instruments: TestSessionInstrumentDto[] | undefined | null;
  onSave: (data: Pick<TestSessionInstrumentDto, 'id' | 'indexNumber'>[]) => void;
  onClose: () => void;
}) => {
  const classes = useStyles();
  const [ordered, setOrdered] = React.useState<TestSessionInstrumentDto[]>(
    orderBy(Object.values(instruments || {}), x => x.indexNumber)
  );

  const onDragEnd = React.useCallback(result => {
    if (!result.destination) {
      return;
    }

    setOrdered(o =>
      reorder(o, result.source.index, result.destination.index).map((item, indexNumber) => ({
        ...item,
        indexNumber,
      }))
    );
  }, []);

  const save = React.useCallback(() => {
    onSave(ordered.map(({ id, indexNumber }) => ({ id, indexNumber })));
  }, [onSave, ordered]);

  if (!instruments) {
    return <></>;
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogContent>
        <List>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {ordered.map((instrument, index) => (
                    <Draggable key={instrument.id} draggableId={instrument.id} index={index}>
                      {provided => (
                        <>
                          <ListItem
                            key={instrument.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ListItemAvatar>
                              <DragIndicatorIcon />
                            </ListItemAvatar>
                            <ListItemText primary={instrument?.instrument?.serial} />
                          </ListItem>
                          <Divider />
                        </>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>

        <div className={classes.actionButtons}>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" color="primary" variant="contained" onClick={save}>
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TestSessionIntrumentsList;
