/* eslint-disable no-param-reassign */
import SetPlannedDeviationsNumbersInput from 'modules/InstrumentsQualityControl/models/SetPlannedDeviationsNumbersInput';
import { TestSessionDto } from 'modules/InstrumentsQualityControl/models';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.title = 'Test Session Planned Deviations';
  sch.properties.plannedDeviationNumbers.items.title = 'Planned Deviation Number';

  return sch;
};

export const uiSchema = ui => {
  ui = {
    'ui:collapse': true,
  };
  ui.plannedDeviationNumbers = {
    'ui:collapse': true,
    'ui:hideTitle': true,
  };
  return ui;
};

export const mapToEdit = () => ({
  plannedDeviationNumbers = [],
}: TestSessionDto): SetPlannedDeviationsNumbersInput => {
  return {
    plannedDeviationNumbers,
  };
};

export const fields = noop;

export const widgets = noop;

export const validation = () => null;

export const empty = noop;
