import React from 'react';
import * as yup from 'yup';
import { FormikValues } from 'formik';
import { formatShortDate } from 'modules/Common/utilities';
import { FormikTextField, FormikCheckboxGroup } from 'modules/Common/components';
import { RecordDefinition } from 'modules/Pilot/BatchRecord/SingleView/components/RecordTable/RecordTable';
import { BatchRecord, MaterialLotNumberWithSplit } from 'modules/Pilot/BatchRecord/domain';
import {
  addLidPrint,
  AddLidPrintActions,
  amendLidPrint,
  AmendLidPrintActions,
} from 'modules/Pilot/BatchRecord/actions';
import { DataTableRow } from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable/TableData';
import { PartStatus } from 'modules/RecordManagement';
import { UserPermission } from 'modules/Core/types';
import getInitialSplits from '../../initialSplits';

interface Values extends FormikValues {
  lotNumber: string;
  splits: number[];
  comment?: string;
}

export default (
  batchRecord: BatchRecord,
  itemsBeingEdited: { key: string; itemId: string }[],
  userPermissions: UserPermission[]
): RecordDefinition<Values> => {
  const dialogName = 'pilot/batch-records/LID_PRINT';
  const recordId = batchRecord.id;
  const { splits } = batchRecord.batchRequest;
  const { status, subRecords, requiredPermissions } = batchRecord.preparation.lidPrints;
  const itemBeingEdited = itemsBeingEdited.find(
    item =>
      item.key === `${dialogName}_EDIT` &&
      subRecords &&
      subRecords.findIndex(lidPrint => lidPrint.id === item.itemId) > -1
  );
  const rowForEditing = itemBeingEdited
    ? subRecords.find(lidPrint => lidPrint.id === itemBeingEdited.itemId)
    : null;

  const isAllowedToEdit = () => {
    if (status === PartStatus.ReadOnly || status === PartStatus.Locked) return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  const newSubmit = (values: Values, _, dispatch) => dispatch(addLidPrint(recordId, { ...values }));
  const editSubmit = (values: Values, _, dispatch) =>
    dispatch(amendLidPrint(recordId, { ...values, subRecordId: rowForEditing?.id }));

  const createDataTableRow = (row: MaterialLotNumberWithSplit): DataTableRow => ({
    key: row.id,
    fields: {
      lotNumber: { value: row.lotNumber },
      splits: { value: row.splits.join(', ') },
      preparedBy: { user: row.preparedBy.user.name, date: formatShortDate(row.preparedBy.date) },
    },
  });

  const validation = {
    lotNumber: yup
      .string()
      .defined()
      .min(1),
    splits: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(splits.length),
  };

  const editValidation = {
    ...validation,
    comment: yup
      .string()
      .defined()
      .required()
      .min(5),
  };

  return {
    title: 'Lid Print',
    tableData: {
      columns: [
        { accessor: 'lotNumber', label: 'Lot Number(s)' },
        { accessor: 'splits', label: 'Splits' },
        { accessor: 'preparedBy', label: 'Prepared by', isSignOff: true },
      ],
      rows: subRecords ? subRecords.map(row => createDataTableRow(row)) : [],
    },
    canAddRow: status === PartStatus.Incomplete,
    canEditRows: isAllowedToEdit(),
    formDefinition: {
      title: 'Lid Print',
      dialogName,
      apiAction: rowForEditing ? AmendLidPrintActions.CALL : AddLidPrintActions.CALL,
      onSubmit: rowForEditing ? editSubmit : newSubmit,
      initialValues: {
        lotNumber: rowForEditing ? rowForEditing.lotNumber : '',
        splits: getInitialSplits(rowForEditing, splits),
      },
      form: () => (
        <>
          <FormikTextField name="lotNumber" label="Lid Print Lot Number" />
          <FormikCheckboxGroup
            name="splits"
            label="Splits"
            options={splits}
            labelPlacement="top"
            allowSelectAll
          />
          {rowForEditing && <FormikTextField name="comment" label="Reason for change" multiline />}
        </>
      ),
      validationSchema: (): yup.ObjectSchema<Values> =>
        yup
          .object()
          .shape(rowForEditing ? editValidation : validation)
          .defined(),
    },
  };
};
