import React from 'react';
import { Fade } from '@material-ui/core';
import { useBoolean } from 'react-use';

import { Spinner, TabManager } from '@ldx-dmp/Common/components';

import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';

import QCContext from 'modules/InstrumentsQualityControl/context';
import TestSessionIntrumentsDataTable from '../TestSessionIntrumentsDataTable';
import TestSessionControlIntrumentsDataTable from '../TestSessionControlIntrumentsDataTable';
import OrderInstruments from '../TestSessionIntrumentsList';
import AddInstruments from '../AddInstruments';
import AddControlInstruments from '../AddControlInstruments';
import TestSessionSummary from '../TestSessionSummary';
import TestInstrumentDetails from '../TestInstrumentDetails';
import VisualInspectionSignOffDialog from '../VisualInspectionSignOffDialog';
import InstrumentDispositionDialog from '../InstrumentDispositionDialog';
import PerformanceTestReviewDialog from '../PerformanceTestReviewDialog';

const Details = () => {
  const [
    currentInstrument,
    setCurrentInstrument,
  ] = React.useState<TestSessionInstrumentDto | null>();

  const [showOrder, setShowOrder] = useBoolean(false);
  const [showSignOff, setShowSignOff] = useBoolean(false);
  const [signOffType, setSignOffType] = React.useState('');

  const [showInstrumentDisposition, setShowInstrumentDisposition] = useBoolean(false);

  const orderInstruments = React.useCallback(() => {
    setShowOrder(true);
  }, [setShowOrder]);

  const closeOrder = React.useCallback(() => setShowOrder(false), [setShowOrder]);

  return (
    <QCContext.Consumer>
      {({
        current,
        isLoading,
        openAddInstruments,
        addInstrumentsOpen,
        details,
        saveOrder,
        openAddControlInstruments,
        addControlInstrumentsOpen,
        submitReview,
        canAddInstruments,
        setCurrent,
        submitInstrumentDisposition,
        canOrderInstruments,
        fetchCurrent,
        onCorrectionsSubmit,
      }) => {
        const handleStatusClick = (type, status, instrument) => {
          setShowSignOff(true);
          setSignOffType(type);
          setCurrentInstrument(instrument);
        };
        const handleStartInstrumentDisposition = instrument => {
          setCurrentInstrument(instrument);
          setShowInstrumentDisposition();
        };

        if (!current && isLoading) {
          return <Spinner />;
        }

        const showInstrumentDetails =
          currentInstrument && !showSignOff && !showInstrumentDisposition;

        return (
          <Fade in>
            <>
              <TestSessionSummary items={details?.summary} />
              <TabManager>
                {[
                  {
                    name: 'instruments',
                    tabLabel: 'Instruments',
                    hideHeading: true,
                    component: (
                      <div style={{ width: '100%' }}>
                        <TestSessionIntrumentsDataTable
                          instruments={current?.instruments}
                          openAddInstruments={openAddInstruments as () => void}
                          onClick={i => {
                            if (fetchCurrent)
                              fetchCurrent().then(sess =>
                                setCurrentInstrument(sess.instruments?.find(x => x.id === i.id))
                              );
                          }}
                          orderInstruments={orderInstruments}
                          canAddInstruments={canAddInstruments}
                          canOrderInstruments={canOrderInstruments}
                          onStatusClick={handleStatusClick}
                          startInstrumentDisposition={handleStartInstrumentDisposition}
                          readOnly
                        />
                      </div>
                    ),
                  },
                  {
                    name: 'controlInstruments',
                    tabLabel: 'Control Instruments',
                    hideHeading: true,
                    component: (
                      <div style={{ width: '100%' }}>
                        <TestSessionControlIntrumentsDataTable
                          instruments={current?.controlInstruments}
                          openAddControlInstruments={openAddControlInstruments as () => void}
                          onClick={() => {}}
                          canAddInstruments={canAddInstruments}
                        />
                      </div>
                    ),
                  },
                ]}
              </TabManager>
              {addInstrumentsOpen && (
                <AddInstruments currentInstruments={current?.instruments?.map(x => x.instrument)} />
              )}
              {addControlInstrumentsOpen && (
                <AddControlInstruments currentInstruments={current?.controlInstruments} />
              )}
              {showInstrumentDetails && (
                <TestInstrumentDetails
                  open={showInstrumentDetails}
                  instrument={currentInstrument}
                  onClose={() => setCurrentInstrument(null)}
                  current={current}
                  setCurrent={setCurrent}
                  fetchCurrent={fetchCurrent}
                  onCorrectionsSubmit={onCorrectionsSubmit}
                />
              )}
              {showOrder && (
                <OrderInstruments
                  instruments={current?.instruments}
                  onClose={closeOrder}
                  onSave={newOrder => {
                    if (saveOrder) saveOrder(newOrder).then(closeOrder);
                  }}
                />
              )}
              <VisualInspectionSignOffDialog
                open={showSignOff && signOffType === 'visual-inspection'}
                setShowSignOff={setShowSignOff}
                setTest={setCurrentInstrument}
                setShowTests={() => {}}
                onSubmit={submitReview}
                test={currentInstrument}
                setCurrent={setCurrent}
                setInstrument={() => {}}
              />
              <PerformanceTestReviewDialog
                open={showSignOff && signOffType === 'performance-test'}
                setShowSignOff={setShowSignOff}
                setTest={setCurrentInstrument}
                setShowTests={() => {}}
                onSubmit={submitReview}
                test={currentInstrument}
                setCurrent={setCurrent}
                setInstrument={() => {}}
              />
              <InstrumentDispositionDialog
                open={showInstrumentDisposition}
                setShowSignOff={setShowInstrumentDisposition}
                setTest={setCurrentInstrument}
                setShowTests={() => {}}
                onSubmit={submitInstrumentDisposition}
                test={currentInstrument}
                setCurrent={setCurrent}
                setInstrument={() => {}}
              />
            </>
          </Fade>
        );
      }}
    </QCContext.Consumer>
  );
};

export default Details;
