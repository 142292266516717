import { apiPost } from 'modules/Core/actions';

export enum SignOffActions {
  CALL = 'pilot/batch-records/SIGN_OFF',
  REQUEST = 'pilot/batch-records/SIGN_OFF_REQUEST',
  SUCCESS = 'pilot/batch-records/SIGN_OFF_SUCCESS',
  FAILURE = 'pilot/batch-records/SIGN_OFF_FAILURE',
}

export enum SignOffType {
  LaserVerification = 'LaserVerification',
  AssemblyVerification = 'AssemblyVerification',
  OgpPostLaminationInspection = 'OgpPostLaminationInspection',
  FirstOffLabelPrepared = 'FirstOffLabelPrepared',
  FirstOffLabelVerification = 'FirstOffLabelVerification',
  RemainingLabelsPrepared = 'RemainingLabelsPrepared',
  LineClearanceGiven = 'LineClearanceGiven',
  DepositionVerification = 'DepositionVerification',
  OvenManagementCardFeedSignOff = 'OvenManagementCardFeedSignOff',
  BaseAssemblySignOff = 'BaseAssemblySignOff',
  SingulationSignOff = 'SingulationSignOff',
  VisualInspectionSignOff = 'VisualInspectionSignOff',
  PackagingAndSealingSignOff = 'PackagingAndSealingSignOff',
  OgpInspection = 'OgpInspection',
}

type SignOffPayload = {
  action: SignOffType;
  subRecordId?: string;
};

export default (recordId: string, payload: SignOffPayload) =>
  apiPost(`/pilot/batch-records/${recordId}/sign`, SignOffActions.CALL, {
    ...payload,
  });
