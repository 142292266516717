export default theme => ({
  testResult: {
    borderBottom: '1px solid #eee',
  },

  testPass: {
    color: '#4caf50',
  },

  testFail: {
    color: '#f44336',
  },

  testNa: {
    color: '#000',
  },

  testCategory: {
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: 0,
    },
  },

  stackedListHeader: {
    backgroundColor: '#eee',
    textAlign: 'right',
  },
});
