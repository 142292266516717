const namespace = 'instruments/repair';

export const OPEN_REQUEST_FORM = `${namespace}/OPEN_REQUEST_FORM`;
export const CLOSE_REQUEST_FORM = `${namespace}/CLOSE_REQUEST_FORM`;

export const CREATE_REPAIR_REQUEST = `${namespace}/CREATE_REPAIR_REQUEST`;
export const CREATE_REPAIR_SUCCESS = `${namespace}/CREATE_REPAIR_SUCCESS`;
export const CREATE_REPAIR_FAILURE = `${namespace}/CREATE_REPAIR_FAILURE`;

export const GET_POST_TESTS_REQUEST = `${namespace}/GET_POST_TESTS_REQUEST`;
export const GET_POST_TESTS_SUCCESS = `${namespace}/GET_POST_TESTS_SUCCESS`;
export const GET_POST_TESTS_FAILURE = `${namespace}/GET_POST_TESTS_FAILURE`;

export const UPDATE_REPAIR_REQUEST = `${namespace}/UPDATE_REPAIR_REQUEST`;
export const UPDATE_REPAIR_SUCCESS = `${namespace}/UPDATE_REPAIR_SUCCESS`;
export const UPDATE_REPAIR_FAILURE = `${namespace}/UPDATE_REPAIR_FAILURE`;

export const RESOLVE_REPAIR_REQUEST = `${namespace}/RESOLVE_REPAIR_REQUEST`;
export const RESOLVE_REPAIR_SUCCESS = `${namespace}/RESOLVE_REPAIR_SUCCESS`;
export const RESOLVE_REPAIR_FAILURE = `${namespace}/RESOLVE_REPAIR_FAILURE`;

export const SET_CURRENT_REPAIR = `${namespace}/SET_CURRENT_REPAIR`;

export const FETCH_REPAIR_REQUESTS_REQUEST = `${namespace}/FETCH_REPAIR_REQUESTS_REQUEST`;
export const FETCH_REPAIR_REQUESTS_SUCCESS = `${namespace}/FETCH_REPAIR_REQUESTS_SUCCESS`;
export const FETCH_REPAIR_REQUESTS_ERROR = `${namespace}/FETCH_REPAIR_REQUESTS_ERROR`;

export const FETCH_REPAIR_REQUEST_REQUEST = `${namespace}/FETCH_REPAIR_REQUEST_REQUEST`;
export const FETCH_REPAIR_REQUEST_SUCCESS = `${namespace}/FETCH_REPAIR_REQUEST_SUCCESS`;
export const FETCH_REPAIR_REQUEST_ERROR = `${namespace}/FETCH_REPAIR_REQUEST_ERROR`;

export const FETCH_REPAIRS_FOR_INSTRUMENT_REQUEST = `${namespace}/FETCH_REPAIRS_FOR_INSTRUMENT_REQUEST`;
export const FETCH_REPAIRS_FOR_INSTRUMENT_SUCCESS = `${namespace}/FETCH_REPAIRS_FOR_INSTRUMENT_SUCCESS`;
export const FETCH_REPAIRS_FOR_INSTRUMENT_ERROR = `${namespace}/FETCH_REPAIRS_FOR_INSTRUMENT_ERROR`;

export const INSTRUMENT_TYPES_REQUEST = `${namespace}/INSTRUMENT_TYPES_REQUEST`;
export const INSTRUMENT_TYPES_SUCCESS = `${namespace}/INSTRUMENT_TYPES_SUCCESS`;
export const INSTRUMENT_TYPES_FAILURE = `${namespace}/INSTRUMENT_TYPES_FAILURE`;

export const TECHNICIAN_USERS_REQUEST = `${namespace}/TECHNICIAN_USERS_REQUEST`;
export const TECHNICIAN_USERS_SUCCESS = `${namespace}/TECHNICIAN_USERS_SUCCESS`;
export const TECHNICIAN_USERS_FAILURE = `${namespace}/TECHNICIAN_USERS_FAILURE`;

export const RECORD_TEST_RESULT_REQUEST = `${namespace}/RECORD_TEST_RESULT_REQUEST`;
export const RECORD_TEST_RESULT_SUCCESS = `${namespace}/RECORD_TEST_RESULT_SUCCESS`;
export const RECORD_TEST_RESULT_FAILURE = `${namespace}/RECORD_TEST_RESULT_FAILURE`;

export const ASSIGN_REPAIR_REQUEST = `${namespace}/ASSIGN_REPAIR_REQUEST`;
export const ASSIGN_REPAIR_SUCCESS = `${namespace}/ASSIGN_REPAIR_SUCCESS`;
export const ASSIGN_REPAIR_FAILURE = `${namespace}/ASSIGN_REPAIR_FAILURE`;

export const FETCH_INSTRUMENT_REPAIR_REQUESTS_REQUEST = `${namespace}/FETCH_INSTRUMENT_REPAIR_REQUESTS_REQUEST`;
export const FETCH_INSTRUMENT_REPAIR_REQUESTS_SUCCESS = `${namespace}/FETCH_INSTRUMENT_REPAIR_REQUESTS_SUCCESS`;
export const FETCH_INSTRUMENT_REPAIR_REQUESTS_ERROR = `${namespace}/FETCH_INSTRUMENT_REPAIR_REQUESTS_ERROR`;

export enum FetchRepairRequestsActionTypes {
  FETCH_REPAIR_REQUESTS = 'instruments/repair/FETCH_REPAIR_REQUESTS',
  REQUEST = 'instruments/repair/FETCH_REPAIR_REQUESTS_REQUEST',
  SUCCESS = 'instruments/repair/FETCH_REPAIR_REQUESTS_SUCCESS',
  ERROR = 'instruments/repair/FETCH_REPAIR_REQUESTS_ERROR',
}

export enum FetchRepairRequestActionTypes {
  FETCH_REPAIR_REQUEST = 'instruments/repair/FETCH_REPAIR_REQUEST',
  REQUEST = 'instruments/repair/FETCH_REPAIR_REQUEST_REQUEST',
  SUCCESS = 'instruments/repair/FETCH_REPAIR_REQUEST_SUCCESS',
  FAILURE = 'instruments/repair/FETCH_REPAIR_REQUEST_FAILURE',
}

export enum FetchRepairsForInstrumentActionTypes {
  FETCH_REPAIRS_FOR_INSTRUMENT = 'instruments/repair/FETCH_REPAIRS_FOR_INSTRUMENT',
  REQUEST = 'instruments/repair/FETCH_REPAIRS_FOR_INSTRUMENT_REQUEST',
  SUCCESS = 'instruments/repair/FETCH_REPAIRS_FOR_INSTRUMENT_SUCCESS',
  FAILURE = 'instruments/repair/FETCH_REPAIRS_FOR_INSTRUMENT_FAILURE',
}

export enum CreateRepairActionTypes {
  CREATE_REPAIR = 'instruments/repair/CREATE_REPAIR',
  REQUEST = 'instruments/repair/CREATE_REPAIR_REQUEST',
  SUCCESS = 'instruments/repair/CREATE_REPAIR_SUCCESS',
  FAILURE = 'instruments/repair/CREATE_REPAIR_FAILURE',
}

export enum UpdateRepairActionTypes {
  UPDATE_REPAIR = 'instruments/repair/UPDATE_REPAIR',
  REQUEST = 'instruments/repair/UPDATE_REPAIR_REQUEST',
  SUCCESS = 'instruments/repair/UPDATE_REPAIR_SUCCESS',
  FAILURE = 'instruments/repair/UPDATE_REPAIR_FAILURE',
}
