import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, EditAttributes, Person, Print } from '@material-ui/icons';
import {
  ContentHeader,
  SummaryPanel,
  SummaryPanelItem,
  PillButtonGroup,
  TabManager,
} from 'modules/Common';
import formatRepairStatus from '../../../../utilities/formatRepairStatus';
import FunctionalTestingView from '../../containers/FunctionalTestingView';
import AssignRequestForm from '../AssignRequestForm';
import ResolveRequestForm from '../ResolveRequestForm';
import RepairTimeline from '../RepairTimeline';
import styles from './RepairRequestDetails.styles';
import RequestDetailsPanel from '../RequestDetailsPanel';
import InstrumentDetailsPanel from '../InstrumentDetailsPanel';

const RepairRequestDetails = ({
  onEditClicked,
  onPrintClicked,
  repairRequest,
  relatedRequests,
  technicianUsers,
  postRepairTestSetup,
  currentUserId,
  instrument,
}) => {
  const classes = makeStyles(styles)();
  const [showAssignForm, setShowAssignForm] = React.useState(false);
  const [showResolveForm, setShowResolveForm] = React.useState(false);

  const handleAssignClicked = () => setShowAssignForm(true);
  const handleAssignClosed = () => setShowAssignForm(false);
  const handleResolveClicked = () => setShowResolveForm(true);
  const handleResolveClosed = () => setShowResolveForm(false);

  const handlePrintClicked = () => onPrintClicked();

  let wipedDownStatus = 'N/A';
  if (repairRequest.requestDetails.isInstrumentWipedDown !== null)
    wipedDownStatus = repairRequest.requestDetails.isInstrumentWipedDown ? 'Yes' : 'No';

  const actionButtons = [
    {
      text: 'Assign',
      onClick: handleAssignClicked,
      icon: <Person />,
    },
    {
      text: 'Edit',
      onClick: onEditClicked,
      icon: <Edit />,
    },
    {
      text: 'Resolve',
      onClick: handleResolveClicked,
      icon: <EditAttributes />,
    },
  ];

  return (
    <>
      <div className={classes.root}>
        <ContentHeader title={`Instrument Repair Request ${repairRequest.irn}`}>
          {repairRequest.status === 'Open' && <PillButtonGroup buttonData={actionButtons} />}
          {repairRequest.status === 'SignedOff' && (
            <PillButtonGroup
              buttonData={[
                {
                  text: 'Print',
                  onClick: handlePrintClicked,
                  icon: <Print />,
                },
              ]}
            />
          )}
        </ContentHeader>
        <Card>
          <CardContent>
            <SummaryPanel>
              <SummaryPanelItem
                primary="Type"
                secondary={repairRequest.instrument.version?.platformVersion}
              />
              <SummaryPanelItem
                primary="Serial Number"
                secondary={repairRequest.instrument.serial}
              />
              <SummaryPanelItem
                primary="Status"
                secondary={formatRepairStatus(repairRequest.status)}
              />
              <SummaryPanelItem
                primary="Assigned to"
                secondary={repairRequest.assignedTo ? repairRequest.assignedTo.name : '-'}
              />
            </SummaryPanel>
          </CardContent>
          <TabManager>
            {[
              {
                tabLabel: 'Request',
                panelHeading: 'Request Details',
                component: <RequestDetailsPanel repairRequest={repairRequest} />,
              },
              {
                tabLabel: 'Instrument',
                panelHeading: 'Instrument Details',
                component: (
                  <InstrumentDetailsPanel
                    instrument={repairRequest.instrument}
                    location={repairRequest.requestDetails.instrumentLocation?.name}
                    isInstrumentWipedDown={wipedDownStatus}
                    relatedRequests={relatedRequests}
                  />
                ),
              },
              {
                tabLabel: 'Testing',
                panelHeading: 'Post-repair Testing',
                component: (
                  <FunctionalTestingView
                    platform={repairRequest.instrument.version?.platform}
                    repairRequest={repairRequest}
                    technicianUsers={technicianUsers}
                    postRepairTestSetup={postRepairTestSetup}
                    currentUserId={currentUserId}
                    instrument={instrument}
                  />
                ),
              },
              {
                tabLabel: 'Activity',
                panelHeading: 'Activity Timeline',
                component: <RepairTimeline actions={repairRequest.actions} />,
              },
            ]}
          </TabManager>
        </Card>
      </div>
      <AssignRequestForm
        show={showAssignForm}
        handleClose={handleAssignClosed}
        technicianUsers={technicianUsers}
        assignedTo={repairRequest.assignedTo}
        requestId={repairRequest.id}
      />
      <ResolveRequestForm
        show={showResolveForm}
        handleClose={handleResolveClosed}
        repairRequest={repairRequest}
      />
    </>
  );
};

RepairRequestDetails.propTypes = {
  onEditClicked: PropTypes.func.isRequired,
  repairRequest: PropTypes.object.isRequired,
  technicianUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
    })
  ).isRequired,
  postRepairTestSetup: PropTypes.object.isRequired,
  relatedRequests: PropTypes.array.isRequired,
  currentUserId: PropTypes.string,
  onPrintClicked: PropTypes.func.isRequired,
  instrument: PropTypes.object.isRequired,
};

RepairRequestDetails.defaultProps = {
  currentUserId: null,
};

export default RepairRequestDetails;
