import { apiPatch } from 'modules/Core/actions';
import { createAction } from 'redux-api-middleware';

export enum RecordFirstLabelPrepared {
  CALL = 'pilot/batch-records/RECORD_FIRST_LABEL_PREPARED',
  REQUEST = 'pilot/batch-records/RECORD_FIRST_LABEL_PREPARED_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_FIRST_LABEL_PREPARED_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_FIRST_LABEL_PREPARED_FAILURE',
}

export const recordFirstLabelPrepared = (recordId: string, image: File) => {
  const data = new FormData();
  data.append('imageFile', image);

  return createAction({
    endpoint: `/pilot/batch-records/${recordId}/preparation/labels/first`,
    method: 'POST',
    types: [
      RecordFirstLabelPrepared.REQUEST,
      RecordFirstLabelPrepared.SUCCESS,
      RecordFirstLabelPrepared.FAILURE,
    ],
    body: data,
  });
};

export enum AmendFirstLabelImageActions {
  CALL = 'pilot/batch-records/AMEND_FIRST_LABEL_IMAGE',
  REQUEST = 'pilot/batch-records/AMEND_FIRST_LABEL_IMAGE_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_FIRST_LABEL_IMAGE_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_FIRST_LABEL_IMAGE_FAILURE',
}

export const amendFirstLabelImage = (recordId: string, image: File, comment: string) => {
  const data = new FormData();
  data.append('imageFile', image);
  data.append('comment', comment);

  return createAction({
    endpoint: `/pilot/batch-records/${recordId}/preparation/labels/first`,
    method: 'PATCH',
    types: [
      AmendFirstLabelImageActions.REQUEST,
      AmendFirstLabelImageActions.SUCCESS,
      AmendFirstLabelImageActions.FAILURE,
    ],
    body: data,
  });
};

export enum VerifyFirstLabelPrepared {
  CALL = 'pilot/batch-records/RECORD_FIRST_LABEL_VERIFIED',
  REQUEST = 'pilot/batch-records/RECORD_FIRST_LABEL_VERIFIED_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_FIRST_LABEL_VERIFIED_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_FIRST_LABEL_VERIFIED_FAILURE',
}

export const verifyFirstLabelPrepared = (recordId: string) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/preparation/labels/first/verify`,
    VerifyFirstLabelPrepared.CALL,
    {}
  );

export enum RecordRemainingLabelsPreparedActions {
  CALL = 'pilot/batch-records/RECORD_REMAINING_LABELS',
  REQUEST = 'pilot/batch-records/RECORD_REMAINING_LABELS_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_REMAINING_LABELS_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_REMAINING_LABELS_FAILURE',
}

export const recordRemainingLabelsPrepared = (recordId: string) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/preparation/labels/remainder`,
    RecordRemainingLabelsPreparedActions.CALL,
    {}
  );
