import React from 'react';
import { FilledInputProps, Grid, TextField } from '@material-ui/core';
import { InstrumentDto } from 'modules/Instruments/models';

export default ({
  onChange,
  value,
  instrument,
}: {
  onChange: FilledInputProps['onChange'];
  value: { firmwares: Record<string, string> };
  instrument: InstrumentDto;
}) => {
  return (
    <>
      {instrument?.subsystems
        ?.filter(x => x.trackFirmware)
        .map(sub => (
          <Grid item xs={12} sm={6} key={`firmwares[${String(sub.firmwareKey)}]`}>
            <TextField
              variant="filled"
              fullWidth
              id={`firmwares[${String(sub.firmwareKey)}]`}
              label={`${sub.name} Firmware Version`}
              onChange={onChange}
              value={value.firmwares[String(sub.firmwareKey)]}
            />
          </Grid>
        ))}
    </>
  );
};
