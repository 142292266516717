import { apiPost } from 'modules/Core/actions';

export enum RecordBaseAssemblyActions {
  CALL = 'pilot/batch-records/RECORD_BASE_ASSEMBLY',
  REQUEST = 'pilot/batch-records/RECORD_BASE_ASSEMBLY_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_BASE_ASSEMBLY_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_BASE_ASSEMBLY_FAILURE',
}

export const recordBaseAssembly = (recordId: string) =>
  apiPost(
    `/pilot/batch-records/${recordId}/post-drying/base-assembly`,
    RecordBaseAssemblyActions.CALL,
    {}
  );
