import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { ChevronUp, ChevronDown } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { navigationActionCreators } from 'modules/Core/actions';
import { selectors } from 'modules/Core/reducers';
import { groupNavigationItem } from 'modules/Core/data/types';
import clsx from 'clsx';
import icons from './icons';
import styles from './NavigationItem.styles';

const TooltipWrapper = ({ show = false, text = null, children }) => (
  <>
    {show && (
      <Tooltip title={text} placement="right-end" arrow>
        <span>{children}</span>
      </Tooltip>
    )}
    {!show && <>{children}</>}
  </>
);

const NavigationListItem = ({
  itemKey,
  text,
  iconKey = null,
  route = null,
  expandable = null,
  open = null,
  className = null,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (route) dispatch(push(route));
    if (expandable) dispatch(navigationActionCreators.toggleMainNavigationItem(itemKey));
  };

  return (
    <ListItem button onClick={handleClick} className={className}>
      {iconKey && <ListItemIcon>{icons[iconKey]}</ListItemIcon>}
      <ListItemText primaryTypographyProps={{ variant: 'subtitle1' }} primary={text} />
      {expandable && <>{open ? <ChevronUp /> : <ChevronDown />}</>}
    </ListItem>
  );
};

const NavigationGroup = ({ item }) => {
  const isSidebarOpen = useSelector(state => selectors.sidebarDrawerIsOpen(state));
  const classes = makeStyles(styles)();
  return (
    <>
      <TooltipWrapper show={!isSidebarOpen} text={item.text}>
        <NavigationListItem
          itemKey={item.key}
          text={item.text}
          iconKey={item.iconKey}
          route={item.route}
          expandable={item.children?.length > 0}
          open={item.open}
        />
      </TooltipWrapper>
      <Collapse in={item.open} timeout="auto">
        {item.children && (
          <List component="div" disablePadding>
            {item.children.map(child => (
              <TooltipWrapper
                show={!isSidebarOpen}
                text={`${item.text} ${child.text}`}
                key={child.key}
              >
                <NavigationListItem
                  itemKey={child.key}
                  text={child.text}
                  iconKey={child.iconKey}
                  route={child.route}
                  className={clsx(classes.menuItem, isSidebarOpen && classes.nested)}
                />
              </TooltipWrapper>
            ))}
          </List>
        )}
      </Collapse>
      <Divider />
    </>
  );
};

TooltipWrapper.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NavigationListItem.propTypes = {
  itemKey: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconKey: PropTypes.string,
  route: PropTypes.string,
  expandable: PropTypes.bool,
  open: PropTypes.bool,
  className: PropTypes.string,
};

NavigationGroup.propTypes = {
  item: PropTypes.shape(groupNavigationItem).isRequired,
};

export default NavigationGroup;
