/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import MUIDataTable, { ExpandButton } from 'mui-datatables';
import TimeAgo from 'react-timeago';
import {
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { formatShortDateTime } from 'modules/Common/utilities';
import { InstrumentSubsystemDto } from 'modules/Global/models';
import { FirmwareVersionDto, InstrumentDto, SerialNumberDto } from 'modules/Instruments/models';
import styles from './InstrumentSubsystems.styles';
import useProcesedFirmwares from './useProcesedFirmwares';

export const FirmwareVersions = ({
  items,
  children,
}: {
  items?: FirmwareVersionDto[];
  children: (a: ReturnType<typeof useProcesedFirmwares>) => React.ReactNode;
}) => {
  return <>{children(useProcesedFirmwares({ items }))}</>;
};

export const FirmwareVersionsTable = ({ items }: { items?: FirmwareVersionDto[] }) => {
  const { latest: data } = useProcesedFirmwares({ items });

  const components = React.useMemo(
    () => ({
      ExpandButton(props) {
        // eslint-disable-next-line react/destructuring-assignment, react/prop-types
        const canExpand = !!data[props.dataIndex]?.others?.length;

        if (!canExpand) return <div style={{ width: '24px' }} />;
        return <ExpandButton {...props} />;
      },
    }),
    [data]
  );

  return (
    <>
      <MUIDataTable
        data={data}
        components={components}
        columns={[
          { name: 'type', label: 'Type' },
          {
            name: 'version',
            label: 'Version',
          },
          {
            name: 'timestamp',
            label: 'Timestamp',
            options: {
              customBodyRender: value => (
                <TimeAgo date={value} title={formatShortDateTime(value)} />
              ),
            },
          },
          {
            name: 'updatedBy',
            label: 'Updated By',
          },
          {
            name: 'version',
            label: ' ',
            options: {
              customBodyRender: () => {
                return (
                  <>
                    <Chip label="Latest" variant="outlined" color="primary" size="small" />
                  </>
                );
              },
            },
          },
        ]}
        options={{
          elevation: 0,
          sort: false,
          filter: false,
          selectableRows: 'none',
          download: false,
          print: false,
          viewColumns: false,
          search: false,
          pagination: false,
          expandableRows: true,
          expandableRowsHeader: false,
          expandableRowsOnClick: true,
          isRowExpandable: index => !!data[index]?.others?.length,
          renderExpandableRow: (_, meta) => {
            return data[meta.dataIndex].others?.map(item => (
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell>{item.version}</TableCell>
                <TableCell>
                  <TimeAgo date={item.timestamp} title={formatShortDateTime(item.timestamp)} />
                </TableCell>
                <TableCell>{item.updatedBy}</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            ));
          },
        }}
      />
    </>
  );
};

export const SerialNumbers = ({ items }: { items?: SerialNumberDto[] }) => {
  return (
    <List subheader={<li />}>
      {items?.map(subsystem => (
        <ListItem key={subsystem.name}>
          <ListItemText secondary={subsystem.name} primary={subsystem.serial} />
        </ListItem>
      ))}
    </List>
  );
};

const Subsystems = ({ items }: { items?: InstrumentSubsystemDto[] }) => {
  return (
    <List subheader={<li />}>
      {items?.map(subsystem => (
        <ListItem key={subsystem.name}>
          <ListItemText primary={subsystem.name} />
        </ListItem>
      ))}
    </List>
  );
};

const InstrumentSubsystems = ({
  isActive,
  instrument,
  isLoading,
}: { instrument: InstrumentDto } & Record<string, any>) => {
  const classes = styles();

  const Loader = React.memo(() => (
    <>{isLoading ? <CircularProgress size={20} style={{ marginRight: '4px' }} /> : ''}</>
  ));

  return (
    <div className={classes.root}>
      <CardContent hidden={!isActive}>
        <Grid container className={classes.stackedList} spacing={4}>
          <Grid item container xs={6} spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5">
                Versions <Loader />
                <Divider />
              </Typography>
              <FirmwareVersionsTable items={instrument.firmwareVersions} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                Serial Numbers <Loader />
                <Divider />
              </Typography>
              <SerialNumbers items={instrument.serialNumbers} />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h5">
              Subsystems <Loader />
              <Divider />
            </Typography>
            <Subsystems items={instrument.subsystems} />
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
};

export default InstrumentSubsystems;
