/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

export type TestContextType = {
  generateReplicateId: (data: { type: string; sample: string; id: string }) => void;
} & Record<string, any>;

const TestContext = React.createContext<Partial<TestContextType>>({});

export default TestContext;
