import React from 'react';
import { RSAAAction } from 'redux-api-middleware';
import { FormDialog } from 'modules/Common/components';
import useFormDefinition from './SectionUnlockDialog.from';

type SectionUnlockDialogProps = {
  apiAction: string;
  actionCreator: (comment: string) => RSAAAction;
};

export default ({ apiAction, actionCreator }: SectionUnlockDialogProps) => {
  const definition = useFormDefinition(apiAction, actionCreator);
  return <FormDialog definition={definition} />;
};
