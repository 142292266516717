import { createAction } from 'redux-api-middleware';
import * as actionTypes from './actionTypes';

const assignRepairRequest = (repairRequestId, assignToUserId) => {
  return createAction({
    endpoint: `/instruments/repairs/${repairRequestId}/assign`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.ASSIGN_REPAIR_REQUEST,
      actionTypes.ASSIGN_REPAIR_SUCCESS,
      actionTypes.ASSIGN_REPAIR_FAILURE,
    ],
    body: JSON.stringify({
      repairRequestId,
      assignToUserId,
    }),
  });
};

export default assignRepairRequest;
