import React, { useState, ReactElement } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { CheckBold } from 'mdi-material-ui';
import TabPanel from './TabPanel';
import useStyles from './TabManager.styles';

function getTabHeading(child: TabChild) {
  if (child.hideHeading) return '';

  return child.panelHeading ? child.panelHeading : child.tabLabel;
}

export type TabChild = {
  name: string;
  tabLabel: string;
  panelHeading?: string;
  panelHeadingComponent?: ReactElement;
  component?: ReactElement;
  complete?: boolean;
  hideHeading?: boolean;
};

type TabManagerProps = {
  initialTabIndex?: number;
  onTabChanged?: Function;
  children: TabChild[];
};

const TabLabel = ({ complete = false, label }: { complete?: boolean; label: string }) => (
  <>
    {complete ? (
      <span>
        <CheckBold style={{ verticalAlign: 'sub', fontSize: '1rem', marginRight: '3px' }} />
        {label}
      </span>
    ) : (
      <span>{label}</span>
    )}
  </>
);

const TabManager = ({
  children,
  initialTabIndex = 0,
  onTabChanged = undefined,
}: TabManagerProps) => {
  const classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = useState(initialTabIndex);

  const handleTabChanged = (_: React.ChangeEvent<{}>, newTabValue: number) => {
    setCurrentTabIndex(newTabValue);
    if (onTabChanged) onTabChanged(newTabValue);
  };

  return (
    <>
      <AppBar position="static" className={classes.root}>
        <Tabs value={currentTabIndex} onChange={handleTabChanged}>
          {children.map(child => (
            <Tab
              key={child.name}
              label={<TabLabel complete={child.complete} label={child.tabLabel} />}
            />
          ))}
        </Tabs>
      </AppBar>
      <TabPanel
        header={getTabHeading(children[currentTabIndex])}
        headerComponent={children[currentTabIndex].panelHeadingComponent}
      >
        {children[currentTabIndex].component}
      </TabPanel>
    </>
  );
};

export default TabManager;
