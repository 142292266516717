import React from 'react';
import { useDispatch } from 'react-redux';
import { startCase } from 'lodash';
import { RSAAAction } from 'redux-api-middleware';
import { Grid, Typography, Divider, Chip } from '@material-ui/core';
import { SignOffDialog } from 'modules/Pilot/BatchRecord/SignOff';
import { openDialog } from 'modules/Core/actions/dialogs';
import { PermissionRequired } from 'modules/Core/components';
import StatusChip from '../StatusChip/StatusChip';
import { SectionStatus } from '../../domain';
import useStyles from './SectionTitle.styles';
import SectionUnlockDialog from '../SectionUnlockDialog/SectionUnlockDialog';

type SectionTitleProps = {
  label: string;
  status: SectionStatus;
  signOffApi?: string;
  signOffAction?: RSAAAction;
  signOffPermission?: string;
  forceSignOffAction?: RSAAAction;
  forceSignOffPermission?: string;
  unlockApi?: string;
  unlockAction?: (comment: string) => RSAAAction;
  unlockPermission?: string;
  cannotBeUnlocked?: boolean;
};

export default ({
  label,
  status,
  signOffApi,
  signOffAction,
  signOffPermission,
  forceSignOffAction,
  forceSignOffPermission,
  unlockApi,
  unlockAction,
  unlockPermission,
  cannotBeUnlocked = false,
}: SectionTitleProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={12} className={classes.root} id={`title-${label}`}>
        <Grid container>
          <Grid item xs>
            <Typography variant="h5">{label}</Typography>
          </Grid>
          {status && (
            <Grid item xs={3}>
              <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                <StatusChip label={startCase(status)} status={status} />
                {signOffPermission && (
                  <>
                    <PermissionRequired permission={signOffPermission}>
                      {status === SectionStatus.Complete && signOffApi && signOffAction && (
                        <>
                          <Chip
                            className="section-action"
                            label="Sign Off"
                            color="primary"
                            onClick={() =>
                              dispatch(openDialog('records/workflow/SIGN_OFF_SECTION'))
                            }
                          />
                          <SignOffDialog
                            dialogName="records/workflow/SIGN_OFF_SECTION"
                            title="Section Sign Off"
                            api={signOffApi}
                            action={signOffAction}
                            reviewText="Are you sure you want to lock this section?"
                          />
                        </>
                      )}
                    </PermissionRequired>
                    {forceSignOffPermission && forceSignOffAction && signOffApi && (
                      <PermissionRequired permission={forceSignOffPermission}>
                        {status === SectionStatus.Incomplete && (
                          <>
                            <Chip
                              className="section-action"
                              label="Force Sign Off"
                              color="primary"
                              onClick={() =>
                                dispatch(openDialog('records/workflow/SIGN_OFF_SECTION'))
                              }
                            />
                            <SignOffDialog
                              dialogName="records/workflow/SIGN_OFF_SECTION"
                              title="Force Section Sign Off"
                              api={signOffApi}
                              action={forceSignOffAction}
                              reviewText="Are you sure you want to force complete this section?"
                            />
                          </>
                        )}
                      </PermissionRequired>
                    )}
                  </>
                )}
                {unlockPermission && (
                  <PermissionRequired permission={unlockPermission}>
                    {!cannotBeUnlocked &&
                      status === SectionStatus.Locked &&
                      unlockApi &&
                      unlockAction && (
                        <>
                          <Chip
                            className="section-action"
                            label="Unlock"
                            color="primary"
                            onClick={() => dispatch(openDialog('records/workflow/UNLOCK_SECTION'))}
                          />
                          <SectionUnlockDialog apiAction={unlockApi} actionCreator={unlockAction} />
                        </>
                      )}
                  </PermissionRequired>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Divider />
      </Grid>
    </>
  );
};
