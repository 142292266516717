import React, { ReactElement } from 'react';
import { CardContent, Divider, Grid, Typography } from '@material-ui/core';

type TabPanelProps = {
  header?: string;
  headerComponent?: ReactElement;
  children?: ReactElement;
};

const TabPanel = ({ header, headerComponent, children }: TabPanelProps) => {
  return (
    <>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {!headerComponent && (
              <Typography variant="h5">
                {header}
                <Divider />
              </Typography>
            )}
            {headerComponent}
          </Grid>
          {children}
        </Grid>
      </CardContent>
    </>
  );
};

export default TabPanel;
