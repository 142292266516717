import { Record, User, SigningAction } from 'modules/RecordManagement/Workflow/domain';
import { PilotLineDto } from 'modules/Pilot/models';
import { Preparation } from './preparation';
import { DepositionMethod } from './common';
import { DepositionSection } from './deposition';
import { Drying } from './drying';
import { Inspection } from './inspection';
import { PostDrying } from './postDrying';
import { SignOffSection } from './signOff';

export const MAX_SPLITS = 50;

export type BatchRecord = Record & {
  lotNumber: string;
  lineClearance?: SigningAction;
  batchRequest: BatchRequest;
  preparation: Preparation;
  deposition: DepositionSection;
  drying: Drying;
  postDrying: PostDrying;
  inspection: Inspection;
  signOff: SignOffSection;
  comments: SectionComment[];
  events: ActivityEvent[];
  pilotLine: PilotLineDto;
};

export type BatchRecordActivity = {
  id: string;
  events: ActivityEvent[];
};

export type ActivityEvent = {
  name: string;
  timestamp: string;
  user: User;
  content: string;
};

export type BatchRequest = {
  chemistry: string;
  chemistryOther?: string;
  scheduledRunDate: string;
  splits: number[];
  numberOfSplits: number;
  numberOfCards: number;
  numberOfStrips: number;
  stripType: string;
  depositionMethod: DepositionMethod;
};

export type BatchComment = {
  comment: string;
  author: SigningAction;
};

export type SectionComment = {
  id: string;
  section: string;
  addedBy: SigningAction;
  comment: string;
};
