import React from 'react';
import Home from './containers/Home';

const path = '/release-review/summary';

const routes = () => {
  return <Home />;
};

routes.path = path;

export default routes;
