import React from 'react';
import * as yup from 'yup';
import { FormikValues } from 'formik';
import {
  FormikTextField,
  FormikDatePicker,
  FormikSelectField,
  FormikCheckboxGroup,
} from 'modules/Common/components';
import { FormDefinition } from 'modules/Common/components/FormDialog/FormDialog';
import moment from 'moment';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { Typography, Grid } from '@material-ui/core';
import {
  CreateBatchRecordActions,
  createBatchRecord,
  AmendBatchRecordActions,
  amendBatchRecord,
} from 'modules/Pilot/BatchRecord/actions';
import { BatchRequest, MAX_SPLITS } from 'modules/Pilot/BatchRecord/domain';
import LinesSelectField from './LinesSelectField';

interface Values extends FormikValues {
  pilotLineId?: string;
  chemistry: string;
  scheduledRunDate: ParsableDate;
  splits?: number[];
  numberOfSplits?: number;
  stripType: string;
  numberOfCards: number;
  depositionMethod: DepositionMethod;
  comment?: string;
}

enum DepositionMethod {
  Unknown = '',
  Hamilton = 'Hamilton',
  Fisnar = 'Fisnar',
  Both = 'Both',
}

export default (id?: string, request?: BatchRequest): FormDefinition<Values> => {
  const dialogName = 'pilot/batch-records/BATCH_RECORD';
  const isEditing = id !== undefined;
  const newSubmit = (values: Values, _, dispatch) =>
    dispatch(
      createBatchRecord({
        ...values,
        scheduledRunDate: moment(values.scheduledRunDate).toISOString(),
      })
    );
  const editSubmit = (values: Values, _, dispatch) =>
    dispatch(
      amendBatchRecord(id as string, {
        ...values,
        scheduledRunDate: moment(values.scheduledRunDate).toISOString(),
      })
    );

  const validation = {
    chemistry: yup
      .string()
      .defined()
      .min(1),
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    chemistryOther: yup.string().validateChemistryOther(yup.ref('chemistry')),
    scheduledRunDate: yup
      .date()
      .defined()
      .required(),
    stripType: yup.string().defined(),
    numberOfSplits: yup
      .number()
      .defined()
      .min(1)
      .max(MAX_SPLITS),
    numberOfCards: yup
      .number()
      .defined()
      .min(1),
    depositionMethod: yup
      .mixed()
      .oneOf(Object.values(DepositionMethod))
      .defined(),
  };

  const newValidation = {
    pilotLineId: yup.string(),
    ...validation,
  };

  const editValidation = {
    ...validation,
    pilotLineId: yup.string(),
    splits: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(MAX_SPLITS),
    comment: yup
      .string()
      .defined()
      .min(5),
  };

  return {
    title: `${isEditing ? 'Edit' : 'New'} Batch Record`,
    dialogName,
    apiAction: isEditing ? AmendBatchRecordActions.CALL : CreateBatchRecordActions.CALL,
    onSubmit: isEditing ? editSubmit : newSubmit,
    initialValues: {
      pilotLineId: '',
      chemistry: request?.chemistry ?? '',
      chemistryOther: request?.chemistryOther ?? '',
      scheduledRunDate: request?.scheduledRunDate ?? null,
      splits: request?.splits ?? [],
      numberOfSplits: request?.numberOfSplits ?? 1,
      stripType: request?.stripType ?? '',
      numberOfCards: request?.numberOfCards ?? 1,
      depositionMethod: request?.depositionMethod ?? DepositionMethod.Unknown,
    },
    // eslint-disable-next-line react/prop-types
    form: ({ values: { chemistry } }) => {
      return (
        <>
          {!isEditing && (
            <>
              <Typography variant="body1" align="center">
                A lot number will be generated once the record is created
              </Typography>
              <LinesSelectField name="pilotLineId" label="Line" />
            </>
          )}
          <FormikSelectField
            name="chemistry"
            label="Chemistry"
            options={[
              { label: 'BNP', value: 'BNP' },
              { label: 'COVID-19', value: 'COVID-19' },
              { label: 'Covid-Flu', value: 'Covid-Flu' },
              { label: 'CRP', value: 'CRP' },
              { label: 'Dengue Fever', value: 'Dengue Fever' },
              { label: 'D-Dimer', value: 'D-Dimer' },
              { label: 'Flu A/B', value: 'Flu A/B' },
              { label: 'HbA1c', value: 'HbA1c' },
              { label: 'H Pylori', value: 'H Pylori' },
              { label: 'INR', value: 'INR' },
              { label: 'OBC', value: 'OBC' },
              { label: 'Particles', value: 'Particles' },
              { label: 'RSV', value: 'RSV' },
              { label: 'TNI', value: 'TNI' },
              { label: 'Wet Assay', value: 'Wet Assay' },
              { label: 'Adenovirus', value: 'Adenovirus' },
              { label: 'Covid-RSV', value: 'Covid-RSV' },
              { label: 'hMPV', value: 'hMPV' },
              { label: 'H.Pylori', value: 'H.Pylori' },
              { label: 'NT-pro-BNP', value: 'NT-pro-BNP' },
              { label: 'PCT', value: 'PCT' },
              { label: 'Other', value: 'Other' },
            ]}
          />
          {chemistry === 'Other' && <FormikTextField name="chemistryOther" label="Other" />}
          <FormikDatePicker name="scheduledRunDate" label="Scheduled Run Date" />
          <Grid container spacing={4}>
            <Grid item xs={isEditing ? 12 : 6}>
              {!isEditing && (
                <FormikTextField
                  name="numberOfSplits"
                  label="Number of Splits"
                  type="number"
                  inputProps={{ min: 1 }}
                  helperText="Between 1 and 50"
                />
              )}
              {isEditing && (
                <FormikCheckboxGroup
                  name="splits"
                  label="Splits"
                  options={request?.splits ?? []}
                  labelPlacement="top"
                  onAddOption={options => options[options.length - 1] + 1}
                  allowAdd
                  maxOptions={MAX_SPLITS}
                />
              )}
            </Grid>
            <Grid item xs={isEditing ? 12 : 6}>
              <FormikTextField
                name="numberOfCards"
                label="Total Number of Cards"
                type="number"
                inputProps={{ min: 1 }}
              />
            </Grid>
          </Grid>
          <FormikTextField name="stripType" label="Strip Type" />
          <FormikSelectField
            name="depositionMethod"
            label="Deposition Method"
            options={[
              { label: 'Hamilton', value: 'Hamilton' },
              { label: 'Fisnar', value: 'Fisnar' },
              { label: 'Both', value: 'Both' },
            ]}
          />
          {isEditing && <FormikTextField name="comment" label="Reason for Change" multiline />}
        </>
      );
    },
    validationSchema: (): yup.ObjectSchema<Values> =>
      yup
        .object()
        .shape(isEditing ? editValidation : newValidation)
        .defined(),
  };
};
