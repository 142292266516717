import React from 'react';
import { DataTable } from 'modules/Core/components';
import { FetchDataFunction } from '@ldx-dmp/Core/components/DataTable/DataTable';

import Context from '../../context';

const DataTabe = () => {
  return (
    <Context.Consumer>
      {({
        list: { fetchAll, totalCount, isLoading, isReloading, data, options, columns } = {},
      }) => {
        return (
          <DataTable
            fetchData={fetchAll as FetchDataFunction}
            isFetching={!!isLoading || !!isReloading}
            totalCount={totalCount as number}
            showData={!isLoading}
            options={options}
            data={data}
            columns={columns}
          />
        );
      }}
    </Context.Consumer>
  );
};

export default DataTabe;
