/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './addCorrectionsUserToVisualInspectionInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      instrumentId: {
        type: 'string',
        title: 'Instrument Id',
      },
      correctionsUser: {
        type: 'string',
        title: 'Corrections User',
      },
      comment: {
        type: 'string',
        title: 'Comment',
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  instrumentId: '',
  correctionsUser: '',
  comment: '',
});
