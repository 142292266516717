import React from 'react';
import * as yup from 'yup';
import { FormikValues } from 'formik';
import { FormikTextField } from 'modules/Common/components';
import { RSAAAction } from 'redux-api-middleware';
import { FormDefinition } from 'modules/Common/components/FormDialog/FormDialog';

interface Values extends FormikValues {
  comment: string;
}

export default (
  apiAction: string,
  actionCreator: (comment: string) => RSAAAction
): FormDefinition<Values> => ({
  title: 'Unlock Section',
  dialogName: 'records/workflow/UNLOCK_SECTION',
  apiAction,
  onSubmit: (values: Values, _, dispatch) => dispatch(actionCreator(values.comment)),
  initialValues: {
    comment: '',
  },
  form: () => (
    <>
      <FormikTextField name="comment" label="Reason for unlocking this section" multiline />
    </>
  ),
  validationSchema: (): yup.ObjectSchema<Values> =>
    yup
      .object()
      .shape({
        comment: yup
          .string()
          .defined()
          .required()
          .min(5),
      })
      .defined(),
});
