import { createAction } from 'redux-api-middleware';

export enum AttachDevelopmentBatchRequestActions {
  CALL = 'pilot/batch-records/ATTACH_BATCH_REQUEST',
  REQUEST = 'pilot/batch-records/ATTACH_BATCH_REQUEST_REQUEST',
  SUCCESS = 'pilot/batch-records/ATTACH_BATCH_REQUEST_SUCCESS',
  FAILURE = 'pilot/batch-records/ATTACH_BATCH_REQUEST_FAILURE',
}

export enum AmendDevelopmentBatchRequestActions {
  CALL = 'pilot/batch-records/AMEND_BATCH_REQUEST',
  REQUEST = 'pilot/batch-records/AMEND_BATCH_REQUEST_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_BATCH_REQUEST_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_BATCH_REQUEST_FAILURE',
}

export const attachDevelopmentBatchRequest = (recordId: string, attachment: File) => {
  const data = new FormData();
  data.append('attachment', attachment);

  return createAction({
    endpoint: `/pilot/batch-records/${recordId}/sign-off/batch-request`,
    method: 'POST',
    types: [
      AttachDevelopmentBatchRequestActions.REQUEST,
      AttachDevelopmentBatchRequestActions.SUCCESS,
      AttachDevelopmentBatchRequestActions.FAILURE,
    ],
    body: data,
  });
};

export const amendDevelopmentBatchRequest = (
  recordId: string,
  attachment: File,
  comment: string
) => {
  const data = new FormData();
  data.append('attachment', attachment);
  data.append('comment', comment);

  return createAction({
    endpoint: `/pilot/batch-records/${recordId}/sign-off/batch-request`,
    method: 'PATCH',
    types: [
      AmendDevelopmentBatchRequestActions.REQUEST,
      AmendDevelopmentBatchRequestActions.SUCCESS,
      AmendDevelopmentBatchRequestActions.FAILURE,
    ],
    body: data,
  });
};
