import { createAction } from 'redux-api-middleware';
import * as actionTypes from './actionTypes';

const recordTestResult = (requestId, payload) => {
  return createAction({
    endpoint: `/instruments/repairs/${requestId}/testing`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.RECORD_TEST_RESULT_REQUEST,
      actionTypes.RECORD_TEST_RESULT_SUCCESS,
      actionTypes.RECORD_TEST_RESULT_FAILURE,
    ],
    body: JSON.stringify({
      ...payload,
      testingCompletedBy: payload.completedBy.id,
      testingCompletedAt: payload.completedAt,
    }),
  });
};

export default recordTestResult;
