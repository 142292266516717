export const rootPath = '/strip-production/buffer-types';
export const entityPath = '/strip-production/buffer-types/:id';

export const addTitle = 'Add Buffer Type';
export const editTitle = 'Editing Buffer Type';
export const listTitle = 'Buffer Types';
export const addButtonText = 'New Buffer Type';
export const i18Namespace = 'BufferType';
export const defaultSort = '';

export const addPermission = 'LDX.StripProduction.Admin';
