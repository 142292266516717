import React from 'react';
import * as yup from 'yup';
import { FormikValues } from 'formik';
import { formatShortDate } from 'modules/Common/utilities';
import { FormikCheckboxGroup, FormikTextField } from 'modules/Common/components';
import { RecordDefinition } from 'modules/Pilot/BatchRecord/SingleView/components/RecordTable/RecordTable';
import { BatchRecord, LASER_NUM_MAX, AssemblySubRecord } from 'modules/Pilot/BatchRecord/domain';
import { DataTableRow } from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable/TableData';
import { recordAssembly, RecordAssemblyActions } from 'modules/Pilot/BatchRecord/actions';
import {
  amendAssembly,
  AmendAssemblyActions,
  verifyAssembly,
  VerifyAssemblyActions,
} from 'modules/Pilot/BatchRecord/actions/assembly';
import { PartStatus } from 'modules/RecordManagement';
import { UserPermission } from 'modules/Core/types';
import getInitialSplits from '../../initialSplits';

interface Values extends FormikValues {
  lasers: number[];
  splits: number[];
  comment?: string;
}

export default (
  batchRecord: BatchRecord,
  itemsBeingEdited: { key: string; itemId: string }[],
  userPermissions: UserPermission[]
): RecordDefinition<Values> => {
  const dialogName = 'pilot/batch-records/ASSEMBLY';
  const recordId = batchRecord.id;
  const { splits } = batchRecord.batchRequest;
  const { status, subRecords, requiredPermissions } = batchRecord.preparation.assembly;
  const itemBeingEdited = itemsBeingEdited.find(
    item =>
      item.key === `${dialogName}_EDIT` &&
      subRecords &&
      subRecords.findIndex(print => print.id === item.itemId) > -1
  );

  const rowForEditing = itemBeingEdited
    ? subRecords.find(print => print.id === itemBeingEdited.itemId)
    : null;

  const laserOptions: number[] = [];
  for (let i = 1; i < LASER_NUM_MAX + 1; i += 1) laserOptions.push(i);

  const isAllowedToEdit = () => {
    if (status === PartStatus.ReadOnly || status === PartStatus.Locked) return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  const newSubmit = (values: Values, _, dispatch) =>
    dispatch(recordAssembly(recordId, { ...values }));
  const editSubmit = (values: Values, _, dispatch) =>
    dispatch(amendAssembly(recordId, { ...values, subRecordId: rowForEditing?.id }));

  const validation = {
    splits: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(splits.length),
    lasers: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(LASER_NUM_MAX),
  };

  const editValidation = {
    ...validation,
    comment: yup
      .string()
      .defined()
      .required()
      .min(5),
  };

  const createDataTableRow = (row: AssemblySubRecord, index: number): DataTableRow => ({
    key: row.id,
    fields: {
      splits: { value: row.splits.join(', ') },
      lasers: { value: row.lasers.join(', ') },
      assembledBy: { user: row.assembledBy.user.name, date: formatShortDate(row.assembledBy.date) },
      verifiedBy: {
        user: row.verification ? row.verification.user.name : null,
        date: formatShortDate(row.verification?.date),
      },
    },
    verification: {
      title: 'Verify Assembly',
      dialogName: `pilot/batch-records/SIGN_OFF_ASSEMBLY_${index}`,
      action: verifyAssembly(recordId, row.id),
      api: VerifyAssemblyActions.CALL,
      maxWidth: 'md',
      review: [
        { title: 'Split(s)', value: row.splits.join(', ') },
        { title: 'Laser Number(s)', value: row.lasers.join(', ') },
        { title: 'Assembled By', value: row.assembledBy.user.name },
        { title: 'Date', value: formatShortDate(row.assembledBy.date) },
      ],
    },
  });

  return {
    title: 'Assembly',
    tableData: {
      columns: [
        { accessor: 'splits', label: 'Split(s)' },
        { accessor: 'lasers', label: 'Laser Number(s)' },
        { accessor: 'assembledBy', label: 'Assembled by', isSignOff: true },
        { accessor: 'verifiedBy', label: 'Verified by', isSignOff: true, isVerification: true },
      ],
      rows: subRecords ? subRecords.map((row, index) => createDataTableRow(row, index)) : [],
    },
    canAddRow: status === PartStatus.Incomplete,
    canEditRows: isAllowedToEdit(),
    formDefinition: {
      title: 'Assembly',
      dialogName: 'pilot/batch-records/ASSEMBLY',
      apiAction: rowForEditing ? AmendAssemblyActions.CALL : RecordAssemblyActions.CALL,
      onSubmit: rowForEditing ? editSubmit : newSubmit,
      initialValues: {
        splits: getInitialSplits(rowForEditing, splits),
        lasers: rowForEditing ? rowForEditing.lasers : [],
      },
      form: () => (
        <>
          <FormikCheckboxGroup
            name="splits"
            label="Splits"
            options={splits}
            labelPlacement="top"
            allowSelectAll
          />
          <br />
          <FormikCheckboxGroup
            name="lasers"
            label="Laser Numbers"
            options={laserOptions}
            labelPlacement="top"
          />
          {rowForEditing && <FormikTextField name="comment" label="Reason for change" multiline />}
        </>
      ),
      validationSchema: (): yup.ObjectSchema<Values> =>
        yup
          .object()
          .shape(rowForEditing ? editValidation : validation)
          .defined(),
    },
  };
};
