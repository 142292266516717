/* eslint-disable react/no-array-index-key */
/* eslint-disable prettier/prettier */
import React from 'react';
import { useDispatch } from 'react-redux';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import useServer from 'modules/Pilot/BatchRecord/diagnostics/useServer';

export type DebugDto = Record<string, unknown> & {
  state?: { version: number };
  events: {
    item1: string;
    item2: { data: Record<string, unknown>; timestamp: string; name: string };
  }[];
  batchRecord: BatchRecord;
};

export function useDiagnostics({
  batchRecord,
  enabled,
  fetch,
}: {
  batchRecord: BatchRecord;
  enabled: boolean;
  fetch: boolean;
}) {
  const server = useServer();
  const dispatch = useDispatch();

  const [diagnosticsEnabled, setDiagnosticsEnabled] = React.useState(false);
  const [currentVersion, setCurrentVersion] = React.useState(0);
  const [result, setResult] = React.useState<BatchRecord | undefined>();
  const [events, setEvents] = React.useState<DebugDto['events'] | undefined>();
  const [confirmed, setConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!enabled || !batchRecord || !fetch) return;

    setLoading(true);
    server.getBatchRecordDebugAsync<DebugDto>(batchRecord?.id, currentVersion).then(res => {
      setEvents(res.data.events);
      if (currentVersion > 0) {
        setResult(res.data.batchRecord);
      }
      setLoading(false);
    });
  }, [batchRecord, currentVersion, enabled, fetch, server]);

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setResult(undefined);
    setCurrentVersion(Number(event.target.value));
  };

  return React.useMemo(
    () => ({
      handleChange,
      events,
      result,
      loading,
      currentVersion,
      confirmed,
      dispatch,
      setConfirm,
      diagnosticsEnabled,
      setDiagnosticsEnabled,
      enabled,
    }),
    [confirmed, currentVersion, diagnosticsEnabled, dispatch, enabled, events, loading, result]
  );
}
