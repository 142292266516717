import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { Form } from '@ldx-dmp/Common/components';

import QCContext from 'modules/InstrumentsQualityControl/context';
import * as form from '../../forms/createTestSessionInputFormSchema';

const CreateTestSessionForm = () => {
  return (
    <QCContext.Consumer>
      {({ createForm: values }) => (
        <Dialog open={!!values?.formOpen} onClose={values?.onFormClose} fullWidth>
          <DialogTitle>Create New Test Session</DialogTitle>
          <DialogContent>
            <Form
              {...form}
              initialValues={form?.empty || {}}
              onSubmit={values?.onFormSubmit || (() => {})}
              onClose={values?.onFormClose || (() => {})}
            />
          </DialogContent>
        </Dialog>
      )}
    </QCContext.Consumer>
  );
};

export default CreateTestSessionForm;
