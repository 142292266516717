import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LotMetadataRoutes from './LotMetadata/routes';
import DispositionMetadataRoutes from './DispositionMetadata/routes';
import LotVerificationRoutes from './LotVerification/routes';
import ReviewSummaryRoutes from './ReviewSummary/routes';
import ReagentMetadataRoutes from './ReagentMetadata/routes';

const path = '/release-review';

const Home = () => <>Release Review</>;

const routes = () => {
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Home />
        </Route>
        <Route path={LotMetadataRoutes.path} component={LotMetadataRoutes} />
        <Route path={DispositionMetadataRoutes.path} component={DispositionMetadataRoutes} />
        <Route path={LotVerificationRoutes.path} component={LotVerificationRoutes} />
        <Route path={ReviewSummaryRoutes.path} component={ReviewSummaryRoutes} />
        <Route path={ReagentMetadataRoutes.path} component={ReagentMetadataRoutes} />
      </Switch>
    </>
  );
};

routes.path = path;

export default routes;
