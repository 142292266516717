import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { getConfig } from 'modules/Core/reducers/selectors';
import { getOidcUser } from 'modules/Auth/reducers/selectors';

import { List, Form } from '../components';
import Context from '../context';
import useEquipment from '../useEquipment';

const IndexPage = () => {
  const config = useSelector(getConfig);
  const user = useSelector(getOidcUser);

  const dispatch = useDispatch();
  const navigate = React.useCallback(url => dispatch(push(url)), [dispatch]);

  const values = useEquipment(config, navigate, user);

  return (
    <Context.Provider value={values}>
      <Switch>
        <Route exact path="/instruments-quality-control/equipment/:id?">
          <List />
        </Route>
      </Switch>
      <Form />
    </Context.Provider>
  );
};

export default IndexPage;
