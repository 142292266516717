import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Divider, Tooltip } from '@material-ui/core';
import styles from './TimelineItem.styles';

const TimelineItem = ({ children, occurred }) => {
  const classes = makeStyles(styles, { name: 'LdxTimelineItem' })();

  return (
    <>
      <div className={classes.root}>
        <span className={classes.badge} />
        <span className={classes.primary}>{children}</span>
        <Tooltip title={occurred.format('MMMM Do YYYY, h:mm:ss a')} placement="left">
          <span className={classes.secondary}>{occurred.fromNow()}</span>
        </Tooltip>
      </div>
      <Divider light variant="middle" />
    </>
  );
};

TimelineItem.propTypes = {
  children: PropTypes.node.isRequired,
  occurred: PropTypes.object.isRequired,
};

export default TimelineItem;
