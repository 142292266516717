/* eslint-disable no-param-reassign */
import { TestSessionDto } from 'modules/InstrumentsQualityControl/models';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.title = 'Test Session Quality Event';
  return sch;
};

export const uiSchema = noop;

export const mapToEdit = () => ({ qualityEventNumber = '' }: TestSessionDto) => {
  return {
    qualityEventNumber: qualityEventNumber || '',
  };
};

export const fields = noop;

export const widgets = noop;

export const validation = () => null;

export const empty = noop;
