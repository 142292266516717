import moment from 'moment';

export default function(repairRequests, onRowClick = null) {
  return {
    options: {
      selectableRows: 'none',
      download: false,
      print: false,
      filterType: 'multiselect',
      elevation: 1,
      viewColumns: false,
      onRowClick,
    },
    columns: [
      { name: 'irn', label: 'IRN', options: { filter: false, sort: true } },
      { name: 'serial', label: 'Instrument Serial', options: { filter: false, sort: true } },
      { name: 'type', label: 'Instrument Type', options: { filter: true, sort: true } },
      {
        name: 'status',
        label: 'Status',
        options: { filter: true, sort: true, filterList: ['Open'] },
      },
      {
        name: 'created',
        label: 'Created',
        options: { filter: false, sort: true, sortDirection: 'desc' },
      },
    ],
    data: repairRequests.map(request => [
      request.irn,
      request.instrument?.serial || 'INVALID',
      request.instrument?.version?.platformVersion || 'INVALID',
      request.status
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim(),
      request.requestDetails.requestedAt !== null
        ? moment(request.requestDetails.requestedAt).format('DD/MM/YYYY')
        : '-',
    ]),
  };
}
