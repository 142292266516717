/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './setQualityEventDetailsInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      qualityEventNumber: {
        type: 'string',
        title: 'Quality Event Number',
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  qualityEventNumber: '',
});
