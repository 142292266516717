import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoginPage, LogoutPage, CallbackPage } from '../containers';
import { SignedOut } from '../components';

const AuthRouter = () => (
  <Switch>
    <Route exact path="/auth/login" component={LoginPage} />
    <Route exact path="/auth/callback" component={CallbackPage} />
    <Route exact path="/auth/logout" component={LogoutPage} />
    <Route exact path="/auth/logout/callback" component={SignedOut} />
  </Switch>
);

export default AuthRouter;
