import React from 'react';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { useSelector } from 'react-redux';
import { getOidcUser } from '@ldx-dmp/Auth/reducers/selectors';
import { getApiBaseUrl } from 'modules/Core/reducers/selectors';
import { useUnmount } from 'react-use';

export default (batchRecordId: string, onUpdate: () => void, autoClose = true) => {
  const oidcUser = useSelector(getOidcUser);
  const apiBaseUrl = useSelector(getApiBaseUrl);
  const [connection, setConnection] = React.useState<HubConnection | null>(null);
  const [shouldUpdate, setShouldUpdate] = React.useState(false);

  React.useEffect(() => {
    if (!connection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${apiBaseUrl}/signalr-hubs/batch-record`, {
          accessTokenFactory: () => oidcUser.access_token,
        })
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);
    }
  }, [apiBaseUrl, batchRecordId, connection, oidcUser.access_token]);

  React.useEffect(() => {
    if (shouldUpdate) {
      onUpdate();
      setShouldUpdate(false);
    }
  }, [onUpdate, shouldUpdate]);

  React.useEffect(() => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      connection.start().then(() => {
        connection.on('updated', () => setShouldUpdate(true));
        connection.invoke('TrackBatchRecord', batchRecordId);
      });
    }
  }, [batchRecordId, connection, setShouldUpdate]);

  const disconnect = React.useCallback(() => {
    if (connection && connection.state === HubConnectionState.Connected) {
      connection.invoke('StopTrackBatchRecord', batchRecordId).then(() => {
        connection.stop();
      });
    }
  }, [batchRecordId, connection]);

  useUnmount(() => {
    if (autoClose) {
      disconnect();
    }
  });

  return React.useMemo(
    () => ({
      disconnect,
    }),
    [disconnect]
  );
};
