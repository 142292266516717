import React from 'react';

import { WidgetProps } from '@rjsf/core';
import FormikRadioGroup, { FormikRadioGroupProps } from '../../FormikRadioGroup';

export type SelectWidgetProps = WidgetProps & FormikRadioGroupProps;

export const RadioWidget = ({ schema, id, options, label }: SelectWidgetProps) => {
  const { enumOptions, enumDisabled } = options;
  const selectOptions = (enumOptions as { label: string; value: string | number }[]).map(
    ({ value, ...data }) => ({
      value,
      disabled: (enumDisabled as string[])?.includes(value as string),
      ...data,
    })
  );

  return <FormikRadioGroup name={id} label={label || schema.title || ''} options={selectOptions} />;
};

export default RadioWidget;
