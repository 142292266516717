/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as appSelectors } from 'modules/Core/reducers';
import { selectors } from '../reducers';
import NotAllowed from './NotAllowed';

const RestrictedRoute = ({ permission = null, component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectors.getOidcUser);
  const checkUserHasPermission = useMemo(() => appSelectors.makeUserHasPermission(), []);
  const hasPermission = useSelector(state => checkUserHasPermission(state, permission));

  if (!user || user.expired) {
    dispatch(push(`/auth/login?redirectTo=${rest.path ? rest.path : '/'}`));
    return <></>;
  }

  if (permission !== null && !hasPermission) return <NotAllowed />;

  return <Route {...rest} render={props => <Component {...props} />} />;
};

RestrictedRoute.propTypes = {
  permission: PropTypes.string,
  component: PropTypes.any.isRequired,
};

export default RestrictedRoute;
