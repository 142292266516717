import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import {
  Button,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Grow,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DateTimePicker } from '@material-ui/pickers/';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { recordTestResult as recordTestResultAction } from 'modules/Instruments/slices/Repair/actions';
import TestResultRadioGroup from '../TestResultRadioGroup/TestResultRadioGroup';
import FirmwareVersionsForm from './FirmwareVersionsForm';
import styles from './TestResultsForm.styles';

const TestResultsForm = ({
  requestId,
  platform,
  technicianUsers,
  recordTestResult,
  onTestResultClosed,
  postRepairTestSetup,
  currentUserId,
  instrument,
}) => {
  const filteredTestGroups = postRepairTestSetup.testGroups.filter(
    group => group.platform === '' || group.platform === platform
  );

  const createEmptyResults = groups => {
    const flattenedTests = [];

    groups.forEach(group => {
      Array.prototype.push.apply(
        flattenedTests,
        group.tests.map(test => ({
          ...test,
          groupName: group.name,
          platform: group.platform,
          result: '',
        }))
      );
    });

    return flattenedTests;
  };

  const tests = createEmptyResults(filteredTestGroups);

  const defaultState = () => {
    return {
      completedBy: currentUserId ? technicianUsers.find(user => user.id === currentUserId) : '',
      completedAt: null,
      meterApiVersion: '',
      serialNumberSet: '',
      testResultLocation: '',
      results: tests,
      validationErrors: [],
      firmwares: {},
    };
  };

  const classes = makeStyles(styles)();
  const [formState, setFormState] = useState(defaultState());
  const [selectedDate, handleDateChange] = useState(new Date());

  const handleChange = event => {
    const { value, id } = event.target;
    const field = !id ? event.target.name : id;

    setFormState(
      set(
        {
          ...formState,
        },
        field,
        value
      )
    );
  };

  const handleCompletedByChange = event => {
    setFormState({
      ...formState,
      completedBy: event.target.value,
    });
  };

  const validate = () => {
    const errors = [];
    const emptyResults = formState.results.filter(result => result.result === '');

    if (emptyResults.length > 0)
      errors.push({
        key: 'incomplete',
        text: 'All tests must have a result even if the test was N/A for this repair',
      });

    if (!formState.completedBy?.id)
      errors.push({ key: 'no-technician', text: 'A technician must be selected' });

    if (!formState.meterApiVersion)
      errors.push({ key: 'no-meterApiVersion', text: 'A Meter API Version must be provided' });

    if (!formState.testResultLocation)
      errors.push({ key: 'no-testLocation', text: 'Test Result Location must be provided' });

    if (errors.length > 0)
      return {
        passed: false,
        errors,
      };

    return {
      passed: true,
    };
  };

  const handleSubmit = event => {
    event.preventDefault();
    const validationResult = validate();

    if (validationResult.passed) {
      recordTestResult(requestId, {
        ...formState,
        completedAt: selectedDate,
      });

      onTestResultClosed();
    } else {
      setFormState({
        ...formState,
        validationErrors: validationResult.errors,
      });
    }
  };

  const handleTestResultChange = event => {
    const index = event.target.name.split('-')[2];
    formState.results[index] = {
      ...formState.results[index],
      result: event.target.value,
    };

    setFormState({
      ...formState,
      results: formState.results,
    });
  };

  const handleCancel = () => {
    setFormState(defaultState());
    onTestResultClosed();
  };

  return (
    <>
      {formState.validationErrors.length > 0 && (
        <Grow in={formState.validationErrors.length > 0}>
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>Errors found</AlertTitle>
              <ul>
                {formState.validationErrors.map(error => (
                  <Fragment key={error.key}>
                    <li>{error.text}</li>
                  </Fragment>
                ))}
              </ul>
            </Alert>
          </Grid>
        </Grow>
      )}
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.select} variant="filled">
                <InputLabel id="completedBy-label">Testing Completed By</InputLabel>
                <Select
                  id="completedBy"
                  labelId="completedBy-label"
                  value={formState.completedBy}
                  onChange={handleCompletedByChange}
                  fullWidth
                >
                  {technicianUsers.map(user => (
                    <MenuItem key={user.id} value={user}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                autoOk
                ampm={false}
                disableFuture
                showTodayButton
                inputVariant="filled"
                value={selectedDate}
                onChange={handleDateChange}
                label="Testing completed at"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="meterApiVersion"
                label="Meter API Version"
                variant="filled"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="serialNumberSet"
                label="Serial Number Set"
                variant="filled"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="testResultLocation"
                label="Test Result Location"
                variant="filled"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <FirmwareVersionsForm
              onChange={handleChange}
              instrument={instrument}
              value={formState}
            />
            {filteredTestGroups.map((group, groupIndex) => (
              <Fragment key={group.name}>
                <Grid item xs={12} className={classes.testCategory} key={group.name}>
                  <Typography variant="h6">{group.name}</Typography>
                </Grid>
                {tests.map((test, testIndex) => (
                  <Fragment key={test.name}>
                    {test.platform === group.platform && (
                      <Grid item xs={12} sm={6} key={test.name}>
                        <Grid container spacing={0}>
                          <TestResultRadioGroup
                            name={test.name}
                            fieldName={`result-${groupIndex}-${testIndex}`}
                            value={formState.results[testIndex].result}
                            onChange={handleTestResultChange}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
            <Grid item xs={12}>
              <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                <Button variant="contained" onClick={handleCancel}>
                  Cancel
                </Button>
                &nbsp;&nbsp;
                <Button type="submit" onClick={handleSubmit} color="primary" variant="contained">
                  Save Test Results
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

const actionCreators = {
  recordTestResult: recordTestResultAction,
};

TestResultsForm.propTypes = {
  requestId: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  technicianUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  recordTestResult: PropTypes.func.isRequired,
  onTestResultClosed: PropTypes.func.isRequired,
  postRepairTestSetup: PropTypes.object.isRequired,
  currentUserId: PropTypes.string,
  instrument: PropTypes.object.isRequired,
};

TestResultsForm.defaultProps = {
  currentUserId: null,
};

export default connect(null, actionCreators)(TestResultsForm);
