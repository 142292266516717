import { Reducer } from 'redux';
import { Instrument } from 'modules/Instruments/domain';
import {
  FetchInstrumentActionTypes,
  FetchInstrumentsActionTypes,
  InstrumentManagementActionTypes,
  RegisterInstrumentActionTypes,
} from '../actions';
import {
  UpdateInstrumentActionTypes,
  FetchInstrumentBySerialActionTypes,
  SetIsControlActionTypes,
} from '../actions/actionTypes';

export interface InstrumentManagementState {
  items: Instrument[];
  current?: string;
}

const initialState: InstrumentManagementState = {
  items: [],
  current: undefined,
};

const reducer: Reducer<InstrumentManagementState> = (state = initialState, action) => {
  switch (action.type) {
    case FetchInstrumentsActionTypes.SUCCESS:
      return {
        ...state,
        items: action.payload.map(instrument => instrument),
      };
    case UpdateInstrumentActionTypes.SUCCESS:
    case FetchInstrumentActionTypes.SUCCESS:
    case FetchInstrumentBySerialActionTypes.SUCCESS:
    case SetIsControlActionTypes.SUCCESS:
      if (!action.payload) return { ...state };

      return {
        ...state,
        items:
          state.items.length === 0
            ? [action.payload]
            : state.items.map(instrument =>
                instrument.id === action.payload.id ? action.payload : instrument
              ),
      };
    case RegisterInstrumentActionTypes.SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case InstrumentManagementActionTypes.SET_CURRENT_INSTRUMENT: {
      return {
        ...state,
        current: action.instrumentId,
      };
    }
    default:
      return state;
  }
};

export default reducer;
