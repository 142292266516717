import { apiPatch, apiPost } from 'modules/Core/actions';

export enum RecordBoxOvenUsageActions {
  CALL = 'pilot/batch-records/RECORD_BOX_OVEN_USAGE',
  REQUEST = 'pilot/batch-records/RECORD_BOX_OVEN_USAGE_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_BOX_OVEN_USAGE_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_BOX_OVEN_USAGE_FAILURE',
}

export enum AmendBoxOvenUsageActions {
  CALL = 'pilot/batch-records/AMEND_BOX_OVEN_USAGE',
  REQUEST = 'pilot/batch-records/AMEND_BOX_OVEN_USAGE_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_BOX_OVEN_USAGE_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_BOX_OVEN_USAGE_FAILURE',
}

export type RecordBoxOvenUsagePayload = {
  splits: number[];
  ovenNumber: number;
  topTemperature: number;
  bottomTemperature: number;
};

export type AmendBoxOvenUsagePayload = RecordBoxOvenUsagePayload & {
  subRecordId?: string;
  comment?: string;
};

export const recordBoxOvenUsage = (recordId: string, payload: RecordBoxOvenUsagePayload) =>
  apiPost(`/pilot/batch-records/${recordId}/drying/box-oven`, RecordBoxOvenUsageActions.CALL, {
    ...payload,
  });

export const amendBoxOvenUsage = (recordId: string, payload: AmendBoxOvenUsagePayload) =>
  apiPatch(`/pilot/batch-records/${recordId}/drying/box-oven`, AmendBoxOvenUsageActions.CALL, {
    ...payload,
  });
