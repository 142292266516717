import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { getInstruments } from 'modules/Instruments/slices/Management/reducers/selectors';
import { fetchInstruments } from 'modules/Instruments/slices/Management/actions';

import { InstrumentSummaryDto } from 'modules/InstrumentsQualityControl/models';

import useStyles from '@ldx-dmp/Common/components/FormDialog/FormDialog.styles';

import QCContext, {
  InstrumentsQualityControlContextType,
} from 'modules/InstrumentsQualityControl/context';

import InstrumentCard from '../../../instruments/components/InstrumentCard';

const AddInstruments = ({
  currentInstruments = [],
}: {
  currentInstruments: (InstrumentSummaryDto | undefined)[] | undefined;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const instruments = useSelector(getInstruments)?.filter(x => x.isControlInstrument);
  const [key, setKey] = React.useState(Date.now());
  const [selectedInstruments, selectInstrument] = React.useState<ReturnType<typeof instruments>>(
    []
  );

  const currentIds = React.useMemo(
    () => [...currentInstruments.map(x => x?.id), ...selectedInstruments.map(x => x.id)],
    [currentInstruments, selectedInstruments]
  );

  React.useEffect(() => {
    if (!instruments.length) {
      dispatch(fetchInstruments());
    }
  }, [dispatch, instruments.length]);

  const removeInstrument = React.useCallback(
    instrument => {
      selectInstrument(selectedInstruments.filter(x => x.id !== instrument.id));
    },
    [selectedInstruments]
  );

  return (
    <QCContext.Consumer>
      {values => {
        return (
          <Dialog
            open={values.addControlInstrumentsOpen as boolean}
            onClose={values.closeAddControlInstruments}
            fullWidth
            maxWidth="lg"
            disableBackdropClick
          >
            <DialogTitle>Add Control Instruments</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Autocomplete
                    key={key}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="add-instrument-combo"
                    options={instruments
                      ?.filter(x => !currentIds.includes(x.id))
                      .map(x => ({
                        title: x.serial,
                      }))}
                    onChange={(_event, data) => {
                      if (typeof data === 'string') {
                        (values as InstrumentsQualityControlContextType)
                          .addNewInstrument(data)
                          .then(instrument => {
                            selectInstrument([...selectedInstruments, instrument]);
                            dispatch(fetchInstruments());
                            setKey(Date.now());
                          });
                      } else {
                        const instrument = instruments.find(x => x.serial === data?.title);
                        selectInstrument([...selectedInstruments, instrument]);
                        setKey(Date.now());
                      }
                    }}
                    getOptionLabel={(option: { title: string; inputValue?: string }) => {
                      if (typeof option === 'string') {
                        return option;
                      }

                      if (option.inputValue) {
                        return option.inputValue;
                      }

                      return option.title;
                    }}
                    style={{ width: 300 }}
                    renderInput={params => (
                      <TextField {...params} label="Instrument Serial" variant="outlined" />
                    )}
                  />
                </Grid>
                {selectedInstruments.map(instrument => (
                  <Grid item xs={3}>
                    <InstrumentCard
                      instrumentSummary={instrument}
                      action={
                        <IconButton
                          edge="start"
                          onClick={() => removeInstrument(instrument)}
                          color="inherit"
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              <Formik
                validateOnMount
                initialValues={{ selectedInstruments }}
                enableReinitialize
                onSubmit={values.onAddControlInstrumentSubmit || (() => {})}
              >
                {form => (
                  <form autoComplete="on" onSubmit={form.handleSubmit}>
                    <div className={classes.actionButtons}>
                      <Button variant="contained" onClick={values.closeAddControlInstruments}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={!form.isValid || form.isSubmitting}
                      >
                        {form.isSubmitting && (
                          <CircularProgress size={20} style={{ marginRight: '4px' }} />
                        )}
                        Save
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        );
      }}
    </QCContext.Consumer>
  );
};

export default AddInstruments;
