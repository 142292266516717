import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@material-ui/core';

const PillButtonGroup = ({ buttonData }) => {
  return (
    <>
      <ButtonGroup color="primary" variant="contained">
        {buttonData.map(button => (
          <Button
            key={button.text}
            onClick={button.onClick}
            startIcon={button.icon ? button.icon : null}
            disabled={button.disabled}
          >
            {button.text}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};

PillButtonGroup.propTypes = {
  buttonData: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.node,
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

export default PillButtonGroup;
