import React from 'react';
import * as yup from 'yup';
import { FormikValues, FieldArray, FormikProps } from 'formik';
import { FormikTextField } from 'modules/Common/components';
import { FormDefinition } from 'modules/Common/components/FormDialog/FormDialog';
import { BatchRecord, RejectCount } from 'modules/Pilot/BatchRecord/domain';
import {
  reviseStripRjectCounts,
  ReviseStripRejectCountsActions,
} from 'modules/Pilot/BatchRecord/actions';
import { TableHead, TableBody, TableCell, Table, TableRow } from '@material-ui/core';
import useStyles from './StripRejects.styles';

interface Values extends FormikValues {
  rejectCounts: RejectCount[];
}

const StripRejectsForm = ({ values }: FormikProps<Values>) => {
  const classes = useStyles();
  return (
    <FieldArray name="rejectCounts">
      {() => (
        <>
          <Table stickyHeader size="small" className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell>Reject Description</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.rejectCounts.map((reject, index) => (
                <TableRow key={reject.id}>
                  <TableCell
                    className="rowHeader formRowHeader"
                    style={{ width: '75%' }}
                    scope="row"
                    variant="head"
                    component="th"
                  >
                    {`${values.rejectCounts[index].code}. ${values.rejectCounts[index].description}`}
                  </TableCell>
                  <TableCell>
                    <FormikTextField
                      name={`rejectCounts.${index}.count`}
                      label=""
                      type="number"
                      fullWidth={false}
                      margin="dense"
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </FieldArray>
  );
};

export default (batchRecord: BatchRecord): FormDefinition<Values> => {
  const recordId = batchRecord.id;
  const rejectCounts = batchRecord.inspection.stripRejects.rejectsByReason ?? [];

  return {
    title: 'Strip Rejects',
    dialogName: 'pilot/batch-records/STRIP_REJECTS',
    apiAction: ReviseStripRejectCountsActions.CALL,
    onSubmit: (values: Values, _, dispatch) =>
      dispatch(
        reviseStripRjectCounts(recordId, {
          rejectCounts: values.rejectCounts.map(rc => ({ id: rc.id, count: rc.count })),
        })
      ),
    initialValues: {
      rejectCounts,
    },
    form: props => <StripRejectsForm {...props} />,
    validationSchema: (): yup.ObjectSchema<Values> =>
      yup
        .object()
        .shape({
          rejectCounts: yup
            .array()
            .of(
              yup
                .object()
                .shape({
                  id: yup.string().defined(),
                  code: yup.string().defined(),
                  description: yup.string().defined(),
                  count: yup.number().defined(),
                })
                .defined()
            )
            .defined(),
        })
        .defined(),
  };
};
