import React from 'react';
import { openDialog } from 'modules/Core/actions/dialogs';
import { useDispatch } from 'react-redux';
import DataTable from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable';
import { BreakpointStep, DialogDefinition } from 'modules/Common/types';
import { RSAAAction } from 'redux-api-middleware';
import SectionPart, {
  signChipAction,
} from 'modules/Pilot/BatchRecord/SingleView/components/SectionPart';
import { PartStatus, SigningAction } from 'modules/RecordManagement';
import { SignOffDialog } from 'modules/Pilot/BatchRecord/SignOff';
import { formatShortDate, formatTime } from 'modules/Common/utilities';
import { DataTableData, DataTableRow } from '../SimpleDataTable/TableData';

export interface SignOffDefinition extends DialogDefinition {
  signOff?: SigningAction | SigningAction[];
  reviewText: string;
  allowsMultiple: boolean;
  action: RSAAAction;
  api: string;
  canSign: boolean;
}

type SignOffTableProps = {
  definition: SignOffDefinition;
  xs?: BreakpointStep;
  status?: PartStatus;
  withTime?: boolean;
};

export default ({
  definition: { title, dialogName, api, action, signOff, reviewText, canSign },
  xs = 4,
  status,
  withTime = false,
}: SignOffTableProps) => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(openDialog(dialogName));
  const createSignatureRows = (): DataTableRow[] => {
    const makeRow = (so?: SigningAction): DataTableRow => ({
      key: so?.user.id ?? 'signOffRow',
      fields: {
        name: { value: so?.user.name ?? '-' },
        date: { value: formatShortDate(so?.date) },
        time: { value: formatTime(so?.date) },
      },
    });

    if (Array.isArray(signOff)) return signOff.map(s => makeRow(s));
    return [makeRow(signOff)];
  };

  const tableData: DataTableData = {
    columns: [
      { accessor: 'name', label: 'Name' },
      { accessor: 'date', label: 'Date' },
    ],
    rows: createSignatureRows(),
  };

  if (withTime) tableData.columns.push({ accessor: 'time', label: 'Time' });

  return (
    <SectionPart
      title={title}
      actionChip={signChipAction(handleClick, canSign)}
      xs={xs}
      status={status}
    >
      <DataTable tableData={tableData} />
      <SignOffDialog
        title={title}
        dialogName={dialogName}
        action={action}
        api={api}
        reviewText={reviewText}
      />
    </SectionPart>
  );
};
