import { apiPost, apiPatch } from 'modules/Core/actions/apiActions';

export type BasePrintPayload = {
  lotNumber: string;
  splits: number[];
};

export type AmendLidPrintPayload = { subRecordId?: string; comment?: string } & BasePrintPayload;

export enum AddBasePrintActions {
  CALL = 'pilot/batch-records/ADD_BASE_PRINT',
  REQUEST = 'pilot/batch-records/ADD_BASE_PRINT_REQUEST',
  SUCCESS = 'pilot/batch-records/ADD_BASE_PRINT_SUCCESS',
  FAILURE = 'pilot/batch-records/ADD_BASE_PRINT_FAILURE',
}

export enum AmendBasePrintActions {
  CALL = 'pilot/batch-records/AMEND_BASE_PRINT',
  REQUEST = 'pilot/batch-records/AMEND_BASE_PRINT_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_BASE_PRINT_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_BASE_PRINT_FAILURE',
}

export const addBasePrint = (recordId: string, payload: BasePrintPayload) =>
  apiPost(`/pilot/batch-records/${recordId}/preparation/base-print`, AddBasePrintActions.CALL, {
    ...payload,
  });

export const amendBasePrint = (recordId: string, payload: AmendLidPrintPayload) =>
  apiPatch(`/pilot/batch-records/${recordId}/preparation/base-print`, AmendBasePrintActions.CALL, {
    ...payload,
  });
