import axios, { AxiosResponse } from 'axios';

import AddInstrumentSubsystemInput from 'modules/Global/models/AddInstrumentSubsystemInput';

export default function createAsync(apiUrl: string) {
  return function api<TResponse>(
    input: AddInstrumentSubsystemInput | undefined | null
  ): Promise<AxiosResponse<TResponse>> {
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      axios
        .request<TResponse>({
          url: `${apiUrl}/api/app/instrument-subsystems`,
          method: 'POST',
          data: input,
        })
        .then(response => {
          if (response.status === 400) {
            reject(response);
            return;
          }
          resolve(response);
        }, reject);
    });
  };
}
