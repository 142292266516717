import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { InstrumentSerial } from 'modules/Instruments/domain';
import { selectors } from 'modules/Instruments/slices/Management/reducers';
import InstrumentForm from '../InstrumentForm';
import useSerialSearch from '../../search/useSerialSearch';

type RegisterInstrumentDialogProps = {
  show: boolean;
  onClose: Function;
};

const InstrumentDialog = ({ show, onClose }: RegisterInstrumentDialogProps) => {
  const dispatch = useDispatch();
  const { search, result } = useSerialSearch();
  const [serial, setSerial] = useState<InstrumentSerial>();
  const instrument = useSelector(state => selectors.getInstrumentBySerial(state, serial?.full));

  const handleClose = () => onClose();

  const handleSerialChanged = newSerial => {
    setSerial(newSerial);
    search(newSerial.full);
  };

  const onComplete = form => {
    dispatch(push(`/instruments/management/${form.serialNumber}`));
  };

  return (
    <>
      <Dialog open={show} onClose={handleClose} fullWidth>
        <DialogTitle>{instrument ? 'Update' : 'Register'} Instrument</DialogTitle>
        <DialogContent>
          <InstrumentForm
            searchResult={result}
            instrument={instrument}
            onComplete={onComplete}
            onCancel={handleClose}
            onInstrumentSerialChanged={handleSerialChanged}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InstrumentDialog;
