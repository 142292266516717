/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import AddMaterialInput from 'modules/InstrumentsQualityControl/models/AddMaterialInput';
import IActionResult from 'modules/InstrumentsQualityControl/models/IActionResult';
import startCase from 'lodash/startCase';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.properties.usage.enumNames = sch.properties.usage.enum.map(x => startCase(x));

  return sch;
};

export const uiSchema = noop;

export const mapToEdit = () => (current: IActionResult): AddMaterialInput => ({
  ...current,
});

export const fields = noop;

export const widgets = noop;

export const validation = () =>
  yup
    .object()
    .shape({
      name: yup
        .string()
        .min(5)
        .defined(),
      specification: yup
        .string()
        .min(5)
        .defined(),
      usage: yup.string().defined(),
      revision: yup.string().defined(),
    })
    .defined();

export const empty = noop;
