/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { FieldTemplateProps } from '@rjsf/core';
import { useField } from 'formik';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';

import WrapIfAdditional from './WrapIfAdditional';

const FieldTemplate = ({
  id,
  children,
  disabled,
  displayLabel,
  label,
  onDropPropertyClick,
  onKeyChange,
  readonly,
  required,
  rawErrors = [],
  rawHelp,
  rawDescription,
  schema,
  uiSchema,
  canLock = false,
}: FieldTemplateProps & Record<string, any>) => {
  const [reviewField] = useField(`review.${id}`);

  if (uiSchema['ui:widget'] === 'hidden') return <></>;
  const review = reviewField?.value;
  const showReview = review && isString(review) && review.length;

  const isLocked = canLock && !isObject(review) && id && !showReview;

  return (
    <WrapIfAdditional
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
    >
      <FormControl fullWidth error={rawErrors.length > 0} required={required}>
        {isLocked && (
          <fieldset style={{ padding: 0, margin: 0, border: 0, opacity: 0.6 }} disabled>
            {children}
          </fieldset>
        )}
        {!isLocked && children}
        {displayLabel && rawDescription ? (
          <Typography variant="caption" color="textSecondary">
            {rawDescription}
          </Typography>
        ) : null}
        {rawErrors.length > 0 && (
          <List dense disablePadding>
            {rawErrors.map(error => {
              return (
                <ListItem key={error} disableGutters>
                  <FormHelperText id={id}>{error}</FormHelperText>
                </ListItem>
              );
            })}
          </List>
        )}
        {rawHelp && <FormHelperText id={id}>{rawHelp}</FormHelperText>}
        {showReview && (
          <Alert severity="warning">
            <AlertTitle>{review}</AlertTitle>
          </Alert>
        )}
      </FormControl>
    </WrapIfAdditional>
  );
};

export default FieldTemplate;
