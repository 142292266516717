/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { PerformanceTestDetailsDto } from 'modules/InstrumentsQualityControl/models';
import * as addOperatorForm from '../../../../forms/VisualInspection/addOperatorToVisualInspectionInputFormSchema';
import * as addMaterialForm from '../../../../forms/VisualInspection/addMaterialToVisualInspectionInputFormSchema';
import * as addEquipmentForm from '../../../../forms/VisualInspection/addEquipmentToVisualInspectionInputFormSchema';
import TestsPane, { TestsPaneProps } from '../../../../components/TestPane/TestPane';
import PerformanceTestContainer from './PerformanceTestContainer';
import { PerformanceTestAdditionalConfig } from './PerformanceTestAdditionalConfig';

export default props => (
  <>
    <PerformanceTestContainer {...props}>
      {({ current, setCurrent, ...p }: TestsPaneProps<PerformanceTestDetailsDto>) => (
        <TestsPane
          {...p}
          setCurrent={setCurrent}
          current={current}
          configuration={current?.performanceTestConfiguration}
          addOperatorForm={addOperatorForm}
          addMaterialForm={addMaterialForm}
          addEquipmentForm={addEquipmentForm}
          additionalConfig={[PerformanceTestAdditionalConfig]}
          lineClearanceEnabled={
            current?.performanceTestConfiguration?.performanceTestTypeAndSamples?.length
          }
        />
      )}
    </PerformanceTestContainer>
  </>
);
