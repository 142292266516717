import {
  AddLidPrintActions,
  AddBasePrintActions,
  AddAdhesiveActions,
  RecordLaserUsageActions,
  UpdateSubAssemblyRejectCountsActions,
  RecordFoilPouchLotNumberActions,
  RecordReagentNumbersActions,
  RecordBlockAidNumbersActions,
  AmendLidPrintActions,
  AmendBasePrintActions,
  AmendAdhesiveActions,
  AmendLaserUsageActions,
  RecordAssemblyActions,
  AmendAssemblyActions,
  RecordSubstrateLaminationActions,
  AmendSubstrateLaminationActions,
  RecordBaseAssemblyActions,
  RecordFinalLaminiationActions,
  RecordSingulationActions,
  AmendFoilPouchLotNumberActions,
  AmendBlockAidNumbersActions,
  AmendFinalLaminationActions,
  AmendSingulationActions,
  AmendDepositionItemActions,
  RecordOvenManagementCardFeed,
  AmendBoxOvenUsageActions,
  RecordTunnelDryerUsageActions,
  ReviseStripRejectCountsActions,
  RecordVisualInspectionCarriedOutActions,
  RecordPackagingAndSealingCarriedOutActions,
  SignOffManufacturingActions,
  RejectBatchRecordActions,
} from 'modules/Pilot/BatchRecord/actions';
import { RecordBatchCompletionActions } from '../actions/batchCompletion';

const handlerMap = new Map();
handlerMap.set(AddLidPrintActions.SUCCESS, {
  message: 'Lid Print recorded',
  dialog: 'pilot/batch-records/LID_PRINT',
});

handlerMap.set(AmendLidPrintActions.SUCCESS, {
  message: 'Lid Print amended',
  dialog: 'pilot/batch-records/LID_PRINT_EDIT',
});

handlerMap.set(AddBasePrintActions.SUCCESS, {
  message: 'Base Print recorded',
  dialog: 'pilot/batch-records/BASE_PRINT',
});

handlerMap.set(AmendBasePrintActions.SUCCESS, {
  message: 'Base Print amended',
  dialog: 'pilot/batch-records/BASE_PRINT_EDIT',
});

handlerMap.set(AddAdhesiveActions.SUCCESS, {
  message: 'Adhesive recorded',
  dialog: 'pilot/batch-records/ADHESIVE',
});

handlerMap.set(AmendAdhesiveActions.SUCCESS, {
  message: 'Adhesive amended',
  dialog: 'pilot/batch-records/ADHESIVE',
});

handlerMap.set(RecordLaserUsageActions.SUCCESS, {
  message: 'Laser recorded',
  dialog: 'pilot/batch-records/LASER',
});

handlerMap.set(AmendLaserUsageActions.SUCCESS, {
  message: 'Laser usage amended',
  dialog: 'pilot/batch-records/LASER',
});

handlerMap.set(RecordAssemblyActions.SUCCESS, {
  message: 'Assembly recorded',
  dialog: 'pilot/batch-records/ASSEMBLY',
});

handlerMap.set(AmendAssemblyActions.SUCCESS, {
  message: 'Assembly amended',
  dialog: 'pilot/batch-records/ASSEMBLY',
});

handlerMap.set(RecordSubstrateLaminationActions.SUCCESS, {
  message: 'Lamination recorded',
  dialog: 'pilot/batch-records/PREP_LAMINATION',
});

handlerMap.set(AmendSubstrateLaminationActions.SUCCESS, {
  message: 'Lamination amended',
  dialog: 'pilot/batch-records/PREP_LAMINATION',
});

handlerMap.set(UpdateSubAssemblyRejectCountsActions.SUCCESS, {
  message: 'Sub Assembly Rejects updated',
  dialog: 'pilot/batch-records/SUB_ASSEMBLY_REJECTS',
});

handlerMap.set(RecordReagentNumbersActions.SUCCESS, {
  message: 'Reagent Numbers recorded',
  dialog: 'pilot/batch-records/REAGENT',
});

handlerMap.set(ReviseStripRejectCountsActions.SUCCESS, {
  message: 'Strip Rejects updated',
  dialog: 'pilot/batch-records/STRIP_REJECTS',
});

handlerMap.set(RecordFoilPouchLotNumberActions.SUCCESS, {
  message: 'Foil Pouch Preparation recorded',
  dialog: 'pilot/batch-records/POUCH_PREPARATION',
});

handlerMap.set(AmendFoilPouchLotNumberActions.SUCCESS, {
  message: 'Foil Pouch Preparation amended',
  dialog: 'pilot/batch-records/POUCH_PREPARATION',
});

handlerMap.set(RecordBlockAidNumbersActions.SUCCESS, {
  message: 'Block Aid number recorded',
  dialog: 'pilot/batch-records/BLOCK_AID',
});

handlerMap.set(AmendBlockAidNumbersActions.SUCCESS, {
  message: 'Block Aid number amended',
  dialog: 'pilot/batch-records/BLOCK_AID',
});

handlerMap.set(AmendDepositionItemActions.SUCCESS, {
  message: 'Deposition amended',
  dialog: 'pilot/batch-records/DEPOSITION',
});

handlerMap.set(RecordOvenManagementCardFeed.SUCCESS, {
  message: 'Oven Management/Card Feed recorded',
  dialog: 'pilot/batch-records/OVEN_CARD_FEED',
});

handlerMap.set(AmendBoxOvenUsageActions.SUCCESS, {
  message: 'Box Oven usage amended',
  dialog: 'pilot/batch-records/BOX_OVEN',
});

handlerMap.set(RecordTunnelDryerUsageActions.SUCCESS, {
  message: 'Tunnel Dryer usage recorded',
  dialog: 'pilot/batch-records/TUNNEL_DRYER',
});

handlerMap.set(RecordTunnelDryerUsageActions.SUCCESS, {
  message: 'Tunnel Dryer usage amended',
  dialog: 'pilot/batch-records/TUNNEL_DRYER',
});

handlerMap.set(RecordBaseAssemblyActions.SUCCESS, {
  message: 'Base Assembly recorded',
  dialog: 'pilot/batch-records/BASE_ASSEMBLY',
});

handlerMap.set(RecordFinalLaminiationActions.SUCCESS, {
  message: 'Lamination recorded',
  dialog: 'pilot/batch-records/FINAL_LAMINATION',
});

handlerMap.set(AmendFinalLaminationActions.SUCCESS, {
  message: 'Lamination amended',
  dialog: 'pilot/batch-records/FINAL_LAMINATION',
});

handlerMap.set(RecordSingulationActions.SUCCESS, {
  message: 'Singulation recorded',
  dialog: 'pilot/batch-records/SINGULATION',
});

handlerMap.set(AmendSingulationActions.SUCCESS, {
  message: 'Singulation amended',
  dialog: 'pilot/batch-records/SINGULATION',
});

handlerMap.set(RecordVisualInspectionCarriedOutActions.SUCCESS, {
  message: 'Visual Inspection recorded',
  dialog: 'pilot/batch-records/VISUAL_INSPECTION_SIGN_OFF',
});

handlerMap.set(RecordPackagingAndSealingCarriedOutActions.SUCCESS, {
  message: 'Packaging and Sealing recorded',
  dialog: 'pilot/batch-records/PACKAGING_SIGN_OFF',
});

handlerMap.set(RecordBatchCompletionActions.SUCCESS, {
  message: 'Batch completed',
  dialog: 'pilot/batch-records/BATCH_COMPLETION',
});

handlerMap.set(SignOffManufacturingActions.SUCCESS, {
  message: 'Batch Record signed off',
  dialog: 'pilot/batch-records/MANUFACTURING_SIGN_OFF',
});

handlerMap.set(RejectBatchRecordActions.SUCCESS, {
  message: 'Batch Record rejected',
  dialog: 'pilot/batch-records/REJECT_BATCH_RECORD',
});

export default handlerMap;
