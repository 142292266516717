/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { FieldProps, utils } from '@rjsf/core';
import TestContext from '../../testContext';

const { getUiOptions } = utils;

export default ({ schema, formData, registry, idSchema, uiSchema }: FieldProps) => {
  const { TitleField: titleField } = registry.fields;
  const { generateReplicateId, currentInstrument } = React.useContext(TestContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const TitleField = titleField as any;

  const options = getUiOptions(uiSchema);

  const canUpload = !options?.readOnly;
  const handleGenerate = React.useCallback(() => {
    if (!generateReplicateId || !options?.type || !options?.sample) return;

    generateReplicateId({
      type: options.type as string,
      sample: options.sample as string,
      id: options.id as string,
    });
  }, [generateReplicateId, options]);

  const get = options?.get as any;

  const finalData =
    (get && get(currentInstrument?.performanceTest)?.replicates) ||
    currentInstrument?.performanceTest?.tests?.find(x => x.id === options?.id)?.replicates ||
    formData;

  return (
    <>
      <Card>
        <CardContent>
          <TitleField id={`${idSchema.$id}-title`} title={schema.title} />
          <MUIDataTable
            data={finalData}
            columns={[
              { name: 'testReplicateId', label: 'Replicate ID' },
              { name: 'testType', label: 'Test Type' },
              { name: 'sampleType', label: 'Sample Type' },
              { name: 'number', label: 'Replicate Number' },
            ]}
            options={{
              elevation: 0,
              download: false,
              search: false,
              sort: false,
              filter: false,
              pagination: false,
              print: false,
              viewColumns: false,
              selectableRows: 'none',
            }}
          />
        </CardContent>
        <CardActions>
          {canUpload && (
            <Button type="button" variant="contained" color="primary" onClick={handleGenerate}>
              Add Replicate
            </Button>
          )}
        </CardActions>
      </Card>
    </>
  );
};
