import { createAction } from 'redux-api-middleware';
import * as actionTypes from './actionTypes';

const createRepairRequest = repairRequest => {
  let isWipedDown = null;
  if (repairRequest.isWipedDown === 'yes') isWipedDown = true;
  else if (repairRequest.isWipedDown === 'no') isWipedDown = false;

  return createAction({
    endpoint: '/instruments/repairs',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.CREATE_REPAIR_REQUEST,
      actionTypes.CREATE_REPAIR_SUCCESS,
      actionTypes.CREATE_REPAIR_FAILURE,
    ],
    body: JSON.stringify({
      repairLocation: repairRequest.repairLocation,
      instrumentSerialNumber: repairRequest.meterSerial,
      instrumentUser: repairRequest.nameOfMeterUser,
      instrumentLocation: repairRequest.meterLocation,
      requestDescription: repairRequest.repairDetails,
      isInstrumentWipedDown: isWipedDown,
      requestedAt: repairRequest.requestedAt,
      affectedSubsystems: repairRequest.affectedSubsystems,
    }),
  });
};

export default createRepairRequest;
