import { apiPatch, apiPost } from 'modules/Core/actions';

export enum RecordTunnelDryerUsageActions {
  CALL = 'pilot/batch-records/RECORD_TUNNEL_DRYER_USAGE',
  REQUEST = 'pilot/batch-records/RECORD_TUNNEL_DRYER_USAGE_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_TUNNEL_DRYER_USAGE_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_TUNNEL_DRYER_USAGE_FAILURE',
}

export enum AmendTunnelDryerUsageActions {
  CALL = 'pilot/batch-records/AMEND_TUNNEL_DRYER_USAGE',
  REQUEST = 'pilot/batch-records/AMEND_TUNNEL_DRYER_USAGE_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_TUNNEL_DRYER_USAGE_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_TUNNEL_DRYER_USAGE_FAILURE',
}

export type RecordTunnelDryerUsagePayload = {
  profilePassed: boolean | null;
  temperature: number;
  speed: number;
  splits: number[];
};

export type AmendTunnelDryerUsagePayload = RecordTunnelDryerUsagePayload & {
  subRecordId?: string;
  comment?: string;
};

export const recordTunnelDryerUsage = (recordId: string, payload: RecordTunnelDryerUsagePayload) =>
  apiPost(
    `/pilot/batch-records/${recordId}/drying/tunnel-dryer`,
    RecordTunnelDryerUsageActions.CALL,
    {
      ...payload,
    }
  );

export const amendTunnelDryerUsage = (recordId: string, payload: AmendTunnelDryerUsagePayload) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/drying/tunnel-dryer`,
    AmendTunnelDryerUsageActions.CALL,
    {
      ...payload,
    }
  );
