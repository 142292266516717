/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import CreateTestSessionInput from 'modules/InstrumentsQualityControl/models/CreateTestSessionInput';
import IActionResult from 'modules/InstrumentsQualityControl/models/IActionResult';
import moment from 'moment';
import InstrumentPlatformsWidget from 'modules/Global/instrumentVersions/components/InstrumentPlatformsWidget';

function noop<T>(id: T) {
  return id;
}

export const schema = (sch: { required: string[]; properties: Record<string, any> }) => {
  sch.required = Object.keys(sch.properties);

  sch.properties.location.enum = ['Stirling', 'Waltham'];

  return sch;
};

export const uiSchema = ui => {
  ui.scheduledRunDate = { 'ui:widget': 'date' };
  ui.platform = {
    'ui:widget': InstrumentPlatformsWidget,
  };

  return ui;
};

export const mapToEdit = () => (current: IActionResult): CreateTestSessionInput => ({
  ...current,
});

export const fields = noop;

export const widgets = noop;

export const validation = () =>
  yup
    .object()
    .shape({
      maxAllowedInstruments: yup
        .number()
        .moreThan(0)
        .defined(),
      platform: yup.string().defined(),
      location: yup.string().defined(),
      scheduledRunDate: yup.date().defined(),
    })
    .defined();

export const empty = initial => ({
  ...initial,
  maxAllowedInstruments: 50,
  scheduledRunDate: moment(),
});
