import moment from 'moment';
import { RepairRequest } from 'modules/Instruments/domain/repairRequest';

export default function(repairRequests: RepairRequest[]) {
  const requestRows = repairRequests.map(request => {
    const {
      id,
      irn,
      status,
      instrument: { serial, version },
      requestDetails: { requestedAt },
    } = request;
    const created = requestedAt !== null ? moment(requestedAt).format('MMM Do YYYY, hh:mm') : '-';

    return {
      id,
      irn,
      serial,
      type: version.platformVersion,
      status: status.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
      created,
    };
  });

  return {
    columns: [
      { accessor: 'irn', name: 'IRN' },
      { accessor: 'serial', name: 'Instrument Serial' },
      { accessor: 'type', name: 'Instrument Type' },
      { accessor: 'status', name: 'Status' },
      { accessor: 'created', name: 'Created' },
    ],
    rows: requestRows,
    rowKeyAccessor: 'id',
  };
}
