import React from 'react';
import { Route, Switch } from 'react-router-dom';
import InstrumentSubsystemsRoutes from './instrumentSubsystems/routes';
import InstrumentVersionsRoutes from './instrumentVersions/routes';
import LocationRoutes from './locations/routes';
import TestTypeRoutes from './testTypeDefinition/routes';
import SampleTypeRoutes from './sampleTypeDefinition/routes';
import StripLinesRoutes from './stripLines/routes';

const path = '/global-data';

const Home = () => <>Global</>;

const routes = () => {
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Home />
        </Route>
        <Route path={TestTypeRoutes.path} component={TestTypeRoutes} />
        <Route path={SampleTypeRoutes.path} component={SampleTypeRoutes} />
        <Route path={LocationRoutes.path} component={LocationRoutes} />
        <Route path={InstrumentVersionsRoutes.path} component={InstrumentVersionsRoutes} />
        <Route path={InstrumentSubsystemsRoutes.path} component={InstrumentSubsystemsRoutes} />
        <Route path={StripLinesRoutes.path} component={StripLinesRoutes} />
      </Switch>
    </>
  );
};

routes.path = path;

export default routes;
