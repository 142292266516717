import { apiPatch } from 'modules/Core/actions';

export enum UpdateSubAssemblyRejectCountsActions {
  CALL = 'pilot/batch-records/UPDATE_SUB_ASSEMBLY_REJECT_COUNTS',
  REQUEST = 'pilot/batch-records/UPDATE_SUB_ASSEMBLY_REJECT_COUNTS_REQUEST',
  SUCCESS = 'pilot/batch-records/UPDATE_SUB_ASSEMBLY_REJECT_COUNTS_SUCCESS',
  FAILURE = 'pilot/batch-records/UPDATE_SUB_ASSEMBLY_REJECT_COUNTS_FAILURE',
}

type UpdateSubAssemblyRejectCountsPayload = {
  rejectCounts: { id: string; count: number }[];
};

export const updateSubAssemblyRejectCounts = (
  recordId: string,
  payload: UpdateSubAssemblyRejectCountsPayload
) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/preparation/sub-assembly-rejects`,
    UpdateSubAssemblyRejectCountsActions.CALL,
    payload
  );
