import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  selectLoading,
  selectCurrent,
  setCurrent,
  startLoading,
  stopLoading,
} from '../testSessionsSlice';
import { TestSessionDto } from '../../../models';
import InstrumentResult from '../../instruments/components/InstrumentResult';

export default function(state, apiUrl, dispatch) {
  const current = selectCurrent(state);
  const isReloading = selectLoading(state) === 'RELOADING';
  const isLoading = selectLoading(state) === 'LOADING' || selectLoading(state) === 'NONE';

  const { id } = useParams();

  const fetchCurrent = React.useCallback(() => {
    return new Promise<TestSessionDto>((resolve, reject) => {
      axios(`${apiUrl}/instruments/quality-control/test-sessions/${id}`).then(response => {
        dispatch(setCurrent(response.data));
        resolve(response.data);
      }, reject);
    });
  }, [apiUrl, dispatch, id]);

  React.useEffect(() => {
    if (!id && current) {
      dispatch(setCurrent(null));
    } else if (id !== current?.id) {
      dispatch(startLoading());
      fetchCurrent().then(() => dispatch(stopLoading()));
    }
  }, [apiUrl, current, dispatch, fetchCurrent, id]);

  return React.useMemo(
    () => ({
      id,
      current,
      setCurrent: (cur: TestSessionDto | null) => dispatch(setCurrent(cur)),
      isLoading: isReloading || isLoading,
      fetchCurrent,

      details: {
        summary: [
          {
            primary: 'Session ID',
            secondary: current?.testSessionId,
          },
          {
            primary: 'Number of Instruments',
            secondary: `${Object.keys(current?.instruments || {}).length}/${
              current?.maxAllowedInstruments
            }`,
          },
          {
            primary: 'Status',
            secondary: <InstrumentResult value={current?.status} />,
          },
        ],
      },
    }),
    [current, dispatch, fetchCurrent, id, isLoading, isReloading]
  );
}
