/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './addOperatorToVisualInspectionInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      operators: {
        title: 'Operators',
        type: 'array',
        items: {
          type: 'string',
          title: 'Operator',
        },
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  operators: [],
});
