const pass = {
  color: '#4caf50',
};

const fail = {
  color: '#f44336',
};

export default () => ({
  passed: {
    ...pass,
    '& .MuiListItemIcon-root': pass,
  },
  failed: {
    ...fail,
    '& .MuiListItemIcon-root': fail,
  },
  resultIcon: {
    minWidth: '32px',
  },
});
