import React from 'react';
import moment from 'moment';
import produce from 'immer';
import { DatePicker } from '@material-ui/pickers';
import { debounceSearchRender } from 'mui-datatables';

export type onRowClickFunction = (a, b) => void;

const DateRunFilter = ({
  filterList,
  onChange,
  index,
  column,
}: {
  filterList: Array<Array<string>>;
  onChange: (filters: Array<string>, index: number, column: number) => void;
  index: number;
  column: number;
}) => {
  const setFrom = React.useCallback(
    date => {
      const newFilters = produce(filterList, draft => {
        // eslint-disable-next-line no-param-reassign
        draft[index][0] = moment(date).format('DD/MM/YYYY');
      });

      onChange(newFilters[index], index, column);
    },
    [column, filterList, index, onChange]
  );

  const setTo = React.useCallback(
    date => {
      const newFilters = produce(filterList, draft => {
        // eslint-disable-next-line no-param-reassign
        draft[index][1] = moment(date).format('DD/MM/YYYY');
      });

      onChange(newFilters[index], index, column);
    },
    [column, filterList, index, onChange]
  );

  function getDate(value) {
    if (!value) return null;
    return moment(value, 'DD/MM/YYYY').toDate();
  }

  return (
    <div>
      <fieldset>
        <legend>Run Date</legend>
        <DatePicker
          label="From"
          fullWidth
          autoOk
          showTodayButton
          name="dateRun.from"
          format="ddd, MMM Do YYYY"
          inputVariant="filled"
          onAccept={date => setFrom(date)}
          onChange={date => setFrom(date)}
          value={getDate(filterList[index][0])}
        />{' '}
        <DatePicker
          label="To"
          fullWidth
          autoOk
          showTodayButton
          name="dateRun.to"
          format="ddd, MMM Do YYYY"
          inputVariant="filled"
          onAccept={date => setTo(date)}
          onChange={date => setTo(date)}
          value={getDate(filterList[index][1])}
        />
      </fieldset>
    </div>
  );
};

export const columns = [
  { name: 'lotNumber', label: 'Lot Number', options: { filter: false, sort: true } },
  { name: 'pilotLineCode', label: 'Line', options: { filter: true, sort: true } },
  {
    name: 'splits',
    label: 'Splits',
    options: {
      filter: false,
      sort: true,
    },
  },
  { name: 'cards', label: 'Cards', options: { filter: false, sort: true } },
  { name: 'stripType', label: 'Strip Type', options: { filter: false, sort: false } },
  {
    name: 'chemistry',
    label: 'Chemistry',
    options: {
      filter: true,
      sort: false,
      filterOptions: {
        names: [
          'COVID-19',
          'CRP',
          'Dengue Fever',
          'D-Dimer',
          'Flu A/B',
          'HbA1c',
          'H Pylori',
          'INR',
          'OBC',
          'Particles',
          'TNI',
          'Wet Assay',
          'Other',
        ],
      },
      customFilterListOptions: {
        render: v => [`Chemistry: ${v}`],
      },
    },
  },
  {
    name: 'depositionMethod',
    label: 'Deposition',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'scheduledRunDate',
    label: 'Run Date',
    options: {
      filter: true,
      sort: true,
      filterType: 'custom',

      customFilterListOptions: {
        render: v => {
          if (v[0] && v[1]) {
            return [`Date Run From: ${v[0]}`, ` Date Run To: ${v[1]}`];
          }
          if (v[0]) {
            return `Date Run From: ${v[0]}`;
          }
          if (v[1]) {
            return `Date Run To: ${v[1]}`;
          }

          return [];
        },
        update: (filterList, filterPos, index) => {
          if (filterPos === 0) {
            filterList[index].splice(filterPos, 1, '');
          } else if (filterPos === 1) {
            filterList[index].splice(filterPos, 1);
          } else if (filterPos === -1) {
            // eslint-disable-next-line no-param-reassign
            filterList[index] = [];
          }

          return filterList;
        },
      },
      filterOptions: {
        names: [],

        display: (filterList, onChange, index, column) => {
          const props = { filterList, onChange, index, column };
          return <DateRunFilter {...props} />;
        },
      },
    },
  },
  { name: 'status', label: 'Status', options: { filter: false, sort: true } },
];

export default function(batchRecords, onRowClick: onRowClickFunction | null = null) {
  return {
    options: {
      filters: true,
      selectableRows: 'none',
      download: false,
      print: false,
      filterType: 'multiselect',
      elevation: 1,
      viewColumns: false,
      search: true,
      onRowClick,
      customSearchRender: debounceSearchRender(500),
    },
    columns,
    data: batchRecords?.map(batch => [
      batch.lotNumber,
      batch.pilotLineCode,
      batch.batchRequest.numberOfSplits,
      batch.batchRequest.numberOfCards,
      batch.batchRequest.stripType,
      batch.batchRequest.chemistryOther || batch.batchRequest.chemistry,
      batch.batchRequest.depositionMethod,
      moment(batch.batchRequest.scheduledRunDate).format('DD/MM/YYYY'),
      batch.status
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim(),
    ]),
  };
}
