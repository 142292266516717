/* eslint-disable no-param-reassign */
import React from 'react';

import { InstrumentsQualityControlContextType } from 'modules/InstrumentsQualityControl/context';
import { AppConfig } from 'modules/Core/types';
import { useBoolean } from 'react-use';

import testSessionsReducer, {
  initialState,
  setCurrent,
  openForm,
  setSummaryType,
  selectSummaryType,
  setAddInstruments,
  selectAddInstrumentsOpen,
  setAddControlInstruments,
  selectAddControlInstrumentsOpen,
} from './features/testSessions/testSessionsSlice';

import {
  useCreateTestSession,
  useCurrentTestSession,
  useTestSessionList,
} from './features/testSessions/hooks';
import { AuditEntityDto, TestSessionDto } from './models';
import useServer from './features/testSessions/hooks/useServer';

function useQCConext({ api: { base_uri: apiUrl } }: AppConfig, navigate: (url: string) => void) {
  const server = useServer();
  const [state, dispatch] = React.useReducer(testSessionsReducer, initialState);
  const [reviewEnabled, setReviewEnabled] = useBoolean(false);
  const [buttonText, setButtonText] = React.useState('Complete');
  const [events, setEvents] = React.useState<AuditEntityDto[] | undefined>();
  const cardsVisible = selectSummaryType(state) === 'CARD';
  const tableVisible = selectSummaryType(state) === 'TABLE';
  const addInstrumentsOpen = selectAddInstrumentsOpen(state);
  const addControlInstrumentsOpen = selectAddControlInstrumentsOpen(state);

  const createForm = useCreateTestSession(dispatch, apiUrl, navigate, state);
  const list = useTestSessionList(state, apiUrl, dispatch, navigate);
  const { id, ...currentSession } = useCurrentTestSession(state, apiUrl, dispatch);

  const onNewItemClick = React.useCallback(() => dispatch(openForm()), [dispatch]);

  const showTable = React.useCallback(() => {
    dispatch(setSummaryType('TABLE'));
  }, []);

  const showCards = React.useCallback(() => {
    dispatch(setSummaryType('CARD'));
  }, []);

  const openAddInstruments = React.useCallback(() => {
    dispatch(setAddInstruments(true));
  }, []);

  const closeAddInstruments = React.useCallback(() => {
    dispatch(setAddInstruments(false));
  }, []);

  const handleResponse = response => {
    dispatch(setCurrent(response.data));
    return response.data;
  };

  const onAddInstrumentSubmit = React.useCallback(
    addInstrumentsData => {
      const formData = {
        instruments: addInstrumentsData.selectedInstruments.map(x => x.id),
      };
      server.patchAddInstruments(id, formData).then(response => {
        closeAddInstruments();
        handleResponse(response);
      });
    },
    [closeAddInstruments, id, server]
  );

  const addNewInstrument: InstrumentsQualityControlContextType['addNewInstrument'] = React.useCallback(
    serial => {
      return new Promise((resolve, reject) => {
        server
          .postNewInstrument({
            instrumentSerialNumber: serial,
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(reject);
      });
    },
    [server]
  );

  const saveOrder: InstrumentsQualityControlContextType['saveOrder'] = React.useCallback(
    order => {
      return new Promise((resolve, reject) => {
        server
          .patchInstrumentOrder(id, order)
          .then(response => {
            resolve(handleResponse(response));
          })
          .catch(reject);
      });
    },
    [id, server]
  );

  const openAddControlInstruments = React.useCallback(() => {
    dispatch(setAddControlInstruments(true));
  }, []);

  const closeAddControlInstruments = React.useCallback(() => {
    dispatch(setAddControlInstruments(false));
  }, []);

  const onAddControlInstrumentSubmit = React.useCallback(
    addInstrumentsData => {
      const formData = {
        instruments: addInstrumentsData.selectedInstruments.map(x => x.id),
      };

      return server.patchAddControlInstruments(id, formData).then(response => {
        closeAddControlInstruments();
        handleResponse(response);
      });
    },
    [closeAddControlInstruments, id, server]
  );

  const moveToMaterials = React.useCallback(() => {
    navigate('/instruments-quality-control/materials');
  }, [navigate]);

  const moveToEquipment = React.useCallback(() => {
    navigate('/instruments-quality-control/equipment');
  }, [navigate]);

  const submitReview = React.useCallback(
    (data, type = 'visual-inspection') => {
      return new Promise<TestSessionDto>((resolve, reject) => {
        server
          .patchReview(id, type, data)
          .then(response => {
            resolve(handleResponse(response));
          })
          .catch(reject);
      });
    },
    [id, server]
  );

  const enableReview = React.useCallback(() => {
    setButtonText('Complete Review');
    setReviewEnabled(true);
  }, [setReviewEnabled]);

  const disableReview = React.useCallback(() => {
    setButtonText('Complete');
    setReviewEnabled(false);
  }, [setReviewEnabled]);

  const onCorrectionsSubmit = React.useCallback(
    (data, type) => {
      return new Promise((resolve, reject) => {
        server.patchSubmit(id, type, data).then(response => {
          resolve(handleResponse(response));
        }, reject);
      });
    },

    [id, server]
  );

  const uploadLogFile = React.useCallback(
    data => {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();

        Object.keys(data).forEach(key => {
          bodyFormData.append(key, data[key]);
        });

        server
          .postLogFile(id, bodyFormData)
          .then(response => {
            resolve(handleResponse(response));
          })
          .catch(reject);
      });
    },
    [id, server]
  );

  const setQualityEvent = React.useCallback(
    data => {
      return new Promise<TestSessionDto>((resolve, reject) => {
        server
          .patchQualityEvent(id, data)
          .then(response => {
            resolve(handleResponse(response));
          })
          .catch(reject);
      });
    },
    [id, server]
  );

  const setPlannedDeviation = React.useCallback(
    data => {
      return new Promise<TestSessionDto>((resolve, reject) => {
        server
          .patchPlannedDeviation(id, data)
          .then(response => {
            resolve(handleResponse(response));
          })
          .catch(reject);
      });
    },
    [id, server]
  );

  const submitInstrumentDisposition = React.useCallback(
    data =>
      new Promise<TestSessionDto>((resolve, reject) => {
        server
          .patchInstrumentDisposition(id, data)
          .then(response => {
            resolve(handleResponse(response));
          })
          .catch(reject);
      }),
    [id, server]
  );

  const submitForReview = React.useCallback(
    () =>
      new Promise<TestSessionDto>((resolve, reject) => {
        server
          .patchSubmitForReview(id)
          .then(response => {
            resolve(handleResponse(response));
          })
          .catch(reject);
      }),
    [id, server]
  );

  const readOnly = !['Draft', 'InProgress'].includes(currentSession.current?.status || 'Draft');
  const canAddInstruments = currentSession.current?.status === 'Draft';
  const canOrderInstruments = !readOnly;

  const getActivity = React.useCallback(() => {
    server.getActivity(id).then(response => {
      setEvents(response.data.auditLog);
    });
  }, [id, server]);

  React.useEffect(() => {
    setEvents(undefined);
  }, [id]);

  const values = React.useMemo(
    () => ({
      list,
      ...currentSession,

      onNewItemClick,
      createForm,

      cardsVisible,
      tableVisible,
      showTable,
      showCards,

      addInstrumentsOpen,
      openAddInstruments,
      closeAddInstruments,
      onAddInstrumentSubmit,
      addNewInstrument,

      saveOrder,

      openAddControlInstruments,
      closeAddControlInstruments,
      addControlInstrumentsOpen,
      onAddControlInstrumentSubmit,

      moveToMaterials,
      moveToEquipment,

      enableReview,
      disableReview,
      submitReview,
      reviewEnabled,

      buttonText,

      canAddInstruments,
      canOrderInstruments,

      onCorrectionsSubmit,
      uploadLogFile,
      setQualityEvent,
      setPlannedDeviation,

      submitInstrumentDisposition,

      submitForReview,
      readOnly,

      events,
      getActivity,
    }),
    [
      list,
      currentSession,
      onNewItemClick,
      createForm,
      cardsVisible,
      tableVisible,
      showTable,
      showCards,
      addInstrumentsOpen,
      openAddInstruments,
      closeAddInstruments,
      onAddInstrumentSubmit,
      addNewInstrument,
      saveOrder,
      openAddControlInstruments,
      closeAddControlInstruments,
      addControlInstrumentsOpen,
      onAddControlInstrumentSubmit,
      moveToMaterials,
      moveToEquipment,
      enableReview,
      disableReview,
      submitReview,
      reviewEnabled,
      buttonText,
      canAddInstruments,
      onCorrectionsSubmit,
      uploadLogFile,
      setQualityEvent,
      setPlannedDeviation,
      submitInstrumentDisposition,
      submitForReview,
      readOnly,
      canOrderInstruments,
      events,
      getActivity,
    ]
  );

  return values;
}

export default useQCConext;
