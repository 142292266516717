/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as yup from 'yup';
import { Typography, Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { FormikRadioGroup, FormikTextField } from 'modules/Common';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import InstrumentResult from 'modules/InstrumentsQualityControl/features/instruments/components/InstrumentResult';
import { useFormSubmitting } from '../../hooks';
import SignOffDialog from '../SignOffDialog';

export default function InstrumentDispositionDialog({
  open = false,
  setShowSignOff,
  setTest,
  setShowTests = () => {},
  onSubmit,
  test,
  setCurrent,
  setInstrument,
  title = 'Instrument Final Disposition',
  canEditOutcome = true,
  initialValues = {},
}: { test?: TestSessionInstrumentDto | null } & Record<string, any>) {
  const { handleSubmit } = useFormSubmitting(data => {
    return new Promise((resolve, reject) => {
      onSubmit({
        instrumentId: test?.instrument?.id,
        ...data,
      }).then(response => {
        setShowTests(true);
        setShowSignOff(false);
        setTest(undefined);
        setCurrent(response);
        setInstrument(
          response.instruments.find(x => x.instrument?.id === test?.instrument?.id),
          response
        );
        resolve(response);
        setShowTests(false);
      }, reject);
    });
  });

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={yup.object({
          comment: yup.string().required(),
        })}
        initialValues={{
          ...initialValues,
          instrumentId: test?.instrument?.id,
          disposition: '',
          comment: '',
        }}
      >
        {form => {
          async function onSign() {
            await form.submitForm();
          }

          return (
            <SignOffDialog
              open={open}
              onSign={onSign}
              title={title}
              onClose={() => {
                setShowSignOff(false);
                setTest(undefined);
              }}
              buttonText="Save"
            >
              <Grid item xs>
                <Form>
                  <Grid container style={{ marginBottom: '10px' }} justify="center" spacing={2}>
                    <Grid xs={4}>
                      <Typography>Visual Inspection</Typography>{' '}
                      <InstrumentResult value={test?.visualInspection?.visualInspectionOutcome} />
                    </Grid>
                    <Grid xs={4}>
                      <Typography>Performance Test</Typography>{' '}
                      <InstrumentResult value={test?.performanceTest?.outcome} />
                    </Grid>
                  </Grid>

                  {canEditOutcome && (
                    <FormikRadioGroup
                      label="Disposition"
                      name="disposition"
                      options={[
                        { label: 'Approved', value: 'Approved' },
                        { label: 'Quarantined', value: 'Quarantined' },
                        { label: 'Rejected', value: 'Rejected' },
                      ]}
                    />
                  )}
                  <FormikTextField label="Comment" name="comment" />
                </Form>
              </Grid>
            </SignOffDialog>
          );
        }}
      </Formik>
    </>
  );
}
