import React from 'react';
import { ButtonGroup, Fade } from '@material-ui/core';

import { ContentHeader, NewItemButton } from '@ldx-dmp/Common/components';

import Context from '../../context';

import DataTable from '../MaterialsDataTable';

const List = () => (
  <Context.Consumer>
    {values => (
      <Fade in>
        <>
          <ContentHeader title="Materials">
            <ButtonGroup variant="contained" color="primary">
              <NewItemButton text="Add Material" onClick={values.onNewItemClick} />
            </ButtonGroup>
          </ContentHeader>
          <DataTable />
        </>
      </Fade>
    )}
  </Context.Consumer>
);

export default List;
