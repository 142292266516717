/* eslint-disable @typescript-eslint/no-explicit-any */
import { SnackbarUtils } from 'modules/Common';
import {
  CreateBatchRecordActions,
  AmendBatchRecordActions,
  SignOffActions,
  GetDepositionAssetsActions,
  GetChemistryDefinitionsActions,
  batchRecordInitialised,
  RecordDepositionItemActions,
  AmendLidPrintActions,
  AmendBasePrintActions,
  AmendAdhesiveActions,
  VerifyLaserUsageActions,
  AmendLaserUsageActions,
  AmendAssemblyActions,
  VerifyAssemblyActions,
  AmendSubstrateLaminationActions,
  RecordSubstrateOgpInspectionActions,
  RecordFirstLabelPrepared,
  VerifyFirstLabelPrepared,
  RecordRemainingLabelsPreparedActions,
  SignOffSectionActions,
  UnlockSectionActions,
  AmendFoilPouchLotNumberActions,
  GiveLineClearanceActions,
  AmendReagentNumbersActions,
  AmendBlockAidNumbersActions,
  AmendFinalLaminationActions,
  AmendSingulationActions,
  AmendBoxOvenUsageActions,
  RecordBoxOvenUsageActions,
  AmendTunnelDryerUsageActions,
  RecordFinalOgpInspectionActions,
  AddCommentToSectionActions,
  AttachDevelopmentBatchRequestActions,
  AmendFirstLabelImageActions,
  AmendSubstrateOgpInspectionActions,
  AmendFinalOgpInspectionActions,
  AmendDevelopmentBatchRequestActions,
} from 'modules/Pilot/BatchRecord/actions';
import { push } from 'connected-react-router';
import { closeDialog } from 'modules/Core/actions/dialogs';
import { selectors, getItemsBeingEdited } from 'modules/Core/reducers';
import { clearApiError } from 'modules/Core/actions/apiErrors';
import { stopEditing } from 'modules/Core/actions/editMode';
import preparationHandlers from './handlers';
import { isBatchRecordInitialised } from '../reducers/selectors';
import { VerifyDepositionItemActions, AmendDepositionItemActions } from '../actions/deposition';

const createHandler = (
  type: string,
  options: { message: string; dialog: string }
): { type: string; handler: Function } => ({
  type,
  handler: (_, dispatch) => () => {
    SnackbarUtils.success(options.message);
    dispatch(closeDialog(options.dialog));
  },
});

const closeOpenDialogs = (dispatch, store) => {
  const openDialogs = selectors.getOpenDialogs(store());
  openDialogs.forEach(dialog => dispatch(closeDialog(dialog.replace(/(_DIALOG)/, ''))));
};

const createHandlers = () => {
  const handlers = [] as any;
  preparationHandlers.forEach((value, key) => handlers.push(createHandler(key, value)));

  handlers.push(
    {
      type: [GetDepositionAssetsActions.SUCCESS, GetChemistryDefinitionsActions.SUCCESS],
      handler: (_, dispatch, store) => () => {
        if (isBatchRecordInitialised(store())) dispatch(batchRecordInitialised());
      },
    },
    {
      type: CreateBatchRecordActions.SUCCESS,
      handler: (action, dispatch) => () => {
        SnackbarUtils.success('Batch record created');
        // calling this here will cause a double load of the record page
        // This is tracked under DMP-207
        // dispatch(batchRecordDialogClose());
        dispatch(push(`/pilot-line/batch-records/${action.payload.id}`));
      },
    },
    {
      type: AmendBatchRecordActions.SUCCESS,
      handler: () => () => {
        SnackbarUtils.success('Batch record amended');
      },
    },
    {
      type: [
        AmendBatchRecordActions.SUCCESS,
        AmendLidPrintActions.SUCCESS,
        AmendBasePrintActions.SUCCESS,
        AmendAdhesiveActions.SUCCESS,
        AmendLaserUsageActions.SUCCESS,
        AmendAssemblyActions.SUCCESS,
        AmendSubstrateLaminationActions.SUCCESS,
        AmendFoilPouchLotNumberActions.SUCCESS,
        AmendReagentNumbersActions.SUCCESS,
        AmendBlockAidNumbersActions.SUCCESS,
        AmendDepositionItemActions.SUCCESS,
        AmendBoxOvenUsageActions.SUCCESS,
        AmendTunnelDryerUsageActions.SUCCESS,
        AmendFinalLaminationActions.SUCCESS,
        AmendSingulationActions.SUCCESS,
      ],
      handler: (_, dispatch, store) => () => {
        const state = store();
        const openDialogs = selectors.getOpenDialogs(state);
        const editingItems = getItemsBeingEdited(state);

        openDialogs.forEach(dialog => {
          const dialogName = dialog.replace(/(_DIALOG)/, '');
          const item = editingItems.find(i => i.key === `${dialogName}_EDIT`);

          dispatch(closeDialog(dialogName));
          if (item) dispatch(stopEditing(`${dialogName}`, item.itemId));
        });
      },
    },
    {
      type: VerifyLaserUsageActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Laser Usage Verified');
        dispatch(clearApiError(VerifyLaserUsageActions.CALL));
      },
    },
    {
      type: RecordSubstrateOgpInspectionActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Substrate OGP Inspection recorded');
        dispatch(clearApiError(RecordSubstrateOgpInspectionActions.CALL));
      },
    },
    {
      type: AmendSubstrateOgpInspectionActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Substrate OGP Inspection amended');
        dispatch(clearApiError(AmendSubstrateOgpInspectionActions.CALL));
      },
    },
    {
      type: RecordFirstLabelPrepared.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('1st off label prepared');
        dispatch(clearApiError(RecordFirstLabelPrepared.CALL));
      },
    },
    {
      type: AmendFirstLabelImageActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('1st off label image amended');
        dispatch(clearApiError(AmendFirstLabelImageActions.CALL));
      },
    },
    {
      type: VerifyFirstLabelPrepared.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('1st off label verified');
        dispatch(clearApiError(VerifyFirstLabelPrepared.CALL));
      },
    },
    {
      type: RecordRemainingLabelsPreparedActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Remaining labels prepared');
        dispatch(clearApiError(RecordRemainingLabelsPreparedActions.CALL));
      },
    },
    {
      type: [SignOffActions.SUCCESS, SignOffSectionActions.SUCCESS],
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Signed off');
        dispatch(clearApiError(SignOffActions.CALL));
        dispatch(clearApiError(SignOffSectionActions.CALL));
      },
    },
    {
      type: [GiveLineClearanceActions.SUCCESS],
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Line Clearance Given');
        dispatch(clearApiError(GiveLineClearanceActions.CALL));
      },
    },
    {
      type: UnlockSectionActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Section Unlocked');
        dispatch(clearApiError(UnlockSectionActions.CALL));
      },
    },
    {
      type: RecordDepositionItemActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Deposition recorded');
      },
    },
    {
      type: RecordBoxOvenUsageActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Box Oven usage recorded');
      },
    },
    {
      type: VerifyAssemblyActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Assembly Verified');
        dispatch(clearApiError(VerifyAssemblyActions.CALL));
      },
    },
    {
      type: VerifyDepositionItemActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Deposition Verified');
        dispatch(clearApiError(VerifyDepositionItemActions.CALL));
      },
    },
    {
      type: RecordFinalOgpInspectionActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('OGP Inspection recorded');
        dispatch(clearApiError(RecordFinalOgpInspectionActions.CALL));
      },
    },
    {
      type: AmendFinalOgpInspectionActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('OGP Inspection amended');
        dispatch(clearApiError(AmendFinalOgpInspectionActions.CALL));
      },
    },
    {
      type: AddCommentToSectionActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('Comment added');
      },
    },
    {
      type: AttachDevelopmentBatchRequestActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('DBR uploaded');
        dispatch(clearApiError(AttachDevelopmentBatchRequestActions.CALL));
      },
    },
    {
      type: AmendDevelopmentBatchRequestActions.SUCCESS,
      handler: (_, dispatch, store) => () => {
        closeOpenDialogs(dispatch, store);
        SnackbarUtils.success('DBR updated');
        dispatch(clearApiError(AmendDevelopmentBatchRequestActions.CALL));
      },
    }
  );

  return handlers;
};

export default createHandlers();
