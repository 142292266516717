/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import AddCorrectionsUserToVisualInspectionInput from 'modules/InstrumentsQualityControl/models/AddCorrectionsUserToVisualInspectionInput';

function noop<T>(id: T) {
  return id;
}

export const schema = noop;

export const uiSchema = ui => {
  ui.instrumentId = {
    'ui:widget': 'hidden',
  };
  return ui;
};

export const mapToEdit = () => (
  current: TestSessionInstrumentDto
): AddCorrectionsUserToVisualInspectionInput => {
  return {
    instrumentId: current.id,
  } as AddCorrectionsUserToVisualInspectionInput;
};

export const fields = noop;

export const widgets = noop;

export const validation = () =>
  yup.object({
    comment: yup.string().required(),
    correctionsUser: yup.string().required(),
  });

export const empty = noop;
