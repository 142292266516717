import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import FunctionalTestList from '../components/FunctionalTestList';
import TestResultsForm from '../components/TestResultsForm';
import FunctionalTestResult from '../components/FunctionalTestResult';

const FunctionalTestingView = ({
  repairRequest: { id, status, functionalTesting },
  platform,
  technicianUsers,
  postRepairTestSetup,
  currentUserId,
  instrument,
}) => {
  const [viewState, setViewState] = useState({
    showTestResultForm: false,
    currentTestIndex: 0,
  });
  const isOpen = status !== 'SignedOff' && status !== 'Rejected';

  const handleNewTestResultClicked = () => {
    setViewState({
      ...viewState,
      showTestResultForm: true,
    });
  };

  const handleTestResultClosed = () => {
    setViewState({
      ...viewState,
      showTestResultForm: false,
    });
  };

  const handleTestSelected = testId => {
    setViewState({
      ...viewState,
      currentTestIndex: testId,
    });
  };

  const createFunctionalTestList = tests => {
    return tests.map((test, index) => ({
      id: test.id,
      overallResult: test.overallResult,
      testingCompletedAt: test.testingCompletedAt,
      selected: index === viewState.currentTestIndex,
    }));
  };

  return (
    <>
      {!viewState.showTestResultForm && (
        <Grid item xs={2}>
          {isOpen && (
            <Button
              onClick={handleNewTestResultClicked}
              variant="contained"
              color="primary"
              fullWidth
            >
              Add Test Results
            </Button>
          )}
          {functionalTesting && (
            <FunctionalTestList
              functionalTests={createFunctionalTestList(functionalTesting)}
              onTestSelected={handleTestSelected}
            />
          )}
        </Grid>
      )}
      {viewState.showTestResultForm && postRepairTestSetup && (
        <TestResultsForm
          requestId={id}
          platform={platform}
          technicianUsers={technicianUsers}
          onTestResultClosed={handleTestResultClosed}
          postRepairTestSetup={postRepairTestSetup}
          currentUserId={currentUserId}
          instrument={instrument}
        />
      )}
      {!viewState.showTestResultForm && (!functionalTesting || functionalTesting.length === 0) && (
        <Grid item xs={10}>
          <Grid container justify="center">
            <Grid item>
              <Typography variant="subtitle1">
                No tests have been recorded for this request
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!viewState.showTestResultForm &&
        functionalTesting &&
        functionalTesting[viewState.currentTestIndex] && (
          <FunctionalTestResult
            platform={platform}
            testResult={functionalTesting[viewState.currentTestIndex]}
          />
        )}
    </>
  );
};

FunctionalTestingView.propTypes = {
  repairRequest: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    instrument: PropTypes.object.isRequired,
    functionalTesting: PropTypes.arrayOf(
      PropTypes.shape({
        testingCompletedBy: PropTypes.shape({
          name: PropTypes.string,
        }),
        testingCompletedAt: PropTypes.string,
        mcuFirmwareVersion: PropTypes.string,
        cpldFirmwareVersion: PropTypes.string,
        cameraSoftwareVersion: PropTypes.string,
        meterApiVersion: PropTypes.string,
        serialNumberSet: PropTypes.string,
        testResultLocation: PropTypes.string,
        testGroups: PropTypes.arrayOf(
          PropTypes.shape({
            platform: PropTypes.string,
            name: PropTypes.string,
            tests: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                name: PropTypes.string,
                result: PropTypes.number,
              })
            ),
          })
        ),
      }).isRequired
    ),
  }).isRequired,
  platform: PropTypes.string.isRequired,
  technicianUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
    })
  ).isRequired,
  postRepairTestSetup: PropTypes.object.isRequired,
  currentUserId: PropTypes.string,
  instrument: PropTypes.object.isRequired,
};

FunctionalTestingView.defaultProps = {
  currentUserId: null,
};

export default FunctionalTestingView;
