import React, { useState, useEffect, useMemo } from 'react';
import { Button, Collapse, CircularProgress, TextField, MenuItem } from '@material-ui/core';
import set from 'lodash/set';
import { useSnackbar } from 'notistack';
import {
  registerInstrument,
  updateInstrument,
} from 'modules/Instruments/slices/Management/actions';
import sortBy from 'lodash/sortBy';
import { useDispatch, useSelector } from 'react-redux';
import { getInstrumentSerial, InstrumentSerial } from 'modules/Instruments/domain/instrument';
import { InstrumentDto } from 'modules/Instruments/models';
import { selectors } from 'modules/Instruments/slices/Management/reducers';
import InstrumentSummary from '../InstrumentDialog/InstrumentSummary';
import useStyles from './InstrumentForm.styles';
import FilledAdornedTextField from './FilledAdornedTextField';
import { SerialSearchResult } from '../../reducers/instrumentSearchReducer';

type FormMode = 'standalone' | 'select';

type FormValues = {
  serialNumber: string;
  dirty: boolean;
  existingValuesLoaded: boolean;
  instrumentChanged: boolean;
  specificationRevision: string;
  firmwares: Record<string, unknown>;
};

type RegisterInstrumentFormProps = {
  mode?: FormMode;
  instrument?: InstrumentDto;
  onComplete: (instrument: Partial<InstrumentDto> & Record<string, unknown>) => void;
  onCancel: () => void;
  onInstrumentSerialChanged?: (current: InstrumentSerial) => void;
  searchResult?: SerialSearchResult;
};

const RegisterInstrumentForm = ({
  mode = 'standalone',
  instrument: d,
  onComplete,
  onCancel,
  onInstrumentSerialChanged,
  searchResult,
}: RegisterInstrumentFormProps) => {
  const initialState = useMemo(
    () => ({
      serialNumber: '',
      specificationRevision: '',
      firmwares: {},
      dirty: true,
      existingValuesLoaded: false,
      instrumentChanged: false,
    }),
    []
  );

  const instrument = React.useMemo(() => {
    return d || searchResult?.instrument;
  }, [d, searchResult?.instrument]);

  const [serial, setSerial] = useState<InstrumentSerial>();
  const [formState, setFormState] = useState<FormValues>(initialState);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isLoading = useSelector(selectors.getIsLoading) || searchResult?.loading;

  // Populate version numbers for a loaded instrument
  useEffect(() => {
    if (searchResult?.instrument && !formState.existingValuesLoaded) {
      const tracked = searchResult?.subsystems?.filter(x => x.trackFirmware);

      setFormState({
        ...formState,
        firmwares: (tracked?.reduce((r, c) => {
          return {
            ...r,
            [String(c.firmwareKey)]:
              sortBy(searchResult?.instrument?.firmwareVersions, x => x.timestamp)
                .reverse()
                ?.find(x => x.type === c.firmwareKey)?.version || undefined,
          };
        }, {}) || {}) as Record<string, unknown>,
        existingValuesLoaded: true,
      });
    }
  }, [instrument, formState, searchResult?.subsystems, searchResult?.instrument]);

  // Call complete once form submission is accepted by API
  // TODO: This needs to handle error cases
  useEffect(() => {
    if (!isLoading && instrument && !formState.dirty) {
      if (formState.instrumentChanged)
        enqueueSnackbar('Instrument updated', { variant: 'success' });

      setFormState(initialState);

      onComplete(formState);
    }
  }, [isLoading, instrument, formState, onComplete, initialState, enqueueSnackbar]);

  // Event handlers

  const handleChange = event => {
    const { value, id } = event.target;
    const field = id || event.target.name;
    setFormState(set({ ...formState }, field, value));
  };

  const handleSerialChange = event => {
    const { value } = event.target;

    if (value) {
      const newSerial = getInstrumentSerial(value);
      setSerial(newSerial || undefined);

      if (newSerial && onInstrumentSerialChanged) onInstrumentSerialChanged(newSerial);
    } else {
      setSerial(undefined);
    }

    setFormState({
      ...formState,
      ...initialState,
      serialNumber: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    let instrumentChanged = false;

    const payload = {
      ...formState,
      instrumentSerialNumber: formState.serialNumber,
    };

    instrumentChanged = true;

    dispatch(instrument ? updateInstrument(payload) : registerInstrument(payload));

    setFormState({
      ...formState,
      instrumentChanged,
      dirty: false,
    });
  };

  const handleCancelClicked = () => onCancel();

  const enableSubmit = useMemo(() => {
    if (searchResult?.loading) return false; // Don't enable if loading
    if (isLoading) return false; // Don't enable if loading
    if (!instrument && serial && serial.full) return true; // New instrument, enable submit
    if (instrument && mode === 'select') return true; // If in select mode, enable submit without changes
    if (instrument) return true; // Existing instrument, enable if changes have been made

    return false;
  }, [searchResult?.loading, isLoading, instrument, serial, mode]);

  return (
    <>
      <form className={classes.root} onSubmit={handleSubmit} autoComplete="on">
        <FilledAdornedTextField
          id="instrumentSerialNumber"
          label="Instrument Serial / Asset Number"
          onChange={handleSerialChange}
          value={formState.serialNumber}
          showAdornment
          adornment={<InstrumentSummary serial={serial} />}
        />
        <Collapse in={serial !== undefined}>
          <div>
            {!instrument && searchResult?.version && (
              <TextField
                id="specificationRevision"
                name="specificationRevision"
                onChange={handleChange}
                variant="filled"
                select
                fullWidth
                label="Specification Revision"
                value={formState.specificationRevision}
              >
                {searchResult?.version?.map(version => (
                  <MenuItem value={version.revision}>{version.revision}</MenuItem>
                ))}
              </TextField>
            )}
            {searchResult?.loading && <>Loading...</>}
            {!searchResult?.loading &&
              searchResult?.subsystems
                ?.filter(x => x.trackFirmware)
                .map(sub => (
                  <TextField
                    variant="filled"
                    fullWidth
                    key={`firmwares[${String(sub.firmwareKey)}]`}
                    id={`firmwares[${String(sub.firmwareKey)}]`}
                    label={`${sub.name} Firmware Version`}
                    onChange={handleChange}
                    value={formState.firmwares[String(sub.firmwareKey)]}
                  />
                ))}
          </div>
        </Collapse>
        <div className={classes.actionButtons}>
          <Button variant="contained" onClick={handleCancelClicked}>
            Cancel
          </Button>
          <Button
            disabled={!enableSubmit}
            type="submit"
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            {isLoading && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
            {mode === 'select' && 'Select Instrument'}
            {mode === 'standalone' && (instrument ? 'Update' : 'Register')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default RegisterInstrumentForm;
