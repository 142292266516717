import React from 'react';
import { useBoolean } from 'react-use';

export default function(onSubmit) {
  const [isSubmitting, setIsSubmitting] = useBoolean(false);

  const handleSubmit = React.useMemo(() => {
    return (...args) =>
      new Promise((resolve, reject) => {
        if (!onSubmit) {
          resolve({});
          return;
        }
        setIsSubmitting(true);
        onSubmit(...args).then(res => {
          setIsSubmitting(false);
          resolve(res);
        }, reject);
      });
  }, [onSubmit, setIsSubmitting]);

  return React.useMemo(
    () => ({
      isSubmitting,
      setIsSubmitting,
      handleSubmit,
    }),
    [handleSubmit, isSubmitting, setIsSubmitting]
  );
}
