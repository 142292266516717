import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {},
};

const def = {
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(def);

export default i18n;

type i18Options = {};

export function reinit(options: i18Options) {
  i18n.init({
    ...def,
    ...options,
  });
}
