/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid } from '@material-ui/core';
import { FieldProps } from '@rjsf/core';
import { Alert, AlertTitle } from '@material-ui/lab';

export default ({ uiSchema, value }: FieldProps) => {
  return (
    <Grid item xs>
      <Alert severity={(uiSchema?.['ui:options']?.severity as any) || 'info'}>
        <AlertTitle>{value}</AlertTitle>
      </Alert>
    </Grid>
  );
};
