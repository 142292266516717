import { union, uniqBy } from 'lodash';
import { actionTypes } from '../actions';
import * as selectors from './selectors';

const initialState = {
  items: [],
  current: null,
  error: null,
  types: [],
  families: [],
  subsystems: [],
  technicians: [],
  requestForm: {
    open: false,
    mode: null,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_REPAIR_REQUESTS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        error: '',
      };
    case actionTypes.FETCH_REPAIRS_FOR_INSTRUMENT_SUCCESS:
      return {
        ...state,
        items: uniqBy(union(state.items, action.payload), 'id'),
      };
    case actionTypes.FETCH_REPAIR_REQUEST_ERROR:
    case actionTypes.FETCH_REPAIR_REQUESTS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.CREATE_REPAIR_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case actionTypes.FETCH_REPAIR_REQUEST_SUCCESS:
    case actionTypes.UPDATE_REPAIR_SUCCESS:
    case actionTypes.RESOLVE_REPAIR_SUCCESS:
    case actionTypes.RECORD_TEST_RESULT_SUCCESS:
    case actionTypes.ASSIGN_REPAIR_SUCCESS:
      return {
        ...state,
        items:
          state.items.length === 0
            ? [action.payload]
            : state.items.map(request =>
                request.id === action.payload.id ? action.payload : request
              ),
      };
    case actionTypes.SET_CURRENT_REPAIR:
      return {
        ...state,
        current: action.repairRequest,
      };
    case actionTypes.GET_POST_TESTS_SUCCESS: {
      return {
        ...state,
        postRepairTestSetup: action.payload,
      };
    }
    case actionTypes.TECHNICIAN_USERS_SUCCESS: {
      return {
        ...state,
        technicians: action.payload,
      };
    }
    case actionTypes.OPEN_REQUEST_FORM: {
      return {
        ...state,
        requestForm: {
          open: true,
          mode: action.mode,
        },
      };
    }
    case actionTypes.CLOSE_REQUEST_FORM: {
      return {
        ...state,
        requestForm: {
          open: false,
          mode: null,
        },
      };
    }
    default:
      return state;
  }
}

export const getCurrentRepairRequest = state =>
  state.instruments.repairRequests.items.find(
    request => request.id === state.instruments.repairRequests.current
  );
export const getRepairRequests = state => state.instruments.repairRequests.items;
export const getTechnicianUsers = state => state.instruments.repairRequests.technicians;
export const getPostRepairTestSetup = state => state.instruments.repairRequests.postRepairTestSetup;
export const getRepairRequestsForInstrument = (state, instrument) =>
  !instrument
    ? []
    : state.instruments.repairRequests.items.filter(
        request => request.instrument?.id === instrument.id
      );

export { selectors };
