import { apiGet, apiPost, apiPut, apiPatch } from 'modules/Core/actions';
import {
  FetchInstrumentActionTypes,
  FetchInstrumentsActionTypes,
  InstrumentManagementActionTypes,
  RegisterInstrumentActionTypes,
  UpdateInstrumentActionTypes,
  FetchInstrumentBySerialActionTypes,
  SetIsControlActionTypes,
} from './actionTypes';

export type InstrumentRegistration = {} & Record<string, unknown>;

export const fetchInstruments = () =>
  apiGet('/instruments', FetchInstrumentsActionTypes.FETCH_INSTRUMENTS);

export const fetchInstrument = (instrumentId: string) =>
  apiGet(`/instruments/${instrumentId}`, FetchInstrumentActionTypes.FETCH_INSTRUMENT);

export const fetchInstrumentBySerial = (serialNumber: string) =>
  apiGet(
    `/instruments/serial/${serialNumber}`,
    FetchInstrumentBySerialActionTypes.FETCH_INSTRUMENT_BY_SERIAL
  );

export const registerInstrument = (registration: InstrumentRegistration) =>
  apiPost('/instruments', RegisterInstrumentActionTypes.REGISTER_INSTRUMENT, registration);

export const updateInstrument = (registration: InstrumentRegistration) =>
  apiPut(
    `/instruments/serial/${registration.instrumentSerialNumber}`,
    UpdateInstrumentActionTypes.UPDATE_INSTRUMENT,
    registration
  );

export const setCurrentInstrument = instrumentId => ({
  type: InstrumentManagementActionTypes.SET_CURRENT_INSTRUMENT,
  instrumentId,
});

export const setIsControlInstrument = (instrumentId: string, isControl: boolean) =>
  apiPatch(
    `/instruments/${instrumentId}/control-instrument`,
    SetIsControlActionTypes.SET_INSTRUMENT_IS_CONTROL,
    {
      isControl,
    }
  );
