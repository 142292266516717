import React from 'react';
import { GridList, GridListItem } from 'modules/Common';
import InstrumentDto from 'modules/Instruments/models/InstrumentDto';
import { FirmwareVersionsTable } from 'modules/Instruments/slices/Management/components/InstrumentSubsystems/InstrumentSubsystems';

type SelectedInstrumentProps = {
  instrument?: InstrumentDto;
};

const SelectedInstrument = ({ instrument }: SelectedInstrumentProps) => (
  <GridList gridSpacing={2}>
    <GridListItem
      fullWidth
      titleWidth={5}
      title="Instrument Serial #"
      value={`${instrument?.serial} - REV ${instrument?.version?.revision}`}
    />

    <FirmwareVersionsTable items={instrument?.firmwareVersions} />
  </GridList>
);

export default SelectedInstrument;
