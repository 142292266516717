import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Timeline, TimelineItem } from 'modules/Common';
import { formatRepairStatus } from 'modules/Instruments/utilities';

const RepairTimeline = ({ actions }) => {
  const messageMap = new Map([
    ['Created', 'created the repair request'],
    ['Comment', 'commented:'],
    ['Updated', 'updated the request:'],
    ['Assigned', 'assigned request to '],
    ['StatusChanged', 'changed status to '],
    ['Tested', 'added test results:'],
    ['SignedOff', 'signed off and closed the request'],
    ['Rejected', 'rejected the request'],
  ]);

  return (
    <>
      <Timeline>
        {actions.map(action => (
          <TimelineItem key={action.occurredAt} occurred={moment(action.occurredAt)}>
            <strong>{action.user.name}</strong> {messageMap.get(action.type)}
            {action.assignedTo && <strong>{action.assignedTo.name}</strong>}
            {action.changedTo && <strong>{formatRepairStatus(action.changedTo)}</strong>}
            {action.content && <blockquote>{action.content}</blockquote>}
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
};

RepairTimeline.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      occurredAt: PropTypes.string.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      type: PropTypes.string.isRequired,
      assignedTo: PropTypes.string,
      changedTo: PropTypes.string,
      content: PropTypes.string,
    })
  ).isRequired,
};

export default RepairTimeline;
