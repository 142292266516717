import { getTableData, TableState } from 'modules/Core/components/DataTable/dataTableSlice';

export enum FetchBatchRecordsActions {
  CALL = 'pilot/batch-records/FETCH_BATCH_RECORDS',
  REQUEST = 'pilot/batch-records/FETCH_BATCH_RECORDS_REQUEST',
  SUCCESS = 'pilot/batch-records/FETCH_BATCH_RECORDS_SUCCESS',
  FAILURE = 'pilot/batch-records/FETCH_BATCH_RECORDS_FAILURE',
  CLEAR = 'pilot/batch-records/FETCH_BATCH_RECORDS_CLEAR',
}
export type BatchRecordsTableViewOptions = 'live' | 'archived' | 'Live' | 'Archived';

export const clearBatchRecords = () => ({
  type: FetchBatchRecordsActions.CLEAR,
});

export default (view: BatchRecordsTableViewOptions, tableState: TableState) =>
  getTableData('/pilot/batch-records', FetchBatchRecordsActions.CALL, tableState, { view });
