import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export default () => {
  return (
    <Alert severity="warning">
      <AlertTitle>Rework required</AlertTitle>
    </Alert>
  );
};
