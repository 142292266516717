import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { BatchRecord, Sections } from 'modules/Pilot/BatchRecord/domain';
import { useSelector } from 'react-redux';
import { getUserId, getUserPermissions } from 'modules/Core/reducers/selectors';
import OgpInspection from 'modules/Pilot/BatchRecord/SingleView/components/OgpInspection';
import {
  amendFinalOgpInspection,
  AmendFinalOgpInspectionActions,
  recordFinalOgpInspection,
  RecordFinalOgpInspectionActions,
} from 'modules/Pilot/BatchRecord/actions';
import StripRejects from './StripRejects';
import SignOffTable from '../SignOffTable';
import makeVisualInspectionDefinition from './visualInspectionDefinition';
import makePackagingSealingDefinition from './packagingSealingDefinition';
import CommentsTable from '../CommentsTable';
import StripSummary from './StripSummary';

type InspectionPanelProps = {
  batchRecord: BatchRecord;
  readOnly?: boolean;
};

const InspectionPanel = ({ batchRecord, readOnly = false }: InspectionPanelProps) => {
  const userPermissions = useSelector(getUserPermissions);
  const userId = useSelector(getUserId);
  const visualInspection = useMemo(
    () => makeVisualInspectionDefinition(batchRecord, userId, userPermissions),
    [batchRecord, userId, userPermissions]
  );

  const packagingSealing = useMemo(
    () => makePackagingSealingDefinition(batchRecord, userId, userPermissions),
    [batchRecord, userId, userPermissions]
  );

  return (
    <>
      <Grid container item xs={6} spacing={1}>
        <StripSummary stripRejects={batchRecord.inspection.stripRejects} />
        <StripRejects batchRecord={batchRecord} />
      </Grid>
      <Grid container item xs={6} spacing={1}>
        <SignOffTable
          definition={visualInspection}
          status={batchRecord.inspection.visualInspection.status}
          xs={12}
        />
        <SignOffTable
          definition={packagingSealing}
          status={batchRecord.inspection.packagingAndSealing.status}
          xs={12}
        />
        <OgpInspection
          inspection={batchRecord.inspection.ogpInspection}
          title="OGP Inspection"
          status={batchRecord.inspection.ogpInspection.status}
          recordAction={(recordId, attachments) => recordFinalOgpInspection(recordId, attachments)}
          amendAction={(recordId, attachments, comment) =>
            amendFinalOgpInspection(recordId, attachments, comment)
          }
          recordApi={RecordFinalOgpInspectionActions.CALL}
          amendApi={AmendFinalOgpInspectionActions.CALL}
          dialogName="pilot/batch-records/OGP_FINAL"
          recordId={batchRecord.id}
          xs={12}
        />
      </Grid>
      <CommentsTable
        recordId={batchRecord.id}
        section={Sections.Inspection}
        comments={batchRecord.comments}
        readOnly={readOnly}
      />
    </>
  );
};

export default InspectionPanel;
