/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TestSessionDto } from 'modules/InstrumentsQualityControl/models';
import PerformanceTestReviewDialog from '../../../../components/PerformanceTestReviewDialog';
import usePerformanceTest from '../../../../hooks/usePerformanceTest';

export default function PerformanceTestContainer({
  current,
  setCurrent,
  fetchCurrent,
  onCorrectionsSubmit,
  children,
  initialInstrument,
  ...props
}: { current: TestSessionDto | any } & Record<string, any>) {
  const usage = 'PerformanceTest';
  const {
    showReview,
    showSignOff,
    showCorrections,
    toggleForm: hideForm,
    setShowReview,
    setTest,
    test,
    setInstrument,
    setShowSignOff,
    onSignOff,
    setShowCorrections,
    submitReview,
    ...rest
  } = usePerformanceTest(current, setCurrent, fetchCurrent, initialInstrument);

  return (
    <>
      {children({
        ...props,
        ...rest,
        setCurrent,
        current,
        usage,
      })}
      <PerformanceTestReviewDialog
        open={showReview && !!test}
        setShowSignOff={setShowReview}
        setTest={setTest}
        setShowTests={hideForm}
        onSubmit={submitReview}
        test={test}
        setCurrent={setCurrent}
        setInstrument={setInstrument}
      />
      <PerformanceTestReviewDialog
        title="Sign Off Performance Test"
        open={showSignOff && !!test}
        setShowSignOff={setShowSignOff}
        setTest={setTest}
        setShowTests={hideForm}
        onSubmit={onSignOff}
        test={test}
        setCurrent={setCurrent}
        setInstrument={setInstrument}
        hideComment
      />
      <PerformanceTestReviewDialog
        title="Submit Corrections"
        open={showCorrections && !!test}
        setShowSignOff={setShowCorrections}
        setTest={setTest}
        setShowTests={hideForm}
        onSubmit={onCorrectionsSubmit}
        test={test}
        initialValues={{
          ...test?.performanceTest,
        }}
        setCurrent={setCurrent}
        setInstrument={setInstrument}
        canEditOutcome={false}
      />
    </>
  );
}
