import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import { Form } from '@ldx-dmp/Common/components';

import Context from '../../context';
import * as form from '../../forms/addMaterialInputFormSchema';

const CreateTestSessionForm = () => {
  return (
    <Context.Consumer>
      {({ createForm: values }) => {
        return (
          <Dialog open={!!values?.formOpen} onClose={values?.onFormClose} fullWidth>
            <DialogTitle>Add Material</DialogTitle>
            <DialogContent>
              <Form
                {...form}
                initialValues={values?.initialValues || form?.empty || {}}
                onSubmit={values?.onFormSubmit || (() => {})}
                onClose={values?.onFormClose || (() => {})}
              />
            </DialogContent>
          </Dialog>
        );
      }}
    </Context.Consumer>
  );
};

export default CreateTestSessionForm;
