/* eslint-disable react/prop-types */
import React from 'react';
import { FormikValues } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Typography } from '@material-ui/core';
import { RSAAAction } from 'redux-api-middleware';
import { FormDefinition } from '@ldx-dmp/Common/components/FormDialog/FormDialog';
import { FormikTextField } from '@ldx-dmp/Common';

interface Values extends FormikValues {
  file1?: File;
  file2?: File;
  comment?: string;
}

export default (
  recordId: string,
  dialogName: string,
  apiAction: string,
  action: (recordId: string, attachments: File[], comment?: string) => RSAAAction,
  isEdit = false
): FormDefinition<Values> => {
  const validation = () => {
    if (!isEdit) return {};
    return {
      comment: yup
        .string()
        .defined()
        .required()
        .min(5),
    };
  };

  return {
    title: 'OGP Inspection',
    dialogName,
    apiAction,
    onSubmit: (values: Values, _, dispatch) => {
      if (values.file1 !== undefined && values.file2 !== undefined)
        dispatch(action(recordId, [values.file1, values.file2], values.comment));
    },
    initialValues: {
      file1: undefined,
      file2: undefined,
    },
    // eslint-disable-next-line react/prop-types
    form: ({ values, setFieldValue }) => (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            Please attach the results of the OGP inspection
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">PDF File</Typography>
          <label htmlFor="file1">
            <input
              type="file"
              name="file1"
              id="file1"
              style={{ display: 'none' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!event.target.files) return;
                setFieldValue('file1', event.target.files[0]);
              }}
              accept=".pdf"
            />
            <Button
              color="primary"
              variant="contained"
              component="span"
              style={{ marginRight: '10px' }}
            >
              Select File
            </Button>
            {values.file1 && <>{values.file1.name}</>}
          </label>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Excel File</Typography>
          <label htmlFor="file2">
            <input
              type="file"
              name="file2"
              id="file2"
              style={{ display: 'none' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!event.target.files) return;
                setFieldValue('file2', event.target.files[0]);
              }}
              accept=".csv,.xls,.xlsx,.ods"
            />
            <Button
              color="primary"
              variant="contained"
              component="span"
              style={{ marginRight: '10px' }}
            >
              Select File
            </Button>
            {values.file2 && <>{values.file2.name}</>}
          </label>
        </Grid>
        {isEdit && <FormikTextField name="comment" label="Reason for change" multiline />}
      </Grid>
    ),
    validationSchema: (): yup.ObjectSchema<Values> =>
      yup
        .object()
        .shape(validation)
        .defined(),
  };
};
