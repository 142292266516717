import { apiPost, apiPatch } from 'modules/Core/actions/apiActions';

export type LidPrintPayload = {
  lotNumber: string;
  splits: number[];
};

export type AmendLidPrintPayload = { subRecordId?: string; comment?: string } & LidPrintPayload;

export enum AddLidPrintActions {
  CALL = 'pilot/batch-records/ADD_LID_PRINT',
  REQUEST = 'pilot/batch-records/ADD_LID_PRINT_REQUEST',
  SUCCESS = 'pilot/batch-records/ADD_LID_PRINT_SUCCESS',
  FAILURE = 'pilot/batch-records/ADD_LID_PRINT_FAILURE',
}

export enum AmendLidPrintActions {
  CALL = 'pilot/batch-records/AMEND_LID_PRINT',
  REQUEST = 'pilot/batch-records/AMEND_LID_PRINT_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_LID_PRINT_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_LID_PRINT_FAILURE',
}

export const addLidPrint = (recordId: string, payload: LidPrintPayload) =>
  apiPost(`/pilot/batch-records/${recordId}/preparation/lid-print`, AddLidPrintActions.CALL, {
    ...payload,
  });

export const amendLidPrint = (recordId: string, payload: AmendLidPrintPayload) =>
  apiPatch(`/pilot/batch-records/${recordId}/preparation/lid-print`, AmendLidPrintActions.CALL, {
    ...payload,
  });
