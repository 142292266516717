import { createAction } from 'redux-api-middleware';
import * as actionTypes from './actionTypes';

export const fetchRepairRequests = () =>
  createAction({
    endpoint: '/instruments/repairs',
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.FETCH_REPAIR_REQUESTS_REQUEST,
      actionTypes.FETCH_REPAIR_REQUESTS_SUCCESS,
      actionTypes.FETCH_REPAIR_REQUESTS_ERROR,
    ],
  });

export const fetchRepairRequest = repairRequestId =>
  createAction({
    endpoint: `/instruments/repairs/${repairRequestId}`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.FETCH_REPAIR_REQUEST_REQUEST,
      actionTypes.FETCH_REPAIR_REQUEST_SUCCESS,
      actionTypes.FETCH_REPAIR_REQUEST_ERROR,
    ],
  });

export const fetchRepairRequestsForInstrument = instrumentId =>
  createAction({
    endpoint: `/instruments/${instrumentId}/repairs`,
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.FETCH_REPAIRS_FOR_INSTRUMENT_REQUEST,
      actionTypes.FETCH_REPAIRS_FOR_INSTRUMENT_SUCCESS,
      actionTypes.FETCH_REPAIRS_FOR_INSTRUMENT_ERROR,
    ],
  });
