const sidebarWidth = '240px';
const getWidth = isSidebarOpen => (isSidebarOpen ? `calc(100% - ${sidebarWidth})` : null);
const getMargin = isSidebarOpen => (isSidebarOpen ? sidebarWidth : null);

export default theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    width: props => getWidth(props.isSidebarOpen),
    marginLeft: props => getMargin(props.isSidebarOpen),
    transition: theme.transitions.create(['width', 'margin']),
  },
  grow: {
    flexGrow: 1,
  },
  toolbarLogo: {
    width: theme.spacing(6) + 1,
    height: theme.spacing(6) + 1,
    marginLeft: '-10px',
  },
  toolbarLogoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  accountButton: {
    color: '#FFF',
  },
});
