import * as actionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const initiateInstrumentsLoad = () => {
  return {
    type: actionTypes.INITIATE_INSTRUMENTS_LOAD,
  };
};

export const instrumentsModuleLoaded = () => {
  return {
    type: actionTypes.INSTRUMENTS_MODULE_LOADED,
  };
};
