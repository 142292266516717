import {
  ContentHeader,
  SummaryPanel,
  SummaryPanelItem,
  Spinner,
  NewItemButton,
} from 'modules/Common';
import {
  actionCreators as repairRequestActionCreators,
  fetchRepairRequests as fetchRepairRequestsAction,
} from 'modules/Instruments/slices/Repair/actions';
import { RepairRequestDialog } from 'modules/Instruments/slices/Repair/components';
import createRepairRequestTableData from 'modules/Instruments/slices/Repair/data/createRepairRequestTableData';
import { getRepairRequests, selectors } from 'modules/Instruments/slices/Repair/reducers';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { push as pushAction } from 'connected-react-router';
import MUIDataTable from 'mui-datatables';
import { createLoadingSelector } from 'modules/Core/reducers';
import { FetchRepairRequestsActionTypes } from 'modules/Instruments/slices/Repair/actions/actionTypes';
import { Button, ButtonGroup, Fade } from '@material-ui/core';

const RepairRequestListPage = ({
  repairRequests,
  fetchRepairRequests,
  setCurrentRepairRequest,
  openRequestForm,
  showRequestForm,
  push,
  isLoading,
}) => {
  const tableData = createRepairRequestTableData(repairRequests, (rowData, rowMeta) => {
    setCurrentRepairRequest(repairRequests[rowMeta.dataIndex].id);
    push(`/instruments/repair/${repairRequests[rowMeta.dataIndex].id}`);
  });

  const handleClickOpen = () => openRequestForm();
  const moveToLocations = () => push('/instruments/repair/locations');

  useEffect(() => {
    fetchRepairRequests();
  }, [fetchRepairRequests]);

  const openRequests = repairRequests.filter(request => request.status === 'Open').length;
  const closedRequests = repairRequests.filter(request => request.status === 'SignedOff').length;
  const rejectedRequests = repairRequests.filter(request => request.status === 'Rejected').length;

  if (isLoading) return <Spinner />;

  return (
    <Fade in={!isLoading}>
      <div>
        <ContentHeader title="Instrument Repair Requests">
          <ButtonGroup variant="contained" color="primary">
            <NewItemButton text="New Repair Request" onClick={handleClickOpen} />
            <Button onClick={moveToLocations}>Manage Locations</Button>
          </ButtonGroup>
        </ContentHeader>
        <RepairRequestDialog show={showRequestForm} />
        <SummaryPanel withCard>
          <SummaryPanelItem primary="Open" secondary={openRequests} />
          <SummaryPanelItem primary="Signed Off" secondary={closedRequests} />
          <SummaryPanelItem primary="Rejected" secondary={rejectedRequests} />
          <SummaryPanelItem primary="Total" secondary={repairRequests.length} />
        </SummaryPanel>
        <br />
        <MUIDataTable
          data={tableData.data}
          columns={tableData.columns}
          options={tableData.options}
        />
      </div>
    </Fade>
  );
};

const mapStateToProps = state => {
  const loadingSelector = createLoadingSelector([
    FetchRepairRequestsActionTypes.FETCH_REPAIR_REQUESTS,
  ]);

  return {
    showRequestForm: selectors.isRequestFormOpen(state),
    repairRequests: getRepairRequests(state),
    isLoading: loadingSelector(state),
  };
};

const actionCreators = {
  fetchRepairRequests: fetchRepairRequestsAction,
  setCurrentRepairRequest: repairRequestActionCreators.setCurrentRepairRequest,
  openRequestForm: repairRequestActionCreators.openRequestForm,
  push: pushAction,
};

RepairRequestListPage.propTypes = {
  repairRequests: PropTypes.array.isRequired,
  fetchRepairRequests: PropTypes.func.isRequired,
  setCurrentRepairRequest: PropTypes.func.isRequired,
  openRequestForm: PropTypes.func.isRequired,
  showRequestForm: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const enhance = compose(withRouter, connect(mapStateToProps, actionCreators));

export default enhance(RepairRequestListPage);
