/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import merge from 'lodash/merge';
import MUIDataTable from 'mui-datatables';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { TestSessionDto } from 'modules/InstrumentsQualityControl/models';

import useStyles from '@ldx-dmp/Common/components/FormDialog/FormDialog.styles';
import { Form } from '@ldx-dmp/Common/components';
import { InstrumentsQualityControlContextType } from 'modules/InstrumentsQualityControl/context';
import useFormSubmitting from '@ldx-dmp/Common/useFormSubmitting';
import * as form from '../../forms/setPlannedDeviationsNumbersInputFormSchema';

const TestSessionPlannedDeviation = ({
  current,
  setPlannedDeviation,
  tableOptions = {},
  readOnly,
}: { tableOptions?: any; readOnly?: boolean } & Partial<InstrumentsQualityControlContextType>) => {
  const { isSubmitting, handleSubmit } = useFormSubmitting(setPlannedDeviation);
  const classes = useStyles();

  const data = current?.instruments
    ?.filter(x => x?.hasPlannedDeviation)
    .map(x => ({
      ...x.instrument,
      ...x,
    }));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Form
                {...form}
                isSubmitting={isSubmitting}
                enableReinitialize
                initialValues={
                  merge({}, form?.empty, form.mapToEdit(current as TestSessionDto)) ||
                  form?.empty ||
                  {}
                }
                onSubmit={handleSubmit}
                onClose={() => {}}
              >
                <div
                  className={classes.actionButtons}
                  style={{
                    marginRight: 0,
                    paddingRight: 0,
                  }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || readOnly}
                  >
                    {isSubmitting && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
                    Save
                  </Button>
                </div>
              </Form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Box mb={1} mt={1}>
                <Typography variant="h5">Instruments with Planned Deviations</Typography>
                <Divider />
              </Box>
            </CardContent>
            <MUIDataTable
              data={data}
              columns={[
                { name: 'serial', label: 'Serial Number' },
                {
                  name: 'visualInspection',
                  label: 'Visual Inspection',
                  options: {
                    customBodyRender: value => {
                      if (!value.hasPlannedDeviation || !value.plannedDeviationNumbers?.length)
                        return 'N/A';

                      return value.plannedDeviationNumbers?.join(', ');
                    },
                  },
                },
                {
                  name: 'performanceTest',
                  label: 'Performance Test',
                  options: {
                    customBodyRender: value => {
                      if (!value.hasPlannedDeviation || !value.plannedDeviationNumbers?.length)
                        return 'N/A';

                      return value.plannedDeviationNumbers?.join(', ');
                    },
                  },
                },
              ]}
              options={{
                elevation: 2,
                sort: false,
                filter: false,
                selectableRows: 'none',
                download: false,
                print: false,
                viewColumns: false,
                search: false,
                pagination: false,
                ...tableOptions,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default (props: {} & Partial<InstrumentsQualityControlContextType>) => {
  return (
    <>
      <TestSessionPlannedDeviation {...props} />
    </>
  );
};
