import React from 'react';
import { useField, useFormikContext } from 'formik';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import moment from 'moment';
import WarningField from '../WarningField';

export type FormikDatePickerProps = {
  name?: string;
  id?: string;
  label: string;
  // RJSF methods we want to ignore
  onChange?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  warning?: {
    check: (value: unknown, values: unknown) => boolean;
    message?: string;
  };
} & Partial<DatePickerProps> &
  Record<string, unknown>;

const FormikDatePicker = ({
  name,
  id,
  label,
  onChange,
  onBlur,
  onFocus,
  warning,
  ...rest
}: FormikDatePickerProps) => {
  const { values } = useFormikContext();
  const [, meta, helpers] = useField(name || id || '');

  return (
    <DatePicker
      fullWidth
      autoOk
      showTodayButton
      name={name || id}
      format="ddd, MMM Do YYYY"
      inputVariant="filled"
      onAccept={date => helpers.setValue(date)}
      onChange={date => helpers.setValue(date)}
      value={!meta.value ? null : moment(meta.value)}
      label={label}
      error={!!(meta.touched && meta.error)}
      helperText={warning && warning.check(meta.value, values) && <WarningField {...warning} />}
      {...rest}
    />
  );
};

export default FormikDatePicker;
