import React from 'react';
import { usePermissionCheck } from 'modules/Core/hooks';

export type SpeedDialActionType = {
  icon: React.ReactElement | null;
  name: string;
  onClick: () => void;
  permissions?: string[];
  disabled?: boolean;
  global?: boolean;
};

export default function useSpeedDialContext() {
  const { check } = usePermissionCheck();

  const [allActions, setActions] = React.useState<SpeedDialActionType[]>([]);

  const resetActions = React.useCallback(() => {
    setActions([]);
  }, []);

  const actions = React.useMemo(
    () =>
      allActions.filter(action => {
        if (action.disabled === true) return false;

        return !action.permissions || check(action.permissions);
      }),
    [allActions, check]
  );

  return React.useMemo(
    () => ({
      actions,
      setActions,
      visible: !!actions.length,
      resetActions,
    }),
    [actions, setActions, resetActions]
  );
}
