import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiGrid-item': {
      '&:nth-last-of-type(n+3)': {
        borderBottom: '0px none',
      },
    },
  },
}));

export default useStyles;
