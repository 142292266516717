import React from 'react';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import { FormikValues } from 'formik';
import { formatShortDate } from 'modules/Common/utilities';
import { FormikTextField, FormikCheckboxGroup } from 'modules/Common/components';
import { RecordDefinition } from 'modules/Pilot/BatchRecord/SingleView/components/RecordTable/RecordTable';
import {
  BatchRecord,
  LAMINATION_MIN_SPEED,
  LAMINATION_MAX_SPEED,
  LaminationItem,
} from 'modules/Pilot/BatchRecord/domain';
import {
  RecordFinalLaminiationActions,
  recordFinalLaminiation,
  AmendFinalLaminationActions,
  amendFinalLamination,
} from 'modules/Pilot/BatchRecord/actions';
import { DataTableRow } from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable/TableData';
import { PartStatus } from 'modules/RecordManagement';
import { UserPermission } from 'modules/Core/types';
import getInitialSplits from '../../initialSplits';

interface Values extends FormikValues {
  splits: number[];
  speed: number;
  passes: number;
  comment?: string;
}

export default (
  batchRecord: BatchRecord,
  itemsBeingEdited: { key: string; itemId: string }[],
  userPermissions: UserPermission[]
): RecordDefinition<Values> => {
  const dialogName = 'pilot/batch-records/FINAL_LAMINATION';
  const recordId = batchRecord.id;
  const { splits } = batchRecord.batchRequest;
  const { status, subRecords, requiredPermissions } = batchRecord.postDrying.laminations;

  const itemBeingEdited = itemsBeingEdited.find(
    item =>
      item.key === `${dialogName}_EDIT` &&
      subRecords &&
      subRecords.findIndex(lam => lam.id === item.itemId) > -1
  );

  const rowForEditing = itemBeingEdited
    ? subRecords.find(lam => lam.id === itemBeingEdited.itemId)
    : null;

  const isAllowedToEdit = () => {
    if (
      status === PartStatus.Locked ||
      status === PartStatus.ReadOnly ||
      status === PartStatus.Blocked
    )
      return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  const newSubmit = (values: Values, _, dispatch) =>
    dispatch(recordFinalLaminiation(recordId, { ...values }));
  const editSubmit = (values: Values, _, dispatch) =>
    dispatch(amendFinalLamination(recordId, { ...values, subRecordId: rowForEditing?.id }));

  const validation = {
    splits: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(splits.length),
    speed: yup
      .number()
      .defined()
      .min(
        LAMINATION_MIN_SPEED,
        `Speed must be between ${LAMINATION_MIN_SPEED} and ${LAMINATION_MAX_SPEED}`
      )
      .max(
        LAMINATION_MAX_SPEED,
        `Speed must be between ${LAMINATION_MIN_SPEED} and ${LAMINATION_MIN_SPEED}`
      ),
    passes: yup
      .number()
      .defined()
      .min(1, 'Number of Passes must be above 0')
      .max(5, 'Number of Passes must no more than 5'),
  };

  const editValidation = {
    ...validation,
    comment: yup
      .string()
      .defined()
      .required()
      .min(5),
  };

  const createDataTableRow = (row: LaminationItem): DataTableRow => ({
    key: row.id,
    fields: {
      splits: { value: row.splits.join(', ') },
      speed: { value: row.speed },
      passes: { value: row.passes },
      laminatedBy: { user: row.laminatedBy.user.name, date: formatShortDate(row.laminatedBy.date) },
    },
  });

  return {
    title: 'Lamination',
    tableData: {
      columns: [
        { accessor: 'splits', label: 'Split(s)' },
        { accessor: 'speed', label: 'Speed (cm/m)' },
        { accessor: 'passes', label: 'Number of Passes' },
        { accessor: 'laminatedBy', label: 'Laminated by', isSignOff: true },
      ],
      rows: subRecords ? subRecords.map(row => createDataTableRow(row)) : [],
    },
    canAddRow: status === PartStatus.Incomplete || status === PartStatus.Complete,
    canEditRows: isAllowedToEdit(),
    formDefinition: {
      title: 'Lamination',
      dialogName,
      apiAction: rowForEditing
        ? AmendFinalLaminationActions.CALL
        : RecordFinalLaminiationActions.CALL,
      onSubmit: rowForEditing ? editSubmit : newSubmit,
      initialValues: {
        splits: getInitialSplits(rowForEditing, splits),
        speed: rowForEditing ? rowForEditing.speed : 0,
        passes: rowForEditing ? rowForEditing.passes : 0,
      },
      form: () => (
        <>
          <FormikCheckboxGroup
            name="splits"
            label="Splits"
            options={splits}
            labelPlacement="top"
            allowSelectAll
          />
          <Grid container spacing={2}>
            <Grid item xs>
              <FormikTextField
                name="speed"
                label="Speed (cm/m)"
                type="number"
                inputMode="decimal"
                step={0.1}
              />
            </Grid>
            <Grid item xs>
              <FormikTextField name="passes" label="Number of Passes" type="number" />
            </Grid>
          </Grid>
          {rowForEditing && <FormikTextField name="comment" label="Reason for change" multiline />}
        </>
      ),
      validationSchema: (): yup.ObjectSchema<Values> =>
        yup
          .object()
          .shape(rowForEditing ? editValidation : validation)
          .defined(),
    },
  };
};
