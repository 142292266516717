import React from 'react';
import { utils, GridList, GridListItem } from 'modules/Common';
import { RepairRequest } from 'modules/Instruments/domain/repairRequest';
import AffectedSubsystemsList from './AffectedSubsystemsList';

type RequestDetailsPanelProps = {
  repairRequest: RepairRequest;
};

const RequestDetailsPanel = ({ repairRequest }: RequestDetailsPanelProps) => {
  const { affectedSubsystems } = repairRequest.requestDetails;

  return (
    <GridList>
      <GridListItem
        title="Name of Instrument User"
        value={repairRequest.requestDetails.instrumentUser}
      />
      <GridListItem
        title="Where is Instrument Used?"
        value={repairRequest.requestDetails.instrumentLocation?.name}
      />
      <GridListItem
        title="Requested On"
        value={utils.formatShortDate(repairRequest.requestDetails.requestedAt)}
      />
      <GridListItem
        title="Last Updated"
        value={utils.formatShortDateTime(repairRequest.lastModificationTime || undefined)}
      />
      <GridListItem
        title="Details of Change / Fault / Rework Required"
        value={repairRequest.requestDetails.requestDescription}
        fullWidth
      />
      <GridListItem fullWidth>
        If any PCB’s are replaced, then a full end of line functional test will need to be be
        performed as detailed on the Testing tab. Any other tests will be decided upon at time of
        repair.
      </GridListItem>
      <GridListItem
        title="Change / Repair / Rework Details"
        value={repairRequest.repairDetails && repairRequest.repairDetails.repairDescription}
        fullWidth
      />
      <GridListItem title="Affected Subsystems" fullWidth>
        <AffectedSubsystemsList affectedSubsystems={affectedSubsystems} />
      </GridListItem>
    </GridList>
  );
};

export default RequestDetailsPanel;
