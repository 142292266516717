/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { debounceSearchRender } from 'mui-datatables';
import startCase from 'lodash/startCase';
import { formatShortDate } from 'modules/Common/utilities';
import { FetchDataFunction } from 'modules/Core/components/DataTable/DataTable';
import { SummaryPanelItemProps } from 'modules/Common/components/SummaryPanel/SummaryPanelItem/SummaryPanelItem';
import { AddEquipmentInput, EquipmentDto } from '../../models';

export type EquipmentContextType = {
  list: Partial<{
    fetchAll: FetchDataFunction;
    totalCount: number;
    isLoading: boolean;
    isReloading: boolean;
    data: any[];
    options: any;
    columns: any[];
  }>;
  details: Partial<{
    summary: SummaryPanelItemProps[];
  }>;
  isLoading: boolean;

  createForm: {
    formOpen: boolean;
    onFormClose: () => void;
    onFormSubmit: (data: Partial<AddEquipmentInput>) => void;
    initialValues?: (Partial<EquipmentDto> & Partial<AddEquipmentInput>) | undefined | null;
  };

  onNewItemClick: () => void;

  current?: EquipmentDto | null;
};

export const columns = [
  { name: 'id', label: 'id', options: { display: 'excluded' } },
  { name: 'name', label: 'Name', options: { filter: false, sort: true } },
  { name: 'assetId', label: 'Asset Id', options: { filter: false, sort: true } },
  {
    name: 'calibrationDueDate',
    label: 'Calibration Due Date',
    options: {
      filter: false,
      sort: true,
      customBodyRender: value => {
        if (!value) return 'N/A';

        return formatShortDate(value);
      },
    },
  },
  {
    name: 'usage',
    label: 'Usage',
    options: {
      filter: false,
      sort: true,
      customBodyRender: value => value.map(x => startCase(x)).join(', '),
    },
  },
];

export const options = {
  filter: false,
  selectableRows: 'none',
  download: false,
  print: false,
  filterType: 'multiselect',
  elevation: 1,
  viewColumns: false,
  search: false,
  customSearchRender: debounceSearchRender(500),
};

const context = React.createContext<Partial<EquipmentContextType>>({
  list: {
    columns,
  },
});

export default context;
