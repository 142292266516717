import { combineReducers } from 'redux';
import { actionTypes as moduleActionTypes } from 'modules/Instruments/actions';
import repairRequests from 'modules/Instruments/slices/Repair/reducers';
import management from 'modules/Instruments/slices/Management/reducers';
import serialSearch from 'modules/Instruments/slices/Management/reducers/instrumentSearchReducer';

const combinedReducer = combineReducers({ repairRequests, management, search: serialSearch });

const loadingReducer = (state, action) => {
  switch (action.type) {
    case moduleActionTypes.INITIATE_INSTRUMENTS_LOAD: {
      return {
        repairRequests: repairRequests(
          {
            ...state.repairRequests,
            loading: true,
          },
          action
        ),
        management: management(
          {
            ...state.management,
            loading: true,
          },
          action
        ),
      };
    }
    case moduleActionTypes.INSTRUMENTS_MODULE_LOADED: {
      return {
        repairRequests: repairRequests(
          {
            ...state.repairRequests,
            loading: false,
          },
          action
        ),
        management: management(
          {
            ...state.management,
            loading: false,
          },
          action
        ),
      };
    }
    default:
      return state;
  }
};

const instrumentsReducer = (state, action) => {
  return loadingReducer(combinedReducer(state, action), action);
};

export default instrumentsReducer;
