/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid } from '@material-ui/core';
import { formatShortDate } from '@ldx-dmp/Common/utilities';
import MUIDataTable from 'mui-datatables';

import {
  EquipmentDto,
  OperatorDto,
  TestSessionMaterialDto,
} from 'modules/InstrumentsQualityControl/models';
import OperatorContext from '../../operatorsContext';
import MaterialContext from '../../materialsContext';
import EquipmentContext from '../../equipmentContext';
import { TestSessionConfigurationOptions } from './TestSessionConfigurationOptions';

const TestSessionTestConfiguration = ({
  children,
  show,
  readOnly,
  additionalConfig = [],
}: {
  children: React.ReactNode;
  show?: boolean;
  readOnly: boolean;
  additionalConfig?: ((props: { readOnly: boolean }) => React.ReactElement)[];
}) => {
  const finalConfiguation = [
    ...(additionalConfig || []),
    () => (
      <OperatorContext.Consumer>
        {options => {
          return (
            <TestSessionConfigurationOptions readOnly={readOnly} {...options}>
              {(current: OperatorDto[]) => (
                <>
                  <MUIDataTable
                    data={current}
                    columns={[{ name: 'name', label: 'Name' }]}
                    options={{
                      elevation: 0,
                      download: false,
                      search: false,
                      sort: false,
                      filter: false,
                      pagination: false,
                      print: false,
                      viewColumns: false,
                      selectableRows: 'none',
                    }}
                  />
                </>
              )}
            </TestSessionConfigurationOptions>
          );
        }}
      </OperatorContext.Consumer>
    ),

    () => (
      <MaterialContext.Consumer>
        {options => {
          return (
            <TestSessionConfigurationOptions readOnly={readOnly} {...options}>
              {(current: TestSessionMaterialDto[]) => (
                <>
                  <MUIDataTable
                    data={current?.map(x => ({
                      ...x.material,
                      ...x,
                      expiryDate: formatShortDate(x.expiryDate),
                    }))}
                    columns={[
                      { name: 'name', label: 'Name' },
                      { name: 'specification', label: 'Specification' },
                      { name: 'revision', label: 'revision' },
                      { name: 'lotNumber', label: 'Lot Number' },
                      {
                        name: 'expiryDate',
                        label: 'Expiry Date',
                      },
                    ]}
                    options={{
                      elevation: 0,
                      download: false,
                      search: false,
                      sort: false,
                      filter: false,
                      pagination: false,
                      print: false,
                      viewColumns: false,
                      selectableRows: 'none',
                    }}
                  />
                </>
              )}
            </TestSessionConfigurationOptions>
          );
        }}
      </MaterialContext.Consumer>
    ),
    () => (
      <EquipmentContext.Consumer>
        {options => {
          return (
            <TestSessionConfigurationOptions readOnly={readOnly} {...options}>
              {(current: EquipmentDto[]) => (
                <>
                  <MUIDataTable
                    data={current?.map(x => ({
                      ...x,
                      calibrationDueDate: formatShortDate(x.calibrationDueDate),
                    }))}
                    columns={[
                      { name: 'name', label: 'Name' },
                      { name: 'assetId', label: 'Asset Id' },
                      {
                        name: 'calibrationDueDate',
                        label: 'Calibration Due Date',
                      },
                    ]}
                    options={{
                      elevation: 0,
                      download: false,
                      search: false,
                      sort: false,
                      filter: false,
                      pagination: false,
                      print: false,
                      viewColumns: false,
                      selectableRows: 'none',
                    }}
                  />
                </>
              )}
            </TestSessionConfigurationOptions>
          );
        }}
      </EquipmentContext.Consumer>
    ),
  ];
  return (
    <Grid container spacing={!show ? 2 : 0}>
      <Grid item sm={12}>
        {children}
      </Grid>
      {show &&
        finalConfiguation.map((Config, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item sm={12} key={index}>
            <Config readOnly={readOnly} />
          </Grid>
        ))}
    </Grid>
  );
};

export default TestSessionTestConfiguration;
