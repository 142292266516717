export const LASER_NUM_MAX = 13;
export const LAMINATION_MIN_SPEED = 30;
export const LAMINATION_MAX_SPEED = 330;

export * from './common';
export * from './batchRecord';
export * from './preparation';
export * from './deposition';
export * from './drying';
export * from './postDrying';
export * from './inspection';
export * from './signOff';
