import React from 'react';
import { Switch } from 'react-router-dom';
import { RestrictedRoute } from 'modules/Auth/components';
import * as permissions from 'modules/Core/domain/permissions';
import { ModuleStateLoader } from 'modules/Common';
import { isBatchRecordInitialised } from 'modules/Pilot/BatchRecord/reducers/selectors';
import { initialiseBatchRecord } from 'modules/Pilot/BatchRecord/actions';
import { BatchRecordListPage, BatchRecordPage } from '../BatchRecord';
import PilotLines from '../Lines/routes';

const BatchRecordRouter = () => (
  <ModuleStateLoader isLoadedSelector={isBatchRecordInitialised} loadAction={initialiseBatchRecord}>
    <Switch>
      <RestrictedRoute
        path={PilotLines.path}
        permission={permissions.LDX_PILOTLINE_LINES_MODIFY}
        component={PilotLines}
      />
      <RestrictedRoute
        exact
        path="/pilot-line/batch-records/:batchRecordId"
        permission={permissions.LDX_PILOTLINE_BATCH_VIEW}
        component={BatchRecordPage}
      />
      <RestrictedRoute
        exact
        path="/pilot-line/batch-records/view/:view"
        permission={permissions.LDX_PILOTLINE_BATCH_VIEW}
        component={BatchRecordListPage}
      />
      <RestrictedRoute
        exact
        path="/pilot-line/batch-records"
        permission={permissions.LDX_PILOTLINE_BATCH_VIEW}
        component={BatchRecordListPage}
      />
    </Switch>
  </ModuleStateLoader>
);

export default BatchRecordRouter;
