/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounceSearchRender } from 'mui-datatables';
import { FetchDataFunction } from 'modules/Core/components/DataTable/DataTable';
import React from 'react';
import { SummaryPanelItemProps } from 'modules/Common/components/SummaryPanel/SummaryPanelItem/SummaryPanelItem';
import {
  InstrumentSummaryDto,
  TestSessionDto,
  CreateTestSessionInput,
  InstrumentDto,
  TestSessionInstrumentDto,
  SetQualityEventDetailsInput,
  SetPlannedDeviationsNumbersInput,
  AuditEntityDto,
} from './models';
import InstrumentResult from './features/instruments/components/InstrumentResult';

export type InstrumentsQualityControlContextType = {
  list: Partial<{
    fetchAll: FetchDataFunction;
    totalCount: number;
    isLoading: boolean;
    isReloading: boolean;
    data: any[];
    options: any;
    columns: any[];
  }>;
  details: Partial<{
    summary: SummaryPanelItemProps[];
  }>;
  isLoading: boolean;

  createForm: {
    formOpen: boolean;
    onFormClose: () => void;
    onFormSubmit: (data: Partial<CreateTestSessionInput>) => void;
  };

  onNewItemClick: () => void;

  current?: TestSessionDto | null;
  setCurrent: (current: TestSessionDto | null) => void;
  fetchCurrent: () => Promise<TestSessionDto>;

  cardsVisible: boolean;
  tableVisible: boolean;
  showTable: () => void;
  showCards: () => void;

  addInstrumentsOpen: boolean;
  closeAddInstruments: () => void;
  openAddInstruments: () => void;
  onAddInstrumentSubmit: (data: { selectedInstruments: InstrumentSummaryDto[] }) => void;
  addNewInstrument: (serial: string) => Promise<InstrumentDto>;

  addControlInstrumentsOpen: boolean;
  closeAddControlInstruments: () => void;
  openAddControlInstruments: () => void;
  onAddControlInstrumentSubmit: (data: { selectedInstruments: InstrumentSummaryDto[] }) => void;

  saveOrder: (
    data: Pick<TestSessionInstrumentDto, 'id' | 'indexNumber'>[]
  ) => Promise<InstrumentDto>;

  moveToMaterials: () => void;
  moveToEquipment: () => void;

  submitReview: any;
  enableReview: () => void;
  disableReview: () => void;
  reviewEnabled: boolean;
  onCorrectionsSubmit: any;

  buttonText: string;
  canAddInstruments;
  canOrderInstruments;

  onStatusClick: any;

  uploadLogFile: any;

  setQualityEvent: (data: SetQualityEventDetailsInput) => Promise<TestSessionDto>;
  setPlannedDeviation: (data: SetPlannedDeviationsNumbersInput) => Promise<TestSessionDto>;
  submitInstrumentDisposition: any;

  submitForReview: () => Promise<TestSessionDto>;
  readOnly: boolean;

  getActivity: any;
  events: AuditEntityDto[] | undefined;
};

export const columns = [
  { name: 'id', label: 'id', options: { display: 'excluded' } },
  { name: 'testSessionId', label: 'Test Session Id', options: { filter: false, sort: true } },
  {
    name: 'instrumentCount',
    label: 'No. of  Instruments in bank',
    options: { filter: false, sort: false },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
      sort: true,
      customBodyRender: value => <InstrumentResult value={value} />,
    },
  },
];

export const options = {
  filter: true,
  selectableRows: 'none',
  download: false,
  print: false,
  filterType: 'multiselect',
  elevation: 1,
  viewColumns: false,
  search: true,
  customSearchRender: debounceSearchRender(500),
};

const InstrumentsQualityControlContext = React.createContext<
  Partial<InstrumentsQualityControlContextType>
>({
  list: {
    columns,
  },
});

export default InstrumentsQualityControlContext;
