import React from 'react';
import { Route } from 'react-router-dom';
import { selectors } from 'modules/Instruments/reducers';
import { actionCreators } from 'modules/Instruments/actions';
import { ModuleStateLoader } from 'modules/Common';
import { InstrumentRepairRouter } from '../slices/Repair';
import { InstrumentManagementRouter } from '../slices/Management';

const InstrumentRouter = () => {
  return (
    <ModuleStateLoader
      isLoadedSelector={selectors.isModuleLoaded}
      loadAction={actionCreators.initiateInstrumentsLoad}
    >
      <>
        <Route path="/instruments/management" component={InstrumentManagementRouter} />
        <Route path="/instruments/repair" component={InstrumentRepairRouter} />
      </>
    </ModuleStateLoader>
  );
};

export default InstrumentRouter;
