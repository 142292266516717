import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { selectors } from 'modules/Core/reducers';
import MiniLogo from './MiniLogo';
import UserDisplay from './UserDisplay';
import styles from './Topbar.styles';

const Topbar = () => {
  const isSidebarOpen = useSelector(state => selectors.sidebarDrawerIsOpen(state));
  const classes = makeStyles(styles)({ isSidebarOpen });

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar>
          {!isSidebarOpen && <MiniLogo isSidebarOpen={isSidebarOpen} />}
          <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
            Data Management Platform
          </Typography>
          <UserDisplay />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Topbar;
