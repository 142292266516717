import { UserPermission } from 'modules/Core/types';
import {
  RecordBatchCompletionActions,
  recordBatchCompletion,
} from 'modules/Pilot/BatchRecord/actions';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { SignOffDefinition } from '../SignOffTable/SignOffTable';

export default (
  batchRecord: BatchRecord,
  userId: string,
  userPermissions: UserPermission[]
): SignOffDefinition => {
  const {
    id,
    signOff: {
      canCompleteBatch,
      batchCompletion: { requiredPermissions, signOffs },
    },
  } = batchRecord;

  const isAllowedToSign = () => {
    if (!canCompleteBatch) return false;
    if (signOffs.find(so => so.user.id === userId)) return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  return {
    signOff: signOffs,
    title: 'Batch & Review Completion',
    dialogName: 'pilot/batch-records/BATCH_COMPLETION',
    reviewText: 'Has the batch record been completed correctly?',
    allowsMultiple: true,
    action: recordBatchCompletion(id),
    api: RecordBatchCompletionActions.CALL,
    canSign: isAllowedToSign(),
  };
};
