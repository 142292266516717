import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup } from '@material-ui/core';
import { FileDocumentEditOutline, FileCancelOutline } from 'mdi-material-ui';
import { batchRecordDialogOpen } from '@ldx-dmp/Pilot/BatchRecord/actions';
import { PermissionRequired } from '@ldx-dmp/Core/components';
import { LDX_PILOTLINE_BATCH_AMEND } from '@ldx-dmp/Core/domain/permissions';
import { openDialog } from 'modules/Core/actions/dialogs';

export default () => {
  const dispatch = useDispatch();
  const handleEditClick = () => dispatch(batchRecordDialogOpen());
  const handleRejectClick = () => dispatch(openDialog('pilot/batch-records/REJECT_BATCH_RECORD'));
  return (
    <PermissionRequired permission={LDX_PILOTLINE_BATCH_AMEND}>
      <ButtonGroup variant="contained" color="primary">
        <Button onClick={handleEditClick} startIcon={<FileDocumentEditOutline />}>
          Amend Request
        </Button>
        <Button onClick={handleRejectClick} startIcon={<FileCancelOutline />}>
          Reject
        </Button>
      </ButtonGroup>
    </PermissionRequired>
  );
};
