/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { NewItemButton } from 'modules/Common';
import { FileDocumentEditOutline } from 'mdi-material-ui';

function ContentHeader({
  children,
  variant = 'h5',
  component = 'h2',
  title,
  withNewItem,
  newItemButtonText,
  newItemButtonOnClick,
  withEditItem,
  editItemButtonText,
  editItemButtonOnClick,
}: any) {
  return (
    <Grid container>
      <Grid item xs>
        <Typography variant={variant} component={component}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs>
        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          {withNewItem && (
            <NewItemButton
              title={newItemButtonText}
              text={newItemButtonText}
              onClick={newItemButtonOnClick}
            />
          )}
          {withEditItem && (
            <Button
              variant="contained"
              color="primary"
              onClick={editItemButtonOnClick}
              startIcon={<FileDocumentEditOutline />}
              title={editItemButtonText}
            >
              {editItemButtonText}
            </Button>
          )}
          {!withNewItem && !withEditItem && <>{children}</>}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ContentHeader;
