/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import {
  PerformanceTestConfigurationDto,
  TestSessionInstrumentDto,
  UpdatePerformanceToInstrumentInput,
} from 'modules/InstrumentsQualityControl/models';
import moment from 'moment';
import TextDisplayWidget from '@ldx-dmp/JsonFormDialog/TextDisplayWidget';
import ConditionalField from '../widgets/ConditionalField';
import PerformanceTestLogFileWidget from '../widgets/PerformanceTestLogFileWidget';
import { formatBool } from '../utils';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.properties.releasePreparation.properties.errorCodes.items.default = '';
  sch.properties.performanceTestOutcome.properties.plannedDeviationNumbers.items.default = '';
  sch.properties.performanceTestOutcome.properties.plannedDeviationNumbers.items.title =
    'Planned Deviation Number';

  sch.properties.plaqueCalibration.properties.calibrationValue.type = 'string';
  sch.properties.plaqueCalibration.properties.meanDifferenceValue.type = 'string';
  sch.properties.plaqueCalibration.properties.biasValue.type = 'string';

  return sch;
};

export const uiSchema = ui => {
  ui['ui:order'] = [
    '*',
    'logFile',
    'piezoMagnetCheckOutcome',
    'auditLogOutcome',
    'plaqueCalibration',
    'releasePreparation',
  ];

  ui.instrumentId = {
    'ui:widget': 'hidden',
  };
  ui.flexReworkInstrument = {
    'ui:widget': TextDisplayWidget,
    format: formatBool,
  };

  ui.piezoMagnetCheckOutcome = {
    outcome: {
      'ui:widget': 'radio',
    },
    qualityEventRequired: {
      'ui:widget': 'radio',
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      predicate: values =>
        values.piezoMagnetCheckOutcome?.qualityEventRequired?.toString() === 'true',
    },
    hasQualityEvents: {
      'ui:widget': 'hidden',
    },
  };

  ui.performanceTestOutcome = {
    hasQualityEvents: {
      'ui:widget': 'hidden',
    },
    qualityEventRequired: {
      'ui:widget': 'radio',
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      predicate: values =>
        values.performanceTestOutcome?.qualityEventRequired?.toString() === 'true',
    },
    comments: {
      'ui:field': ConditionalField,
      predicate: values =>
        values.performanceTestOutcome?.qualityEventRequired?.toString() === 'true',
    },
    plannedDeviationNumbers: {},
  };

  ui.auditLogOutcome = {
    qualityEventRequired: {
      'ui:widget': 'radio',
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      predicate: values => values.auditLogOutcome?.qualityEventRequired?.toString() === 'true',
    },
    comments: {
      'ui:field': ConditionalField,
      predicate: values => values.auditLogOutcome?.qualityEventRequired?.toString() === 'true',
    },
    plannedDeviationNumbers: {
      'ui:field': ConditionalField,
      predicate: values => values.auditLogOutcome?.qualityEventRequired?.toString() === 'true',
    },
  };

  ui.releasePreparation = {
    factoryResetDate: { 'ui:widget': 'date' },
    anyErrorsDuringInstrumentBootUpAfterFactoryReset: {
      'ui:widget': 'radio',
    },
    errorCodes: {
      'ui:helpText': 'Outside of testing',
      'ui:description': 'Outside of testing',
      'ui:field': ConditionalField,
      predicate: values =>
        values?.releasePreparation?.anyErrorsDuringInstrumentBootUpAfterFactoryReset?.toString() ===
        'true',
    },
    comments: {
      'ui:field': ConditionalField,
      predicate: values =>
        values?.releasePreparation?.anyErrorsDuringInstrumentBootUpAfterFactoryReset?.toString() ===
        'true',
    },
    decontaminationDate: { 'ui:widget': 'date' },
    cleanDate: { 'ui:widget': 'date' },
    carriedOutBy: {
      'ui:widget': 'hidden',
    },
    verifiedBy: {
      'ui:widget': 'hidden',
    },
  };

  ui.logFile = {
    'ui:widget': PerformanceTestLogFileWidget,
    'ui:options': {
      readOnly: false,
    },
  };

  return ui;
};

export const mapToEdit = () => (
  current: TestSessionInstrumentDto,
  config?: PerformanceTestConfigurationDto
): UpdatePerformanceToInstrumentInput => {
  const performanceTest = current?.performanceTest || {};
  return {
    tests: config?.performanceTestTypeAndSamples?.map(x => {
      return {
        status: 'AwaitingAnalysis',
        errorCodes: [],
        ...x,
        ...(performanceTest?.tests?.find(t => t.id === x.id) || {}),
      };
    }),
    review: performanceTest?.review,
    piezoMagnetCheckOutcome: performanceTest?.piezoMagnetCheckOutcome,
    performanceTestOutcome: {
      ...performanceTest?.performanceTestOutcome,
      plannedDeviationNumbers:
        performanceTest?.performanceTestOutcome?.plannedDeviationNumbers || [],
    },
    auditLogOutcome: {
      ...performanceTest?.auditLogOutcome,
      plannedDeviationNumbers: performanceTest?.auditLogOutcome?.plannedDeviationNumbers || [],
    },
    releasePreparation: {
      ...performanceTest?.releasePreparation,
      errorCodes: performanceTest?.releasePreparation?.errorCodes || [],
    },
    logFile: {
      ...performanceTest?.logFile,
      instrumentId: current?.id,
    },
    instrumentId: current?.id,
    plaqueCalibration: performanceTest.plaqueCalibration,
  } as UpdatePerformanceToInstrumentInput;
};

export const fields = noop;

export const widgets = noop;

export const validation = () =>
  yup
    .object({
      piezoMagnetCheckOutcome: yup
        .object({
          qualityEventRequired: yup.mixed().required(),
          qualityEventNumber: yup.mixed().when('qualityEventRequired', {
            is: value => value?.toString() === 'true',
            then: yup.mixed().required(),
            otherwise: yup.mixed().optional(),
          }),
        })
        .defined(),
      tests: yup.array(
        yup
          .object({
            anyErrorsDuringInstrumentBootUp: yup.mixed().required(),
            comments: yup.mixed().when('anyErrorsDuringInstrumentBootUp', {
              is: value => value?.toString() === 'true',
              then: yup.mixed().required(),
              otherwise: yup.mixed().optional(),
            }),
          })
          .defined()
      ),
      performanceTestOutcome: yup
        .object({
          qualityEventRequired: yup.mixed().required(),
          qualityEventNumber: yup.mixed().when('qualityEventRequired', {
            is: value => value?.toString() === 'true',
            then: yup.mixed().required(),
            otherwise: yup.mixed().optional(),
          }),
          comments: yup.mixed().when('qualityEventRequired', {
            is: value => value?.toString() === 'true',
            then: yup.mixed().required(),
            otherwise: yup.mixed().optional(),
          }),
        })
        .defined(),
      auditLogOutcome: yup
        .object({
          qualityEventRequired: yup.mixed().required(),
          qualityEventNumber: yup.mixed().when('qualityEventRequired', {
            is: value => value?.toString() === 'true',
            then: yup.mixed().required(),
            otherwise: yup.mixed().optional(),
          }),
          comments: yup.mixed().when('qualityEventRequired', {
            is: value => value?.toString() === 'true',
            then: yup.mixed().required(),
            otherwise: yup.mixed().optional(),
          }),
        })
        .defined(),
      releasePreparation: yup
        .object({
          factoryResetDate: yup.mixed().required(),
          anyErrorsDuringInstrumentBootUpAfterFactoryReset: yup.mixed().required(),
          comments: yup.mixed().when('anyErrorsDuringInstrumentBootUpAfterFactoryReset', {
            is: value => value?.toString() === 'true',
            then: yup.mixed().required(),
            otherwise: yup.mixed().optional(),
          }),
          cleanDate: yup.mixed().required(),
          decontaminationDate: yup.mixed().required(),
        })
        .defined(),
    })
    .defined();

export const empty = initial => ({
  ...initial,
  releasePreparation: {
    factoryResetDate: moment(),
    decontaminationDate: moment(),
    cleanDate: moment(),
  },
});
