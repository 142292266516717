import { push } from 'connected-react-router';
import {
  actionTypes as moduleActionTypes,
  actionCreators as moduleActionCreators,
} from 'modules/Instruments/actions';
import {
  InstrumentManagementActionTypes,
  FetchInstrumentActionTypes,
  fetchInstrument,
} from 'modules/Instruments/slices/Management/actions';

import {
  actionTypes as repairActionTypes,
  fetchTechnicianUsers,
  fetchRepairRequestsForInstrument,
  actionCreators as repairActionCreators,
  fetchRepairRequest,
  fetchPostTests,
} from 'modules/Instruments/slices/Repair/actions';
import { selectors as moduleSelectors } from 'modules/Instruments/reducers';
import { SnackbarUtils } from 'modules/Common';
import { setCurrentRepairRequest } from '../slices/Repair/actions/actionCreators';

export default [
  {
    type: moduleActionTypes.INITIATE_INSTRUMENTS_LOAD,
    handler: (_, dispatch) => () => {
      dispatch(fetchTechnicianUsers());
    },
  },
  {
    type: repairActionTypes.TECHNICIAN_USERS_SUCCESS,
    handler: (_, dispatch, store) => () => {
      if (moduleSelectors.isModuleLoaded(store()))
        dispatch(moduleActionCreators.instrumentsModuleLoaded());
    },
  },
  {
    type: InstrumentManagementActionTypes.SET_CURRENT_INSTRUMENT,
    handler: (action, dispatch) => () => {
      dispatch(fetchInstrument(action.instrumentId));
    },
  },
  {
    type: FetchInstrumentActionTypes.SUCCESS,
    handler: (action, dispatch) => () => {
      dispatch(fetchRepairRequestsForInstrument(action.payload.id));
    },
  },
  {
    type: repairActionTypes.SET_CURRENT_REPAIR,
    handler: (action, dispatch) => () => {
      dispatch(fetchRepairRequest(action.repairRequest));
      dispatch(fetchPostTests());
    },
  },
  {
    type: repairActionTypes.FETCH_REPAIR_REQUEST_SUCCESS,
    handler: (action, dispatch) => () => {
      dispatch(fetchRepairRequestsForInstrument(action.payload.instrument.id));
      dispatch(fetchPostTests());
    },
  },
  {
    type: repairActionTypes.CREATE_REPAIR_SUCCESS,
    handler: (action, dispatch) => () => {
      SnackbarUtils.success('Repair request created');
      dispatch(repairActionCreators.closeRequestForm());
      dispatch(setCurrentRepairRequest(action.payload.id));
      dispatch(push(`/instruments/repair/${action.payload.id}`));
    },
  },
  {
    type: repairActionTypes.UPDATE_REPAIR_SUCCESS,
    handler: (action, dispatch) => () => {
      SnackbarUtils.success(`Repair request ${action.payload.irn} updated`);
      dispatch(repairActionCreators.closeRequestForm());
    },
  },
  {
    type: repairActionTypes.RESOLVE_REPAIR_SUCCESS,
    handler: action => () => {
      SnackbarUtils.success(`Repair request ${action.payload.irn} resolved`);
    },
  },
  {
    type: repairActionTypes.ASSIGN_REPAIR_SUCCESS,
    handler: action => () => {
      SnackbarUtils.success(
        `Repair request ${action.payload.irn} assigned to ${action.payload.assignedTo.name}`
      );
    },
  },
  {
    type: repairActionTypes.RECORD_TEST_RESULT_SUCCESS,
    handler: action => () => {
      SnackbarUtils.success(`Test results recorded against ${action.payload.irn}`);
    },
  },
];
