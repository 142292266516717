/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import MaterialTable from '@ldx-dmp/MaterialTable';
import TimeAgo from 'react-timeago';

import { Grid } from '@material-ui/core';
import { formatShortDateTime } from 'modules/Common/utilities';
import styles from './InstrumentRmas.styles';

const InstrumentRmas = ({ isActive, instrument }: Record<string, any>) => {
  const classes = styles();

  if (!isActive) return <></>;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          className={classes.stackedList}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <MaterialTable
            data={instrument.deliveryRecords?.filter(x => x.rmaNumber) || []}
            title=""
            columns={[
              {
                field: 'timestamp',
                title: 'Timestamp',
                // eslint-disable-next-line react/prop-types
                render: ({ timestamp }) => (
                  <TimeAgo date={timestamp} title={formatShortDateTime(timestamp)} />
                ),
              },
              { field: 'rmaNumber', title: 'RMA Number' },
            ]}
            options={{
              columnsButton: false,
              search: false,
              emptyRowsWhenPaging: false,
              paging: false,
              toolbar: false,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InstrumentRmas;
