import { AddConfigurationOptionToTestSessionProps } from './components/AddConfigurationOptionToTestSession';

export type TestConfigurationOptionsContextType<T> = {
  all: T[];
  current: T[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form?: any | undefined;
  formOpen: boolean;
  onOpen: () => void;
  onSubmit: (data) => Promise<unknown>;
  onClose: () => void;
  title: string;
  buttonText: string;
} & AddConfigurationOptionToTestSessionProps<T>['select'];

export const defaultOptions = {
  all: [],
  current: [],
  formOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onSubmit: () => Promise.resolve(),
  title: '',
  buttonText: '',
  select: () => {},
};
