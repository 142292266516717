import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './containers/Home';

const path = '/release-review/qc-lot-verification';

const routes = () => {
  return (
    <>
      <Switch>
        <Route path={path}>
          <Home />
        </Route>
      </Switch>
    </>
  );
};

routes.path = path;

export default routes;
