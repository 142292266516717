import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { resolveRepairRequest as resolveRequestAction } from 'modules/Instruments/slices/Repair/actions';
import styles from './ResolveRequestForm.styles';

const ResolveRequestForm = ({ show, handleClose, repairRequest, resolveRequest }) => {
  const classes = makeStyles(styles)();
  const [formState, setFormState] = useState({
    resolutionOutcome: '',
    comments: '',
  });

  const handleAction = event => {
    setFormState({
      resolutionOutcome: event.target.value,
    });
  };

  const handleChange = event => {
    const { value, id } = event.target;
    const field = !id ? event.target.name : id;

    setFormState({
      ...formState,
      [field]: value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    resolveRequest(repairRequest, formState);

    handleClose();
  };

  return (
    <>
      <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Resolve {repairRequest.irn}</DialogTitle>
        <DialogContent>
          <form className={classes.root} onSubmit={handleSubmit}>
            <FormControl className={classes.select}>
              <InputLabel id="resolutionOutcome-label">Resolution</InputLabel>
              <Select
                id="resolutionOutcome"
                labelId="resolutionOutcome-label"
                value={formState.resolutionOutcome}
                onChange={handleAction}
              >
                <MenuItem value="SignedOff" selected>
                  Sign Off
                </MenuItem>
                <MenuItem value="Rejected">Reject</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="comments"
              label="comments"
              rows="5"
              multiline
              fullWidth
              onChange={handleChange}
              value={formState.comments}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit} color="primary" variant="contained">
            Resolve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const actionCreators = {
  resolveRequest: resolveRequestAction,
};

ResolveRequestForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  repairRequest: PropTypes.object.isRequired,
  resolveRequest: PropTypes.func.isRequired,
};

export default connect(null, actionCreators)(ResolveRequestForm);
