import React, { useMemo } from 'react';
import { BatchRecord, Sections } from 'modules/Pilot/BatchRecord/domain';
import { useSelector } from 'react-redux';
import { getItemsBeingEdited } from 'modules/Core/reducers';
import {
  amendSubstrateOgpInspection,
  AmendSubstrateOgpInspectionActions,
  recordSubstrateOgpInspection,
  RecordSubstrateOgpInspectionActions,
} from 'modules/Pilot/BatchRecord/actions';
import { getUserPermissions } from 'modules/Core/reducers/selectors';
import SubAssemblyRejects from './SubAssemblyRejects';
import LabelPreparation from './LabelPreparation';
import lidPrintDefinition from './lidPrintDefinition';
import basePrintDefinition from './basePrintDefinition';
import adhesiveDefinition from './adhesiveDefinition';
import laserRunDefinition from './laserUsageDefinition';
import assemblyDefinition from './assemblyDefinition';
import laminationDefinition from './laminationDefinition';
import RecordTable from '../RecordTable/RecordTable';
import OgpInspection from '../OgpInspection';
import CommentsTable from '../CommentsTable/CommentsTable';

type PreparationPanelProps = {
  batchRecord: BatchRecord;
  readOnly?: boolean;
};

const PreparationPanel = ({ batchRecord, readOnly = false }: PreparationPanelProps) => {
  const itemsBeingEdited = useSelector(getItemsBeingEdited);
  const userPermissions = useSelector(getUserPermissions);
  const lidPrint = useMemo(
    () => lidPrintDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );
  const basePrint = useMemo(
    () => basePrintDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );
  const adhesive = useMemo(
    () => adhesiveDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );
  const laserRuns = useMemo(
    () => laserRunDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );
  const assembly = useMemo(
    () => assemblyDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );
  const lamination = useMemo(
    () => laminationDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );

  const hideOgp = batchRecord?.pilotLine?.hiddenSections?.includes(
    'Preparation > OGP Post Lamination Inspection'
  );

  return (
    <>
      <RecordTable definition={lidPrint} status={batchRecord.preparation.lidPrints.status} />
      <RecordTable definition={basePrint} status={batchRecord.preparation.basePrints.status} />
      <RecordTable definition={adhesive} status={batchRecord.preparation.adhesives.status} />
      <RecordTable
        definition={laserRuns}
        xs={6}
        status={batchRecord.preparation.laserUsage.status}
      />
      <RecordTable definition={assembly} xs={6} status={batchRecord.preparation.assembly.status} />
      <RecordTable
        definition={lamination}
        xs={6}
        status={batchRecord.preparation.laminations.status}
      />
      {!hideOgp && (
        <OgpInspection
          inspection={batchRecord.preparation?.ogpInspection}
          title="OGP Post Lamination Inspection"
          status={batchRecord.preparation.ogpInspection.status}
          recordAction={(recordId, attachments) =>
            recordSubstrateOgpInspection(recordId, attachments)
          }
          amendAction={(recordId, attachments, comment) =>
            amendSubstrateOgpInspection(recordId, attachments, comment)
          }
          recordApi={RecordSubstrateOgpInspectionActions.CALL}
          amendApi={AmendSubstrateOgpInspectionActions.CALL}
          dialogName="pilot/batch-records/OGP_POST_LAMINATION"
          recordId={batchRecord.id}
        />
      )}
      <SubAssemblyRejects batchRecord={batchRecord} />
      <LabelPreparation batchRecord={batchRecord} />
      <CommentsTable
        recordId={batchRecord.id}
        section={Sections.Preparation}
        comments={batchRecord.comments}
        readOnly={readOnly}
      />
    </>
  );
};

export default PreparationPanel;
