import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Fade } from '@material-ui/core';
import { push } from 'connected-react-router';

import { ContentHeader, FormDialog, TabManager, NewItemButton } from 'modules/Common/components';

import { clearBatchRecords } from 'modules/Pilot/BatchRecord/actions/fetchBatchRecords';
import { batchRecordDialogOpen } from 'modules/Pilot/BatchRecord/actions';
import { batchRecordFormDefinition } from 'modules/Pilot/BatchRecord/Form';

import { LDX_PILOTLINE_BATCH_CREATE } from 'modules/Core/domain/permissions';
import { PermissionRequired } from 'modules/Core/components';
import BatchRecordsTable from '../BatchRecordsTable/BatchRecordsTable';

const BatchRecordListPage = () => {
  const dispatch = useDispatch();
  const { view } = useParams();

  const handleClickOpen = () => dispatch(batchRecordDialogOpen());
  const formDefinition = useMemo(() => batchRecordFormDefinition(), []);

  return (
    <Fade in>
      <div>
        <ContentHeader title="Prototype Strip Batch Records">
          <PermissionRequired permission={LDX_PILOTLINE_BATCH_CREATE}>
            <NewItemButton text="New Batch Record" onClick={handleClickOpen} />
          </PermissionRequired>
        </ContentHeader>
        <br />
        <TabManager
          initialTabIndex={view?.toLowerCase() === 'archived' ? 1 : 0}
          onTabChanged={index => {
            const newView = index === 1 ? 'archived' : 'live';
            dispatch(push(`/pilot-line/batch-records/view/${newView}`));
            dispatch(clearBatchRecords());
          }}
        >
          {[
            {
              name: 'live',
              tabLabel: 'Live',
              hideHeading: true,
              component: <BatchRecordsTable key="live" view="live" />,
            },
            {
              name: 'archived',
              tabLabel: 'Archived',
              hideHeading: true,
              component: <BatchRecordsTable key="archived" view="archived" />,
            },
          ]}
        </TabManager>

        <FormDialog definition={formDefinition} />
      </div>
    </Fade>
  );
};

export default BatchRecordListPage;
