/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { FieldProps, utils } from '@rjsf/core';
import { useBoolean } from 'react-use';
import { Formik, Form, useField } from 'formik';
import QCContext from 'modules/InstrumentsQualityControl/context';

const { getUiOptions } = utils;

export default ({ schema, formData, registry, idSchema, uiSchema }: FieldProps) => {
  const [showUpload, toggle] = useBoolean(false);
  const [, , { setValue }] = useField(idSchema.$id);

  const { TitleField: titleField } = registry.fields;

  const { uploadLogFile } = React.useContext(QCContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const TitleField = titleField as any;

  const options = getUiOptions(uiSchema);

  const canUpload = !options?.readOnly && formData?.status !== 'Uploaded';

  return (
    <>
      <Card>
        <CardContent>
          <TitleField id={`${idSchema.$id}-title`} title={schema.title} />
          {!!formData?.filename && <Typography>{formData?.filename}</Typography>}
          {!!formData?.filename && <Typography>{formData?.status || 'Uploaded'}</Typography>}
        </CardContent>
        <CardActions>
          {canUpload && (
            <Button type="button" variant="contained" color="primary" onClick={() => toggle()}>
              Upload File
            </Button>
          )}
        </CardActions>
      </Card>
      <Dialog open={showUpload} onClose={() => toggle()} fullWidth>
        <Formik
          initialValues={{ logFile: { name: '' }, instrumentId: formData?.instrumentId }}
          onSubmit={data => {
            return new Promise((resolve, reject) => {
              if (!uploadLogFile) {
                reject(new Error('no uploadLogFile handler'));
                return;
              }

              uploadLogFile(data).then(response => {
                resolve(response);
                toggle();
                setValue({
                  filename: (data?.logFile as any)?.name,
                  status: 'Uploaded',
                });
              }, reject);
            });
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <>
                <DialogTitle>Upload Log File</DialogTitle>
                <DialogContent>
                  <label htmlFor="logFile">
                    {values.logFile && (
                      <div>
                        <Typography variant="body1">{values.logFile?.name}</Typography>
                      </div>
                    )}
                    <input
                      type="file"
                      name="logFile"
                      id="logFile"
                      accept=".log"
                      style={{ display: 'none' }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (!event.target.files) return;
                        setFieldValue('logFile', event.target.files[0]);
                      }}
                    />
                    <Button color="primary" variant="contained" component="span">
                      Select File
                    </Button>
                  </label>
                </DialogContent>
                <DialogActions>
                  <Button type="submit" color="primary" variant="contained">
                    Upload
                  </Button>
                </DialogActions>
              </>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
