import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { getUserDisplayName } from 'modules/Core/reducers/selectors';
import { SummaryPanelItem } from 'modules/Common';
import { useFormSubmitting } from '../../hooks';

type SignOffDialogProps = {
  title: string | React.ReactNode;
  review?: { title: string; value: string | number | symbol }[];
  reviewText?: string;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
  open: boolean;
  onSign: () => Promise<void>;
  onClose: () => void;
  buttonText?: string;
  children?: React.ReactNode;
};

const SignOffDialog = ({
  title,
  review,
  reviewText,
  maxWidth,
  onSign,
  onClose,
  open,
  buttonText = 'Sign',
  children,
}: SignOffDialogProps) => {
  const userDisplayName = useSelector(getUserDisplayName);
  const { isSubmitting, handleSubmit } = useFormSubmitting(onSign);

  const def = (
    <>
      {review &&
        review.map(item => (
          <SummaryPanelItem
            key={item.title}
            primary={item.title}
            secondary={item.value}
            secondaryVariant="subtitle1"
          />
        ))}
      {reviewText && <Typography variant="subtitle1">{reviewText}</Typography>}
    </>
  );

  return (
    <Dialog open={open} maxWidth={maxWidth} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid container>{children || def}</Grid>
        <Divider />
        <Grid container>
          <SummaryPanelItem
            primary="Sign As"
            secondary={userDisplayName}
            secondaryVariant="subtitle1"
          />
          <SummaryPanelItem
            primary="Date"
            secondary={moment().format('D MMM YYYY, HH:mm')}
            secondaryVariant="subtitle1"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" disabled={isSubmitting}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignOffDialog;
