import { useSelector } from 'react-redux';
import { createLoadingSelector } from 'modules/Core/reducers';
import {
  FetchRepairRequestActionTypes,
  CreateRepairActionTypes,
  UpdateRepairActionTypes,
} from 'modules/Instruments/slices/Repair/actions/actionTypes';

export default (): boolean => {
  const actions = [
    FetchRepairRequestActionTypes.FETCH_REPAIR_REQUEST,
    CreateRepairActionTypes.CREATE_REPAIR,
    UpdateRepairActionTypes.UPDATE_REPAIR,
  ];

  return useSelector(state => createLoadingSelector(actions)(state));
};
