/* eslint-disable react/prop-types */
import React from 'react';
import { FormikValues } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Typography } from '@material-ui/core';
import {
  amendFirstLabelImage,
  AmendFirstLabelImageActions,
  recordFirstLabelPrepared,
  RecordFirstLabelPrepared,
} from '@ldx-dmp/Pilot/BatchRecord/actions';
import { FormDefinition } from '@ldx-dmp/Common/components/FormDialog/FormDialog';
import { BatchRecord } from '@ldx-dmp/Pilot/BatchRecord/domain';
import { FormikTextField } from '@ldx-dmp/Common';

interface Values extends FormikValues {
  image?: File;
  comment?: string;
}

export default (batchRecord: BatchRecord, isEdit = false): FormDefinition<Values> => {
  const recordId = batchRecord.id;

  const validation = () => {
    if (!isEdit)
      return {
        comment: yup.string().optional(),
      };

    return {
      comment: yup
        .string()
        .defined()
        .required()
        .min(5),
    };
  };

  return {
    title: 'First Off Label',
    dialogName: 'pilot/batch-records/FIRST_OFF_LABEL',
    apiAction: isEdit ? AmendFirstLabelImageActions.CALL : RecordFirstLabelPrepared.CALL,
    onSubmit: (values: Values, _, dispatch) => {
      if (values.image !== undefined) {
        if (isEdit) dispatch(amendFirstLabelImage(recordId, values.image, values.comment ?? ''));
        else dispatch(recordFirstLabelPrepared(recordId, values.image));
      }
    },
    initialValues: {
      image: undefined,
      comment: '',
    },
    // eslint-disable-next-line react/prop-types
    form: ({ values, setFieldValue }) => (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" component="p">
            Please select the photo of the prepared label from your device
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <label htmlFor="image">
            <input
              type="file"
              name="image"
              id="image"
              style={{ display: 'none' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (!event.target.files) return;
                setFieldValue('image', event.target.files[0]);
              }}
              accept="image/*"
            />
            <Button color="primary" variant="contained" component="span">
              Select Image
            </Button>
          </label>
        </Grid>
        <Grid item xs>
          {values.image && (
            <div>
              <img
                src={URL.createObjectURL(values.image)}
                alt="Preview"
                style={{ maxWidth: '100%' }}
              />
              <Typography variant="body1">{values.image?.name}</Typography>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          {isEdit && <FormikTextField name="comment" label="Reason for change" multiline />}
        </Grid>
      </Grid>
    ),
    validationSchema: (): yup.ObjectSchema<Values> =>
      yup
        .object()
        .shape(validation())
        .defined(),
  };
};
