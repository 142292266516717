import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { GridList, GridListItem } from 'modules/Common';
import { InstrumentDto } from 'modules/Instruments/models';
import {
  FirmwareVersionsTable,
  SerialNumbers,
} from 'modules/Instruments/slices/Management/components/InstrumentSubsystems/InstrumentSubsystems';
import { RepairRequest } from 'modules/Instruments/domain/repairRequest';
import RelatedRequestsTable from './RelatedRequestsTable';
import useStyles from './InstrumentDetailsPanel.styles';

type InstrumentDetailsPanelProps = {
  instrument: InstrumentDto;
  location: string;
  isInstrumentWipedDown?: string | null;
  relatedRequests: RepairRequest[];
};

const InstrumentDetailsPanel = ({
  instrument,
  location,
  isInstrumentWipedDown,
  relatedRequests,
}: InstrumentDetailsPanelProps) => {
  const classes = useStyles();

  const listItems = [
    { key: 'serial', title: 'Instrument Serial / Asset Number', value: instrument.serial },
    { key: 'type', title: 'Instrument Type', value: instrument.version?.platformVersion },
    { key: 'revision', title: 'Revision', value: instrument.version?.revision },
    { key: 'hardwareNumber', title: 'Hardware Version', value: instrument.version?.hardwareNumber },
  ];

  return (
    <>
      <Grid item xs={12} className={classes.root}>
        <GridList>
          {listItems.map(item => (
            <GridListItem
              key={item.key}
              title={item.title}
              titleWidth={3}
              valueWidth={3}
              value={item.value}
            />
          ))}
        </GridList>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <GridList>
          <GridListItem
            title="Where is Instrument Used?"
            titleWidth={3}
            valueWidth={3}
            value={location}
          />
          <GridListItem
            title="Instrument exterior wiped down?"
            titleWidth={3}
            valueWidth={3}
            value={isInstrumentWipedDown}
          />
        </GridList>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5">
            Versions
            <Divider />
          </Typography>
          <FirmwareVersionsTable items={instrument.firmwareVersions} />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5">
            Serial Numbers
            <Divider />
          </Typography>
          <SerialNumbers items={instrument.serialNumbers} />
        </Grid>
      </Grid>
      <RelatedRequestsTable relatedRequests={relatedRequests} />
    </>
  );
};

export default InstrumentDetailsPanel;
