import { apiGet } from 'modules/Core/actions';

export enum GetDepositionAssetsActions {
  CALL = 'pilot/batch-records/GET_DEPOSITION_ASSETS',
  REQUEST = 'pilot/batch-records/GET_DEPOSITION_ASSETS_REQUEST',
  SUCCESS = 'pilot/batch-records/GET_DEPOSITION_ASSETS_SUCCESS',
  FAILURE = 'pilot/batch-records/GET_DEPOSITION_ASSETS_FAILURE',
}

export const getDespositionAssets = (line?: string) =>
  apiGet(`/pilot/assets/deposition?line=${line || ''}`, GetDepositionAssetsActions.CALL);
