import React from 'react';
import set from 'lodash/set';
import camelCase from 'lodash/camelCase';
import { Grow } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormikErrors, useFormikContext } from 'formik';

export type ApiErrorType = {
  message: string[];
  validationErrors?: {
    message: string;
    members: string[];
  }[];
};

export default function ApiErrors({ apiError }: { apiError?: ApiErrorType }) {
  const { setErrors, setFieldTouched } = useFormikContext();
  const [formErrors, setFormErrors] = React.useState<FormikErrors<unknown>>();
  const [touched, setToTouch] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (apiError && apiError.validationErrors) {
      const errors = {};
      const errorKeys: string[] = [];
      apiError.validationErrors.forEach(error =>
        error.members.forEach(field => {
          const finalName = field
            .split('.')
            .map(camelCase)
            .join('.');

          set(errors, finalName, error.message);
          errorKeys.push(finalName);
        })
      );
      setFormErrors(errors);
      setToTouch(errorKeys);
    }
  }, [setFormErrors, apiError]);

  React.useEffect(() => {
    if (formErrors) {
      setErrors(formErrors);
      touched.forEach(s => setFieldTouched(s));
    }
  }, [formErrors, setErrors, setFieldTouched, touched]);

  if (apiError && !apiError.validationErrors)
    return (
      <Grow in={apiError && !apiError.validationErrors}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {apiError.message}
        </Alert>
      </Grow>
    );

  return null;
}
