/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PagedResultDto } from '@ldx-dmp/Core/models';

import { MaterialDto } from 'modules/InstrumentsQualityControl/models';

export type MaterialsState = {
  items: PagedResultDto<MaterialDto>;
  current?: MaterialDto | null;
  formOpen: boolean;
  loading: 'NONE' | 'IDLE' | 'LOADING' | 'RELOADING';
  summaryType: 'CARD' | 'TABLE';
  addInstrumentsOpen: boolean;
  addControlInstrumentsOpen: boolean;
};

export const initialState: MaterialsState = {
  loading: 'NONE',
  formOpen: false,
  items: new PagedResultDto<MaterialDto>(),
  summaryType: 'TABLE',
  addInstrumentsOpen: false,
  addControlInstrumentsOpen: false,
};

export const slice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<PagedResultDto<MaterialDto>>) => {
      state.items = action.payload;
    },
    openForm: state => {
      state.formOpen = true;
    },
    closeForm: state => {
      state.formOpen = false;
    },
    setCurrent: (state, action: PayloadAction<MaterialDto | null>) => {
      state.current = action.payload;
    },
    startLoading: state => {
      state.loading = state.loading === 'NONE' ? 'LOADING' : 'RELOADING';
    },
    stopLoading: state => {
      state.loading = state.loading === 'NONE' ? 'NONE' : 'IDLE';
    },
  },
});

export const {
  setData,
  openForm,
  closeForm,
  startLoading,
  stopLoading,
  setCurrent,
} = slice.actions;

export const selectFormOpen = (state: MaterialsState) => state.formOpen;
export const selectItems = (state: MaterialsState) => state.items;
export const selectLoading = (state: MaterialsState) => state.loading;
export const selectCurrent = (state: MaterialsState) => state.current;

export default slice.reducer;
