import React from 'react';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { getConfig } from 'modules/Core/reducers/selectors';

import QCContext from 'modules/InstrumentsQualityControl/context';
import useQCConext from 'modules/InstrumentsQualityControl/useQCContext';

import { Details, List, Form } from '../components';

const TestSessionPage = () => {
  const config = useSelector(getConfig);

  const dispatch = useDispatch();
  const navigate = React.useCallback(url => dispatch(push(url)), [dispatch]);

  const QCContextValues = useQCConext(config, navigate);

  return (
    <QCContext.Provider value={QCContextValues}>
      <Switch>
        <Route exact path="/instruments-quality-control">
          <List />
        </Route>
        <Route exact path="/instruments-quality-control/:id">
          <Details />
        </Route>
      </Switch>
      <Form />
    </QCContext.Provider>
  );
};

export default TestSessionPage;
