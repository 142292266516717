import moment from 'moment';
import InstrumentDto from '../models/InstrumentDto';

export type Instrument = {
  serial: string;
  buildYear: number;
  buildMonth: number;
  buildWeek: number;
  type: InstrumentType;
  id: string;
  creationTime: string;
  currentMcuFirmwareVersion: TrackedComponentVersion;
  currentCpldFirmwareVersion: TrackedComponentVersion;
  currentCameraSoftwareVersion?: TrackedComponentVersion | null;
  previousMcuFirmwareVersions: TrackedComponentVersion[];
  previousCpldFirmwareVersions: TrackedComponentVersion[];
  previousCameraSoftwareVersions?: TrackedComponentVersion[] | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  version: any;
} & InstrumentDto;

export type InstrumentSerial = {
  full: string;
  buildNumber: number;
  buildYear: number;
  buildMonth: number;
  buildWeek: number;
  serial: number;
};

export type InstrumentType = {
  definitionId: string;
  platform: string;
  name: string;
  buildNumber: number;
};

export type InstrumentSubsystem = {
  id: string;
  platform: string;
  name: string;
};

export type InstrumentFamily = {
  definitionId: string;
  name: string;
};

export type BuildDate = {
  buildMonth: number;
  buildYear: number;
};

export type TrackedComponentVersion = {
  versionNumber?: string;
  from: string;
  to?: string | null;
  version?: string;
  timestamp?: string;
};

export const getInstrumentSerial = (serialNumber: string): InstrumentSerial | null => {
  const regEx = /(\d{5})-(\d{2})-(\d{2})-(\d{5,})/;
  const match = regEx.exec(serialNumber);
  if (match === null) return null;

  const fullYear = parseInt(`20${match[2]}`, 10);
  const weekNumber = parseInt(match[3], 10) - 1;
  const date = moment()
    .year(fullYear)
    .month(1)
    .day(1)
    .add(weekNumber * 7, 'days');

  return {
    full: serialNumber,
    buildNumber: parseInt(match[1], 10),
    buildYear: fullYear,
    buildWeek: weekNumber,
    buildMonth: date.month(),
    serial: parseInt(match[4], 10),
  };
};
