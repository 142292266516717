import React from 'react';
import { useSelector } from 'react-redux';
import { getAppConfig } from '@ldx-dmp/Core/reducers/selectors';
import { reinit } from './i18next';

export default function useLocalization() {
  const config = useSelector(getAppConfig);

  const { localization } = config || {};

  React.useEffect(() => {
    if (!localization) {
      return;
    }
    const { values, currentCulture } = localization;

    reinit({
      resources: {
        [currentCulture.cultureName]: values,
      },
      lng: currentCulture.cultureName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localization?.currentCulture]);
}
