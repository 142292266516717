import { createAction } from 'redux-api-middleware';
import * as actionTypes from './actionTypes';

const updateRepairRequest = (requestId, payload) => {
  let isWipedDown = null;
  if (payload.isWipedDown === 'yes') isWipedDown = true;
  else if (payload.isWipedDown === 'no') isWipedDown = false;

  return createAction({
    endpoint: `/instruments/repairs/${requestId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.UPDATE_REPAIR_REQUEST,
      actionTypes.UPDATE_REPAIR_SUCCESS,
      actionTypes.UPDATE_REPAIR_FAILURE,
    ],
    body: JSON.stringify({
      instrumentUser: payload.nameOfMeterUser,
      instrumentLocation: payload.meterLocation,
      requestDescription: payload.repairDetails,
      repairDescription: payload.workCarriedOut,
      isInstrumentWipedDown: isWipedDown,
      requestedAt: payload.requestedAt,
      affectedSubsystems: payload.affectedSubsystems,
    }),
  });
};

export default updateRepairRequest;
