/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import AddTestAndSampleTypeInput from 'modules/InstrumentsQualityControl/models/AddTestAndSampleTypeInput';
import IActionResult from 'modules/InstrumentsQualityControl/models/IActionResult';

function noop<T>(id: T) {
  return id;
}

export const schema = noop;

export const uiSchema = ui => {
  ui.testType = {
    'ui:grid:xs': 6,
  };
  ui.sampleType = {
    'ui:grid:xs': 6,
  };
  return ui;
};

export const mapToEdit = () => (current: IActionResult): AddTestAndSampleTypeInput => ({
  ...current,
});

export const fields = noop;

export const widgets = noop;

export const validation = () =>
  yup.object({
    testType: yup.string().required(),
    sampleType: yup.string().required(),
  });

export const empty = noop;
