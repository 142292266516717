export default theme => ({
  root: {
    position: 'relative',
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
    padding: '.3rem 0',
    margin: '0',

    '&:first-child': {
      paddingTop: 0,
      marginTop: 0,

      '&:before': {
        backgroundColor: '#fff',
      },
    },
    '&:last-child': {
      paddingTop: 0,
      marginTop: 0,
    },

    '&:before': {
      // backgroundColor: '#fff',
      position: 'absolute',
      display: 'block',
      content: '" "',
      width: '1px',
      height: '50%',
      top: 0,
      bottom: 0,
      left: '3px',
    },
  },
  badge: {
    textAlign: 'left',
    verticalAlign: 'middle',
    display: 'table-cell',
    position: 'relative',
    width: '20px',

    '&:before': {
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      display: 'block',
      content: '" "',
      width: '7px',
      height: '7px',
      left: 0,
      top: '50%',
      marginTop: '-3.5px',
      borderRadius: '100%',
    },
  },
  primary: {
    display: 'table-cell',
    textAlign: 'left',
    verticalAlign: 'middle',
    width: '100%',
    padding: '0 0 0 5px',
    fontSize: '1rem',

    '& blockquote': {
      color: theme.palette.text.secondary,
    },
  },
  secondary: {
    display: 'table-cell',
    textAlign: 'right',
    verticalAlign: 'middle',
    width: '100px',
    padding: '0 0 0 5px',
    fontSize: '.9rem',
    color: '#595d6e',
  },
});
