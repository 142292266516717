/* eslint-disable no-param-reassign */
import React from 'react';
import produce from 'immer';

export default function usePermissionStates(sessionPermissions: Record<string, boolean> = {}) {
  const result = React.useCallback((permissions, v) => {
    const make = produce(draft => {
      const readOnly = draft.ReadOnly;
      if (!permissions.Update) {
        draft.Pending = readOnly;
        draft.InProgress = readOnly;
      }
      if (!permissions.Review) {
        draft.ReadyForReview = readOnly;
      }
      if (!permissions.Corrections) {
        draft.CorrectionsRequired = readOnly;
      }
      return draft;
    });

    return make(v);
  }, []);

  return (additionalPermissions, states) =>
    result(
      {
        ...sessionPermissions,
        ...additionalPermissions,
      },
      states
    );
}
