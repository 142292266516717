import React from 'react';
import { EquipmentDto } from 'modules/InstrumentsQualityControl/models';
import {
  defaultOptions,
  TestConfigurationOptionsContextType,
} from './TestConfigurationOptionsContextType';

const EquipmentContext = React.createContext<TestConfigurationOptionsContextType<EquipmentDto>>(
  defaultOptions
);

export default EquipmentContext;
