/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import MaterialTable from '@ldx-dmp/MaterialTable';
import TimeAgo from 'react-timeago';

import { Grid } from '@material-ui/core';
import { formatShortDateTime } from 'modules/Common/utilities';
import styles from './InstrumentDeliveryRecords.styles';

const InstrumentDeliveryRecords = ({ isActive, instrument }: Record<string, any>) => {
  const classes = styles();

  if (!isActive) return <></>;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          className={classes.stackedList}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <MaterialTable
            data={instrument.deliveryRecords || []}
            title=""
            columns={[
              {
                field: 'timestamp',
                title: 'Timestamp',
                // eslint-disable-next-line react/prop-types
                render: ({ timestamp }) => (
                  <TimeAgo date={timestamp} title={formatShortDateTime(timestamp)} />
                ),
              },
              { field: 'rmaNumber', title: 'RMA Number' },
              { field: 'wirelessId', title: 'Wireless Id' },
              { field: 'opticsRevision', title: 'Optics Revision' },
              { field: 'eolStatus', title: 'EoL Status' },
              { field: 'multipackNo', title: 'Multipack No.' },
              { field: 'palletNo', title: 'Pallet No.' },
              { field: 'piezoSerial', title: 'Piezo Serial' },
              { field: 'insionSpectrometerSerial', title: 'Insion Spectrometer Serial' },
              { field: 'echemPcbaSerial', title: 'EChem PCBA Serial' },
              { field: 'hctPhotodiodeSerial', title: 'HCT Photodiode Serial' },
              { field: 'cpldPcbaSerial', title: 'CPLD PCBA Serial' },
              { field: 'coverSubAssemblySerial', title: 'Cover Sub Assembly Serial' },
              { field: 'opticsSubAssemblySerial', title: 'Optics Sub Assembly Serial' },
              { field: 'baseSubAssemblySerial', title: 'Base Sub Assembly Serial' },
              { field: 'magnetSubAssemblySerial', title: 'Magnet Sub Assembly Serial' },
            ]}
            options={{
              columnsButton: false,
              search: false,
              emptyRowsWhenPaging: false,
              paging: false,
              toolbar: false,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InstrumentDeliveryRecords;
