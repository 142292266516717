import moment from 'moment';

export default function(repairRequests) {
  const requestRows = repairRequests.map(request => {
    const {
      id,
      irn,
      status,
      requestDetails: { requestedAt },
    } = request;
    const created = requestedAt !== null ? moment(requestedAt).format('MMM Do YYYY, hh:mm') : '-';

    return {
      id,
      irn,
      status,
      affectedSubsystems: '',
      created,
    };
  });

  return {
    columns: [
      { accessor: 'irn', name: 'IRN' },
      { accessor: 'status', name: 'Status' },
      { accessor: 'affectedSubsystems', name: 'Affected Subsystems' },
      { accessor: 'created', name: 'Created' },
    ],
    rows: requestRows,
    rowKeyAccessor: 'id',
  };
}
