export type Record = {
  id: string;
  status: RecordStatus;
};

export type Section = {
  status: SectionStatus;
  name: string;
};

export type Part = {
  status: PartStatus;
  name: string;
  requiredPermissions?: string[];
};

export type SubRecord = {
  id: string;
};

export type SubRecordPart<T> = Part & {
  subRecords: T[];
};

export type SingleSignOffPart = Part & {
  signOff: SigningAction;
};

export type MultiSignOffPart = Part & {
  signOffs: SigningAction[];
};

export type SigningAction = {
  user: User;
  date: string;
};

export type User = {
  id: string;
  name: string;
};

export enum RecordStatus {
  Draft = 'Draft',
  Ready = 'Ready',
  Incomplete = 'Incomplete',
  Complete = 'Complete',
  SignedOff = 'SignedOff',
  Rejected = 'Rejected',
}

export enum SectionStatus {
  Unavailable = 'Unavailable',
  Incomplete = 'Incomplete',
  Complete = 'Complete',
  Locked = 'Locked',
  ReadOnly = 'ReadOnly',
}

export enum PartStatus {
  Unknown = 'Unknown',
  Incomplete = 'Incomplete',
  Complete = 'Complete',
  Locked = 'Locked',
  Ignore = 'Ignore',
  Blocked = 'Blocked',
  ReadOnly = 'ReadOnly',
}
