export default () => ({
  root: {
    tabBar: {
      '& .MuiTab-root': {
        '&.Mui-selected': {
          backgroundColor: 'rgb(28, 184, 220);',
        },

        '&:hover': {
          backgroundColor: 'rgb(28, 184, 220);',
        },
      },
    },
  },
});
