/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { debounceSearchRender } from 'mui-datatables';
import startCase from 'lodash/startCase';
import { FetchDataFunction } from 'modules/Core/components/DataTable/DataTable';
import { SummaryPanelItemProps } from 'modules/Common/components/SummaryPanel/SummaryPanelItem/SummaryPanelItem';
import { AddMaterialInput, MaterialDto } from '../../models';

export type MaterialsContextType = {
  list: Partial<{
    fetchAll: FetchDataFunction;
    totalCount: number;
    isLoading: boolean;
    isReloading: boolean;
    data: any[];
    options: any;
    columns: any[];
  }>;
  details: Partial<{
    summary: SummaryPanelItemProps[];
  }>;
  isLoading: boolean;

  createForm: {
    formOpen: boolean;
    onFormClose: () => void;
    onFormSubmit: (data: Partial<AddMaterialInput>) => void;
    initialValues?: (Partial<MaterialDto> & Partial<AddMaterialInput>) | undefined | null;
  };

  onNewItemClick: () => void;

  current?: MaterialDto | null;
};

export const columns = [
  { name: 'id', label: 'id', options: { display: 'excluded' } },
  { name: 'name', label: 'Name', options: { filter: false, sort: true } },
  { name: 'specification', label: 'Specification', options: { filter: false, sort: true } },
  { name: 'revision', label: 'Revision', options: { filter: false, sort: true } },
  {
    name: 'usage',
    label: 'Usage',
    options: { filter: false, sort: true, customBodyRender: value => startCase(value) },
  },
];

export const options = {
  filter: false,
  selectableRows: 'none',
  download: false,
  print: false,
  filterType: 'multiselect',
  elevation: 1,
  viewColumns: false,
  search: false,
  customSearchRender: debounceSearchRender(500),
};

const context = React.createContext<Partial<MaterialsContextType>>({
  list: {
    columns,
  },
});

export default context;
