import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import styles from './FunctionalTestResult.styles';

const FunctionalTestResult = ({ testResult, platform }) => {
  const classes = makeStyles(styles)();

  const isFamilyMatch = familyToTest =>
    familyToTest === '00000000-0000-0000-0000-000000000000' || familyToTest === platform;

  return (
    <>
      <Grid item xs={10} className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Testing completed by</Typography>
            {testResult.testingCompletedBy.name}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Testing completed at</Typography>
            {moment(testResult.testingCompletedAt).format('MMMM Do YYYY, h:mm:ss a')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">MCU Firmware Version</Typography>
            {testResult.firmwares?.find(x => x.type === 'MCU')?.version}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">CPLD Firmware Version</Typography>
            {testResult.firmwares?.find(x => x.type === 'CPLD')?.version}
          </Grid>
          {!!testResult.firmwares?.find(x => x.type === 'CAMERA')?.version && (
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Camera Software Version</Typography>
              {testResult.firmwares?.find(x => x.type === 'CAMERA')?.version}
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Meter API Version</Typography>
            {testResult.meterApiVersion}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Serial Number Set</Typography>
            {testResult.serialNumberSet}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Test Result Location</Typography>
            {testResult.testResultLocation}
          </Grid>
          {testResult.testGroups &&
            testResult.testGroups.map(group => (
              <Fragment key={group.name}>
                {isFamilyMatch(group.platform) && (
                  <Grid item xs={12} className={classes.testCategory}>
                    <Typography variant="h6">{group.name}</Typography>
                  </Grid>
                )}
                {group.tests.map(test => (
                  <Fragment key={`${group.id}_${test.name}`}>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} className={classes.stackedListHeader}>
                          <Typography variant="subtitle2">{test.name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.testResult}>
                          {test.result === 1 && (
                            <Typography variant="body1" className={classes.testPass}>
                              <Check style={{ verticalAlign: 'middle' }} /> PASS
                            </Typography>
                          )}
                          {test.result === 0 && (
                            <Typography variant="body1" className={classes.testFail}>
                              <Clear style={{ verticalAlign: 'middle' }} /> FAIL
                            </Typography>
                          )}
                          {test.result === 2 && (
                            <Typography variant="body1" className={classes.testNa}>
                              N/A
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fragment>
                ))}
              </Fragment>
            ))}
        </Grid>
      </Grid>
    </>
  );
};

FunctionalTestResult.propTypes = {
  testResult: PropTypes.shape({
    testingCompletedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    testingCompletedAt: PropTypes.string,
    firmwares: PropTypes.arrayOf(PropTypes.object).isRequired,
    meterApiVersion: PropTypes.string,
    serialNumberSet: PropTypes.string,
    testResultLocation: PropTypes.string,
    testGroups: PropTypes.arrayOf(
      PropTypes.shape({
        platform: PropTypes.string,
        name: PropTypes.string,
        tests: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            result: PropTypes.number,
          })
        ),
      })
    ),
  }).isRequired,
  platform: PropTypes.string.isRequired,
};

export default FunctionalTestResult;
