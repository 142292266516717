import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileDocumentEditOutline } from 'mdi-material-ui';
import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { utils } from '@ldx-dmp/Common';
import { BatchRecord } from '@ldx-dmp/Pilot/BatchRecord/domain';
import { openDialog } from '@ldx-dmp/Core/actions/dialogs';
import { SignOffDialog } from '@ldx-dmp/Pilot/BatchRecord/SignOff';
import {
  verifyFirstLabelPrepared,
  recordRemainingLabelsPrepared,
  RecordRemainingLabelsPreparedActions,
  VerifyFirstLabelPrepared,
} from '@ldx-dmp/Pilot/BatchRecord/actions';
import { FormDialog } from '@ldx-dmp/Common/components';
import { getConfig, getItemsBeingEdited } from '@ldx-dmp/Core/reducers/selectors';
import { PartStatus, SigningAction } from '@ldx-dmp/RecordManagement';
import { startEditing } from '@ldx-dmp/Core/actions/editMode';
import useStyles from './LabelPreparation.styles';
import SectionPart from '../../SectionPart';
import useFirstLabel from './firstLabel.form';

const LabelRow = ({
  text,
  signature,
  isReadOnly: isReadonly,
  canEdit = false,
  showButton,
  buttonText,
  onEditClick,
  onButtonClick,
}: {
  text: string;
  signature: SigningAction | null;
  isReadOnly: boolean;
  canEdit?: boolean;
  showButton: boolean;
  buttonText: string;
  onButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <TableRow>
    <TableCell className="rowHeader" scope="row" variant="head" component="th">
      {text}
    </TableCell>
    {!signature && showButton && <ButtonLabelRow text={buttonText} onButtonClick={onButtonClick} />}
    {!signature && !showButton && <EmptyLabelRow />}
    {signature && (
      <FilledLabelRow
        signature={signature}
        isReadonly={isReadonly}
        canEdit={canEdit}
        onEditClick={onEditClick}
      />
    )}
  </TableRow>
);

const FilledLabelRow = ({
  signature,
  isReadonly,
  canEdit = false,
  onEditClick,
}: {
  signature: SigningAction;
  isReadonly: boolean;
  canEdit?: boolean;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <>
    <TableCell>{signature.user.name}</TableCell>
    <TableCell>{utils.formatShortDate(signature.date)}</TableCell>
    <TableCell>
      {!isReadonly && canEdit && (
        <Tooltip title="Edit Image">
          <IconButton size="small" onClick={onEditClick}>
            <FileDocumentEditOutline color="primary" />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  </>
);

const ButtonLabelRow = ({
  text,
  onButtonClick,
}: {
  text: string;
  onButtonClick: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <TableCell colSpan={3} align="center">
    <Button size="small" color="primary" onClick={onButtonClick} variant="contained">
      {text}
    </Button>
  </TableCell>
);

const EmptyLabelRow = () => <TableCell colSpan={3}>-</TableCell>;

export default ({ batchRecord }: { batchRecord: BatchRecord }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const config = useSelector(getConfig);

  const firstOffLabelPreparedDialog = 'pilot/batch-records/FIRST_OFF_LABEL';
  const firstOffLabelVerifiedDialog = 'pilot/batch-records/SIGN_OFF_FIRST_LABEL_VERIFIED';
  const prepareRemainingLabelsDialog = 'pilot/batch-records/SIGN_OFF_REMAINING_LABELS';

  const isBeingEdited =
    useSelector(getItemsBeingEdited).find(
      item => item.key === `${firstOffLabelPreparedDialog}_EDIT`
    ) !== undefined;
  const firstLabel = useFirstLabel(batchRecord, isBeingEdited);
  const recordId = batchRecord.id;
  const {
    labelPreparation: {
      status,
      labelImage,
      firstOffLabelPreparedBy,
      firstOffLabelVerifiedBy,
      remainderOfLabelsPreparedBy,
    },
  } = batchRecord.preparation;

  const isReadOnly =
    status === PartStatus.ReadOnly || status === PartStatus.Locked || status === PartStatus.Blocked;
  const handleEditClick = () => {
    dispatch(startEditing(firstOffLabelPreparedDialog, batchRecord.id));
    dispatch(openDialog(firstOffLabelPreparedDialog));
  };

  return (
    <SectionPart xs={6} title="Label Preparation" status={status}>
      <Table stickyHeader size="small" className={classes.root}>
        <TableBody>
          <LabelRow
            text="1st Off Label Prepared By"
            signature={firstOffLabelPreparedBy}
            canEdit
            isReadOnly={isReadOnly}
            showButton={!isReadOnly && !firstOffLabelPreparedBy}
            buttonText="Prepare"
            onButtonClick={() => dispatch(openDialog(firstOffLabelPreparedDialog))}
            onEditClick={handleEditClick}
          />
          <LabelRow
            text="1st Off Label Verified By"
            signature={firstOffLabelVerifiedBy}
            isReadOnly={isReadOnly}
            showButton={!isReadOnly && firstOffLabelPreparedBy !== null && !firstOffLabelVerifiedBy}
            buttonText="Verify"
            onButtonClick={() => dispatch(openDialog(firstOffLabelVerifiedDialog))}
          />
          <LabelRow
            text="Remainder of Labels Prepared By"
            signature={remainderOfLabelsPreparedBy}
            isReadOnly={isReadOnly}
            showButton={
              !isReadOnly && firstOffLabelVerifiedBy !== null && !remainderOfLabelsPreparedBy
            }
            buttonText="Prepare"
            onButtonClick={() => dispatch(openDialog(prepareRemainingLabelsDialog))}
          />
          <TableRow>
            <TableCell className="rowHeader" scope="row" variant="head" component="th">
              Label Image
            </TableCell>
            <TableCell colSpan={3}>
              {labelImage && (
                <img
                  src={`${config.app.cdn_base_uri}/${labelImage.key}`}
                  alt="Prepared label"
                  className={classes.labelImage}
                />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <SignOffDialog
        title="Verify First Off Label"
        action={verifyFirstLabelPrepared(recordId)}
        api={VerifyFirstLabelPrepared.CALL}
        dialogName={firstOffLabelVerifiedDialog}
        reviewText="Have you verified the first label?"
      />
      <SignOffDialog
        title="Prepare Remaining Labels"
        action={recordRemainingLabelsPrepared(recordId)}
        api={RecordRemainingLabelsPreparedActions.CALL}
        dialogName={prepareRemainingLabelsDialog}
        reviewText="Have the remaining labels been prepared?"
      />
      <FormDialog definition={firstLabel} />
    </SectionPart>
  );
};
