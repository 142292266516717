import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Timeline.styles';

const Timeline = ({ children }) => {
  const classes = makeStyles(styles, { name: 'LdxTimeline' })();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.list}>{children}</div>
      </div>
    </>
  );
};

Timeline.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default Timeline;
