import React from 'react';
import axios from 'axios';
import { selectFormOpen, setCurrent, closeForm } from '../testSessionsSlice';

export default function(dispatch, apiUrl, navigate: (url: string) => void, state) {
  const formOpen = selectFormOpen(state);
  const onFormClose = React.useCallback(() => dispatch(closeForm()), [dispatch]);

  const onFormSubmit = React.useCallback(
    formData => {
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}/instruments/quality-control/test-sessions`, formData)
          .then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }

            onFormClose();
            dispatch(setCurrent(response.data));
            navigate(`/instruments-quality-control/${response.data.id}`);
            resolve(response.data);
          });
      });
    },
    [apiUrl, dispatch, navigate, onFormClose]
  );

  return React.useMemo(
    () => ({
      onFormClose,
      onFormSubmit,
      formOpen,
    }),
    [onFormClose, onFormSubmit, formOpen]
  );
}
