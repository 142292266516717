import { createAction, RSAACall } from 'redux-api-middleware';

export enum RecordSubstrateOgpInspectionActions {
  CALL = 'pilot/batch-records/RECORD_SUBSTRATE_OGP',
  REQUEST = 'pilot/batch-records/RECORD_SUBSTRATE_OGP_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_SUBSTRATE_OGP_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_SUBSTRATE_OGP_FAILURE',
}

export enum AmendSubstrateOgpInspectionActions {
  CALL = 'pilot/batch-records/AMEND_SUBSTRATE_OGP',
  REQUEST = 'pilot/batch-records/AMEND_SUBSTRATE_OGP_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_SUBSTRATE_OGP_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_SUBSTRATE_OGP_FAILURE',
}

export enum RecordFinalOgpInspectionActions {
  CALL = 'pilot/batch-records/RECORD_FINAL_OGP',
  REQUEST = 'pilot/batch-records/RECORD_FINAL_OGP_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_FINAL_OGP_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_FINAL_OGP_FAILURE',
}

export enum AmendFinalOgpInspectionActions {
  CALL = 'pilot/batch-records/AMEND_FINAL_OGP',
  REQUEST = 'pilot/batch-records/AMEND_FINAL_OGP_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_FINAL_OGP_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_FINAL_OGP_FAILURE',
}

const createOgpAction = (
  { method, endpoint, types }: Pick<RSAACall, 'method' | 'endpoint' | 'types'>,
  attachments: File[],
  comment?: string
) => {
  const data = new FormData();
  attachments.forEach(attachment => data.append('attachments', attachment));
  if (comment) data.append('comment', comment);

  return createAction({
    endpoint,
    method,
    types,
    body: data,
  });
};

export const recordSubstrateOgpInspection = (recordId: string, attachments: File[]) => {
  return createOgpAction(
    {
      endpoint: `/pilot/batch-records/${recordId}/preparation/ogp-inspection`,
      method: 'POST',
      types: [
        RecordSubstrateOgpInspectionActions.REQUEST,
        RecordSubstrateOgpInspectionActions.SUCCESS,
        RecordSubstrateOgpInspectionActions.FAILURE,
      ],
    },
    attachments
  );
};

export const recordFinalOgpInspection = (recordId: string, attachments: File[]) => {
  return createOgpAction(
    {
      endpoint: `/pilot/batch-records/${recordId}/inspection/ogp-inspection`,
      method: 'POST',
      types: [
        RecordFinalOgpInspectionActions.REQUEST,
        RecordFinalOgpInspectionActions.SUCCESS,
        RecordFinalOgpInspectionActions.FAILURE,
      ],
    },
    attachments
  );
};

export const amendSubstrateOgpInspection = (
  recordId: string,
  attachments: File[],
  comment?: string
) => {
  return createOgpAction(
    {
      endpoint: `/pilot/batch-records/${recordId}/preparation/ogp-inspection`,
      method: 'PATCH',
      types: [
        AmendSubstrateOgpInspectionActions.REQUEST,
        AmendSubstrateOgpInspectionActions.SUCCESS,
        AmendSubstrateOgpInspectionActions.FAILURE,
      ],
    },
    attachments,
    comment
  );
};

export const amendFinalOgpInspection = (
  recordId: string,
  attachments: File[],
  comment?: string
) => {
  return createOgpAction(
    {
      endpoint: `/pilot/batch-records/${recordId}/inspection/ogp-inspection`,
      method: 'PATCH',
      types: [
        AmendFinalOgpInspectionActions.REQUEST,
        AmendFinalOgpInspectionActions.SUCCESS,
        AmendFinalOgpInspectionActions.FAILURE,
      ],
    },
    attachments,
    comment
  );
};
