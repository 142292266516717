import React, { useMemo } from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { DatePicker } from '@material-ui/pickers';
import { Button, CircularProgress } from '@material-ui/core';

import { useEffectOnce } from 'react-use';
import useLocationsSelectList from 'modules/Global/locations/useLocationsSelectList';

import {
  FormikTextField,
  FormikRadioGroup,
  FormikSelectField,
  Spinner,
} from 'modules/Common/components';
import {
  createRepairRequest,
  updateRepairRequest,
} from 'modules/Instruments/slices/Repair/actions';
import { RepairRequest } from 'modules/Instruments/domain/repairRequest';
import { InstrumentDto } from 'modules/Instruments/models';
import AffectedSubsystemsSelect from './AffectedSubsystemsSelect';
import useStyles from './RepairRequestForm.styles';
import createSchema, { FormValues } from './RepairRequestForm.schema';
import SelectedInstrument from './SelectedInstrument';
import useLoadingCheck from './useLoadingCheck';
import RepairLocationsSelectList from '../../Locations/components/RepairLocationsSelectList';

type RepairRequestFormProps = {
  instrument?: InstrumentDto;
  repairRequest?: RepairRequest;
  onCancel: () => void;
  editMode?: boolean;
};

const LocationsSelectList = props => {
  const { items, fetch } = useLocationsSelectList();
  useEffectOnce(() => {
    fetch();
  });

  return <FormikSelectField {...props} options={items} />;
};

const RepairRequestForm = ({
  instrument,
  repairRequest,
  onCancel,
  editMode = false,
}: RepairRequestFormProps) => {
  const repairRequestSchema = useMemo(() => createSchema(editMode), [editMode]);

  const instrumentWipeDownValue = booleanValue => {
    if (booleanValue === null) return 'na';
    if (booleanValue) return 'yes';
    return 'no';
  };

  const createInitialState = (): FormValues => {
    if (editMode && repairRequest)
      return {
        repairLocation: repairRequest.requestDetails.repairLocation?.id,
        requestDate: moment(repairRequest.requestDetails.requestedAt).toDate(),
        isWipedDown: instrumentWipeDownValue(repairRequest?.requestDetails.isInstrumentWipedDown),
        nameOfMeterUser: repairRequest.requestDetails.instrumentUser,
        meterLocation: repairRequest.requestDetails.instrumentLocation?.id,
        requestDescription: repairRequest.requestDetails.requestDescription,
        workCarriedOut: repairRequest.repairDetails?.repairDescription,
        affectedSubsystems:
          repairRequest.requestDetails.affectedSubsystems?.map(x => String(x.id)) || [],
      };

    return {
      repairLocation: '',
      requestDate: new Date(),
      isWipedDown: '',
      nameOfMeterUser: '',
      meterLocation: '',
      affectedSubsystems: [],
      requestDescription: '',
      workCarriedOut: '',
    };
  };

  const initialState = createInitialState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLoading = useLoadingCheck();
  const handleCancelClicked = () => onCancel();

  if (!instrument) return <Spinner />;

  return (
    <>
      <SelectedInstrument instrument={instrument} />
      <Formik
        initialValues={initialState}
        onSubmit={(values, actions) => {
          const payload = {
            meterSerial: instrument?.serial,
            requestedAt: values.requestDate,
            repairLocation: values.repairLocation,
            isWipedDown: values.isWipedDown,
            nameOfMeterUser: values.nameOfMeterUser,
            meterLocation: values.meterLocation,
            repairDetails: values.requestDescription,
            affectedSubsystems: values.affectedSubsystems,
            workCarriedOut: values.workCarriedOut,
          };

          if (editMode) dispatch(updateRepairRequest(repairRequest?.id, payload));
          else dispatch(createRepairRequest(payload));

          actions.setSubmitting(true);
        }}
        validationSchema={repairRequestSchema}
      >
        {({ values, handleBlur, handleSubmit, setFieldValue }) => (
          <form className={classes.root} autoComplete="on" onSubmit={handleSubmit}>
            {!editMode && (
              <RepairLocationsSelectList name="repairLocation" label="Repair Location" />
            )}
            <DatePicker
              fullWidth
              autoOk
              disableFuture
              showTodayButton
              name="requestDate"
              format="ddd, MMM Do YYYY"
              inputVariant="filled"
              value={values.requestDate}
              onAccept={date => setFieldValue('requestDate', date)}
              onChange={date => setFieldValue('requestDate', date)}
              onBlur={handleBlur}
              label="Request Date"
            />
            <FormikRadioGroup
              name="isWipedDown"
              label="Has Instrument Been De-contaminated?"
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
                { label: 'N/A', value: 'na' },
              ]}
            />
            <FormikTextField name="nameOfMeterUser" label="Name of Meter User" />
            <LocationsSelectList name="meterLocation" label="Where is Meter Used?" />

            <AffectedSubsystemsSelect name="affectedSubsystems" instrument={instrument} />
            <FormikTextField
              name="requestDescription"
              label="Details of Change / Fault / Rework Required (inc. Software Updates)"
              rows="5"
              multiline
            />
            {editMode && (
              <FormikTextField
                name="workCarriedOut"
                label="Change / Repair / Rework Details"
                rows="5"
                multiline
                helperText="Include serial numbers of boards replaced and software revision"
              />
            )}
            <div className={classes.actionButtons}>
              <Button variant="contained" onClick={handleCancelClicked}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained" disabled={isLoading}>
                {isLoading && <CircularProgress size={20} style={{ marginRight: '4px' }} />}
                {editMode ? 'Update Request' : 'Create Request'}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default RepairRequestForm;
