import React from 'react';
import { useBoolean } from 'react-use';
import { IconButton } from '@material-ui/core';
import AccountPlusOutline from 'mdi-material-ui/AccountPlusOutline';

import {
  AddCorrectionsUserInput,
  PerformanceTestDetailsDto,
  TestSessionDto,
  TestSessionInstrumentDto,
  VisualInspectionDetailsDto,
} from 'modules/InstrumentsQualityControl/models';

import OperatorContext from '../../operatorsContext';
import * as form from '../../forms/VisualInspection/addCorrectionsUserToVisualInspectionInputFormSchema';
import AddConfigurationOptionToTestSession from '../AddConfigurationOptionToTestSession';

export default function AddCorrectionsUser({
  instrument,
  status,
  permissions,
  test,
  addCorrectionsUser,
}: {
  instrument: TestSessionInstrumentDto;
  status?: string;
  permissions?: Record<string, boolean>;
  test: VisualInspectionDetailsDto | PerformanceTestDetailsDto;
  addCorrectionsUser: (data: AddCorrectionsUserInput) => Promise<TestSessionDto>;
}) {
  const [showDialog, toggleShowDialog] = useBoolean(false);

  const handleAddCorrectionsUser = React.useCallback(
    data => {
      return new Promise((resolve, reject) => {
        addCorrectionsUser(data).then(response => {
          toggleShowDialog();
          resolve(response);
        }, reject);
      });
    },
    [addCorrectionsUser, toggleShowDialog]
  );

  if (status !== 'CorrectionsRequired' || !permissions?.Review) {
    return <></>;
  }

  const title = 'Add corrections user';

  return (
    <>
      <IconButton onClick={() => toggleShowDialog()} aria-label={title} title={title}>
        <AccountPlusOutline />
      </IconButton>
      {showDialog && (
        <OperatorContext.Consumer>
          {options => {
            return (
              <AddConfigurationOptionToTestSession
                onFormSubmit={handleAddCorrectionsUser}
                form={form}
                onFormClose={toggleShowDialog}
                formOpen={showDialog}
                items={options.all}
                current={test?.correctionUsers?.map(id => ({ id }))}
                select={f => f.correctionsUser}
                initialValues={form.mapToEdit(instrument)}
              />
            );
          }}
        </OperatorContext.Consumer>
      )}
    </>
  );
}
