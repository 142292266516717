import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FlagRemove } from 'mdi-material-ui';
import { FieldProps } from '@rjsf/core';
import { FormikTextField } from 'modules/Common/components';
import { useField } from 'formik';
import InstrumentResult from 'modules/InstrumentsQualityControl/features/instruments/components/InstrumentResult';

export default ({ schema, formData, value, id: _id, uiSchema = {}, idSchema }: FieldProps) => {
  const id = _id || idSchema.$id;
  const [open, setOpen] = React.useState(false);
  const fieldId = `review.${id}`;
  const [field] = useField(fieldId);
  const showComment = field.value && !open;

  const { format } = uiSchema;

  const result = (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">
          {schema.title}
          {!!id && (
            <IconButton onClick={() => setOpen(v => !v)} style={{ float: 'right' }}>
              <FlagRemove color={field.value?.length > 5 ? 'primary' : undefined} />
            </IconButton>
          )}
        </Typography>
        {format ? format(value) : <InstrumentResult value={formData || value} />}
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Flag Entry</DialogTitle>
        <DialogContent>
          <FormikTextField name={`review.${id}`} label="Comment" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  if (showComment) {
    return (
      <Alert
        severity="warning"
        style={{
          opacity: 1,
        }}
      >
        {result}
        <AlertTitle>{field.value}</AlertTitle>
      </Alert>
    );
  }

  return result;
};
