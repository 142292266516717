import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    '& ul': {
      padding: 0,
      listStyleType: 'none',
      clear: 'both',
      lineHeight: '1em',
      margin: '0 -1px',
      textAlign: 'center',
    },
    '& li': {
      float: 'left',
      padding: '10px 30px 10px 40px',
      background: '#333',
      position: 'relative',
      borderTop: '1px solid #666',
      borderBottom: '1px solid #666',
      width: '16%',
      margin: '0 1px',
      '&:before': {
        content: '""',
        borderLeft: '18px solid #fff',
        borderTop: '18px solid transparent',
        borderBottom: '18px solid transparent',
        position: 'absolute',
        top: -1,
        left: 0,
      },
      '&:after': {
        content: '""',
        borderLeft: '18px solid #333',
        borderTop: '18px solid transparent',
        borderBottom: '18px solid transparent',
        position: 'absolute',
        top: -1,
        left: '100%',
        zIndex: 20,
      },
      '&.incomplete': {
        background: '#ffd54f',
        borderTop: '#ffd54f',
        borderBottom: '#ffd54f',
        '&:after': {
          borderLeftColor: '#ffd54f',
        },
      },
      '&.complete, &.locked, &.readOnly': {
        background: '#81c784',
        borderTop: '#81c784',
        borderBottom: '#81c784',
        '&:after': {
          borderLeftColor: '#81c784',
        },
      },
    },
  },
}));
