import axios, { AxiosResponse } from 'axios';

import UpdateInstrumentRequest from 'modules/Instruments/models/UpdateInstrumentRequest';

export default function updateInstrumentAsync(apiUrl: string) {
  return function api<TResponse>(
    serialNumber: string | undefined | null,
    request: UpdateInstrumentRequest | undefined | null
  ): Promise<AxiosResponse<TResponse>> {
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      axios
        .request<TResponse>({
          url: `${apiUrl}/instruments/serial/${serialNumber}`,
          method: 'PUT',
          data: request,
        })
        .then(response => {
          if (response.status === 400) {
            reject(response);
            return;
          }
          resolve(response);
        }, reject);
    });
  };
}
