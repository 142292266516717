import { apiPost } from 'modules/Core/actions';

export enum SignOffManufacturingActions {
  CALL = 'pilot/batch-records/MANUFACTURING_SIGN_OFF',
  REQUEST = 'pilot/batch-records/MANUFACTURING_SIGN_OFF_REQUEST',
  SUCCESS = 'pilot/batch-records/MANUFACTURING_SIGN_OFF_SUCCESS',
  FAILURE = 'pilot/batch-records/MANUFACTURING_SIGN_OFF_FAILURE',
}

export const signOffManufacturing = (recordId: string) =>
  apiPost(
    `/pilot/batch-records/${recordId}/sign-off/manufacturing`,
    SignOffManufacturingActions.CALL,
    {}
  );
