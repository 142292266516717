import React from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { AppConfig } from 'modules/Core/types';
import { getConfig } from 'modules/Core/reducers/selectors';
import {
  InstrumentDto,
  TestSessionAuditLogDto,
  TestSessionDto,
} from 'modules/InstrumentsQualityControl/models';

export default function useServer() {
  const config = useSelector(getConfig) as AppConfig;
  const apiUrl = config.api.base_uri;

  const server = React.useMemo(
    () => ({
      patchReview: (id, type, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/${type}/review`,
              {
                ...data.review,
                instrumentId: data.instrumentId,
                comment: data.comment,
                outcome: data.outcome,
              }
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchUpdate: (id, type, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/${type}`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchSubmit: (id, type, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/${type}/submit`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchSignOff: (id, type, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/${type}/sign-off`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchAddInstruments: (id, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/add-instruments`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      postNewInstrument: data =>
        new Promise<AxiosResponse<InstrumentDto>>((resolve, reject) => {
          axios.post<InstrumentDto>(`${apiUrl}/instruments`, data).then(response => {
            if (response.status === 400) {
              reject(response);
              return;
            }
            resolve(response);
          }, reject);
        }),
      patchInstrumentOrder: (id, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .post<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/update-instrument-order`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchAddControlInstruments: (id, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/add-control-instruments`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      postLogFile: (id, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .post<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/performance-test/audit-log`,
              data,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchQualityEvent: (id, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/quality-event`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchPlannedDeviation: (id, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/planned-deviation`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchInstrumentDisposition: (id, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/instrument-disposition`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchCorrectionsUser: (id, type, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/${type}/add-corrections-user`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchSubmitForReview: id =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/submit-for-review`,
              {}
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchGenrateTestReplicateId: (id, data) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/performance-test/generate-replicate-id`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      getActivity: id =>
        new Promise<AxiosResponse<TestSessionAuditLogDto>>((resolve, reject) => {
          axios
            .get<TestSessionAuditLogDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/audit-log`
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchLineClearance: (id, type, data = {}) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/${type}/line-clearance`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
      patchPerformanceTestConfiguration: (id, data = {}) =>
        new Promise<AxiosResponse<TestSessionDto>>((resolve, reject) => {
          axios
            .patch<TestSessionDto>(
              `${apiUrl}/instruments/quality-control/test-sessions/${id}/performance-test/type-and-sample`,
              data
            )
            .then(response => {
              if (response.status === 400) {
                reject(response);
                return;
              }
              resolve(response);
            }, reject);
        }),
    }),
    [apiUrl]
  );

  return server;
}
