import { apiGet } from 'modules/Core/actions';

export enum GetChemistryDefinitionsActions {
  CALL = 'pilot/batch-records/GET_CHEMISTRY_DEFINITIONS',
  REQUEST = 'pilot/batch-records/GET_CHEMISTRY_DEFINITIONS_REQUEST',
  SUCCESS = 'pilot/batch-records/GET_CHEMISTRY_DEFINITIONS_SUCCESS',
  FAILURE = 'pilot/batch-records/GET_CHEMISTRY_DEFINITIONS_FAILURE',
}

export const getChemistryDefinitions = (line: string) => {
  return apiGet(
    `/pilot/chemistry/definitions?line=${line || ''}`,
    GetChemistryDefinitionsActions.CALL
  );
};
