import React from 'react';
import { WidgetProps, FieldProps } from '@rjsf/core';

export default ({ registry, uiSchema, ...props }: WidgetProps & FieldProps) => {
  const selectOptions = [
    {
      label: 'Pass',
      value: true,
    },
    { label: 'Fail', value: false },
  ];
  const { RadioWidget } = registry.widgets;

  return (
    <RadioWidget
      {...props}
      uiSchema={uiSchema}
      options={{
        enumOptions: selectOptions,
      }}
    />
  );
};
