import React from 'react';
import * as yup from 'yup';
import { FormikValues } from 'formik';
import { formatShortDate } from 'modules/Common/utilities';
import { FormikCheckboxGroup, FormikTextField, FormikRadioGroup } from 'modules/Common/components';
import { RecordDefinition } from 'modules/Pilot/BatchRecord/SingleView/components/RecordTable/RecordTable';
import { BatchRecord, TunnelDryerSubRecord } from 'modules/Pilot/BatchRecord/domain';
import { DataTableRow } from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable/TableData';
import { PartStatus } from 'modules/RecordManagement';
import { Grid } from '@material-ui/core';
import {
  amendTunnelDryerUsage,
  AmendTunnelDryerUsageActions,
  recordTunnelDryerUsage,
  RecordTunnelDryerUsageActions,
} from 'modules/Pilot/BatchRecord/actions/tunnelDryer';
import { UserPermission } from 'modules/Core/types';
import getInitialSplits from '../../initialSplits';

interface Values extends FormikValues {
  profilePassed: boolean | null;
  temperature: number;
  speed: number;
  splits: number[];
}

export default (
  batchRecord: BatchRecord,
  itemsBeingEdited: { key: string; itemId: string }[],
  userPermissions: UserPermission[]
): RecordDefinition<Values> => {
  const dialogName = 'pilot/batch-records/TUNNEL_DRYER';
  const recordId = batchRecord.id;
  const { status, requiredPermissions } = batchRecord.drying.ovens;
  const subRecords = batchRecord.drying.ovens.tunnelDryerSubRecords;
  const { splits } = batchRecord.batchRequest;
  const itemBeingEdited = itemsBeingEdited.find(
    item =>
      item.key === `${dialogName}_EDIT` &&
      subRecords &&
      subRecords.findIndex(print => print.id === item.itemId) > -1
  );

  const rowForEditing = itemBeingEdited
    ? subRecords.find(print => print.id === itemBeingEdited.itemId)
    : null;

  const isAllowedToEdit = () => {
    if (
      status === PartStatus.Locked ||
      status === PartStatus.ReadOnly ||
      status === PartStatus.Blocked
    )
      return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  const newSubmit = (values: Values, _, dispatch) =>
    dispatch(recordTunnelDryerUsage(recordId, { ...values }));
  const editSubmit = (values: Values, _, dispatch) =>
    dispatch(amendTunnelDryerUsage(recordId, { ...values, subRecordId: rowForEditing?.id }));

  const createDataTableRow = (row: TunnelDryerSubRecord): DataTableRow => ({
    key: row.id,
    fields: {
      splits: { value: row.splits.join(', ') },
      profilePassed: { value: row.profilePassed ? 'Yes' : 'No' },
      temperature: { value: row.temperature },
      speed: { value: row.speed },
      performedBy: { user: row.performedBy.user.name, date: formatShortDate(row.performedBy.date) },
    },
  });

  const validation = {
    profilePassed: yup
      .bool()
      .defined()
      .required(),
    temperature: yup
      .number()
      .min(25)
      .max(55)
      .defined(),
    speed: yup
      .number()
      .min(0.1)
      .max(2)
      .defined(),
    splits: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(splits.length),
  };

  const editValidation = {
    ...validation,
    comment: yup
      .string()
      .defined()
      .required()
      .min(5),
  };

  return {
    title: 'Tunnel Dryer',
    tableData: {
      columns: [
        { accessor: 'splits', label: 'Split(s)' },
        { accessor: 'profilePassed', label: 'Profile Passed' },
        { accessor: 'temperature', label: 'Temp °C' },
        { accessor: 'speed', label: 'Speed (M/min)' },
        { accessor: 'performedBy', label: 'Performed by', isSignOff: true },
      ],
      rows: subRecords ? subRecords.map(row => createDataTableRow(row)) : [],
    },
    canAddRow: status === PartStatus.Incomplete,
    canEditRows: isAllowedToEdit(),
    formDefinition: {
      title: 'Tunnel Dryer',
      dialogName,
      apiAction: rowForEditing
        ? AmendTunnelDryerUsageActions.CALL
        : RecordTunnelDryerUsageActions.CALL,
      onSubmit: rowForEditing ? editSubmit : newSubmit,
      initialValues: {
        profilePassed: rowForEditing ? rowForEditing.profilePassed : null,
        temperature: rowForEditing ? rowForEditing.temperature : 0,
        speed: rowForEditing ? rowForEditing.speed : 0,
        splits: getInitialSplits(rowForEditing, splits),
      },
      form: () => (
        <>
          <FormikCheckboxGroup
            name="splits"
            label="Split(s)"
            options={splits}
            labelPlacement="top"
            allowSelectAll
          />
          <FormikRadioGroup
            name="profilePassed"
            label="Profile Passed"
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
          />
          <Grid container spacing={2}>
            <Grid item xs>
              <FormikTextField
                name="temperature"
                label="Temperature °C"
                helperText="Between 25 and 55"
              />
            </Grid>
            <Grid item xs={6}>
              <FormikTextField name="speed" label="Speed (M/min)" helperText="Between 0.1 and 2" />
            </Grid>
          </Grid>
          {rowForEditing && <FormikTextField name="comment" label="Reason for change" multiline />}
        </>
      ),
      validationSchema: (): yup.ObjectSchema<Values> =>
        yup
          .object()
          .shape(rowForEditing ? editValidation : validation)
          .defined(),
    },
  };
};
