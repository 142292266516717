import React, { ReactNode } from 'react';
import { Grid, GridSpacing } from '@material-ui/core';
import useStyles from './GridList.styles';

type GridListProps = {
  children?: ReactNode;
  gridSpacing?: GridSpacing;
};

const GridList = ({ children, gridSpacing = 4 }: GridListProps) => {
  const classes = useStyles();

  return (
    <Grid container spacing={gridSpacing} className={classes.root}>
      {children}
    </Grid>
  );
};

export default GridList;
