import React from 'react';
import * as yup from 'yup';
import { FormikValues } from 'formik';
import { formatShortDate } from 'modules/Common/utilities';
import { FormikTextField, FormikCheckboxGroup, FormikSelectField } from 'modules/Common/components';
import { RecordDefinition } from 'modules/Pilot/BatchRecord/SingleView/components/RecordTable/RecordTable';
import {
  BatchRecord,
  DepositionMethod,
  ChemistryDefinition,
  DepositionAsset,
  LidOrBase,
  DepositionSubRecord,
} from 'modules/Pilot/BatchRecord/domain';
import { DataTableRow } from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable/TableData';
import {
  recordDepositionItem,
  RecordDepositionItemActions,
  verifyDepositionItem,
  VerifyDepositionItemActions,
  amendDepositionItem,
} from 'modules/Pilot/BatchRecord/actions';
import { PartStatus } from 'modules/RecordManagement';
import { UserPermission } from 'modules/Core/types';
import getInitialSplits from '../../initialSplits';

interface Values extends FormikValues {
  assetId: string;
  splits: number[];
  channels: number[];
  zone: '' | 'A' | 'B' | 'C';
  chemistry: string;
  chemistryOther?: string;
  volume: number;
  lidOrBase: LidOrBase | null;
  comment?: string;
}

export default (
  batchRecord: BatchRecord,
  method: DepositionMethod,
  assetOptions: DepositionAsset[],
  chemistyOptions: ChemistryDefinition[],
  itemsBeingEdited: { key: string; itemId: string }[],
  userPermissions: UserPermission[]
): RecordDefinition<Values> => {
  const recordId = batchRecord.id;
  const dialogName = `pilot/batch-records/DEPOSITION_${method.toUpperCase()}`;
  const { status, subRecords, requiredPermissions } = batchRecord.deposition.depositions;
  const { splits } = batchRecord.batchRequest;
  const depositions = subRecords.filter(item => item.depositionMethod === method);
  const channelOptions = [1, 2, 3, 4];
  const zoneOptions = ['A', 'B', 'C'];
  const lidOrBaseOptions = [LidOrBase.NA, LidOrBase.Lid, LidOrBase.Base];
  const otherChem = chemistyOptions.find(x => x.name === 'Other');

  const itemBeingEdited = itemsBeingEdited.find(
    item =>
      item.key === `${dialogName}_EDIT` &&
      subRecords &&
      subRecords.findIndex(print => print.id === item.itemId) > -1
  );

  const rowForEditing = itemBeingEdited
    ? subRecords.find(print => print.id === itemBeingEdited.itemId)
    : null;

  const isAllowedToEdit = () => {
    if (
      status === PartStatus.Locked ||
      status === PartStatus.ReadOnly ||
      status === PartStatus.Blocked
    )
      return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  const newSubmit = (values: Values, _, dispatch) => {
    const chem = chemistyOptions.find(x => x.id === values.chemistry);
    return dispatch(
      recordDepositionItem(recordId, {
        ...values,
        chemistry: chem?.name || '',
        depositionMethod: method,
      })
    );
  };

  const editSubmit = (values: Values, _, dispatch) => {
    const chem = chemistyOptions.find(x => x.id === values.chemistry);
    return dispatch(
      amendDepositionItem(recordId, {
        ...values,
        chemistry: chem?.name || '',
        depositionMethod: method,
        subRecordId: rowForEditing?.id,
      })
    );
  };

  const validation = {
    assetId: yup
      .string()
      .defined()
      .min(1),
    splits: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(splits.length),
    channels: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(channelOptions.length),
    zone: yup
      .string()
      .defined()
      .oneOf(['A', 'B', 'C']),
    chemistry: yup
      .string()
      .defined()
      .min(1),
    chemistryOther: yup
      .string()
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      .validateChemistryOther(yup.ref('chemistry'), otherChem?.id) as yup.StringSchema,
    volume: yup
      .number()
      .defined()
      .min(0.01),
    lidOrBase: yup
      .string()
      .defined()
      .oneOf([LidOrBase.NA, LidOrBase.Lid, LidOrBase.Base]),
  };

  const editValidation = {
    ...validation,
    comment: yup
      .string()
      .defined()
      .required()
      .min(5),
  };

  const createDataTableRow = (row: DepositionSubRecord, index: number): DataTableRow => ({
    key: row.id,
    fields: {
      assetNumber: { value: row.assetNumber },
      splits: { value: row.splits.join(', ') },
      channels: { value: row.channels.join(', ') },
      zone: { value: row.zone },
      chemistry: { value: row.chemistry.name },
      volume: { value: row.volume },
      lidOrBase: { value: row.lidOrBase },
      addedBy: { user: row.addedBy.user.name, date: formatShortDate(row.addedBy.date) },
      verifiedBy: {
        user: row.verifiedBy ? row.verifiedBy.user.name : null,
        date: formatShortDate(row.verifiedBy?.date),
      },
    },
    verification: {
      title: 'Verify Deposition',
      subRecordId: row.id,
      api: VerifyDepositionItemActions.CALL,
      action: verifyDepositionItem(recordId, row.id),
      dialogName: `pilot/batch-records/VERIFTY_DEPOSITION_${index}`,
      maxWidth: 'md',
      review: [
        { title: 'Asset Number', value: row.assetNumber },
        { title: 'Split(s)', value: row.splits.join(', ') },
        { title: 'Channel(s)', value: row.channels.join(', ') },
        { title: 'Zone', value: row.zone },
        { title: 'Chemistry', value: row.chemistry.name },
        { title: 'Volume (uL)', value: row.volume },
        { title: 'Lid or Base', value: row.lidOrBase },
        {
          title: 'Added By',
          value: `${row.addedBy.user.name} ${formatShortDate(row.addedBy.date)}`,
        },
      ],
    },
  });

  function getChemistryName(chem) {
    if (chemistyOptions.find(x => x.name === chem)) return '';

    return chem;
  }

  return {
    title: `${method} Deposition`,
    tableData: {
      columns: [
        { accessor: 'assetNumber', label: 'Asset Number' },
        { accessor: 'splits', label: 'Split(s)' },
        { accessor: 'channels', label: 'Channels(s)' },
        { accessor: 'zone', label: 'Zone' },
        { accessor: 'chemistry', label: 'Chemistry' },
        { accessor: 'volume', label: 'Volume' },
        { accessor: 'lidOrBase', label: 'Lid or Base' },
        { accessor: 'addedBy', label: 'Added by', isSignOff: true },
        { accessor: 'verifiedBy', label: 'Verified by', isSignOff: true, isVerification: true },
      ],
      rows: depositions ? depositions.map((row, index) => createDataTableRow(row, index)) : [],
    },
    canAddRow: status === PartStatus.Incomplete || status === PartStatus.Complete,
    canEditRows: depositions.length > 0 && isAllowedToEdit(),
    formDefinition: {
      title: `${method} Deposition`,
      dialogName,
      apiAction: RecordDepositionItemActions.CALL,
      onSubmit: rowForEditing ? editSubmit : newSubmit,
      initialValues: {
        assetId: rowForEditing
          ? assetOptions.find(asset => asset.assetNumber === rowForEditing.assetNumber)?.id ?? ''
          : '',
        splits: getInitialSplits(rowForEditing, splits),
        channels: rowForEditing ? rowForEditing.channels : [],
        zone: rowForEditing ? rowForEditing.zone : '',
        chemistry: rowForEditing ? rowForEditing.chemistry.id : '',
        chemistryOther: rowForEditing ? getChemistryName(rowForEditing.chemistry.name) : '',
        volume: rowForEditing ? rowForEditing.volume : 0,
        lidOrBase: rowForEditing ? rowForEditing.lidOrBase : null,
      },
      // eslint-disable-next-line react/prop-types
      form: ({ values: { chemistry } }) => {
        return (
          <>
            <FormikSelectField
              name="assetId"
              label="Asset"
              options={assetOptions.map(asset => ({ label: asset.assetNumber, value: asset.id }))}
            />
            <FormikCheckboxGroup
              name="splits"
              label="Splits"
              options={splits}
              labelPlacement="top"
              allowSelectAll
            />
            <br />
            <FormikCheckboxGroup
              name="channels"
              label="Channel(s)"
              options={channelOptions}
              labelPlacement="top"
            />
            <FormikSelectField
              name="zone"
              label="Zone"
              options={zoneOptions.map(zone => ({ label: zone, value: zone }))}
            />
            <FormikSelectField
              name="chemistry"
              label="Chemistry"
              options={chemistyOptions.map(chem => ({ label: chem.name, value: chem.id }))}
            />
            {chemistry === otherChem?.id && <FormikTextField name="chemistryOther" label="Other" />}
            <FormikTextField name="volume" label="Volume (uL)" />
            <FormikSelectField
              name="lidOrBase"
              label="Lid or Base"
              options={lidOrBaseOptions.map(opt => ({ label: opt, value: opt }))}
            />
            {rowForEditing && (
              <FormikTextField name="comment" label="Reason for change" multiline />
            )}
          </>
        );
      },
      validationSchema: (): yup.ObjectSchema<Values> =>
        yup
          .object()
          .shape(rowForEditing ? editValidation : validation)
          .defined(),
    },
  };
};
