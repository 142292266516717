/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useEffectOnce } from 'react-use';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Spinner } from 'modules/Common';
import { Paper } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import InstrumentResult from 'modules/InstrumentsQualityControl/features/instruments/components/InstrumentResult';
import {
  TestSessionInstrumentTestFormProps,
  TestSessionInstrumentTestForm,
} from './TestSessionInstrumentTestForm';
import TestContext from '../../testContext';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export type TestTabsProps<T> = {
  instruments: TestSessionInstrumentDto[] | undefined;
  onTestChange?: (instrument: TestSessionInstrumentDto, skipApiCall?: boolean) => Promise<unknown>;
  getOutcome: any;
  getStatus: any;
} & Pick<
  TestSessionInstrumentTestFormProps<T>,
  'form' | 'onSave' | 'onSubmit' | 'buttonText' | 'type'
> &
  Record<string, any>;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    display: 'inline-block',
    width: 191,
    minWidth: 191,
    '& .MuiTab-root': {
      '&.Mui-selected': {
        backgroundColor: theme.palette.grey[300],
      },
    },
  },
  tabPanels: {},
}));

export default function TestTabs<T>({
  instruments = [],
  onTestChange = () => Promise.resolve(),
  getOutcome,
  getStatus,
  isTestEnabled = () => true,
  ...props
}: TestTabsProps<T>) {
  const classes = useStyles();
  const [value, setValue] = React.useState<number>();

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    onTestChange(instruments[newValue]).then(() => setValue(newValue));
  };

  useEffectOnce(() => {
    if (!instruments.length) return;

    onTestChange(instruments[0], false).then(() => setValue(0));
  });

  const current = !instruments.length || value === undefined ? null : instruments[value];

  return (
    <Paper className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Test Session Tests"
        className={classes.tabs}
      >
        {instruments?.map(inst => (
          <Tab
            disabled={!isTestEnabled(inst)}
            label={
              <>
                <InstrumentResult value={getOutcome(inst) || getStatus(inst)} hideTitle>
                  {inst.instrument?.serial}
                </InstrumentResult>
              </>
            }
            {...a11yProps(1)}
          />
        )) || <Spinner />}
      </Tabs>

      <TabPanel value={value} index={value}>
        <Container className={classes.tabPanels}>
          {(current && (
            <TestContext.Provider
              value={{
                ...props,
                instrument: current,
                getStatus,
              }}
            >
              <TestSessionInstrumentTestForm
                key={current.id}
                instrument={current}
                getStatus={getStatus}
                {...props}
              />
            </TestContext.Provider>
          )) || <Spinner />}
        </Container>
      </TabPanel>
    </Paper>
  );
}
