import React from 'react';
import * as yup from 'yup';
import { Grid, Typography } from '@material-ui/core';
import { FormikValues } from 'formik';
import { FormikTextField } from '@ldx-dmp/Common';
import { FormDefinition } from '@ldx-dmp/Common/components/FormDialog/FormDialog';
import { rejectBatchRecord, RejectBatchRecordActions } from '@ldx-dmp/Pilot/BatchRecord/actions';

interface Values extends FormikValues {
  comment: string;
}

export default (recordId: string): FormDefinition<Values> => {
  const dialogName = 'pilot/batch-records/REJECT_BATCH_RECORD';
  const onSubmit = (values: Values, _, dispatch) =>
    dispatch(rejectBatchRecord(recordId, values.comment));

  return {
    title: 'Reject Batch Record',
    dialogName,
    apiAction: RejectBatchRecordActions.CALL,
    onSubmit,
    initialValues: {
      comment: '',
    },
    form: () => (
      <Grid container spacing={2}>
        <Grid item xs>
          <Typography variant="body1">
            The record will be put into a read-only state and archived in it&apos;s current form
          </Typography>
          <FormikTextField name="comment" label="Reason for rejection" multiline />
        </Grid>
      </Grid>
    ),
    validationSchema: (): yup.ObjectSchema<Values> =>
      yup
        .object()
        .shape({
          comment: yup
            .string()
            .defined()
            .required()
            .min(5),
        })
        .defined(),
  };
};
