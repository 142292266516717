/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Select, Grid, FormControl, Box, Container, InputLabel, Button } from '@material-ui/core';
import ReactJson from 'react-json-view';
import { formatDate } from 'modules/Common/utilities';
import { FetchBatchRecordActions } from 'modules/Pilot/BatchRecord/actions';
import { useDiagnostics } from '../../useDiagnostics';

type DiagnosticsPanelProps = {} & ReturnType<typeof useDiagnostics>;

export default ({
  handleChange,
  events,
  result,
  loading,
  currentVersion,
  confirmed,
  dispatch,
  setConfirm,
}: DiagnosticsPanelProps) => {
  if (!events && !result) return <Container>Loading....</Container>;

  return (
    <>
      <Box ml={5} mr={5}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <FormControl>
              <InputLabel>Events</InputLabel>
              <Select onChange={handleChange} value={currentVersion} variant="filled">
                {events?.map(({ item1: name, item2: e }, i) => (
                  <option key={e.timestamp} value={i + 1}>
                    {i + 1} - {formatDate(e.timestamp)} {name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {result && (
            <Grid item xs={12}>
              {!!result && !confirmed && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setConfirm(true);
                  }}
                >
                  Enable Time Travel
                </Button>
              )}
              {!!result && confirmed && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch({
                      type: FetchBatchRecordActions.SUCCESS,
                      payload: result,
                    });
                  }}
                >
                  Apply
                </Button>
              )}
            </Grid>
          )}
          <Grid item>
            {!!result && <ReactJson src={result} />}
            {loading && <>Loading...</>}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
