import React from 'react';
import { Field } from 'formik';
import SelectField, { SelectFieldProps } from '../SelectField';

export type FormikSelectFieldProps = SelectFieldProps & {};

const FormikSelectField = ({ name, ...rest }: FormikSelectFieldProps) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <SelectField
          name={name}
          {...field}
          value={!field.value ? '' : field.value}
          error={meta.touched && !!meta.error}
          {...rest}
        />
      )}
    </Field>
  );
};

export default FormikSelectField;
