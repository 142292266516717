import React, { useEffect } from 'react';
import { LoadingScreen } from 'modules/Common';
import authManager from '../authManager';

const LogoutPage = () => {
  const userManager = authManager.getUserManager();
  useEffect(() => {
    userManager.signoutRedirect();
  });

  return <LoadingScreen message="Signing out..." />;
};

export default LogoutPage;
