import React from 'react';
import * as yup from 'yup';
import { FormikValues } from 'formik';
import { formatShortDate } from 'modules/Common/utilities';
import { FormikCheckboxGroup, FormikTextField } from 'modules/Common/components';
import { RecordDefinition } from 'modules/Pilot/BatchRecord/SingleView/components/RecordTable/RecordTable';
import { BatchRecord, BoxOvenSubRecord } from 'modules/Pilot/BatchRecord/domain';
import { DataTableRow } from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable/TableData';
import {
  amendBoxOvenUsage,
  AmendBoxOvenUsageActions,
  recordBoxOvenUsage,
  RecordBoxOvenUsageActions,
} from 'modules/Pilot/BatchRecord/actions';
import { PartStatus } from 'modules/RecordManagement';
import { Grid } from '@material-ui/core';
import { UserPermission } from 'modules/Core/types';
import getInitialSplits from '../../initialSplits';

interface Values extends FormikValues {
  splits: number[];
  topTemperature: number;
  bottomTemperature: number;
}

export default (
  batchRecord: BatchRecord,
  ovenNumber: 1 | 2,
  itemsBeingEdited: { key: string; itemId: string }[],
  userPermissions: UserPermission[]
): RecordDefinition<Values> => {
  const dialogName = `pilot/batch-records/BOX_OVEN_${ovenNumber}`;
  const recordId = batchRecord.id;
  const { status, requiredPermissions } = batchRecord.drying.ovens;
  const subRecords = batchRecord.drying.ovens.boxOvenSubRecords.filter(
    sr => sr.ovenNumber === ovenNumber
  );
  const itemBeingEdited = itemsBeingEdited.find(
    item =>
      item.key === `${dialogName}_EDIT` &&
      subRecords &&
      subRecords.findIndex(print => print.id === item.itemId) > -1
  );

  const rowForEditing = itemBeingEdited
    ? subRecords.find(print => print.id === itemBeingEdited.itemId)
    : null;

  const { splits } = batchRecord.batchRequest;

  const isAllowedToEdit = () => {
    if (
      status === PartStatus.Locked ||
      status === PartStatus.ReadOnly ||
      status === PartStatus.Blocked
    )
      return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  const newSubmit = (values: Values, _, dispatch) =>
    dispatch(recordBoxOvenUsage(recordId, { ...values, ovenNumber }));
  const editSubmit = (values: Values, _, dispatch) =>
    dispatch(
      amendBoxOvenUsage(recordId, { ...values, ovenNumber, subRecordId: rowForEditing?.id })
    );

  const createDataTableRow = (row: BoxOvenSubRecord): DataTableRow => ({
    key: row.id,
    fields: {
      splits: { value: row.splits.join(', ') },
      topTemperature: { value: row.topTemperature },
      bottomTemperature: { value: row.bottomTemperature },
      performedBy: { user: row.performedBy.user.name, date: formatShortDate(row.performedBy.date) },
    },
  });

  const validation = {
    splits: yup
      .array()
      .of(yup.number().defined())
      .defined()
      .min(1)
      .max(splits.length),
    topTemperature: yup
      .number()
      .min(22)
      .max(55)
      .defined(),
    bottomTemperature: yup
      .number()
      .min(22)
      .max(55)
      .defined(),
  };

  const editValidation = {
    ...validation,
    comment: yup
      .string()
      .defined()
      .required()
      .min(5),
  };

  return {
    title: `Box Oven ${ovenNumber}`,
    tableData: {
      columns: [
        { accessor: 'splits', label: 'Split(s)' },
        { accessor: 'topTemperature', label: 'Top Temp °C' },
        { accessor: 'bottomTemperature', label: 'Bottom Temp °C' },
        { accessor: 'performedBy', label: 'Performed by', isSignOff: true },
      ],
      rows: subRecords ? subRecords.map(row => createDataTableRow(row)) : [],
    },
    canAddRow: status === PartStatus.Incomplete,
    canEditRows: isAllowedToEdit(),
    formDefinition: {
      title: `Box Oven ${ovenNumber}`,
      dialogName,
      apiAction: rowForEditing ? AmendBoxOvenUsageActions.CALL : RecordBoxOvenUsageActions.CALL,
      onSubmit: rowForEditing ? editSubmit : newSubmit,
      initialValues: {
        splits: getInitialSplits(rowForEditing, splits),
        topTemperature: rowForEditing ? rowForEditing.topTemperature : 0,
        bottomTemperature: rowForEditing ? rowForEditing.bottomTemperature : 0,
      },
      form: () => (
        <>
          <FormikCheckboxGroup
            name="splits"
            label="Split(s)"
            options={splits}
            labelPlacement="top"
            allowSelectAll
          />
          <Grid container spacing={2}>
            <Grid item xs>
              <FormikTextField
                name="topTemperature"
                label="Top Temperature °C"
                helperText="Between 22 and 55"
              />
            </Grid>
            <Grid item xs>
              <FormikTextField
                name="bottomTemperature"
                label="Bottom Temperature °C"
                helperText="Between 22 and 55"
              />
            </Grid>
          </Grid>
          {rowForEditing && <FormikTextField name="comment" label="Reason for change" multiline />}
        </>
      ),
      validationSchema: (): yup.ObjectSchema<Values> =>
        yup
          .object()
          .shape(rowForEditing ? editValidation : validation)
          .defined(),
    },
  };
};
