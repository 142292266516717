import React from 'react';
import PropTypes from 'prop-types';
import { CardContent } from '@material-ui/core';
import { SummaryPanel, SummaryPanelItem } from 'modules/Common';

const InstrumentSummary = ({ instrument }) => {
  return (
    <>
      <CardContent>
        <SummaryPanel>
          <SummaryPanelItem
            primary="Serial Number"
            secondary={`${instrument.serial} Rev ${instrument.version?.revision}`}
          />
          <SummaryPanelItem primary="Wireless ID" secondary={instrument.wirelessId} />
          <SummaryPanelItem
            primary="Hardware Number"
            secondary={instrument.version?.hardwareNumber}
          />
          <SummaryPanelItem primary="Type" secondary={instrument.version?.platform} />
          <SummaryPanelItem primary="Family" secondary={instrument.version?.platformVersion} />
        </SummaryPanel>
      </CardContent>
    </>
  );
};

InstrumentSummary.propTypes = {
  instrument: PropTypes.object.isRequired,
};

export default InstrumentSummary;
