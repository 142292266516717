/* eslint-disable no-param-reassign */
import AddOperatorToVisualInspectionInput from 'modules/InstrumentsQualityControl/models/AddOperatorToVisualInspectionInput';
import IActionResult from 'modules/InstrumentsQualityControl/models/IActionResult';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.title = 'Add Operators';
  sch.properties.operators.type = 'string';

  return sch;
};

export const uiSchema = ui => {
  ui.operators = { multiple: true };
  return ui;
};

export const mapToEdit = () => (current: IActionResult): AddOperatorToVisualInspectionInput => ({
  ...current,
});

export const fields = noop;

export const widgets = noop;

export const validation = () => null;

export const empty = noop;
