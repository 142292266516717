/* eslint-disable no-param-reassign */
import {
  PerformanceTestConfigurationDto,
  TestSessionInstrumentDto,
  UpdatePerformanceToInstrumentInput,
} from 'modules/InstrumentsQualityControl/models';
import { formatShortDate } from 'modules/Common/utilities';
import moment from 'moment';
import { formatBool } from '../utils';
import ConditionalField from '../widgets/ConditionalField';
import TextDisplayWidget from '../widgets/ReviewField';
import StringReviewField from '../widgets/StringReviewField';
import AlertWidget from '../widgets/AlertWidget';
import PerformanceTestLogFileWidget from '../widgets/PerformanceTestLogFileWidget';
import PerformanceTestReplicateIdWidget from '../widgets/PerformanceTestReplicateIdWidget';
import PerformanceTestTestWidget from '../widgets/PerformanceTestTestWidget';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.properties.submitComment = {
    type: 'string',
    title: 'Comment',
  };

  sch.properties.flexReworkInstrument.type = 'string';
  sch.properties.piezoMagnetCheckOutcome.properties.qualityEventRequired.type = 'string';
  sch.properties.performanceTestOutcome.properties.qualityEventRequired.type = 'string';
  sch.properties.auditLogOutcome.properties.qualityEventRequired.type = 'string';
  sch.properties.releasePreparation.properties.anyErrorsDuringInstrumentBootUpAfterFactoryReset.type =
    'string';

  return sch;
};

export const uiSchema = ui => {
  ui['ui:order'] = [
    'submitComment',
    '*',
    'logFile',
    'piezoMagnetCheckOutcome',
    'auditLogOutcome',
    'plaqueCalibration',
    'performanceTestOutcome',
    'releasePreparation',
  ];

  ui.submitComment = {
    'ui:field': ConditionalField,
    predicate: data => !!data.submitComment,
    'ui:widget': AlertWidget,
    'ui:options': {
      severity: 'warning',
    },
  };

  ui.instrumentId = {
    'ui:widget': 'hidden',
  };
  ui.flexReworkInstrument = {
    'ui:widget': StringReviewField,
    format: formatBool,
  };

  ui.piezoMagnetCheckOutcome = {
    'ui:card': true,
    outcome: {
      'ui:grid:xs': 6,
      'ui:field': TextDisplayWidget,
    },
    qualityEventRequired: {
      'ui:grid:xs': 6,
      format: formatBool,
      'ui:widget': StringReviewField,
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      'ui:widget': StringReviewField,
      predicate: values =>
        values.piezoMagnetCheckOutcome?.qualityEventRequired?.toString() === 'true',
    },
    hasQualityEvents: {
      'ui:widget': 'hidden',
    },
  };

  ui.tests = {
    'ui:card': true,
    'ui:field': PerformanceTestTestWidget,
    'ui:options': {
      addable: false,
      removable: false,
      orderable: false,
    },
    items: {
      'ui:card': false,
      'ui:grid:xs': 12,
      'ui:order': [
        'anyErrorsDuringInstrumentBootUp',
        'errorCodes',
        'comments',
        'replicateCount',
        '*',
      ],
      anyErrorsDuringInstrumentBootUp: {
        'ui:field': StringReviewField,
        format: formatBool,
      },
      errorCodes: {
        items: {
          'ui:widget': StringReviewField,
        },
        'ui:options': {
          addable: false,
          removable: false,
          orderable: false,
        },
        'ui:helpText': 'Outside of testing',
        'ui:description': 'Outside of testing',
        'ui:field': ConditionalField,
      },
      comments: {},
      replicates: {
        'ui:field': PerformanceTestReplicateIdWidget,
        'ui:options': {
          readOnly: true,
        },
      },
      status: {
        'ui:widget': TextDisplayWidget,
      },
    },
  };

  ui.performanceTestOutcome = {
    'ui:card': true,
    qualityEventRequired: {
      format: formatBool,
      'ui:widget': StringReviewField,
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      'ui:widget': StringReviewField,
      predicate: values =>
        values.performanceTestOutcome?.qualityEventRequired?.toString() === 'true',
    },
    comments: {
      'ui:field': ConditionalField,
      'ui:widget': StringReviewField,
      predicate: values =>
        values.performanceTestOutcome?.qualityEventRequired?.toString() === 'true',
    },
    plannedDeviationNumbers: {
      'ui:field': ConditionalField,
      'ui:widget': StringReviewField,
      'ui:options': {
        addable: false,
        removable: false,
        orderable: false,
      },
      items: {
        'ui:widget': StringReviewField,
      },
      predicate: values =>
        values.performanceTestOutcome?.qualityEventRequired?.toString() === 'true',
    },
    hasQualityEvents: {
      'ui:widget': 'hidden',
    },
  };

  ui.auditLogOutcome = {
    'ui:card': true,
    firstMagnetSelfTestAdcValue: {
      'ui:grid:xs': 5,
      'ui:widget': StringReviewField,
    },
    lastMagnetSelfTestAdcValue: {
      'ui:grid:xs': 5,
      'ui:widget': StringReviewField,
    },
    qualityEventRequired: {
      'ui:widget': StringReviewField,
      format: formatBool,
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      'ui:widget': StringReviewField,
      predicate: values => values.auditLogOutcome?.qualityEventRequired?.toString() === 'true',
    },
    comments: {
      'ui:field': ConditionalField,
      'ui:widget': StringReviewField,
      predicate: values => values.auditLogOutcome?.qualityEventRequired?.toString() === 'true',
    },
    plannedDeviationNumbers: {
      'ui:field': ConditionalField,
      'ui:widget': StringReviewField,
      'ui:options': {
        addable: false,
        removable: false,
        orderable: false,
      },
      items: {
        'ui:widget': StringReviewField,
      },
      predicate: values => values.auditLogOutcome?.plannedDeviationNumbers?.length,
    },
    difference: {
      'ui:grid:xs': 2,
      'ui:widget': StringReviewField,
    },
    outcome: {
      'ui:widget': TextDisplayWidget,
    },
  };

  ui.releasePreparation = {
    'ui:card': true,
    factoryResetDate: { 'ui:widget': TextDisplayWidget, format: value => formatShortDate(value) },
    anyErrorsDuringInstrumentBootUpAfterFactoryReset: {
      'ui:widget': StringReviewField,
      format: formatBool,
    },
    errorCodes: {
      items: {
        'ui:widget': StringReviewField,
      },
      'ui:options': {
        addable: false,
        removable: false,
        orderable: false,
      },
      'ui:helpText': 'Outside of testing',
      'ui:description': 'Outside of testing',
      'ui:field': ConditionalField,
      predicate: values =>
        values?.releasePreparation?.anyErrorsDuringInstrumentBootUpAfterFactoryReset?.toString() ===
        'true',
    },
    comments: {
      'ui:field': ConditionalField,
      'ui:widget': StringReviewField,
      predicate: values =>
        values?.releasePreparation?.anyErrorsDuringInstrumentBootUpAfterFactoryReset?.toString() ===
        'true',
    },
    decontaminationDate: {
      'ui:widget': TextDisplayWidget,
      format: value => formatShortDate(value),
    },
    cleanDate: { 'ui:widget': TextDisplayWidget, format: value => formatShortDate(value) },
    carriedOutBy: {
      'ui:widget': 'hidden',
    },
    verifiedBy: {
      'ui:widget': 'hidden',
    },
  };

  ui.logFile = {
    'ui:field': PerformanceTestLogFileWidget,
    'ui:options': {
      readOnly: true,
    },
  };

  ui.plaqueCalibration = {
    'ui:card': true,
    calibrationValue: {
      'ui:grid:xs': 4,
      'ui:widget': StringReviewField,
      format: value => value,
    },
    meanDifferenceValue: {
      'ui:grid:xs': 4,
      'ui:widget': StringReviewField,
      format: value => value,
    },
    biasValue: {
      'ui:grid:xs': 4,
      'ui:widget': StringReviewField,
      format: value => value,
    },
  };

  return ui;
};

export const mapToEdit = () => (
  current: TestSessionInstrumentDto,
  config?: PerformanceTestConfigurationDto
): UpdatePerformanceToInstrumentInput => {
  const performanceTest = current?.performanceTest || {};
  return {
    ...performanceTest,
    tests: config?.performanceTestTypeAndSamples?.map(x => {
      return {
        ...x,
        ...(performanceTest?.tests?.find(t => t.id === x.id) || {}),
      };
    }),
    instrumentId: current?.id,
  } as UpdatePerformanceToInstrumentInput;
};

export const fields = noop;

export const widgets = noop;

export const validation = () => null;

export const empty = initial => ({
  ...initial,
  releasePreparation: {
    factoryResetDate: moment(),
    decontaminationDate: moment(),
    cleanDate: moment(),
  },
});
