import React from 'react';
import { useSelector } from 'react-redux';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { getUserId, getUserPermissions } from 'modules/Core/reducers/selectors';
import BatchComments from './BatchComments';
import RecordProgress from './RecordProgress/RecordProgress';
import SignOffTable from '../SignOffTable';
import BatchRequest from './BatchRequest';
import useBatchCompletionDefinition from './batchCompletionDefinition';
import useManufacturingSignOffDefinition from './manufacturingSignOffDefinition';

type SignOffPanelProps = {
  batchRecord: BatchRecord;
  readOnly?: boolean;
};

const SignOffPanel = ({ batchRecord, readOnly = false }: SignOffPanelProps) => {
  const userPermissions = useSelector(getUserPermissions);
  const userId = useSelector(getUserId);
  const batchCompletion = useBatchCompletionDefinition(batchRecord, userId, userPermissions);
  const manufacturing = useManufacturingSignOffDefinition(batchRecord, userPermissions);

  return (
    <>
      <RecordProgress batchRecord={batchRecord} />
      <BatchComments
        recordId={batchRecord.id}
        comments={batchRecord.comments}
        readOnly={readOnly}
      />
      <SignOffTable
        definition={batchCompletion}
        status={batchRecord.signOff.batchCompletion.status}
        xs={6}
      />
      <BatchRequest batchRecord={batchRecord} />
      <SignOffTable
        definition={manufacturing}
        status={batchRecord.signOff.manufacturing.status}
        xs={12}
        withTime
      />
    </>
  );
};

export default SignOffPanel;
