import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BatchRecord, Sections } from 'modules/Pilot/BatchRecord/domain';
import {
  getItemsBeingEdited,
  getUserId,
  getUserPermissions,
} from 'modules/Core/reducers/selectors';
import { Grid } from '@material-ui/core';
import SignOffTable from '../SignOffTable';
import RecordTable from '../RecordTable/RecordTable';
import makeBaseAssemblyDefinition from './baseAssemblyDefinition';
import makeFinalLaiminationDefinition from './finalLaminationDefinition';
import makeSingulationDefinition from './singulationDefinition';
import CommentsTable from '../CommentsTable';

type PostDryingPanelProps = {
  batchRecord: BatchRecord;
  readOnly?: boolean;
};

const PostDryingPanel = ({ batchRecord, readOnly = false }: PostDryingPanelProps) => {
  const userPermissions = useSelector(getUserPermissions);
  const itemsBeingEdited = useSelector(getItemsBeingEdited);
  const userId = useSelector(getUserId);

  const baseAssemblyDefinition = useMemo(
    () => makeBaseAssemblyDefinition(batchRecord, userId, userPermissions),
    [batchRecord, userId, userPermissions]
  );
  const laminationDefinition = useMemo(
    () => makeFinalLaiminationDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );
  const singulationDefinition = useMemo(
    () => makeSingulationDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );

  return (
    <>
      <SignOffTable
        definition={baseAssemblyDefinition}
        status={batchRecord.postDrying.baseAssembly.status}
        xs={6}
      />
      <Grid item xs={6} />
      <RecordTable
        definition={laminationDefinition}
        status={batchRecord.postDrying.laminations.status}
        xs={6}
      />
      <RecordTable
        definition={singulationDefinition}
        status={batchRecord.postDrying.singulation.status}
        xs={6}
      />
      <CommentsTable
        recordId={batchRecord.id}
        section={Sections.PostDrying}
        comments={batchRecord.comments}
        readOnly={readOnly}
      />
    </>
  );
};

export default PostDryingPanel;
