/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import isFunction from 'lodash/isFunction';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Card, CardContent } from '@material-ui/core';

import { ObjectFieldTemplateProps } from '@rjsf/core';

import { Alert } from '@material-ui/lab';

const Content = ({ disabled, uiSchema, registry, ...props }: ObjectFieldTemplateProps & any) => {
  const { values } = useFormikContext();

  if (uiSchema['ui:widget'] === 'hidden') return <></>;

  function calcIsDisabled() {
    if (uiSchema['ui:disable']) {
      if (isFunction(uiSchema['ui:disable'])) {
        return uiSchema['ui:disable'](values);
      }

      return uiSchema['ui:disable'];
    }
    return false;
  }

  const isDisabled = calcIsDisabled();

  const { SchemaField } = registry.fields;
  const result = (
    <>
      <SchemaField uiSchema={uiSchema} idPrefix="" registry={registry} {...props} />
    </>
  );

  if (!isDisabled) {
    return result;
  }

  return (
    <fieldset
      disabled
      style={{
        border: 'none',
        padding: 0,
        margin: 0,
        opacity: 0.4,
      }}
    >
      {result}
    </fieldset>
  );
};

const CaseDefectAlert = () => (
  <Grid item xs>
    <Alert severity="error">Testing can not proceed due to case defect</Alert>
  </Grid>
);

const ObjectFieldTemplate = ({ uiSchema, formData, ...props }: ObjectFieldTemplateProps) => {
  if (uiSchema['ui:widget'] === 'hidden') return <></>;

  const showAlert = Object.values(formData)
    .map((x: any) => x?.toString())
    .some(x => x === 'false');

  if (uiSchema['ui:card']) {
    return (
      <Card>
        <CardContent>
          <Content
            uiSchema={{
              ...uiSchema,
              'ui:field': undefined,
              'ui:card': false,
            }}
            formData={formData}
            {...props}
          />
          {showAlert && <CaseDefectAlert />}
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Content
        uiSchema={{
          ...uiSchema,
          'ui:field': undefined,
        }}
        formData={formData}
        {...props}
      />
      {showAlert && <CaseDefectAlert />}
    </>
  );
};

export default ObjectFieldTemplate;
