import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { assignRepairRequest as assignRepairAction } from 'modules/Instruments/slices/Repair/actions';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './AssignRequestForm.styles';

const AssignRequestForm = ({
  show = false,
  handleClose,
  assignedTo,
  requestId,
  assignRepair,
  technicianUsers,
}) => {
  const classes = makeStyles(styles)();
  const [formState, setFormState] = useState({
    assignedTo,
  });

  const handleAssignedToChange = event => {
    setFormState({
      assignedTo: event.target.value,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    assignRepair(requestId, formState.assignedTo.id);

    handleClose();
  };

  return (
    <>
      <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Assign Request</DialogTitle>
        <DialogContent>
          <form className={classes.root} onSubmit={handleSubmit}>
            <FormControl className={classes.select}>
              <InputLabel id="assignedTo-label">Assign To</InputLabel>
              <Select
                id="assignedTo"
                labelId="assignedTo-label"
                value={formState.assignedTo}
                onChange={handleAssignedToChange}
              >
                {technicianUsers.map(user => (
                  <MenuItem key={user.id} value={user}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit} color="primary" variant="contained">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const actionCreators = {
  assignRepair: assignRepairAction,
};

AssignRequestForm.defaultProps = {
  assignedTo: null,
};

AssignRequestForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  technicianUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
    })
  ).isRequired,
  assignedTo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }),
  requestId: PropTypes.string.isRequired,
  assignRepair: PropTypes.func.isRequired,
};

export default connect(null, actionCreators)(AssignRequestForm);
