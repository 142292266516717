/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './createTestSessionInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      maxAllowedInstruments: {
        type: 'number',
        title: 'Max Allowed Instruments',
      },
      platform: {
        type: 'string',
        title: 'Platform',
      },
      location: {
        type: 'string',
        title: 'Location',
      },
      scheduledRunDate: {
        type: 'string',
        title: 'Scheduled Run Date',
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  maxAllowedInstruments: 0,
  platform: '',
  location: '',
  scheduledRunDate: '',
});
