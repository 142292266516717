/* istanbul ignore file */
import produce from 'immer';

import * as overrides from './addTestAndSampleTypeInputFormSchema.overrides';

export const schema = produce(
  {
    type: 'object',
    properties: {
      testType: {
        type: 'string',
        title: 'Test Type',
      },
      sampleType: {
        type: 'string',
        title: 'Sample Type',
      },
    },
  },
  overrides.schema
);

export const uiSchema = produce({}, overrides.uiSchema);

export const mapToEdit = overrides.mapToEdit();

export const fields = produce({}, overrides.fields);

export const widgets = produce({}, overrides.widgets);

export const validation = overrides.validation();

export const empty = overrides.empty({
  testType: '',
  sampleType: '',
});
