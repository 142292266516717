import { apiPatch } from 'modules/Core/actions';

export enum SignOffSectionActions {
  CALL = 'pilot/batch-records/SIGN_OFF_SECTION',
  REQUEST = 'pilot/batch-records/SIGN_OFF_SECTION_REQUEST',
  SUCCESS = 'pilot/batch-records/SIGN_OFF_SECTION_SUCCESS',
  FAILURE = 'pilot/batch-records/SIGN_OFF_SECTION_FAILURE',
}

export enum UnlockSectionActions {
  CALL = 'pilot/batch-records/UNLOCK_SECTION',
  REQUEST = 'pilot/batch-records/UNLOCK_SECTION_REQUEST',
  SUCCESS = 'pilot/batch-records/UNLOCK_SECTION_SUCCESS',
  FAILURE = 'pilot/batch-records/UNLOCK_SECTION_FAILURE',
}

export const signOffSection = (recordId: string, sectionName: string) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/${sectionName}/signoff`,
    SignOffSectionActions.CALL,
    {}
  );

export const forceSignOffSection = (recordId: string, sectionName: string) =>
  apiPatch(`/pilot/batch-records/${recordId}/${sectionName}/signoff`, SignOffSectionActions.CALL, {
    force: true,
  });

export const unlockSection = (recordId: string, sectionName: string, comment: string) =>
  apiPatch(`/pilot/batch-records/${recordId}/${sectionName}/unlock`, UnlockSectionActions.CALL, {
    comment,
  });
