import { apiPatch } from 'modules/Core/actions';

export enum GiveLineClearanceActions {
  CALL = 'pilot/batch-records/GIVE_LINE_CLEARANCE',
  REQUEST = 'pilot/batch-records/GIVE_LINE_CLEARANCE_REQUEST',
  SUCCESS = 'pilot/batch-records/GIVE_LINE_CLEARANCE_SUCCESS',
  FAILURE = 'pilot/batch-records/GIVE_LINE_CLEARANCE_FAILURE',
}

export const giveLineClearance = (recordId: string) =>
  apiPatch(`/pilot/batch-records/${recordId}/line-clearance`, GiveLineClearanceActions.CALL, {});
