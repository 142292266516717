/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import isFunction from 'lodash/isFunction';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Card, CardContent, makeStyles } from '@material-ui/core';

import { ObjectFieldTemplateProps, utils } from '@rjsf/core';

import AddButton from '../../NewItemButton';

const { canExpand } = utils;

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
});

const NormalProperties = ({ properties, uiSchema, values, idSchema }: any) => (
  <>
    {properties.map((element: any, index: number) => {
      const curentUiSchema = uiSchema[element.name] || {};
      if (curentUiSchema['ui:widget'] === 'hidden') return <></>;

      if (curentUiSchema.predicate && !curentUiSchema.predicate(values)) {
        return <></>;
      }

      return (
        <Grid
          data-testid={idSchema[element.name]?.$id}
          item
          xs={curentUiSchema['ui:grid:xs'] || 12}
          key={`${index}_${element.name}`}
          style={{ marginBottom: '10px' }}
        >
          {element.content}
        </Grid>
      );
    })}
  </>
);

const Content = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  disabled,
  readonly,
  uiSchema,
  idSchema,
  schema,
  formData,
  onAddClick,
}: ObjectFieldTemplateProps) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  if (uiSchema['ui:widget'] === 'hidden') return <></>;

  function calcIsDisabled() {
    if (uiSchema['ui:disable']) {
      if (isFunction(uiSchema['ui:disable'])) {
        return uiSchema['ui:disable'](values);
      }

      return uiSchema['ui:disable'];
    }
    return false;
  }

  const isDisabled = calcIsDisabled();

  const result = (
    <>
      {(uiSchema['ui:title'] || title) && !uiSchema['ui:hideTitle'] && (
        <TitleField id={`${idSchema.$id}-title`} title={title} required={required} />
      )}
      {description && (
        <DescriptionField id={`${idSchema.$id}-description`} description={description} />
      )}
      {!uiSchema['ui:collapse'] && (
        <Grid container spacing={2} className={classes.root}>
          <NormalProperties
            properties={properties}
            uiSchema={uiSchema}
            values={values}
            idSchema={idSchema}
          />

          {canExpand(schema, uiSchema, formData) && (
            <Grid container justify="flex-end">
              <Grid item>
                <AddButton
                  className="object-property-expand"
                  onClick={onAddClick(schema)}
                  disabled={disabled || readonly}
                  text="+"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {uiSchema['ui:collapse'] && (
        <>
          <NormalProperties
            properties={properties}
            uiSchema={uiSchema}
            values={values}
            idSchema={idSchema}
          />
          {canExpand(schema, uiSchema, formData) && (
            <Grid container justify="flex-end">
              <Grid item>
                <AddButton
                  className="object-property-expand"
                  onClick={onAddClick(schema)}
                  disabled={disabled || readonly}
                  text="+"
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );

  if (!isDisabled) {
    return result;
  }

  return (
    <fieldset
      disabled
      style={{
        border: 'none',
        padding: 0,
        margin: 0,
        opacity: 0.4,
      }}
    >
      {result}
    </fieldset>
  );
};

const ObjectFieldTemplate = ({ uiSchema, ...props }: ObjectFieldTemplateProps) => {
  if (uiSchema['ui:widget'] === 'hidden') return <></>;

  if (uiSchema['ui:card']) {
    return (
      <Card>
        <CardContent>
          <Content uiSchema={uiSchema} {...props} />
        </CardContent>
      </Card>
    );
  }

  return <Content uiSchema={uiSchema} {...props} />;
};

export default ObjectFieldTemplate;
