import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  oppositeContent: {
    flex: 0.2,
  },
  root: {
    '& .MuiTimelineDot-root': {
      marginTop: '25px',
      marginBottom: '8px',
    },
    '& .MuiTimelineItem-oppositeContent': {
      flex: 0.25,
      padding: '18px',
    },
    '& .eventHeader': {
      display: 'flex',
      padding: theme.spacing(1),
      backgroundColor: '#bde3f2',
      '& .MuiTypography-h6': {
        fontWeight: 'bold',
      },
      '& .MuiTypography-subtitle1': {
        marginLeft: theme.spacing(1),
        marginTop: '2px',
      },
    },
  },
}));
