import axios from 'axios';
import { getOidcUser } from 'modules/Auth/reducers/selectors';

export default function(store) {
  axios.interceptors.request.use(function addToken(config) {
    const token = getOidcUser(store.getState())?.access_token;

    if (!token) return config;

    const result = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
      validateStatus(status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    };

    return result;
  });
}
