export default theme => ({
  root: {
    padding: theme.spacing(3),
  },
  list: {
    position: 'relative',
    padding: '0',
    margin: '0',

    '&:before': {
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      display: 'block',
      content: '" "',
      width: '1px',
      height: '100%',
      top: '0',
      bottom: '0',
      left: '3px',
    },
  },
});
