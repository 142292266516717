import axios, { AxiosResponse } from 'axios';

import UpdateLocationInput from 'modules/Global/models/UpdateLocationInput';

export default function updateAsync(apiUrl: string) {
  return function api<TResponse>(
    id: string | undefined | null,
    input: UpdateLocationInput | undefined | null
  ): Promise<AxiosResponse<TResponse>> {
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      axios
        .request<TResponse>({
          url: `${apiUrl}/api/app/locations/${id}`,
          method: 'PUT',
          data: input,
        })
        .then(response => {
          if (response.status === 400) {
            reject(response);
            return;
          }
          resolve(response);
        }, reject);
    });
  };
}
