import React, { ReactNode } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { SummaryPanel, SummaryPanelItem } from '../SummaryPanel';
import useStyles from './RecordCard.styles';

type RecordCardProps = {
  summary?: {
    key: string;
    primary?: ReactNode;
    secondary?: ReactNode;
  }[];
  children?: ReactNode;
};

const RecordCard = ({ summary, children }: RecordCardProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        {summary && (
          <SummaryPanel>
            {summary.map(item => (
              <SummaryPanelItem key={item.key} primary={item.primary} secondary={item.secondary} />
            ))}
          </SummaryPanel>
        )}
        {children}
      </CardContent>
    </Card>
  );
};

export default RecordCard;
