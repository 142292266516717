import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    '& > .MuiGrid-container': {
      width: '100%',
      padding: 0,
    },
  },
}));
