import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';

const SelectedInstrumentsToolbar = () => (
  <div style={{ marginRight: '8px' }}>
    <ButtonGroup color="primary" aria-label="outlined primary button group">
      <Button color="primary" variant="contained">
        Move to Test Session
      </Button>
      <Button color="primary" variant="contained">
        Create new Test Session
      </Button>
      <Button color="primary" variant="contained">
        Update GRN
      </Button>
    </ButtonGroup>
  </div>
);

export default SelectedInstrumentsToolbar;
