/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InstrumentSubsystemDto, InstrumentVersionDto } from 'modules/Global/models';
import { InstrumentDto } from 'modules/Instruments/models';

export type SerialSearchResult = {
  instrument?: InstrumentDto;
  version?: InstrumentVersionDto[];
  subsystems?: InstrumentSubsystemDto[];
  loading?: boolean;
};

interface InstrumentSerialSearchState {
  state: 'idle' | 'loading' | 'loaded' | 'error';
  result?: SerialSearchResult;
}

const initialState = {
  state: 'idle',
} as InstrumentSerialSearchState;

const instrumentSerialSearchSlice = createSlice({
  name: 'instrumentSerialSearch',
  initialState,
  reducers: {
    recieveSearch(state, { payload: result }: PayloadAction<SerialSearchResult>) {
      state.result = { ...result, loading: false };
      state.state = 'loaded';

      return state;
    },
    startSearch(state) {
      state.state = 'loading';
      state.result = {
        loading: true,
      };

      return state;
    },
  },
});

export const { recieveSearch, startSearch } = instrumentSerialSearchSlice.actions;
export default instrumentSerialSearchSlice.reducer;
