import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { WidgetProps, FieldProps } from '@rjsf/core';
import { useServer } from 'modules/Global/instrumentVersions';
import { useEffectOnce } from 'react-use';
import { PagedResultDto } from 'modules/Core';
import { InstrumentVersionDto } from 'modules/Global/models';

export default ({ registry, uiSchema, ...props }: WidgetProps & FieldProps) => {
  const [values, setValues] = React.useState<{ value: string | number; label: string }[]>([]);
  const server = useServer();
  const { SelectWidget } = registry.widgets;

  useEffectOnce(() => {
    server
      .getListAsync<PagedResultDto<InstrumentVersionDto>>(0, 1000, 'platform')
      .then(response => {
        const items = uniqBy(response?.data?.items || [], x => x.platform);
        return setValues(
          items.map(x => ({
            value: String(x.platform),
            label: String(x.platform),
          })) || []
        );
      });
  });

  return (
    <SelectWidget
      {...props}
      uiSchema={uiSchema}
      options={{
        enumOptions: values || [],
      }}
    />
  );
};
