import React from 'react';
import produce from 'immer';
import { FeedbackFieldTemplate } from 'modules/Common/components';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import { InstrumentsQualityControlContextType } from 'modules/InstrumentsQualityControl/context';
import * as form from '../forms/PerformanceTest/updatePerformanceToInstrumentInputFormSchema';
import * as gridForm from '../forms/PerformanceTest/updatePerformanceToInstrumentInputFormSchema.grid.overrides';
import * as reviewForm from '../forms/PerformanceTest/reviewPerformanceToInstrumentInputFormSchema.overrides';
import * as signedOffForm from '../forms/PerformanceTest/signedOffPerformanceToInstrumentInputFormSchema.overrides';
import useInstrumentTest from './useInstrumentTest';
import useServer from './useServer';
import usePermissionStates from './usePermissionStates';

export default function usePerformanceTest(
  current: InstrumentsQualityControlContextType['current'],
  setCurrent: InstrumentsQualityControlContextType['setCurrent'],
  fetchCurrent: InstrumentsQualityControlContextType['fetchCurrent'],
  initialInstrument?: TestSessionInstrumentDto
) {
  const server = useServer();

  const [showReview, setShowReview] = React.useState(false);
  const [showSignOff, setShowSignOff] = React.useState(false);
  const [showCorrections, setShowCorrections] = React.useState(false);
  const [hideForm, setHideForm] = React.useState(() => () => {});
  const [setInstrument, setSetInstrument] = React.useState(() => () => {});
  const [test, setTest] = React.useState<TestSessionInstrumentDto | undefined>();

  const onReview = React.useCallback((t, hide, set) => {
    setHideForm(() => value => {
      hide(value);
    });
    setSetInstrument(() => (value, sess) => set(value, sess));
    setShowReview(true);
    setTest(t);
  }, []);

  const onSignOff = React.useCallback(
    data => {
      return new Promise((resolve, reject) => {
        server.patchSignOff(current?.id, 'performance-test', data).then(response => {
          resolve(response.data);
        }, reject);
      });
    },
    [current?.id, server]
  );

  const readyForReviewState = React.useMemo(
    () => ({
      form: () => ({
        ...form,
        schema: produce(form.schema, reviewForm.schema),
        validation: reviewForm.validation(),
        mapToEdit: reviewForm.mapToEdit(),
        uiSchema: reviewForm.uiSchema({}),
      }),
    }),
    []
  );
  const signedOffState = React.useMemo(
    () => ({
      form: () => ({
        ...form,
        schema: produce(form.schema, signedOffForm.schema),
        validation: reviewForm.validation(),
        mapToEdit: signedOffForm.mapToEdit(),
        uiSchema: signedOffForm.uiSchema({}),
      }),
    }),
    []
  );

  const inProgressState = React.useMemo(
    () => ({
      form: () => ({
        ...form,
        uiSchema: gridForm.uiSchema({}),
      }),
    }),
    []
  );

  const options = React.useMemo(
    () => ({
      showReview,
      showSignOff,
      showCorrections,
      toggleForm: hideForm,
      setShowReview,
      setTest,
      test,
      setInstrument,
      setShowSignOff,
      onSignOff,
      onReview,
      setShowCorrections,

      testEnabled: i => i?.performanceTestEnabled,
      getStatus: values => values?.performanceTest?.status,
      getOutcome: values => values?.performanceTest?.outcome,
      states: {
        Pending: {
          ...inProgressState,
          autoSaveEnabled: true,
        },
        InProgress: {
          ...inProgressState,
          autoSaveEnabled: true,
        },
        ReadyForReview: {
          ...readyForReviewState,
          autoSaveEnabled: false,
          buttonText: 'Submit Review',
        },
        ReadOnly: {
          ...signedOffState,
          hideButton: true,
          autoSaveEnabled: false,
          name: 'ReadOnly',
        },
        Reviewed: {
          ...signedOffState,
          onSubmit: (_data, t, hide, set) => {
            setHideForm(() => value => {
              hide(value);
            });
            setSetInstrument(() => (value, sess) => set(value, sess));
            setTest(t);
            setShowSignOff(true);
            return Promise.resolve();
          },
          buttonText: 'Sign Off',
          autoSaveEnabled: false,
        },
        CorrectionsRequired: {
          ...inProgressState,
          autoSaveEnabled: true,
          fieldTemplate: FeedbackFieldTemplate,
          onSubmit: (data, t, hide, set) => {
            setHideForm(() => value => {
              hide(value);
            });
            setSetInstrument(() => (value, sess) => set(value, sess));
            setTest({
              ...t,
              performanceTest: {
                ...t.performanceTest,
                ...data,
              },
            });
            setShowCorrections(true);
            return Promise.resolve();
          },
        },
        SignedOff: { hideButton: true, autoSaveEnabled: false, ...signedOffState },
      },
    }),
    [
      hideForm,
      inProgressState,
      onReview,
      onSignOff,
      readyForReviewState,
      setInstrument,
      showCorrections,
      showReview,
      showSignOff,
      signedOffState,
      test,
    ]
  );

  const currentId = '';
  const { states, currentInstrument, setCurrentInstrument, ...instrumentTest } = useInstrumentTest(
    current,
    setCurrent,
    'performance-test',
    fetchCurrent,
    options.getStatus,
    options.getOutcome,
    currentId,
    options.onReview,
    options.states,
    undefined,
    options.testEnabled,
    usePermissionStates(current?.permissions),
    i => i?.performanceTest?.permissions,
    initialInstrument
  );

  const generateReplicateId = React.useCallback(
    data => {
      server
        .patchGenrateTestReplicateId(current?.id, {
          ...data,
          instrumentId: currentInstrument?.id,
        })
        .then(response => {
          setCurrent(response.data);

          const updated = response.data?.instruments?.find(
            x => x.instrument?.id === currentInstrument?.id
          ) as TestSessionInstrumentDto;

          setCurrentInstrument(updated);
        });
    },
    [current?.id, currentInstrument?.id, server, setCurrent, setCurrentInstrument]
  );

  return React.useMemo(
    () => ({
      ...options,
      ...instrumentTest,
      submitReview: states.SubmitReview.onSubmit,
      states,
      getTest: i => i?.performanceTest,
      generateReplicateId,
      currentInstrument,
      setCurrentInstrument,
    }),
    [instrumentTest, options, states, generateReplicateId, currentInstrument, setCurrentInstrument]
  );
}
