/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { useAutoSave } from 'modules/AutoSave/useAutoSave';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import useServer from './useServer';

export default function useInstrumentTest(
  current,
  setCurrent,
  type,
  fetchCurrent,
  getStatus: (d: TestSessionInstrumentDto | undefined) => string | undefined,
  getOutcome: (d: TestSessionInstrumentDto | undefined) => string | undefined,
  currentId: string | undefined | null = undefined,
  onReview: any,
  api = {},
  nextButtonText = 'Complete',
  checkTestEnabled: (i: TestSessionInstrumentDto | undefined) => boolean = () => true,
  applyPermissions: any = a => a,
  getInstrumentPermissions: any = a => a,
  initialInstrument?: TestSessionInstrumentDto
) {
  const { flush } = useAutoSave();

  const [instruments, setInstruments] = React.useState(current.instruments);
  const [currentInstrument, setCurrentInstrument] = React.useState<TestSessionInstrumentDto>(
    initialInstrument as TestSessionInstrumentDto
  );
  const [currentStatus, setCurrentStatus] = React.useState<string | undefined>('');
  const [currentOutcome, setCurrentOutcome] = React.useState<string | undefined>('');
  const [hideForm, setHideForm] = React.useState(false);

  const server = useServer();

  const handleResponse = React.useCallback(
    (data, response) => {
      if (setCurrent) setCurrent(response.data);

      setInstruments(response.data.instruments);
      const updated = response.data?.instruments?.find(
        x => x.instrument?.id === data?.instrumentId
      ) as TestSessionInstrumentDto;
      setCurrentInstrument(v => {
        return updated || v;
      });

      return { updated };
    },
    [setCurrent]
  );

  const submitReviewState = React.useMemo(
    () => ({
      name: 'SubmitReview',
      autoSaveEnabled: false,
      onSubmit: data =>
        new Promise((resolve, reject) => {
          server.patchReview(current?.id, type, data).then(response => {
            setHideForm(true);
            handleResponse(data, response);
            resolve(response.data);
            setHideForm(false);
          }, reject);
        }),
    }),
    [current?.id, handleResponse, server, type]
  );

  const inReviewState = React.useMemo(
    () => ({
      name: 'ReadyForReview',
      autoSaveEnabled: false,
      onSubmit: data => {
        // eslint-disable-next-line no-debugger
        const review = {
          ...data.review,
        };
        delete review.instrumentId;

        const hasComments = Object.values(review || {}).filter(x => !isEmpty(x))?.length > 0;

        if (!hasComments) {
          onReview(currentInstrument, setHideForm, (updated, session) => {
            setCurrentInstrument(v => {
              return updated || v;
            });

            setInstruments(session.instruments);
          });
          return Promise.resolve({
            data: current,
          });
        }

        return submitReviewState.onSubmit(data);
      },
    }),
    [current, currentInstrument, onReview, submitReviewState]
  );

  const inProgressState = React.useMemo(
    () => ({
      name: 'InProgress',
      autoSaveEnabled: true,
      onSave: async data => {
        const response = await server.patchUpdate(current?.id, type, data);
        const updated = response?.data?.instruments?.find(
          x => x.id === currentInstrument?.id
        ) as TestSessionInstrumentDto;

        if (getOutcome(updated) !== getOutcome(currentInstrument)) {
          setCurrentInstrument(updated);
          setInstruments(response.data.instruments);
        }

        setCurrentOutcome(getOutcome(updated));
        setCurrentStatus(getStatus(updated));

        return updated;
      },
      onSubmit: async data => {
        await flush(currentInstrument?.id);
        const response = await server.patchSubmit(current?.id, type, data);
        setHideForm(true);
        const { updated } = handleResponse(data, response);
        setHideForm(false);

        return updated;
      },
    }),
    [current?.id, currentInstrument, flush, getOutcome, getStatus, handleResponse, server, type]
  );

  const finalApi = React.useMemo(() => {
    const session = applyPermissions(
      getInstrumentPermissions(currentInstrument),
      merge(
        {},
        {
          Pending: {
            ...inProgressState,
            name: 'Pending',
          },
          InProgress: inProgressState,
          ReadyForReview: inReviewState,
          SubmitReview: submitReviewState,
          CorrectionsRequired: {
            ...inProgressState,
            name: 'CorrectionsRequired',
          },
        },
        api
      )
    );
    return session;
  }, [
    api,
    applyPermissions,
    currentInstrument,
    getInstrumentPermissions,
    inProgressState,
    inReviewState,
    submitReviewState,
  ]);

  const currentState = finalApi[currentStatus || 'Pending'];
  const apiState = currentState;

  React.useEffect(() => {
    if (!currentInstrument && current.instruments?.length) {
      const instrument = currentId
        ? current.instruments.find(x => x.id === currentId)
        : current.instruments[0];
      setCurrentInstrument(instrument);
    }
  }, [current.instruments, currentId, currentInstrument, getOutcome, getStatus]);

  React.useEffect(() => {
    const newStatus = getStatus(currentInstrument);
    const newOutcome = getOutcome(currentInstrument);
    setCurrentStatus(newStatus);
    setCurrentOutcome(newOutcome);
  }, [currentInstrument, getOutcome, getStatus]);

  const { onSave } = currentState;

  const onSubmit = React.useCallback(
    data => {
      const apiHandler = apiState;

      if (apiHandler?.onSubmit) {
        return apiHandler.onSubmit(data, currentInstrument, setHideForm, (updated, session) => {
          setCurrentInstrument(v => {
            return updated || v;
          });

          setInstruments(session.instruments);
        });
      }

      return currentState.onSubmit(data);
    },
    [apiState, currentInstrument, currentState]
  );

  const onTestChange = React.useCallback(
    async function change(instrument, skip = false) {
      if (skip) {
        const updated = instruments.find(x => x.id === instrument?.id) as TestSessionInstrumentDto;

        setCurrentInstrument(v => {
          return updated || v;
        });

        return current;
      }

      const data = await fetchCurrent();

      setCurrent(data);
      setInstruments(data.instruments);

      const updated = data.instruments.find(
        x => x.id === instrument?.id
      ) as TestSessionInstrumentDto;

      setCurrentInstrument(v => {
        return updated || v;
      });

      return data;
    },
    [current, fetchCurrent, instruments, setCurrent]
  );

  const finalForm = React.useMemo(() => {
    return apiState.form();
  }, [apiState]);

  const addCorrectionsUser = React.useCallback(
    data =>
      new Promise((resolve, reject) => {
        server.patchCorrectionsUser(current?.id, type, data).then(response => {
          setCurrent(response.data);
          setInstruments(response.data.instruments);
          const updated = response?.data?.instruments?.find(
            x => x.id === currentInstrument?.id
          ) as TestSessionInstrumentDto;

          setCurrentInstrument(v => {
            return updated || v;
          });

          resolve(response);
        }, reject);
      }),
    [current?.id, currentInstrument?.id, server, setCurrent, type]
  );

  const { buttonText } = currentState || { buttonText: nextButtonText };
  const { autoSaveEnabled } = currentState || { autoSaveEnabled: false };
  const { hideButton } = currentState || { hideButton: false };
  const { fieldTemplate } = currentState || { fieldTemplate: undefined };
  const testEnabled = checkTestEnabled(currentInstrument);

  return React.useMemo(
    () => ({
      onSave,
      onSubmit,
      form: finalForm,
      autoSaveEnabled,
      instruments,
      onTestChange,
      status: currentStatus,
      outcome: currentOutcome,
      getOutcome,
      getStatus,
      type,
      hideForm,
      buttonText,
      hideButton,
      fieldTemplate,
      testEnabled,
      states: finalApi,
      currentInstrument,
      getTestPermissions: getInstrumentPermissions,
      addCorrectionsUser,
      setCurrentInstrument,
    }),
    [
      onSave,
      onSubmit,
      finalForm,
      autoSaveEnabled,
      instruments,
      onTestChange,
      currentStatus,
      currentOutcome,
      getOutcome,
      getStatus,
      type,
      hideForm,
      buttonText,
      hideButton,
      fieldTemplate,
      testEnabled,
      finalApi,
      currentInstrument,
      getInstrumentPermissions,
      addCorrectionsUser,
      setCurrentInstrument,
    ]
  );
}
