import React, { ReactNode } from 'react';
import { Grid, ListItem, ListItemText } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

export interface SummaryPanelItemProps {
  key?: string;
  primary?: ReactNode;
  secondary?: ReactNode;
  primaryVariant?: Variant | 'inherit';
  secondaryVariant?: Variant | 'inherit';
}

const SummaryPanelItem = ({
  primary,
  secondary,
  primaryVariant = 'overline',
  secondaryVariant = 'h6',
}: SummaryPanelItemProps) => (
  <>
    <Grid item xs>
      <ListItem dense disableGutters>
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ variant: primaryVariant, color: 'textSecondary' }}
          secondary={secondary}
          secondaryTypographyProps={{ variant: secondaryVariant, color: 'textPrimary' }}
        />
      </ListItem>
    </Grid>
  </>
);

export default SummaryPanelItem;
