export enum InstrumentManagementActionTypes {
  SET_CURRENT_INSTRUMENT = 'instruments/management/SET_CURRENT_INSTRUMENT',
}

export enum FetchInstrumentsActionTypes {
  FETCH_INSTRUMENTS = 'instruments/management/FETCH_INSTRUMENTS',
  REQUEST = 'instruments/management/FETCH_INSTRUMENTS_REQUEST',
  SUCCESS = 'instruments/management/FETCH_INSTRUMENTS_SUCCESS',
  FAILURE = 'instruments/management/FETCH_INSTRUMENTS_FAILURE',
}

export enum FetchInstrumentActionTypes {
  FETCH_INSTRUMENT = 'instruments/management/FETCH_INSTRUMENT',
  REQUEST = 'instruments/management/FETCH_INSTRUMENT_REQUEST',
  SUCCESS = 'instruments/management/FETCH_INSTRUMENT_SUCCESS',
  FAILURE = 'instruments/management/FETCH_INSTRUMENT_FAILURE',
}

export enum FetchInstrumentBySerialActionTypes {
  FETCH_INSTRUMENT_BY_SERIAL = 'instruments/management/FETCH_INSTRUMENT_BY_SERIAL',
  REQUEST = 'instruments/management/FETCH_INSTRUMENT_BY_SERIAL_REQUEST',
  SUCCESS = 'instruments/management/FETCH_INSTRUMENT_BY_SERIAL_SUCCESS',
  FAILURE = 'instruments/management/FETCH_INSTRUMENT_BY_SERIAL_FAILURE',
}

export enum RegisterInstrumentActionTypes {
  REGISTER_INSTRUMENT = 'instruments/management/REGISTER_INSTRUMENT',
  REQUEST = 'instruments/management/REGISTER_INSTRUMENT_REQUEST',
  SUCCESS = 'instruments/management/REGISTER_INSTRUMENT_SUCCESS',
  FAILURE = 'instruments/management/REGISTER_INSTRUMENT_FAILURE',
}

export enum UpdateInstrumentActionTypes {
  UPDATE_INSTRUMENT = 'instruments/management/UPDATE_INSTRUMENT',
  REQUEST = 'instruments/management/UPDATE_INSTRUMENT_REQUEST',
  SUCCESS = 'instruments/management/UPDATE_INSTRUMENT_SUCCESS',
  FAILURE = 'instruments/management/UPDATE_INSTRUMENT_FAILURE',
}

export enum SetIsControlActionTypes {
  SET_INSTRUMENT_IS_CONTROL = 'instruments/management/SET_INSTRUMENT_IS_CONTROL',
  REQUEST = 'instruments/management/SET_INSTRUMENT_IS_CONTROL_REQUEST',
  SUCCESS = 'instruments/management/SET_INSTRUMENT_IS_CONTROL_SUCCESS',
  FAILURE = 'instruments/management/SET_INSTRUMENT_IS_CONTROL_FAILURE',
}
