export const rootPath = '/strip-production/strip-lot-records';
export const entityPath = '/strip-production/strip-lot-records/:id';

export const addTitle = 'Add Strip Lot Record';
export const editTitle = 'Editing Strip Lot Record';
export const listTitle = 'Strip Lot Records';
export const addButtonText = 'New Strip Lot Record';
export const i18Namespace = 'StripLotRecord';
export const defaultSort = '';

export const addPermission = 'LDX.StripProduction.RecordProduction';
