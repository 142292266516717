import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import { selectors } from 'modules/Core/reducers';
import clsx from 'clsx';
import MainNavigation from './MainNavigation';
import styles from './Sidebar.styles';
import ExpandCollapseToggle from './ExpandCollapseToggle';
import Logo from './Logo';

const Sidebar = () => {
  const isOpen = useSelector(selectors.sidebarDrawerIsOpen);
  const classes = makeStyles(styles)({ isOpen });

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      className={clsx(classes.root, isOpen && classes.open, !isOpen && classes.closed)}
    >
      <Logo />
      <MainNavigation />
      <ExpandCollapseToggle open={isOpen} />
    </Drawer>
  );
};

export default Sidebar;
