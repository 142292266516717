import React from 'react';
import { openDialog } from 'modules/Core/actions/dialogs';
import { useDispatch } from 'react-redux';
import FormDialog from 'modules/Common/components/FormDialog';
import { FormDefinition } from 'modules/Common/components/FormDialog/FormDialog';
import { DataTableData } from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable/TableData';
import { startEditing } from 'modules/Core/actions/editMode';
import DataTable from 'modules/Pilot/BatchRecord/SingleView/components/SimpleDataTable';
import SectionPart, {
  addRowChipAction,
} from 'modules/Pilot/BatchRecord/SingleView/components/SectionPart';
import { PartStatus } from 'modules/RecordManagement';
import { BreakpointStep } from 'modules/Common/types';

export type RecordDefinition<TFormValues> = {
  title: string;
  tableData: DataTableData;
  formDefinition: FormDefinition<TFormValues>;
  canAddRow?: boolean;
  canEditRows?: boolean;
};

type RecordTableProps<TFormValues> = {
  definition: RecordDefinition<TFormValues>;
  xs?: BreakpointStep;
  status?: PartStatus;
};

const RecordTable = <TFormValues extends {}>({
  definition: { title, tableData, formDefinition, canAddRow = false, canEditRows },
  xs = 4,
  status,
}: RecordTableProps<TFormValues>) => {
  const dispatch = useDispatch();

  const handleClick = () => dispatch(openDialog(formDefinition?.dialogName));
  const handleEditClick = rowKey => {
    dispatch(startEditing(formDefinition?.dialogName, rowKey));
    dispatch(openDialog(formDefinition?.dialogName));
  };

  const isReadOnly =
    status === PartStatus.ReadOnly || status === PartStatus.Blocked || status === PartStatus.Locked;

  return (
    <SectionPart
      title={title}
      actionChip={addRowChipAction(handleClick, canAddRow)}
      xs={xs}
      status={status}
    >
      <DataTable
        tableData={tableData}
        onRowEditClicked={handleEditClick}
        editable={canEditRows}
        readOnly={isReadOnly}
      />
      {}
      <FormDialog definition={formDefinition} />
    </SectionPart>
  );
};

export default RecordTable;
