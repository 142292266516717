import clsx from 'clsx';
import React from 'react';
import { lowerCase } from 'lodash';
import { Chip } from '@material-ui/core';
import { PartStatus, SectionStatus } from '../../domain';
import useStyles from './StatusChip.styles';

type StatusChipProps = {
  label: string;
  status: PartStatus | SectionStatus;
};

export default ({ label, status }: StatusChipProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, lowerCase(status))}>
      <Chip label={label} />
    </div>
  );
};
