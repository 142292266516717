import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Spinner';

const ModuleStateLoader = ({ isLoadedSelector, loadAction, children }) => {
  const dispatch = useDispatch();
  const isModuleLoaded = useSelector(isLoadedSelector);

  useEffect(() => dispatch(loadAction()), [dispatch, loadAction]);

  return isModuleLoaded ? (
    <>{children}</>
  ) : (
    <>
      <Spinner />
    </>
  );
};

ModuleStateLoader.propTypes = {
  isLoadedSelector: PropTypes.func.isRequired,
  loadAction: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModuleStateLoader;
