import React from 'react';
import { render } from 'react-dom';
import { processSilentRenew } from 'redux-oidc';
import { App } from 'modules/App';

import registerValidation from 'modules/Common/validation';

registerValidation();

if (window.location.pathname === '/silent-renew') processSilentRenew();
else {
  if (module.hot) {
    module.hot.accept();
  }
  render(<App />, document.getElementById('root'));
}
