import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './InstrumentTabs.styles';

const InstrumentTabs = ({ selectedTab, handleTabChanged }) => {
  const classes = makeStyles(styles)();

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.tabBar}>
          <Tabs value={selectedTab} onChange={handleTabChanged}>
            <Tab label="Subsystems" />
            <Tab label="Repair Requests" />
            <Tab label="EoL Tests" />
            <Tab label="Delivery Records" />
            <Tab label="RMAs" />
            <Tab label="History" />
          </Tabs>
        </AppBar>
      </div>
    </>
  );
};

InstrumentTabs.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  handleTabChanged: PropTypes.func.isRequired,
};

export default InstrumentTabs;
