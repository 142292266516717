import * as actionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export function setCurrentRepairRequest(repairRequest) {
  return {
    type: actionTypes.SET_CURRENT_REPAIR,
    repairRequest,
  };
}

export const openRequestForm = (mode = 'create') => ({
  type: actionTypes.OPEN_REQUEST_FORM,
  mode,
});

export const closeRequestForm = () => ({
  type: actionTypes.CLOSE_REQUEST_FORM,
});
