import React, { ReactNode, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './GridListItem.styles';

type BreakpointSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type GridListItemProps = {
  title?: ReactNode;
  value?: ReactNode;
  fullWidth?: boolean;
  children?: ReactNode;
  titleWidth?: BreakpointSize;
  valueWidth?: BreakpointSize;
};

const getValueWidth = (
  noTitle: boolean,
  isFullWidth: boolean,
  titleWidth: BreakpointSize,
  valueWidth?: BreakpointSize
): BreakpointSize => {
  if (valueWidth) return valueWidth;
  if (noTitle && isFullWidth) return 12;
  if (titleWidth !== 2) return (12 - titleWidth) as BreakpointSize;
  if (!noTitle && isFullWidth) return 10;
  return 4;
};

const GridListItem = ({
  title,
  value,
  fullWidth = false,
  titleWidth = 2,
  valueWidth = undefined,
  children,
}: GridListItemProps) => {
  const classes = useStyles();
  const calculatedValueWidth = useMemo(
    () => getValueWidth(!title, fullWidth, titleWidth, valueWidth),
    [title, fullWidth, titleWidth, valueWidth]
  );

  return (
    <>
      {title && (
        <Grid item xs={titleWidth} className={classes.root}>
          <Typography variant="subtitle2">{title}</Typography>
        </Grid>
      )}
      <Grid item xs={calculatedValueWidth}>
        {value && <Typography>{value}</Typography>}
        {children}
      </Grid>
    </>
  );
};

export default GridListItem;
