/* istanbul ignore file */
enum InstrumentTestStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  ReadyForReview = 'ReadyForReview',
  CorrectionsRequired = 'CorrectionsRequired',
  Reviewed = 'Reviewed',
  SignedOff = 'SignedOff',
}

export default InstrumentTestStatus;
