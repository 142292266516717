import axios, { AxiosResponse } from 'axios';

export default function getListAsync(apiUrl: string) {
  return function api<TResponse>(urlPart: string): Promise<AxiosResponse<TResponse>> {
    return new Promise<AxiosResponse<TResponse>>((resolve, reject) => {
      axios
        .request<TResponse>({
          url: `${apiUrl}/api/app/test-type-definitions/${urlPart}`,
          method: 'GET',
        })
        .then(response => {
          if (response.status === 400) {
            reject(response);
            return;
          }
          resolve(response);
        }, reject);
    });
  };
}
