import React from 'react';
import { Chip, Grid } from '@material-ui/core';
import { InstrumentSubsystemDto } from 'modules/Global/models';
import useStyles from './AffectedSubsystemsList.styles';

type AffectedSubsystemsListProps = {
  affectedSubsystems?: InstrumentSubsystemDto[];
};

const AffectedSubsystemsList = ({ affectedSubsystems }: AffectedSubsystemsListProps) => {
  const classes = useStyles();
  if (!affectedSubsystems) return <></>;

  return (
    <Grid container spacing={4} className={classes.root}>
      {affectedSubsystems?.map(
        subsys =>
          subsys && (
            <Grid item key={subsys.id}>
              <Chip label={subsys.name} />
            </Grid>
          )
      )}
    </Grid>
  );
};

export default AffectedSubsystemsList;
