import { apiPatch, apiPut } from 'modules/Core/actions';

export enum RecordReagentNumbersActions {
  CALL = 'pilot/batch-records/RECORD_REAGENT_NUMBER',
  REQUEST = 'pilot/batch-records/RECORD_REAGENT_NUMBER_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_REAGENT_NUMBER_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_REAGENT_NUMBER_FAILURE',
}

export enum AmendReagentNumbersActions {
  CALL = 'pilot/batch-records/AMEND_REAGENT_NUMBER',
  REQUEST = 'pilot/batch-records/AMEND_REAGENT_NUMBER_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_REAGENT_NUMBER_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_REAGENT_NUMBER_FAILURE',
}

export enum RecordBlockAidNumbersActions {
  CALL = 'pilot/batch-records/RECORD_BLOCK_AID_NUMBER',
  REQUEST = 'pilot/batch-records/RECORD_BLOCK_AID_NUMBER_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_BLOCK_AID_NUMBER_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_BLOCK_AID_NUMBER_FAILURE',
}

export enum AmendBlockAidNumbersActions {
  CALL = 'pilot/batch-records/AMEND_BLOCK_AID_NUMBER',
  REQUEST = 'pilot/batch-records/AMEND_BLOCK_AID_NUMBER_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_BLOCK_AID_NUMBER_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_BLOCK_AID_NUMBER_FAILURE',
}

type RecordReagentNumberPayload = {
  number: string;
};

type AmendReagentNumberPayload = RecordReagentNumberPayload & {
  comment?: string;
};

type RecordBlockAidNumberPayload = {
  number: string;
};

type AmendBlockAidNumberPayload = RecordReagentNumberPayload & {
  comment?: string;
};

export const recordReagentNumber = (recordId: string, payload: RecordReagentNumberPayload) =>
  apiPut(
    `/pilot/batch-records/${recordId}/deposition/reagent`,
    RecordReagentNumbersActions.CALL,
    payload
  );

export const amendReagentNumber = (recordId: string, payload: AmendReagentNumberPayload) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/deposition/reagent`,
    AmendReagentNumbersActions.CALL,
    payload
  );

export const recordBlockAidNumber = (recordId: string, payload: RecordBlockAidNumberPayload) =>
  apiPut(
    `/pilot/batch-records/${recordId}/deposition/block-aid`,
    RecordBlockAidNumbersActions.CALL,
    payload
  );

export const amendBlockAidNumber = (recordId: string, payload: AmendBlockAidNumberPayload) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/deposition/block-aid`,
    AmendBlockAidNumbersActions.CALL,
    payload
  );
