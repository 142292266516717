import React, { ReactNode, useEffect } from 'react';
import { AnyAction, Dispatch } from 'redux';
import * as yup from 'yup';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Grow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDialogStateSelector,
  createApiErrorSelector,
  getItemsBeingEdited,
} from 'modules/Core/reducers/selectors';
import { DialogState, DialogDefinition, ValidationError } from 'modules/Common/types';
import { Formik, FormikHelpers, FormikValues, FormikProps, useFormikContext } from 'formik';
import { closeDialog } from 'modules/Core/actions/dialogs';
import { Alert, AlertTitle } from '@material-ui/lab';
import { clearApiError } from 'modules/Core/actions/apiErrors';
import { stopEditing } from 'modules/Core/actions/editMode';
import useStyles from './FormDialog.styles';

type FormDialogProps<T> = {
  definition: FormDefinition<T>;
};

export type FormDialogContextType = {
  saveRestricted: boolean;
  saveRestrictedMessage?: ReactNode | null | undefined;
};
export const FormDialogContext = React.createContext<FormDialogContextType>({
  saveRestricted: false,
});

export interface FormDefinition<Values extends FormikValues> extends DialogDefinition {
  apiAction: string;
  initialValues: Values;
  onSubmit: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
    dispatch: Dispatch<AnyAction>
  ) => void;
  validationSchema: () => yup.ObjectSchema<Values>;
  form: (props: FormikProps<Values>, apiErrors?: ValidationError[] | null) => JSX.Element;
}

const ApiErrors = ({ apiAction }: { apiAction: string }) => {
  const { setErrors } = useFormikContext();
  const apiError = useSelector(state => createApiErrorSelector(`${apiAction}`)(state));

  useEffect(() => {
    if (apiError && apiError.validationErrors) {
      const errors = {};
      apiError.validationErrors.forEach(error =>
        // eslint-disable-next-line no-return-assign
        error.members.forEach(field => (errors[field] = error.message))
      );
      setErrors(errors);
    }
  }, [setErrors, apiError]);

  if (apiError && !apiError.validationErrors)
    return (
      <Grow in={apiError && !apiError.validationErrors}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {apiError.message}
        </Alert>
      </Grow>
    );

  return null;
};

const FormDialog = <T extends {}>({
  definition: { title, dialogName, initialValues, validationSchema, onSubmit, apiAction, form },
}: FormDialogProps<T>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogState = useSelector(state =>
    createDialogStateSelector(`${dialogName}_DIALOG`)(state)
  );
  const editItems = useSelector(getItemsBeingEdited);

  const handleClose = () => {
    dispatch(clearApiError(apiAction));
    dispatch(closeDialog(dialogName));

    const editItem = editItems.find(item => item.key === `${dialogName}_EDIT`);
    if (editItem) dispatch(stopEditing(dialogName, editItem.itemId));
  };
  const handleSubmit = (values: T, formikHelpers: FormikHelpers<T>) =>
    onSubmit(values, formikHelpers, dispatch);

  return (
    <FormDialogContext.Consumer>
      {({ saveRestricted, saveRestrictedMessage }) => (
        <Dialog open={dialogState === DialogState.Open} onClose={handleClose} fullWidth>
          {saveRestricted && saveRestrictedMessage && (
            <Alert severity="warning" onClose={handleClose}>
              {saveRestrictedMessage}
            </Alert>
          )}
          {!saveRestricted && (
            <>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <Formik
                  validateOnMount
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {(props: FormikProps<T>) => (
                    <form className={classes.root} autoComplete="on" onSubmit={props.handleSubmit}>
                      <ApiErrors apiAction={apiAction} />
                      <fieldset style={{ border: 'none' }} disabled={saveRestricted}>
                        {form(props)}
                        <div className={classes.actionButtons}>
                          <Button
                            variant="contained"
                            onClick={handleClose}
                            disabled={saveRestricted}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={saveRestricted || !props.isValid || props.isSubmitting}
                          >
                            {props.isSubmitting && (
                              <CircularProgress size={20} style={{ marginRight: '4px' }} />
                            )}
                            Save
                          </Button>
                        </div>
                      </fieldset>
                    </form>
                  )}
                </Formik>
              </DialogContent>
            </>
          )}
        </Dialog>
      )}
    </FormDialogContext.Consumer>
  );
};

export default FormDialog;
