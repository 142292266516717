import { createAction } from 'redux-api-middleware';
import * as actionTypes from './actionTypes';

const fetchPostTests = () =>
  createAction({
    endpoint: '/instruments/repairs/post-repair-tests',
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.GET_POST_TESTS_REQUEST,
      actionTypes.GET_POST_TESTS_SUCCESS,
      actionTypes.GET_POST_TESTS_FAILURE,
    ],
  });

export default fetchPostTests;
