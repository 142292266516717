import React from 'react';
import { useDispatch } from 'react-redux';
import { SectionComment } from 'modules/Pilot/BatchRecord/domain';
import { openDialog } from 'modules/Core/actions/dialogs';
import { FormDialog } from 'modules/Common/components';
import { formatShortDateTime } from 'modules/Common/utilities';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { PartStatus } from 'modules/RecordManagement';
import SectionPart, { addRowChipAction } from '../SectionPart';
import useFormDefinition from './commentsTable.form';

type CommentsTableProps = {
  recordId: string;
  section: string;
  comments: SectionComment[];
  title?: string;
  readOnly?: boolean;
};

const CommentItem = ({
  id,
  userName,
  date,
  content,
}: {
  id: string;
  userName: string;
  date: string;
  content: string;
}) => (
  <>
    <ListItem key={id} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText
        primary={userName}
        secondary={
          <>
            <Typography component="p" variant="body1">
              {formatShortDateTime(date)}
            </Typography>
            {content}
          </>
        }
      />
    </ListItem>
    <Divider variant="inset" component="li" />
  </>
);

const NotApplicableItem = () => (
  <ListItem>
    <ListItemText primary="N/A" />
  </ListItem>
);

export default ({
  recordId,
  section,
  comments,
  title = 'Comments',
  readOnly = false,
}: CommentsTableProps) => {
  const dispatch = useDispatch();
  const dialogName = `pilot/batch-records/SECTION_COMMENT_${section}`;
  const definition = useFormDefinition(recordId, section, dialogName);
  definition.title = `Add ${title}`;
  return (
    <SectionPart
      title={title}
      xs={12}
      actionChip={addRowChipAction(() => dispatch(openDialog(dialogName)), !readOnly)}
      status={PartStatus.Ignore}
    >
      <List>
        {comments
          .filter(com => com.section === section)
          .map(comment => (
            <CommentItem
              key={comment.id}
              id={comment.id}
              userName={comment.addedBy.user.name}
              date={comment.addedBy.date}
              content={comment.comment}
            />
          ))}
        {comments.filter(com => com.section === section).length === 0 && <NotApplicableItem />}
      </List>
      <FormDialog definition={definition} />
    </SectionPart>
  );
};
