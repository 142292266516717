/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import {
  TestSessionInstrumentDto,
  UpdatePerformanceToInstrumentInput,
} from 'modules/InstrumentsQualityControl/models';
import TextDisplayWidget from '@ldx-dmp/JsonFormDialog/TextDisplayWidget';
import ConditionalField from '../widgets/ConditionalField';
import InstrumentResultWidget from '../widgets/InstrumentResultWidget';
import PerformanceTestLogFileWidget from '../widgets/PerformanceTestLogFileWidget';
import PerformanceTestTestWidget from '../widgets/PerformanceTestTestWidget';
import { formatBool, noop } from '../utils';
import PerformanceTestReplicateIdWidget from '../widgets/PerformanceTestReplicateIdWidget';

export const uiSchema = ui => {
  ui['ui:order'] = [
    '*',
    'logFile',
    'piezoMagnetCheckOutcome',
    'auditLogOutcome',
    'plaqueCalibration',
    'performanceTestOutcome',
    'releasePreparation',
  ];

  ui.instrumentId = {
    'ui:widget': 'hidden',
  };
  ui.flexReworkInstrument = {
    'ui:widget': TextDisplayWidget,
    format: formatBool,
  };

  ui.tests = {
    'ui:card': true,
    'ui:field': PerformanceTestTestWidget,
    'ui:options': {
      addable: false,
      removable: false,
      orderable: false,
    },
    items: {
      'ui:card': false,
      'ui:grid:xs': 12,
      'ui:order': [
        'anyErrorsDuringInstrumentBootUp',
        'errorCodes',
        'comments',
        'replicateCount',
        '*',
      ],
      replicates: {
        'ui:field': PerformanceTestReplicateIdWidget,
        'ui:options': {
          readOnly: false,
        },
      },
      anyErrorsDuringInstrumentBootUp: {
        'ui:widget': 'radio',
      },
      errorCodes: {
        'ui:helpText': 'Outside of testing',
        'ui:description': 'Outside of testing',
        'ui:field': ConditionalField,
      },
      comments: {
        'ui:field': ConditionalField,
      },
      status: {
        'ui:widget': InstrumentResultWidget,
      },
    },
  };

  ui.piezoMagnetCheckOutcome = {
    'ui:card': true,
    outcome: {
      'ui:grid:xs': 6,
      'ui:field': InstrumentResultWidget,
    },
    qualityEventRequired: {
      'ui:grid:xs': 6,
      'ui:widget': 'radio',
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      predicate: values =>
        values.piezoMagnetCheckOutcome?.qualityEventRequired?.toString() === 'true',
    },
    hasQualityEvents: {
      'ui:widget': 'hidden',
    },
  };

  ui.performanceTestOutcome = {
    'ui:card': true,
    qualityEventRequired: {
      'ui:widget': 'radio',
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      predicate: values =>
        values.performanceTestOutcome?.qualityEventRequired?.toString() === 'true',
    },
    comments: {
      'ui:field': ConditionalField,
      predicate: values =>
        values.performanceTestOutcome?.qualityEventRequired?.toString() === 'true',
    },
    plannedDeviationNumbers: {},

    hasQualityEvents: {
      'ui:widget': 'hidden',
    },
  };

  ui.auditLogOutcome = {
    'ui:card': true,
    firstMagnetSelfTestAdcValue: {
      'ui:widget': TextDisplayWidget,
      'ui:grid:xs': 5,
    },
    lastMagnetSelfTestAdcValue: {
      'ui:widget': TextDisplayWidget,
      'ui:grid:xs': 5,
    },
    qualityEventRequired: {
      'ui:widget': 'radio',
    },
    qualityEventNumber: {
      'ui:field': ConditionalField,
      predicate: values => values.auditLogOutcome?.qualityEventRequired?.toString() === 'true',
    },
    comments: {
      'ui:field': ConditionalField,
      predicate: values => values.auditLogOutcome?.qualityEventRequired?.toString() === 'true',
    },
    plannedDeviationNumbers: {
      'ui:field': ConditionalField,
      predicate: values => values.auditLogOutcome?.qualityEventRequired?.toString() === 'true',
    },
    difference: {
      'ui:grid:xs': 2,
      'ui:widget': TextDisplayWidget,
    },
    outcome: {
      'ui:widget': InstrumentResultWidget,
    },
  };

  ui.releasePreparation = {
    'ui:card': true,
    factoryResetDate: { 'ui:widget': 'date' },
    anyErrorsDuringInstrumentBootUpAfterFactoryReset: {
      'ui:widget': 'radio',
    },
    errorCodes: {
      'ui:helpText': 'Outside of testing',
      'ui:description': 'Outside of testing',
      'ui:field': ConditionalField,
      predicate: values =>
        values?.releasePreparation?.anyErrorsDuringInstrumentBootUpAfterFactoryReset?.toString() ===
        'true',
    },
    comments: {
      'ui:field': ConditionalField,
      predicate: values =>
        values?.releasePreparation?.anyErrorsDuringInstrumentBootUpAfterFactoryReset?.toString() ===
        'true',
    },
    decontaminationDate: { 'ui:widget': 'date' },
    cleanDate: { 'ui:widget': 'date' },
    carriedOutBy: {
      'ui:widget': 'hidden',
    },
    verifiedBy: {
      'ui:widget': 'hidden',
    },
  };

  ui.logFile = {
    'ui:field': PerformanceTestLogFileWidget,
    'ui:options': {
      readOnly: false,
    },
  };

  ui.plaqueCalibration = {
    'ui:card': true,
    calibrationValue: {
      'ui:grid:xs': 4,
      'ui:widget': TextDisplayWidget,
      format: value => value,
    },
    meanDifferenceValue: {
      'ui:grid:xs': 4,
      'ui:widget': TextDisplayWidget,
      format: value => value,
    },
    biasValue: {
      'ui:grid:xs': 4,
      'ui:widget': TextDisplayWidget,
      format: value => value,
    },
  };

  return ui;
};

export const mapToEdit = () => (
  current: TestSessionInstrumentDto
): UpdatePerformanceToInstrumentInput => {
  const performanceTest = current?.performanceTest || {};
  return {
    review: performanceTest?.review,
    piezoMagnetCheckOutcome: performanceTest?.piezoMagnetCheckOutcome,
    covidAntigenSpike: {
      ...performanceTest?.covidAntigenSpike,
      errorCodes: performanceTest?.covidAntigenSpike?.errorCodes || [],
    },
    extractionBuffer: {
      ...performanceTest?.extractionBuffer,
      errorCodes: performanceTest?.extractionBuffer?.errorCodes || [],
    },
    performanceTestOutcome: {
      ...performanceTest?.performanceTestOutcome,
      plannedDeviationNumbers:
        performanceTest?.performanceTestOutcome?.plannedDeviationNumbers || [],
    },
    auditLogOutcome: {
      ...performanceTest?.auditLogOutcome,
      plannedDeviationNumbers: performanceTest?.auditLogOutcome?.plannedDeviationNumbers || [],
    },
    releasePreparation: {
      ...performanceTest?.releasePreparation,
      errorCodes: performanceTest?.releasePreparation?.errorCodes || [],
    },
    instrumentId: current?.id,
  } as UpdatePerformanceToInstrumentInput;
};

export const fields = noop;

export const widgets = noop;

export const validation = () => null;

export const empty = initial => ({
  ...initial,
  releasePreparation: {
    factoryResetDate: moment(),
    decontaminationDate: moment(),
    cleanDate: moment(),
  },
});
