import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Button } from '@material-ui/core';
import { Plus } from 'mdi-material-ui';
import { TestSessionInstrumentDto, InstrumentDto } from 'modules/InstrumentsQualityControl/models';
import SelectedInstrumentsToolbar from '../SelectedInstrumentsToolbar';

const DataTable = ({
  instruments,
  onClick,
  openAddControlInstruments,
  canAddInstruments,
}: {
  instruments: InstrumentDto[] | undefined | null;
  openAddControlInstruments: () => void | undefined;
  onClick: (item: TestSessionInstrumentDto) => void;
  canAddInstruments: boolean;
}) => {
  const data = React.useMemo(() => {
    return Object.values(instruments || {}).map(instrument => {
      return [instrument?.serial];
    });
  }, [instruments]);

  if (!instruments) {
    return <></>;
  }

  return (
    <MUIDataTable
      data={data}
      columns={[{ name: 'serial', label: 'Serial Number' }]}
      options={{
        sort: false,
        filter: true,
        selectableRows: 'multiple',
        download: false,
        print: false,
        elevation: 1,
        viewColumns: false,
        search: true,
        onRowClick: (_data, row) => onClick(instruments[row.dataIndex]),
        customToolbar: () => (
          <>
            {canAddInstruments && (
              <Button color="primary" variant="contained" onClick={openAddControlInstruments}>
                <Plus /> Add Control Instruments
              </Button>
            )}
          </>
        ),
        customToolbarSelect: () => <SelectedInstrumentsToolbar />,
      }}
    />
  );
};

export default DataTable;
