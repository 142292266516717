import React, { ReactNode } from 'react';
import { FilledInput, FormControl, InputAdornment, InputLabel } from '@material-ui/core';

type FilledAdornedTextFieldProps = {
  id: string;
  label: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
  adornment?: ReactNode;
  showAdornment?: boolean;
};

const FilledAdornedTextField = ({
  id,
  label,
  onChange,
  value,
  adornment,
  showAdornment,
}: FilledAdornedTextFieldProps) => (
  <FormControl variant="filled" fullWidth>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <FilledInput
      id={id}
      type="text"
      onChange={onChange}
      value={value}
      endAdornment={showAdornment && <InputAdornment position="end">{adornment}</InputAdornment>}
    />
  </FormControl>
);

export default FilledAdornedTextField;
