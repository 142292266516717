import React from 'react';
import { WidgetProps, FieldProps } from '@rjsf/core';
import { useFormikContext } from 'formik';

export default ({ registry, uiSchema, ...props }: WidgetProps & FieldProps) => {
  const { values } = useFormikContext();

  if (uiSchema.predicate && uiSchema.predicate(values)) {
    const { SchemaField } = registry.fields;
    return (
      <SchemaField
        registry={registry}
        uiSchema={{ ...uiSchema, 'ui:field': undefined }}
        {...props}
      />
    );
  }

  return <></>;
};
