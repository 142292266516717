import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ListTable } from 'modules/Common';
import { RepairRequest } from 'modules/Instruments/domain/repairRequest';
import { useDispatch } from 'react-redux';
import { setCurrentRepairRequest } from 'modules/Instruments/slices/Repair/actions/actionCreators';
import { push } from 'connected-react-router';
import createRelatedRequestTableData from './createRelatedRequestTableData';

type RelatedRequestsTableProps = {
  relatedRequests: RepairRequest[];
};

const RelatedRequestsTable = ({ relatedRequests }: RelatedRequestsTableProps) => {
  const dispatch = useDispatch();

  const tableData = useMemo(() => createRelatedRequestTableData(relatedRequests), [
    relatedRequests,
  ]);

  const handleRowClicked = (id: string) => {
    dispatch(setCurrentRepairRequest(id));
    dispatch(push(`/instruments/repair/${id}`));
  };

  return (
    <Grid item xs={12}>
      <Typography variant="h6">History</Typography>
      <ListTable tableData={tableData} onRowClicked={handleRowClicked} noPaper />
    </Grid>
  );
};

export default RelatedRequestsTable;
