import React from 'react';
import { BatchRecord, FileAttachment } from 'modules/Pilot/BatchRecord/domain';
import { formatShortDate } from 'modules/Common/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from 'modules/Core/actions/dialogs';
import SectionPart, {
  addRowChipAction,
} from 'modules/Pilot/BatchRecord/SingleView/components/SectionPart';
import { PartStatus } from 'modules/RecordManagement';
import { FormDialog } from 'modules/Common/components';
import { getConfig, getItemsBeingEdited } from 'modules/Core/reducers/selectors';
import { Link } from '@material-ui/core';
import { startEditing } from 'modules/Core/actions/editMode';
import useForm from './batchRequest.form';
import DataTable from '../../SimpleDataTable';

export default ({ batchRecord }: { batchRecord: BatchRecord }) => {
  const dialogName = 'pilot/batch-records/BATCH_REQUEST';
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const isBeingEdited =
    useSelector(getItemsBeingEdited).find(item => item.key === `${dialogName}_EDIT`) !== undefined;
  const definition = useForm(batchRecord.id, dialogName, isBeingEdited);
  const { status, signOff, attachment } = batchRecord.signOff.developmentBatchRequest;

  const createAttachmentLink = (file: FileAttachment) => {
    if (!file) return <>-</>;
    return (
      <>
        <Link href={`${config.app.cdn_base_uri}/${file.key}`} target="_blank" rel="noopener">
          {file.fileName}
        </Link>
      </>
    );
  };

  const handleEditClick = () => {
    dispatch(startEditing(dialogName, batchRecord.id));
    dispatch(openDialog(dialogName));
  };

  return (
    <SectionPart
      xs={6}
      title="Development Batch Request"
      actionChip={addRowChipAction(
        () => dispatch(openDialog(dialogName)),
        status === PartStatus.Incomplete
      )}
      status={status}
    >
      <DataTable
        editable={status === PartStatus.Complete}
        onRowEditClicked={handleEditClick}
        tableData={{
          columns: [
            { accessor: 'attachment', label: 'Attachment' },
            { accessor: 'addedBy', label: 'Added By', isSignOff: true },
          ],
          rows: batchRecord.signOff.developmentBatchRequest.attachment
            ? [
                {
                  key: 'batch-request',
                  fields: {
                    attachment: {
                      value: createAttachmentLink(attachment),
                    },
                    addedBy: {
                      user: signOff.user.name,
                      date: formatShortDate(signOff.date),
                    },
                  },
                },
              ]
            : [],
        }}
      />
      <FormDialog definition={definition} />
    </SectionPart>
  );
};
