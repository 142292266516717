import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FieldProps } from '@rjsf/core';
import { useField } from 'formik';

export default ({ schema, formData, value, id: _id, uiSchema = {}, idSchema }: FieldProps) => {
  const id = _id || idSchema.$id;
  const fieldId = `review.${id}`;
  const [field] = useField(fieldId);

  const showComment = field.value;

  const { format } = uiSchema;

  const data = (value === null || value === undefined ? formData : value)?.toString() || '-';

  const result = (
    <>
      <Grid item xs={12}>
        <Typography variant="body1">{schema.title}</Typography>
        {format ? format(data) : data}
      </Grid>
    </>
  );

  if (showComment) {
    return (
      <Alert
        severity="warning"
        style={{
          opacity: 1,
        }}
      >
        {result}
        <AlertTitle>{field.value}</AlertTitle>
      </Alert>
    );
  }

  return result;
};
