import React from 'react';
import {
  ClipboardListOutline,
  FlaskOutline,
  FormatListChecks,
  ProgressCheck,
  Stove,
  Tools,
  CellphoneDock,
  Earth,
  Eyedropper,
  Checkbook,
  Flask,
  LedStrip,
  RocketLaunch,
  EyeCheck,
  ArrowDecision,
  DesktopMacDashboard,
  MiddlewareOutline,
  Chip,
  Server,
  TableClock,
  PaperRoll,
} from 'mdi-material-ui';

export default {
  reagentsTracker: <FlaskOutline />,
  instruments: <CellphoneDock />,
  clipboardList: <ClipboardListOutline />,
  tools: <Tools />,
  stove: <Stove />,
  formatListChecks: <FormatListChecks />,
  progressCheck: <ProgressCheck />,
  globe: <Earth />,
  testType: <Checkbook />,
  sampleType: <Eyedropper />,
  reagents: <Flask />,
  manufacturing: <RocketLaunch />,
  stripLotMetadata: <LedStrip />,
  reagentsMetadata: <Flask />,
  qcLotVerification: <EyeCheck />,
  disposition: <ArrowDecision />,
  reviewSummary: <DesktopMacDashboard />,
  pilotLines: <MiddlewareOutline />,
  instrumentVersions: <Chip />,
  instrumentSubsystems: <Server />,
  stripPlanning: <TableClock />,
  striplines: <PaperRoll />,
};
