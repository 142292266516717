/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { ErrorSchema, FieldProps, utils } from '@rjsf/core';
import { JSONSchema7 } from 'json-schema';
import { PerformanceTestTypeAndSampleDto } from '@ldx-dmp/InstrumentsQualityControl/models';
import { TabManager } from '@ldx-dmp/Common';
import { toIdSchema } from '@ldx-dmp/Common/components/JsonForm/utils';

const { retrieveSchema } = utils;

export default ({
  uiSchema,
  registry,
  formData,
  errorSchema,
  idSchema,
  idPrefix,
  ...props
}: FieldProps) => {
  const { SchemaField } = registry.fields;
  const { rootSchema } = registry as any;

  const schema = (props?.schema?.items || {}) as Record<string, unknown>;
  const tabs = (formData as (PerformanceTestTypeAndSampleDto & {
    anyErrorsDuringInstrumentBootUp: any;
  })[])?.map((test, index) => {
    const type = test?.testType?.definition?.code;
    const sample = test?.sampleType?.definition?.code;
    const title = test?.testType?.definition?.type || 'Test';
    const itemSchema = retrieveSchema(schema, rootSchema, test);
    const itemErrorSchema = (errorSchema ? errorSchema[index] : undefined) as ErrorSchema;
    const itemIdPrefix = `${idSchema.$id}[${index}]`;
    const itemIdSchema = toIdSchema(itemSchema, itemIdPrefix, rootSchema, test, `${itemIdPrefix}.`);
    const get = values => values.tests[index];

    const itemUiSchema = {
      ...uiSchema.items,
      errorCodes: {
        ...uiSchema.items.errorCodes,
        'ui:widget':
          test.anyErrorsDuringInstrumentBootUp?.toString() === 'false'
            ? 'hidden'
            : uiSchema.items.errorCodes['ui:widget'],
        predicate: values => values.tests[index]?.anyErrorsDuringInstrumentBootUp,
        'ui:options': {
          ...uiSchema.items.errorCodes['ui:options'],
          predicate: values => values.tests[index]?.anyErrorsDuringInstrumentBootUp,
        },
      },
      comments: {
        ...uiSchema.items.comments,
        'ui:widget':
          test.anyErrorsDuringInstrumentBootUp?.toString() === 'false'
            ? 'hidden'
            : uiSchema.items.comments['ui:widget'],
        predicate: values => values.tests[index]?.anyErrorsDuringInstrumentBootUp,
        'ui:options': {
          ...uiSchema.items.comments['ui:options'],
          predicate: values => values.tests[index]?.anyErrorsDuringInstrumentBootUp,
        },
      },
      replicates: {
        ...uiSchema.items.replicates,
        'ui:options': {
          ...uiSchema.items.replicates['ui:options'],
          type,
          sample,
          get,
          id: test.id,
        },
      },
    };

    return {
      name: test.id as string,
      tabLabel: title,
      hideHeading: true,
      component: (
        <CardContent
          style={{
            width: '100%',
          }}
        >
          <SchemaField
            {...props}
            idPrefix={itemIdPrefix}
            index={index}
            errorSchema={itemErrorSchema}
            idSchema={itemIdSchema}
            formData={{
              ...test,
              errorCodes: (test as any).errorCodes ?? [],
            }}
            schema={{ ...itemSchema, title } as JSONSchema7}
            registry={registry}
            uiSchema={itemUiSchema}
          />
        </CardContent>
      ),
    };
  });
  return (
    <Card>
      {/* <Card>{tabs.map(x => x.component)}</Card> */}
      <TabManager>{tabs}</TabManager>
    </Card>
  );
};
