import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { TabManager } from '@ldx-dmp/Common';

import {
  PerformanceTestDetailsDto,
  TestSessionDto,
  TestSessionInstrumentDto,
  VisualInspectionDetailsDto,
} from 'modules/InstrumentsQualityControl/models';
import InstrumentResult from 'modules/InstrumentsQualityControl/features/instruments/components/InstrumentResult';

import TestSessionSummary from '../TestSessionSummary';
import InstrumentTest, { InstrumentTestProps } from './InstrumentTest';

import VisualInspectionContainer from '../TestSessionVisualInspectionPane/VisualInspectionContainer';
import PerformanceTestContainer from '../../instrumentTests/performanceTest/components/TestSessionPerformanceTestPane/PerformanceTestContainer';

const TestInstrumentsDetails = ({
  onClose,
  instrument,
  current,
  setCurrent,
  fetchCurrent,
  onCorrectionsSubmit,
  open,
}: {
  onClose: () => void;
  instrument: TestSessionInstrumentDto | null | undefined;
  current: TestSessionDto | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg" fullScreen>
      <DialogTitle>{instrument?.instrument?.serial}</DialogTitle>
      <DialogContent>
        <TestSessionSummary
          items={[
            {
              key: 'Software Version',
              primary: 'Software Version',
              secondary: instrument?.softwareVersion || '-',
            },
            {
              key: 'Visual Inspection',
              primary: 'Visual Inspection',
              secondary: (
                <InstrumentResult
                  value={
                    instrument?.visualInspection?.visualInspectionOutcome ||
                    instrument?.visualInspection?.visualInspectionStatus
                  }
                />
              ),
            },
            {
              key: 'Performance Test',
              primary: 'Performance Test',
              secondary: (
                <InstrumentResult
                  value={
                    instrument?.performanceTest?.outcome || instrument?.performanceTest?.status
                  }
                />
              ),
            },
          ]}
        />
        <TabManager>
          {[
            {
              name: 'visualInspection',
              tabLabel: 'Visual Inspection',
              hideHeading: true,
              component: (
                <VisualInspectionContainer
                  current={current}
                  setCurrent={setCurrent}
                  onCorrectionsSubmit={onCorrectionsSubmit}
                  fetchCurrent={fetchCurrent}
                  initialInstrument={instrument}
                >
                  {(p: InstrumentTestProps<VisualInspectionDetailsDto>) => (
                    <InstrumentTest
                      {...p}
                      instrument={instrument as TestSessionInstrumentDto}
                      testEnabled={current?.visualInspectionConfiguration?.enabled}
                    />
                  )}
                </VisualInspectionContainer>
              ),
            },
            {
              name: 'performanceTest',
              tabLabel: 'Performance Test',
              hideHeading: true,
              component: (
                <PerformanceTestContainer
                  current={current}
                  setCurrent={setCurrent}
                  onCorrectionsSubmit={onCorrectionsSubmit}
                  fetchCurrent={fetchCurrent}
                  initialInstrument={instrument}
                >
                  {(p: InstrumentTestProps<PerformanceTestDetailsDto>) => (
                    <InstrumentTest {...p} instrument={instrument as TestSessionInstrumentDto} />
                  )}
                </PerformanceTestContainer>
              ),
            },
          ]}
        </TabManager>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TestInstrumentsDetails;
