import { apiPost } from 'modules/Core/actions';

export enum RecordVisualInspectionCarriedOutActions {
  CALL = 'pilot/batch-records/RECORD_VISUAL_INSPECTION',
  REQUEST = 'pilot/batch-records/RECORD_VISUAL_INSPECTION_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_VISUAL_INSPECTION_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_VISUAL_INSPECTION_FAILURE',
}

export const recordVisualInspectionCarriedOut = (recordId: string) =>
  apiPost(
    `/pilot/batch-records/${recordId}/inspection/visual-inspection`,
    RecordVisualInspectionCarriedOutActions.CALL,
    {}
  );
