import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BatchRecord, Sections } from 'modules/Pilot/BatchRecord/domain';
import {
  getItemsBeingEdited,
  getUserId,
  getUserPermissions,
} from 'modules/Core/reducers/selectors';
import { Grid } from '@material-ui/core';
import SignOffTable from '../SignOffTable';
import RecordTable from '../RecordTable/RecordTable';
import makeOvenCardFeedDefinition from './ovenCardFeedDefinition';
import makeBoxOvenDefinition from './boxOvenDefinition';
import makeTunnelDryerDefinition from './tunnelDryerDefinition';
import CommentsTable from '../CommentsTable';

type DryingPanelProps = {
  batchRecord: BatchRecord;
  readOnly?: boolean;
};

const DryingPanel = ({ batchRecord, readOnly }: DryingPanelProps) => {
  const userPermissions = useSelector(getUserPermissions);
  const itemsBeingEdited = useSelector(getItemsBeingEdited);
  const userId = useSelector(getUserId);

  const ovenCardFeed = useMemo(
    () => makeOvenCardFeedDefinition(batchRecord, userId, userPermissions),
    [batchRecord, userId, userPermissions]
  );
  const boxOven1 = useMemo(
    () => makeBoxOvenDefinition(batchRecord, 1, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );
  const boxOven2 = useMemo(
    () => makeBoxOvenDefinition(batchRecord, 2, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );
  const tunnelDryer = useMemo(
    () => makeTunnelDryerDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );

  return (
    <>
      <SignOffTable
        definition={ovenCardFeed}
        status={batchRecord.drying.ovenManagementCardFeed.status}
        xs={6}
      />
      <Grid item xs={6} />
      <RecordTable definition={boxOven1} status={batchRecord.drying.ovens.status} xs={6} />
      <RecordTable definition={boxOven2} status={batchRecord.drying.ovens.status} xs={6} />
      <RecordTable definition={tunnelDryer} status={batchRecord.drying.ovens.status} xs={12} />
      <CommentsTable
        recordId={batchRecord.id}
        section={Sections.Drying}
        comments={batchRecord.comments}
        readOnly={readOnly}
      />
    </>
  );
};

export default DryingPanel;
