/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { WidgetProps, FieldProps } from '@rjsf/core';
import uniq from 'lodash/uniq';
import QCContext from 'modules/InstrumentsQualityControl/context';
import { TestSessionDto } from 'modules/InstrumentsQualityControl/models';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField } from 'formik';

export default ({ uiSchema, id, label }: WidgetProps & FieldProps) => {
  const { current } = React.useContext(QCContext);
  const [field, meta] = useField(id);
  if (!uiSchema['ui:options']?.select) {
    throw new Error('select must be defined');
  }

  const selectOptions = (uiSchema['ui:options']?.select || (() => undefined)) as (
    current: TestSessionDto
  ) => { goodsReceivedNumber: string }[] | undefined;

  const options =
    selectOptions(current as TestSessionDto)
      ?.map(x => x.goodsReceivedNumber)
      .filter(x => x) || [];

  return (
    <Autocomplete
      fullWidth
      freeSolo
      autoSelect
      id={id}
      options={uniq(options)}
      value={field.value}
      onChange={field.onChange}
      renderInput={params => (
        <TextField
          {...params}
          name={id}
          label={label}
          variant="filled"
          fullWidth
          error={meta.touched && !!meta.error}
        />
      )}
    />
  );
};
