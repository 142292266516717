/* eslint-disable no-param-reassign */
import * as yup from 'yup';
import AddEquipmentInput from 'modules/InstrumentsQualityControl/models/AddEquipmentInput';
import IActionResult from 'modules/InstrumentsQualityControl/models/IActionResult';
import startCase from 'lodash/startCase';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.properties.usage.enumNames = sch.properties.usage.enum.map(x => startCase(x));

  return sch;
};

export const uiSchema = ui => {
  ui.calibrationDueDate = { 'ui:widget': 'date' };
  ui.usage = {
    multiple: true,
  };
  return ui;
};

export const mapToEdit = () => (current: IActionResult): AddEquipmentInput => ({
  ...current,
});

export const fields = noop;

export const widgets = noop;

export const validation = () =>
  yup
    .object()
    .shape({
      name: yup
        .string()
        .min(5)
        .defined(),
      assetId: yup
        .string()
        .min(5)
        .defined(),
      usage: yup.string().defined(),
    })
    .defined();

export const empty = initial => ({
  ...initial,
  calibrationDueDate: null,
});
