/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import TimeAgo from 'react-timeago';
import { Grid } from '@material-ui/core';
import { formatShortDateTime } from 'modules/Common/utilities';
import MaterialTable from '@ldx-dmp/MaterialTable';
import styles from './InstrumentEolTests.styles';

const InstrumentEolTests = ({ isActive, instrument }: Record<string, any>) => {
  const classes = styles();

  if (!isActive) return <></>;

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          className={classes.stackedList}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <MaterialTable
            title=""
            data={instrument.eolTests || []}
            columns={[
              { field: 'iteration', title: 'Iteration' },
              {
                field: 'timestamp',
                title: 'Timestamp',
                // eslint-disable-next-line react/prop-types
                render: ({ timestamp }) => (
                  <TimeAgo date={timestamp} title={formatShortDateTime(timestamp)} />
                ),
              },
              { field: 'meterApiApplicationVersion', title: 'Meter Api Application Version' },
              { field: 'meterSoftwareVersion', title: 'Meter Software Version' },
              { field: 'meterChecksum', title: 'Meter Checksum' },
              { field: 'machineName', title: 'Machine Name' },
              { field: 'result', title: 'Result' },
            ]}
            options={{
              columnsButton: false,
              search: false,
              emptyRowsWhenPaging: false,
              paging: false,
              toolbar: false,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InstrumentEolTests;
