import { apiPost, apiPatch } from 'modules/Core/actions';

export enum RecordSingulationActions {
  CALL = 'pilot/batch-records/RECORD_SINGULATION',
  REQUEST = 'pilot/batch-records/RECORD_SINGULATION_REQUEST',
  SUCCESS = 'pilot/batch-records/RECORD_SINGULATION_SUCCESS',
  FAILURE = 'pilot/batch-records/RECORD_SINGULATION_FAILURE',
}

export enum AmendSingulationActions {
  CALL = 'pilot/batch-records/AMEND_SINGULATION',
  REQUEST = 'pilot/batch-records/AMEND_SINGULATION_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_SINGULATION_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_SINGULATION_FAILURE',
}

export type SingulationPayload = {
  splits: number[];
  lasers: number[];
};

export type AmendSingulationPayload = {
  subRecordId?: string;
  comment?: string;
} & SingulationPayload;

export const recordSingulation = (recordId: string, payload: SingulationPayload) =>
  apiPost(
    `/pilot/batch-records/${recordId}/post-drying/singulation`,
    RecordSingulationActions.CALL,
    {
      ...payload,
    }
  );

export const amendSingulation = (recordId: string, payload: AmendSingulationPayload) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/post-drying/singulation`,
    AmendSingulationActions.CALL,
    {
      ...payload,
    }
  );
