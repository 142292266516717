/* eslint-disable no-param-reassign */
import AddEquipmentToVisualInspectionInput from 'modules/InstrumentsQualityControl/models/AddEquipmentToVisualInspectionInput';
import IActionResult from 'modules/InstrumentsQualityControl/models/IActionResult';

function noop<T>(id: T) {
  return id;
}

export const schema = sch => {
  sch.properties.equipmentId.title = 'Equipment';

  return sch;
};

export const uiSchema = noop;

export const mapToEdit = () => (current: IActionResult): AddEquipmentToVisualInspectionInput => ({
  ...current,
});

export const fields = noop;

export const widgets = noop;

export const validation = () => null;

export const empty = noop;
