import React from 'react';

import { Divider } from '@material-ui/core';

const SummaryChips = ({
  show,
  children,
  className,
}: {
  show: boolean;
  children: React.ReactNode;
  className?: string;
}) => {
  if (!show) return <></>;

  return (
    <>
      <Divider />
      <div className={className}>{children}</div>
    </>
  );
};

export default SummaryChips;
