/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import React from 'react';
import startCase from 'lodash/startCase';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { Typography, Paper, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineOppositeContent,
} from '@material-ui/lab';
import { Spinner } from 'modules/Common';
import {
  AuditEntityDto,
  TestSessionDto,
  TestSessionInstrumentDto,
} from 'modules/InstrumentsQualityControl/models';
import { formatDate } from 'modules/Common/utilities';
import useStyles from './ActivityPanel.styles';

type ActivityPanelProps = {
  events?: AuditEntityDto[] | undefined;
  getActivity?: any;
  current?: TestSessionDto | null;
};

const getContents = (content: object, instruments: TestSessionInstrumentDto[] = []) => {
  const things: { field: string; value: React.ReactNode }[] = [];

  Object.entries(content).forEach(([field, value]) => {
    if (['timestamp', 'id'].includes(field)) {
      return;
    }

    if (field === 'instrumentId') {
      things.push({
        field: 'Instrument',
        value: instruments?.find(x => x.id === value)?.instrument?.serial || value,
      });

      return;
    }

    if (!isObject(value) && value !== undefined && value !== null) {
      things.push({ field: startCase(field), value: value?.toString() });

      return;
    }

    if (isArray(value) && value.length > 0) {
      things.push({
        field: startCase(field),
        value: (
          <ul>
            {value
              .filter(x => !isObject(x))
              .map(x => (
                <li>{x}</li>
              ))}
          </ul>
        ),
      });
    }
  });

  return things;
};

const ActivityTimelineItem = ({
  action: { message, timestamp, user, data },
  skipConnector = false,
  instruments = [],
}: {
  action: AuditEntityDto;
  skipConnector: boolean;
  instruments: TestSessionInstrumentDto[];
}) => (
  <TimelineItem>
    <TimelineOppositeContent>
      <Typography variant="subtitle1" color="textSecondary">
        {formatDate(timestamp)}
      </Typography>
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineDot color="primary" />
      {!skipConnector && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent>
      <Paper elevation={3} variant="outlined">
        <div className="eventHeader">
          <Typography variant="h6">
            {user?.name} {startCase(message)}
          </Typography>
        </div>
        <Table>
          <TableBody>
            {data &&
              getContents(data, instruments).map(fieldValue => (
                <TableRow key={fieldValue.field}>
                  <TableCell style={{ width: '50%' }}>
                    <strong>{fieldValue.field}</strong>
                  </TableCell>
                  <TableCell>{fieldValue.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </TimelineContent>
  </TimelineItem>
);

export default ({ events, getActivity, current }: ActivityPanelProps) => {
  const classes = useStyles();

  React.useEffect(() => {
    getActivity();
  }, [getActivity]);

  if (!events) {
    return <Spinner />;
  }

  return (
    <>
      {events && (
        <Timeline align="left" className={classes.root}>
          {events.map((e, index) => (
            <ActivityTimelineItem
              key={`${e.message}-${e.timestamp}`}
              action={e}
              skipConnector={index >= events.length - 1}
              instruments={current?.instruments || []}
            />
          ))}
        </Timeline>
      )}
    </>
  );
};
