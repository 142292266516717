import { apiGet } from 'modules/Core/actions';

export enum FetchBatchRecordActions {
  CALL = 'pilot/batch-records/FETCH_BATCH_RECORD',
  REQUEST = 'pilot/batch-records/FETCH_BATCH_RECORD_REQUEST',
  SUCCESS = 'pilot/batch-records/FETCH_BATCH_RECORD_SUCCESS',
  FAILURE = 'pilot/batch-records/FETCH_BATCH_RECORD_FAILURE',
}

export default (recordId: string) =>
  apiGet(`/pilot/batch-records/${recordId}`, FetchBatchRecordActions.CALL);
