import React from 'react';
import { PagedResultDto } from 'modules/Core';
import { RepairLocationDto } from 'modules/Instruments/models';
import useServer from './useServer';

export default () => {
  const server = useServer();
  const [items, setItems] = React.useState<{ value: string | number; label: string }[]>([]);

  return React.useMemo(
    () => ({
      items,
      fetch: () =>
        server.getListAsync<PagedResultDto<RepairLocationDto>>(0, 1000, 'name').then(response =>
          setItems(
            response?.data?.items?.map(x => ({
              value: String(x.id),
              label: `${String(x.name)} (${String(x.code)})`,
            })) || []
          )
        ),
    }),
    [server, items]
  );
};
