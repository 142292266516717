/* eslint-disable react/prop-types */
import React from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, ButtonGroup } from '@material-ui/core';
import { Plus, Sort } from 'mdi-material-ui';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import InstrumentResult from 'modules/InstrumentsQualityControl/features/instruments/components/InstrumentResult';
import SelectedInstrumentsToolbar from '../SelectedInstrumentsToolbar';

const DataTable = ({
  instruments,
  openAddInstruments,
  onClick,
  orderInstruments,
  canAddInstruments,
  onStatusClick,
  startInstrumentDisposition,
  canOrderInstruments,
  readOnly,
}: {
  instruments: TestSessionInstrumentDto[] | undefined | null;
  openAddInstruments: () => void | undefined;
  onClick?: (item: TestSessionInstrumentDto) => void;
  orderInstruments: () => void | undefined;
  canAddInstruments: boolean;
  onStatusClick?: (type: string, status: string, instrument: TestSessionInstrumentDto) => void;
  startInstrumentDisposition: (item: TestSessionInstrumentDto) => void;
  canOrderInstruments: boolean;
  readOnly: boolean | undefined;
}) => {
  const data = React.useMemo(() => {
    return Object.values(instruments || {}).map(instrument => {
      return [
        instrument?.instrument?.serial,
        instrument?.visualInspection?.goodsReceivedNumber,
        instrument?.visualInspection?.visualInspectionStatus,
        instrument?.visualInspection?.visualInspectionOutcome ?? 'NONE',
        instrument?.performanceTest?.status ?? 'NONE',
        instrument?.performanceTest?.outcome ?? 'NONE',
        instrument?.instrumentDisposition ?? 'Pending',
      ];
    });
  }, [instruments]);

  if (!instruments) {
    return <></>;
  }

  const onRowClick = onClick ? (_data, row) => onClick(instruments[row.dataIndex]) : undefined;

  return (
    <MUIDataTable
      data={data}
      columns={[
        { name: 'serial', label: 'Serial Number' },
        { name: 'latestGrn', label: 'GRN' },
        {
          name: 'visualInspection',
          label: 'Visual Inspection Status',
          options: {
            filter: false,
            customBodyRenderLite: dataIndex => {
              const currentInstrument = instruments[dataIndex];
              const value = data[dataIndex][2];

              if (value !== 'ReadyForReview') return <InstrumentResult value={value} />;

              return (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (onStatusClick) onStatusClick('visual-inspection', value, currentInstrument);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <InstrumentResult value={value} />
                </Button>
              );
            },
          },
        },
        {
          name: 'visualInspection',
          label: 'Visual Inspection Outcome',
          options: {
            filter: false,
            customBodyRender: value => <InstrumentResult value={value} />,
          },
        },
        {
          name: 'performanceTestStatus',
          label: 'Performance Test Status',
          options: {
            filter: false,
            customBodyRenderLite: dataIndex => {
              const currentInstrument = instruments[dataIndex];
              const value = data[dataIndex][4];

              if (value !== 'ReadyForReview') return <InstrumentResult value={value} />;

              return (
                <Button
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (onStatusClick) onStatusClick('performance-test', value, currentInstrument);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  <InstrumentResult value={value} />
                </Button>
              );
            },
          },
        },
        {
          name: 'performanceTestOutcome',
          label: 'Performance Test Outcome',
          options: {
            filter: false,
            customBodyRender: value => <InstrumentResult value={value} />,
          },
        },
        {
          name: 'instrumentDisposition',
          label: 'Disposition',
          options: {
            filter: false,
            customBodyRenderLite: dataIndex => {
              const currentInstrument = instruments[dataIndex];
              const { instrumentDisposition: value } = currentInstrument;
              if (currentInstrument.canSetInstrumentDisposition && value === 'Pending') {
                return (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      startInstrumentDisposition(currentInstrument);
                    }}
                  >
                    Final Disposition
                  </Button>
                );
              }

              return <InstrumentResult value={value || 'Pending'} />;
            },
          },
        },
      ]}
      options={{
        sort: false,
        filter: true,
        selectableRows: readOnly ? 'none' : 'multiple',
        download: false,
        print: false,
        elevation: 1,
        viewColumns: false,
        search: true,
        onRowClick,
        customToolbar: () => (
          <ButtonGroup>
            {canAddInstruments && (
              <Button color="primary" variant="contained" onClick={openAddInstruments}>
                <Plus /> Add Instruments
              </Button>
            )}
            {canOrderInstruments && (
              <Button color="primary" variant="contained" onClick={orderInstruments}>
                <Sort /> Order
              </Button>
            )}
          </ButtonGroup>
        ),
        customToolbarSelect: () => <SelectedInstrumentsToolbar />,
      }}
    />
  );
};

export default DataTable;
