import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';
import { AccountCircle } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { selectors } from 'modules/Core/reducers';
import styles from './UserDisplay.styles';

const UserDisplay = () => {
  const classes = makeStyles(styles)();
  const userDisplayName = useSelector(state => selectors.getUserDisplayName(state));

  return (
    <>
      <div>
        <Link to="/auth/logout">
          <IconButton className={classes.accountButton}>
            <AccountCircle />
          </IconButton>
        </Link>
      </div>
      <div>
        <Typography variant="subtitle1" color="inherit" noWrap>
          {userDisplayName}
        </Typography>
      </div>
    </>
  );
};

export default UserDisplay;
