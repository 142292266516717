import { LDX_PILOTLINE_BATCH_LINE_CLEARANCE } from 'modules/Core/domain/permissions';
import { UserPermission } from 'modules/Core/types';
import { giveLineClearance, GiveLineClearanceActions } from 'modules/Pilot/BatchRecord/actions';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { SectionStatus } from 'modules/RecordManagement';
import { SignOffDefinition } from '../SignOffTable/SignOffTable';

export default (batchRecord: BatchRecord, userPermissions: UserPermission[]): SignOffDefinition => {
  const { id, lineClearance } = batchRecord;

  const isAllowedToSign = () => {
    if (lineClearance) return false;
    if (
      userPermissions.find(perm => perm.name === LDX_PILOTLINE_BATCH_LINE_CLEARANCE) === undefined
    )
      return false;

    return (
      batchRecord.preparation.status === SectionStatus.Complete ||
      batchRecord.preparation.status === SectionStatus.Locked
    );
  };

  return {
    signOff: lineClearance,
    title: 'Line Clearance',
    dialogName: 'pilot/batch-records/SIGN_OFF_LINE_CLEARANCE',
    reviewText: 'Give line clearance?',
    allowsMultiple: false,
    action: giveLineClearance(id),
    api: GiveLineClearanceActions.CALL,
    canSign: isAllowedToSign(),
  };
};
