import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as oidcReducer } from 'redux-oidc';
import {
  appReducer,
  loadingReducer,
  dialogReducer,
  apiErrorsReducer,
  editModeReducer,
} from '@ldx-dmp/Core/reducers';
import { reducer as autoSave } from '@ldx-dmp/AutoSave';
import instruments from '../Instruments/reducers';
import { pilotReducer as pilot } from '../Pilot';

const createRootReducer = history =>
  combineReducers({
    app: appReducer,
    apiLoading: loadingReducer,
    apiErrors: apiErrorsReducer,
    dialogs: dialogReducer,
    editMode: editModeReducer,
    router: connectRouter(history),
    oidc: oidcReducer,
    instruments,
    pilot,
    autoSave,
  });

export default createRootReducer;
