import React from 'react';

import { WidgetProps } from '@rjsf/core';
import { SelectFieldProps } from '../../SelectField';
import FormikSelectField from '../../FormikSelectField';

export type SelectWidgetProps = WidgetProps & SelectFieldProps;

export const SelectWidget = ({
  schema,
  id,
  options,
  label,
  multiple,
  required,
  uiSchema,
}: SelectWidgetProps) => {
  const { enumOptions, enumDisabled } = options;
  const selectOptions = (enumOptions as { label: string; value: string | number }[])?.map(
    ({ value, ...data }) => ({
      value,
      disabled: (enumDisabled as string[])?.includes(value as string),
      ...data,
    })
  );

  return (
    <FormikSelectField
      name={id}
      label={label || schema.title}
      options={selectOptions}
      multiple={multiple || uiSchema?.multiple}
      required={required}
    />
  );
};

export default SelectWidget;
