import React from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator, VersionInfo } from 'modules/Common';
import { FlexWrapper, MainContent, Sidebar, Topbar } from 'modules/Core/components';
import { SpeedDial, useSpeedDialContext, SpeedDialContext } from 'modules/SpeedDial';

const SignedInLayout = ({ children }) => {
  return (
    <SpeedDialContext.Provider value={useSpeedDialContext()}>
      <>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <SnackbarUtilsConfigurator />
          <FlexWrapper>
            <Sidebar />
            <Topbar />
            <MainContent>
              {children}
              <SpeedDial />
              <VersionInfo />
            </MainContent>
          </FlexWrapper>
        </SnackbarProvider>
      </>
    </SpeedDialContext.Provider>
  );
};

SignedInLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SignedInLayout;
