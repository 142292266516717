import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from './MainContent.styles';

const MainContent = ({ children }) => {
  const classes = makeStyles(styles)();
  return (
    <>
      <main className={classes.content}>{children}</main>
    </>
  );
};

MainContent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default MainContent;
