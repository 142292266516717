import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    margin: 0,
    width: '100%',

    '& .MuiGrid-item': {
      '&:nth-last-of-type(n+3)': {
        borderBottom: '1px solid #bde3f2',
      },
    },
  },
}));

export default useStyles;
