import React from 'react';
import { MaterialDto } from 'modules/InstrumentsQualityControl/models';
import {
  defaultOptions,
  TestConfigurationOptionsContextType,
} from './TestConfigurationOptionsContextType';

const MaterialsContext = React.createContext<TestConfigurationOptionsContextType<MaterialDto>>(
  defaultOptions
);

export default MaterialsContext;
