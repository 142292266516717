import { apiPost, apiPatch } from 'modules/Core/actions/apiActions';

export type BatchRecordPayload = {
  pilotLineId?: string;
  chemistry: string;
  scheduledRunDate: string;
  numberOfSplits?: number;
  splits?: number[];
  numberOfCards: number;
  stripType: string;
  depositionMethod: string;
};

export enum CreateBatchRecordActions {
  CALL = 'pilot/batch-records/CREATE_BATCH_RECORD',
  REQUEST = 'pilot/batch-records/CREATE_BATCH_RECORD_REQUEST',
  SUCCESS = 'pilot/batch-records/CREATE_BATCH_RECORD_SUCCESS',
  FAILURE = 'pilot/batch-records/CREATE_BATCH_RECORD_FAILURE',
}

export enum AmendBatchRecordActions {
  CALL = 'pilot/batch-records/AMEND_BATCH_RECORD',
  REQUEST = 'pilot/batch-records/AMEND_BATCH_RECORD_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_BATCH_RECORD_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_BATCH_RECORD_FAILURE',
}

export const createBatchRecord = (batchRecord: BatchRecordPayload) =>
  apiPost('/pilot/batch-records', CreateBatchRecordActions.CALL, batchRecord);

export const amendBatchRecord = (
  recordId: string,
  batchRecord: BatchRecordPayload & { comment?: string }
) => apiPatch(`/pilot/batch-records/${recordId}`, AmendBatchRecordActions.CALL, batchRecord);
