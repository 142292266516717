import React from 'react';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import { FormikValues } from 'formik';
import { FormikTextField } from 'modules/Common/components';
import { RecordDefinition } from 'modules/Pilot/BatchRecord/SingleView/components/RecordTable/RecordTable';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import {
  amendReagentNumber,
  AmendReagentNumbersActions,
  recordReagentNumber,
  RecordReagentNumbersActions,
} from 'modules/Pilot/BatchRecord/actions';
import { formatShortDate } from 'modules/Common/utilities';
import { PartStatus } from 'modules/RecordManagement';
import { UserPermission } from 'modules/Core/types';

interface Values extends FormikValues {
  number: string;
  comment?: string;
}

export default (
  batchRecord: BatchRecord,
  itemsBeingEdited: { key: string; itemId: string }[],
  userPermissions: UserPermission[]
): RecordDefinition<Values> => {
  const dialogName = 'pilot/batch-records/REAGENT';
  const recordId = batchRecord.id;
  const { status, number, recordedBy, requiredPermissions } = batchRecord.deposition.reagent;
  const isEdit = itemsBeingEdited.find(item => item.key === `${dialogName}_EDIT`) !== undefined;

  const isAllowedToEdit = () => {
    if (
      status === PartStatus.Locked ||
      status === PartStatus.ReadOnly ||
      status === PartStatus.Blocked
    )
      return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  const newSubmit = (values: Values, _, dispatch) =>
    dispatch(recordReagentNumber(recordId, { ...values }));
  const editSubmit = (values: Values, _, dispatch) =>
    dispatch(amendReagentNumber(recordId, { ...values }));

  const validation = {
    number: yup
      .string()
      .defined()
      .min(1),
  };

  const editValidation = {
    ...validation,
    comment: yup
      .string()
      .defined()
      .required()
      .min(5),
  };

  return {
    title: 'Reagent',
    tableData: {
      columns: [
        { accessor: 'number', label: 'Reagent Number' },
        { accessor: 'recordedBy', label: 'Recorded By', isSignOff: true },
      ],
      rows: [
        {
          key: 'reagent',
          fields: {
            number: { value: number ?? '-' },
            recordedBy: { user: recordedBy?.user.name, date: formatShortDate(recordedBy?.date) },
          },
        },
      ],
    },
    canAddRow: status === PartStatus.Incomplete,
    canEditRows: number !== undefined && number !== null && isAllowedToEdit(),
    formDefinition: {
      title: 'Reagent Number',
      dialogName,
      apiAction: isEdit ? AmendReagentNumbersActions.CALL : RecordReagentNumbersActions.CALL,
      onSubmit: isEdit ? editSubmit : newSubmit,
      initialValues: {
        number: isEdit ? number ?? '' : '',
      },
      form: () => (
        <Grid container spacing={2}>
          <Grid item xs>
            <FormikTextField name="number" label="Reagent Number" />
            {isEdit && <FormikTextField name="comment" label="Reason for change" multiline />}
          </Grid>
        </Grid>
      ),
      validationSchema: (): yup.ObjectSchema<Values> =>
        yup
          .object()
          .shape(isEdit ? editValidation : validation)
          .defined(),
    },
  };
};
