import { apiPatch } from 'modules/Core/actions';

export enum ReviseStripRejectCountsActions {
  CALL = 'pilot/batch-records/REVISE_STRIP_REJECT_COUNTS',
  REQUEST = 'pilot/batch-records/REVISE_STRIP_REJECT_COUNTS_REQUEST',
  SUCCESS = 'pilot/batch-records/REVISE_STRIP_REJECT_COUNTS_SUCCESS',
  FAILURE = 'pilot/batch-records/REVISE_STRIP_REJECT_COUNTS_FAILURE',
}

type ReviseStripRejectCountsPayload = {
  rejectCounts: { id: string; count: number }[];
};

export const reviseStripRjectCounts = (recordId: string, payload: ReviseStripRejectCountsPayload) =>
  apiPatch(
    `/pilot/batch-records/${recordId}/inspection/strip-rejects`,
    ReviseStripRejectCountsActions.CALL,
    payload
  );
