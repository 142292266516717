import { createAction } from 'redux-api-middleware';
import * as actionTypes from './actionTypes';

const fetchTechnicianUsers = () =>
  createAction({
    endpoint: '/instruments/repairs/technicians',
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    types: [
      actionTypes.TECHNICIAN_USERS_REQUEST,
      actionTypes.TECHNICIAN_USERS_SUCCESS,
      actionTypes.TECHNICIAN_USERS_FAILURE,
    ],
  });

export default fetchTechnicianUsers;
