import React from 'react';
import { PagedResultDto } from 'modules/Core';
import ActivityLogEntryDto from 'modules/Instruments/models/ActivityLogEntryDto';
import useServer from 'modules/Instruments/useServer';

export default id => {
  const server = useServer();
  const [list, setItems] = React.useState<PagedResultDto<ActivityLogEntryDto> | undefined>();
  const [status, setStatus] = React.useState<'idle' | 'loading' | 'loaded'>('idle');

  const fetch = React.useCallback(
    async function x() {
      setStatus('loading');
      const response = await server.getInstrumentHistoryAsync<PagedResultDto<ActivityLogEntryDto>>(
        id,
        id,
        null,
        null,
        0,
        1000
      );

      setItems(response.data);
      setStatus('loaded');
    },
    [id, server]
  );

  return React.useMemo(
    () => ({
      items: list?.items || [],
      list,
      fetch,
      status,
      clear: () => setItems(undefined),
    }),
    [fetch, list, status]
  );
};
