import React from 'react';
import { FileAttachment, OgpInspection } from 'modules/Pilot/BatchRecord/domain';
import { formatShortDate } from 'modules/Common/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from 'modules/Core/actions/dialogs';
import { RSAAAction } from 'redux-api-middleware';
import SectionPart, {
  addRowChipAction,
} from 'modules/Pilot/BatchRecord/SingleView/components/SectionPart';
import { PartStatus } from 'modules/RecordManagement';
import { FormDialog } from 'modules/Common/components';
import { getConfig, getItemsBeingEdited } from 'modules/Core/reducers/selectors';
import { Link } from '@material-ui/core';
import { BreakpointStep } from 'modules/Common/types';
import { startEditing } from 'modules/Core/actions/editMode';
import DataTable from '../SimpleDataTable';
import useForm from './ogpInspection.form';

type OgpInspectionProps = {
  title: string;
  status?: PartStatus;
  recordAction: (recordId: string, attachments: File[], comment?: string) => RSAAAction;
  amendAction: (recordId: string, attachments: File[], comment?: string) => RSAAAction;
  recordApi: string;
  amendApi: string;
  dialogName: string;
  inspection?: OgpInspection;
  recordId: string;
  xs?: BreakpointStep;
};

export default ({
  title,
  status,
  recordAction,
  amendAction,
  recordApi,
  amendApi,
  dialogName,
  inspection,
  recordId,
  xs = 6,
}: OgpInspectionProps) => {
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const isBeingEdited =
    useSelector(getItemsBeingEdited).find(item => item.key === `${dialogName}_EDIT`) !== undefined;
  const definition = useForm(
    recordId,
    dialogName,
    isBeingEdited ? amendApi : recordApi,
    isBeingEdited ? amendAction : recordAction,
    isBeingEdited
  );

  const createAttachmentLink = (attachment: FileAttachment) => {
    if (!attachment) return <>-</>;
    return (
      <>
        <Link href={`${config.app.cdn_base_uri}/${attachment.key}`} target="_blank" rel="noopener">
          {attachment.fileName}
        </Link>
      </>
    );
  };

  const editRowClickedHandler = () => {
    dispatch(startEditing(dialogName, recordId));
    dispatch(openDialog(dialogName));
  };

  return (
    <SectionPart
      xs={xs}
      title={title}
      actionChip={addRowChipAction(
        () => dispatch(openDialog(dialogName)),
        status === PartStatus.Incomplete
      )}
      status={status}
    >
      <DataTable
        editable={status === PartStatus.Complete}
        onRowEditClicked={editRowClickedHandler}
        tableData={{
          columns: [
            { accessor: 'attachment1', label: 'Attachment 1' },
            { accessor: 'attachment2', label: 'Attachment 2' },
            { accessor: 'inspectedBy', label: 'Inspected By', isSignOff: true },
          ],
          rows: inspection
            ? [
                {
                  key: 'sub-ogp-inspection',
                  fields: {
                    attachment1: {
                      value: createAttachmentLink(inspection.attachments[0]),
                    },
                    attachment2: {
                      value: createAttachmentLink(inspection.attachments[1]),
                    },
                    inspectedBy: {
                      user: inspection.inspectedBy?.user.name,
                      date: formatShortDate(inspection.inspectedBy?.date),
                    },
                  },
                },
              ]
            : [],
        }}
      />
      <FormDialog definition={definition} />
    </SectionPart>
  );
};
