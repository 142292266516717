import React from 'react';
import { useSelector } from 'react-redux';
import { getUserPermissions } from '../reducers/selectors';

function usePermissionCheck() {
  const userPerms = useSelector(getUserPermissions)?.map(x => x.name) as string[];
  const check = React.useCallback(
    permission => {
      if (!Array.isArray(permission)) {
        return userPerms.includes(permission);
      }

      return permission?.some(x => userPerms.includes(x));
    },
    [userPerms]
  );

  return React.useMemo(() => ({ check, userPerms }), [check, userPerms]);
}

export default usePermissionCheck;
