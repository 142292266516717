/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import InstrumentTestConfirmDialog from '../InstrumentTestConfirmDialog';

export default ({
  title = 'Review Performance Test',
  initialValues = {},
  test,
  ...props
}: { test?: TestSessionInstrumentDto | null } & Record<string, any>) => (
  <InstrumentTestConfirmDialog
    title={title}
    type="performance-test"
    test={test}
    initialValues={{
      ...initialValues,
      instrumentId: test?.instrument?.id,
      outcome: test?.performanceTest?.outcome,
      comment: '',
    }}
    {...props}
  />
);
