import { apiPost } from 'modules/Core/actions/apiActions';

export enum AddCommentToSectionActions {
  CALL = 'pilot/batch-records/ADD_COMMENT_TO_SECTION',
  REQUEST = 'pilot/batch-records/ADD_COMMENT_TO_SECTION_REQUEST',
  SUCCESS = 'pilot/batch-records/ADD_COMMENT_TO_SECTION_SUCCESS',
  FAILURE = 'pilot/batch-records/ADD_COMMENT_TO_SECTION_FAILURE',
}

export const addCommentToSection = (recordId: string, section: string, comment: string) =>
  apiPost(`/pilot/batch-records/${recordId}/comments`, AddCommentToSectionActions.CALL, {
    section,
    comment,
  });
