/* eslint-disable no-param-reassign */
import React from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { buildUrl } from '@ldx-dmp/Core/components/DataTable/dataTableSlice';

import { AppConfig } from 'modules/Core/types';

import { columns, options } from './context';

import reducer, {
  initialState,
  selectFormOpen,
  selectItems,
  selectLoading,
  selectCurrent,
  setCurrent,
  setData,
  startLoading,
  stopLoading,
  openForm,
  closeForm,
} from './equipmentSlice';

function useCreateForm(
  dispatch,
  apiUrl,
  navigate: (url: string) => void,
  state,
  user: { access_token: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues: any
) {
  const formOpen = selectFormOpen(state);
  const onFormClose = React.useCallback(() => dispatch(closeForm()), [dispatch]);

  const onFormSubmit = React.useCallback(
    formData => {
      return axios
        .post(`${apiUrl}/instruments/quality-control/equipment`, formData)
        .then(response => {
          onFormClose();
          onSubmit(response.data);
        });
    },
    [apiUrl, onFormClose, onSubmit]
  );

  return React.useMemo(
    () => ({
      onFormClose,
      onFormSubmit,
      formOpen,
      initialValues,
    }),
    [onFormClose, onFormSubmit, formOpen, initialValues]
  );
}

function useEquipmentContext(
  { api: { base_uri: apiUrl } }: AppConfig,
  navigate: (url: string) => void,
  user: { access_token: string }
) {
  const [tState, setTableState] = React.useState({});

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const data = selectItems(state);
  const isReloading = selectLoading(state) === 'RELOADING';
  const isLoading = selectLoading(state) === 'LOADING' || selectLoading(state) === 'NONE';
  const current = selectCurrent(state);

  const { id } = useParams();

  React.useEffect(() => {
    if (!id && current) {
      dispatch(setCurrent(null));
    } else if (id !== current?.id) {
      dispatch(startLoading());
      axios(`${apiUrl}/instruments/quality-control/equipment/${id}`).then(response => {
        dispatch(setCurrent(response.data));
        dispatch(stopLoading());
      });
      dispatch(openForm());
    }
  }, [apiUrl, current, id]);

  const fetchAll = React.useCallback(
    function fetchAll(tableState) {
      setTableState(tableState);
      dispatch(startLoading());

      if (current) {
        tableState.searchText = current?.id;
      }

      const url = buildUrl(`${apiUrl}/instruments/quality-control/equipment`, tableState);
      axios.get(url).then(response => {
        dispatch(setData(response.data));
        dispatch(stopLoading());
      });
    },
    [apiUrl, current]
  );

  const createForm = useCreateForm(
    dispatch,
    apiUrl,
    navigate,
    state,
    user,
    () => fetchAll(tState),
    current
  );
  const onNewItemClick = React.useCallback(() => dispatch(openForm()), [dispatch]);

  const values = React.useMemo(
    () => ({
      list: {
        isReloading: isReloading || isLoading,
        fetchAll,
        columns,
        data: data.items,
        totalCount: data.totalCount,
        options: {
          ...options,
          onRowClick: () => {},
        },
      },
      details: {
        summary: [],
      },

      onNewItemClick,
      createForm,

      current,
      isLoading: isReloading || isLoading,
    }),
    [
      createForm,
      current,
      data.items,
      data.totalCount,
      fetchAll,
      isLoading,
      isReloading,
      onNewItemClick,
    ]
  );

  return values;
}

export default useEquipmentContext;
