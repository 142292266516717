import React from 'react';
import { TableCell } from '@material-ui/core';
import { DataTableColumn } from './TableData';

type DataTableHeadingProps = {
  column: DataTableColumn;
};

export default ({ column }: DataTableHeadingProps) =>
  column.isSignOff ? (
    <TableCell colSpan={2} align="center">
      {column.label}
    </TableCell>
  ) : (
    <TableCell>{column.label}</TableCell>
  );
