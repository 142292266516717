import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Fade } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { ContentHeader } from 'modules/Common';
import { fetchInstruments, setCurrentInstrument } from '../actions';
import createInstrumentTableData from '../data/createInstrumentTableData';
import InstrumentDialog from '../components/InstrumentDialog';
import { getIsLoading, getInstruments } from '../reducers/selectors';

const InstrumentListPage = () => {
  const isLoading = useSelector(getIsLoading);
  const instruments = useSelector(getInstruments);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstruments());
  }, [dispatch]);

  const handleInstrumentSelected = (_, rowMeta) => {
    const instrumentId = instruments[rowMeta.dataIndex].serial;

    dispatch(setCurrentInstrument(instrumentId));
    dispatch(push(`/instruments/management/${instrumentId}`));
  };

  const [showForm, setShowForm] = useState(false);
  const tableData = createInstrumentTableData(instruments, handleInstrumentSelected);

  const handleClickOpen = () => {
    setShowForm(true);
  };

  const handleClose = () => {
    setShowForm(false);
  };

  return (
    <>
      <Fade in>
        <div>
          <ContentHeader
            title="Instruments"
            withNewItem
            newItemButtonText="Register Instrument"
            newItemButtonOnClick={handleClickOpen}
          />
          <InstrumentDialog show={showForm} onClose={handleClose} />
          <MUIDataTable
            data={tableData.data}
            columns={tableData.columns}
            options={{
              ...tableData.options,
              isLoading: isLoading && !showForm,
            }}
          />
        </div>
      </Fade>
    </>
  );
};

export default InstrumentListPage;
