import React from 'react';
import { ButtonGroup, Fade, Card, CardContent, Button } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useBoolean } from 'react-use';

import { ContentHeader } from 'modules/Common';
import { TestSessionDto } from 'modules/InstrumentsQualityControl/models';

import TestSessionTestConfiguration from '../TestSessionTestConfigurationTables';
import OperatorContext from '../../operatorsContext';
import MaterialContext from '../../materialsContext';
import EquipmentContext from '../../equipmentContext';
import useManageTestOperators from '../../hooks/useManageTestOperators';
import useManageTestMaterials from '../../hooks/useManageTestMaterials';
import useManageTestEquipment from '../../hooks/useManageTestEquipment';
import useServer from '../../hooks/useServer';
import TestSessionInstrumentTests from '../TestSessionsInstrumentsTests';
import { TestTabsProps } from '../TestSessionsInstrumentsTests/TestSessionsInstrumentsTests';
import SignOffDialog from '../SignOffDialog/SignOffDialog';

export type TestsPaneProps<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any> &
  TestTabsProps<T>;

function AddLineClearance({
  type,
  current,
  setCurrent,
  disabled,
}: {
  type: string;
  current: TestSessionDto;
  setCurrent: (data: TestSessionDto) => void;
  disabled?: boolean;
}) {
  const [showDialog, toggle] = useBoolean(false);
  const server = useServer();

  const addLineClearance = React.useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      server.patchLineClearance(current?.id, type).then(response => {
        setCurrent(response.data);
        toggle();
        resolve();
      }, reject);
    });
  }, [current?.id, server, setCurrent, toggle, type]);

  if (disabled) return <></>;

  return (
    <Card>
      <CardContent>
        <ContentHeader
          title="Line Clearance Requried"
          withNewItem={!disabled}
          newItemButtonText="Add Line Clearance"
          newItemButtonOnClick={toggle}
        />
      </CardContent>

      <SignOffDialog
        title="Line Clearance"
        reviewText="Are you sure you want add line clearance?"
        open={showDialog}
        onClose={toggle}
        onSign={addLineClearance}
        buttonText="Confirm"
      />
    </Card>
  );
}

export default function TestPaneWithOptionsNoHook<T>({
  current,
  setCurrent,
  configuration,
  addOperatorForm,
  addMaterialForm,
  addEquipmentForm,
  type,
  children,
  usage,
  buttons = () => {},
  readOnly,
  additionalConfig,
  lineClearanceEnabled = true,
  ...props
}: TestsPaneProps<T>) {
  const [showConfig, toggle] = useBoolean(false);

  const showLineClearance = configuration?.lineClearance === null && !showConfig;
  const showTest = !showConfig && !showLineClearance;

  return (
    <Fade in>
      <OperatorContext.Provider
        value={{
          current: configuration?.operators || [],
          form: addOperatorForm,
          ...useManageTestOperators(current, setCurrent, type),
        }}
      >
        <MaterialContext.Provider
          value={{
            current: configuration?.materials || [],
            form: addMaterialForm,
            ...useManageTestMaterials(current, setCurrent, false, usage, type),
          }}
        >
          <EquipmentContext.Provider
            value={{
              current: configuration?.equipment || [],
              form: addEquipmentForm,
              ...useManageTestEquipment(current, setCurrent, false, usage, type),
            }}
          >
            <TestSessionTestConfiguration
              readOnly={readOnly}
              show={showConfig}
              additionalConfig={additionalConfig}
            >
              {children}
              <ButtonGroup
                color="primary"
                aria-label="configuration options"
                style={{
                  padding: 10,
                }}
              >
                {buttons(toggle)}
                <Button
                  onClick={toggle}
                  title="Show configuration"
                  color="primary"
                  variant="contained"
                  startIcon={showConfig ? <NavigateBeforeIcon /> : <SettingsIcon />}
                >
                  {showConfig ? 'Return To Tests' : 'Test Setup'}
                </Button>
              </ButtonGroup>
              {showTest && (
                <TestSessionInstrumentTests
                  type={type}
                  readOnly={readOnly}
                  configuration={configuration}
                  {...props}
                />
              )}
              {showLineClearance && (
                <AddLineClearance
                  type={type}
                  current={current}
                  setCurrent={setCurrent}
                  disabled={!lineClearanceEnabled}
                />
              )}
            </TestSessionTestConfiguration>
          </EquipmentContext.Provider>
        </MaterialContext.Provider>
      </OperatorContext.Provider>
    </Fade>
  );
}
