import React from 'react';
import { InstrumentSerial, InstrumentType } from 'modules/Instruments/domain';
import { formatBuildDate } from 'modules/Instruments/utilities';

type InstrumentSummaryProps = {
  serial?: InstrumentSerial;
  type?: InstrumentType;
};

const InstrumentSummary = ({ serial, type }: InstrumentSummaryProps) => {
  const buildDate = formatBuildDate(serial);

  return (
    <>
      {type && (
        <>
          {type.name} {buildDate}
        </>
      )}
    </>
  );
};

export default InstrumentSummary;
