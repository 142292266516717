import React from 'react';
import { OperatorDto } from 'modules/InstrumentsQualityControl/models';
import {
  TestConfigurationOptionsContextType,
  defaultOptions,
} from './TestConfigurationOptionsContextType';

const OperatorsContext = React.createContext<TestConfigurationOptionsContextType<OperatorDto>>(
  defaultOptions
);

export default OperatorsContext;
