import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { AppConfig } from 'modules/Core/types';
import { getConfig } from 'modules/Core/reducers/selectors';
import { MaterialDto } from 'modules/InstrumentsQualityControl/models';

export default function(
  current,
  setCurrent,
  autoLoad = false,
  usage = 'VisualInspection',
  type = 'visual-inspection'
) {
  const config = useSelector(getConfig) as AppConfig;

  const [formOpen, setShow] = React.useState(false);
  const [items, setItems] = React.useState<MaterialDto[] | undefined>();

  const fetch = React.useCallback(() => {
    return axios
      .get(`${config.api.base_uri}/instruments/quality-control/materials?filters[usage]=${usage}`)
      .then(response => setItems(response.data.items));
  }, [config.api.base_uri, usage]);

  React.useEffect(() => {
    if (items || !autoLoad) return;

    fetch();
  });

  const onSubmit = React.useCallback(
    data =>
      axios
        .patch(
          `${config.api.base_uri}/instruments/quality-control/test-sessions/${current?.id}/${type}/add-material`,
          data
        )
        .then(response => {
          if (setCurrent) setCurrent(response.data);
          setShow(false);
        }),
    [config.api.base_uri, current?.id, setCurrent, type]
  );

  return React.useMemo(
    () => ({
      all: items || [],
      operators: items,
      formOpen,
      onOpen: () => {
        if (!items) {
          return fetch().then(() => setShow(true));
        }
        setShow(true);
        return Promise.resolve();
      },
      onSubmit,
      onClose: () => setShow(false),
      title: 'Materials',
      buttonText: 'Manage Materials',
      select: props => props.materialId,
      formatName: (item: MaterialDto) =>
        `${item.name} - ${item.specification} - rev ${item.revision}`,
      fetch,
    }),
    [items, formOpen, onSubmit, fetch]
  );
}
