import { createSelector } from 'reselect';
import { RepairRequestState } from './types';

export const isRequestFormOpen = (state: RepairRequestState) =>
  state.instruments.repairRequests.requestForm.open;
export const isRequestFormInEditMode = (state: RepairRequestState) =>
  state.instruments.repairRequests.requestForm.mode === 'edit';

export const getRepairRequests = (state: RepairRequestState) =>
  state.instruments.repairRequests.items;
export const getCurrentRepairRequestId = (state: RepairRequestState) =>
  state.instruments.repairRequests.current;

export const getCurrentRepairRequest = createSelector(
  getRepairRequests,
  getCurrentRepairRequestId,
  (requests, currentId) => requests.find(req => req.id === currentId)
);
