import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { StripRejects } from 'modules/Pilot/BatchRecord/domain';
import SectionPart from 'modules/Pilot/BatchRecord/SingleView/components/SectionPart';
import useStyles from './StripSummary.styles';

type StripSummaryProps = {
  stripRejects: StripRejects;
};

const SummaryRow = ({ label, value }: { label: string; value: string | number }) => (
  <TableRow>
    <TableCell className="rowHeader" scope="row" variant="head" component="th">
      {label}
    </TableCell>
    <TableCell>{value}</TableCell>
  </TableRow>
);

const StripSummary = ({ stripRejects }: StripSummaryProps) => {
  const classes = useStyles();

  return (
    <SectionPart title="Strip Summary" xs={12}>
      <Table stickyHeader size="small" className={classes.root}>
        <TableBody>
          <SummaryRow label="Total Number of Strips" value={stripRejects.totalStrips} />
          <SummaryRow
            label="Total Rejects"
            value={`${stripRejects.totalRejects} (${stripRejects.rejectedPercentage}%)`}
          />
          <SummaryRow label="Quantity of Good Strips Passed" value={stripRejects.goodStrips} />
          <SummaryRow label="Batch Yield" value={`${stripRejects.batchYieldPercentage}%`} />
        </TableBody>
      </Table>
    </SectionPart>
  );
};

export default StripSummary;
