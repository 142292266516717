import React from 'react';
import { ContentHeader } from 'modules/Common';
import { Card, CardContent, Typography } from '@material-ui/core';

const NotAllowed = () => (
  <>
    <ContentHeader title="Action Not Allowed" />
    <Card>
      <CardContent>
        <Typography>You do not have permission to perform this action.</Typography>
        <Typography>Please contact your manager for access</Typography>
      </CardContent>
    </Card>
  </>
);

export default NotAllowed;
