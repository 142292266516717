import { UserPermission } from 'modules/Core/types';
import {
  RecordPackagingAndSealingCarriedOutActions,
  recordPackagingSealingCarriedOut,
} from 'modules/Pilot/BatchRecord/actions';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { PartStatus } from 'modules/RecordManagement';
import { SignOffDefinition } from '../SignOffTable/SignOffTable';

export default (
  batchRecord: BatchRecord,
  userId: string,
  userPermissions: UserPermission[]
): SignOffDefinition => {
  const {
    id,
    inspection: {
      packagingAndSealing: { status, requiredPermissions, signOffs },
    },
  } = batchRecord;

  const isAllowedToSign = () => {
    if (
      status === PartStatus.Locked ||
      status === PartStatus.ReadOnly ||
      status === PartStatus.Blocked
    )
      return false;
    if (signOffs.find(so => so.user.id === userId)) return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  return {
    signOff: signOffs,
    title: 'Packaging and Sealing Carried Out',
    dialogName: 'pilot/batch-records/PACKAGING_SIGN_OFF',
    reviewText: 'Has packagaing and sealing been carried out?',
    allowsMultiple: true,
    action: recordPackagingSealingCarriedOut(id),
    api: RecordPackagingAndSealingCarriedOutActions.CALL,
    canSign: isAllowedToSign(),
  };
};
