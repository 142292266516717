import React from 'react';
import { Switch } from 'react-router-dom';
import { RestrictedRoute } from 'modules/Auth/components';
import * as permissions from 'modules/Core/domain/permissions';
import { RepairRequestListPage, RepairRequestPage } from '../containers';
import Locations from '../Locations/routes';

const InstrumentRepairRouter = () => (
  <Switch>
    <RestrictedRoute
      path={Locations.path}
      permission={permissions.LDX_INSTRUMENT_REPAIR_VIEW}
      component={Locations}
    />
    <RestrictedRoute
      exact
      path="/instruments/repair/:requestId"
      permission={permissions.LDX_INSTRUMENT_REPAIR_VIEW}
      component={RepairRequestPage}
    />
    <RestrictedRoute
      exact
      path="/instruments/repair"
      permission={permissions.LDX_INSTRUMENT_REPAIR_VIEW}
      component={RepairRequestListPage}
    />
  </Switch>
);

export default InstrumentRepairRouter;
