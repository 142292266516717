import React from 'react';

import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { DotsVertical, WashingMachine } from 'mdi-material-ui';

import {
  InstrumentSummaryDto,
  TestSessionInstrumentDto,
} from '@ldx-dmp/InstrumentsQualityControl/models';

import { formatBuildDate } from '@ldx-dmp/Instruments/utilities';

import InstrumentResult from '../InstrumentResult';
import SummaryChips from '../SummaryChips';
import useStyles from './InstrumentCard.styles';

const InstrumentSummaryItem = ({
  label,
  value,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}) => (
  <ListItem dense disableGutters alignItems="center">
    <ListItemText
      primary={label}
      primaryTypographyProps={{ color: 'textSecondary' }}
      secondary={value}
      secondaryTypographyProps={{ color: 'textPrimary' }}
    />
  </ListItem>
);

export const TestSessionInstrumentCardContent = ({
  instrument,
}: {
  instrument?: Partial<TestSessionInstrumentDto>;
}) => (
  <Grid container spacing={1}>
    <Grid item xs={6}>
      <List dense disablePadding>
        <InstrumentSummaryItem
          label="Visual Inspection"
          value={<InstrumentResult value={instrument?.visualInspection?.visualInspectionStatus} />}
        />
      </List>
    </Grid>
    <Grid item xs={6}>
      <List dense disablePadding>
        <InstrumentSummaryItem
          label="Performance Testing"
          value={<InstrumentResult value={instrument?.performanceTest?.status} />}
        />
      </List>
    </Grid>
    <Grid item xs={12}>
      <List dense disablePadding>
        <InstrumentSummaryItem
          label="Instrument Final Dispositon"
          value={<InstrumentResult value={instrument?.instrumentDisposition} />}
        />
      </List>
    </Grid>
  </Grid>
);

export type InstrumentSummaryProps = Pick<CardProps, 'elevation'> & {
  instrumentSummary?: Pick<
    InstrumentSummaryDto,
    'serial' | 'type' | 'buildYear' | 'buildMonth' | 'goodsReceivedNumbers' | 'isControlInstrument'
  >;
  action?: React.ReactNode;
  softwareVersion?: string;
  children?: React.ReactNode;
};

const InstrumentSummary = ({
  instrumentSummary,
  softwareVersion,
  elevation,
  action,
  children,
}: InstrumentSummaryProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!instrumentSummary) return <></>;

  const { serial, type, goodsReceivedNumbers } = instrumentSummary;
  const latestGrn =
    goodsReceivedNumbers && goodsReceivedNumbers.length > 0 ? goodsReceivedNumbers[0].name : '-';
  const buildDate = formatBuildDate(instrumentSummary);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showSummaryChips = !!instrumentSummary?.isControlInstrument;

  return (
    <Card className={classes.root} elevation={elevation}>
      <CardHeader
        color="primary"
        avatar={
          <Avatar>
            <WashingMachine />
          </Avatar>
        }
        action={
          action || (
            <IconButton edge="start" onClick={handleClick} color="inherit">
              <DotsVertical />
            </IconButton>
          )
        }
        title={serial}
        subheader={
          <>
            {type?.name} - {buildDate}
          </>
        }
        titleTypographyProps={{ component: 'h6', variant: 'subtitle1' }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <List dense disablePadding>
              <InstrumentSummaryItem label="Software Version" value={`${softwareVersion ?? '-'}`} />
            </List>
          </Grid>
          <Grid item xs={6}>
            <List dense disablePadding>
              <InstrumentSummaryItem label="GRN" value={latestGrn} />
            </List>
          </Grid>
        </Grid>
        {children && (
          <>
            <Divider />
            {children}
          </>
        )}
        <SummaryChips show={showSummaryChips} className={classes.chips}>
          {instrumentSummary?.isControlInstrument && (
            <Chip avatar={<Avatar>C</Avatar>} label="Control" color="primary" />
          )}
        </SummaryChips>
      </CardContent>
      <Menu
        id="instrument-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} dense>
          Update GRN
        </MenuItem>
        <MenuItem onClick={handleClose} dense>
          View History
        </MenuItem>
      </Menu>
    </Card>
  );
};

export default InstrumentSummary;
