/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormikRadioGroup, FormikTextField } from 'modules/Common';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import SignOffDialog from '../SignOffDialog/SignOffDialog';

export default function InstrumentTestConfirmDialog({
  open = false,
  setShowSignOff,
  setTest,
  setShowTests = () => {},
  onSubmit,
  test,
  setCurrent,
  setInstrument,
  title,
  type,
  canEditOutcome = true,
  initialValues = {},
  ...props
}: { test?: TestSessionInstrumentDto | null } & Record<string, any>) {
  const dialogTitle = (
    <>
      {title} - {test?.instrument?.serial}
    </>
  );
  return (
    <>
      <Formik
        onSubmit={data => {
          return new Promise((resolve, reject) => {
            onSubmit(
              {
                instrumentId: test?.instrument?.id,
                ...data,
              },
              type
            ).then(response => {
              setShowTests(true);
              setShowSignOff(false);
              setTest(undefined);
              setCurrent(response);
              setInstrument(
                response.instruments.find(x => x.instrument?.id === test?.instrument?.id),
                response
              );
              resolve(response);
              setShowTests(false);
            }, reject);
          });
        }}
        enableReinitialize
        validationSchema={yup.object({
          comment: yup.string().required(),
        })}
        initialValues={{
          ...initialValues,
        }}
      >
        {form => {
          async function onSign() {
            await form.submitForm();
          }

          return (
            <SignOffDialog
              open={open}
              onSign={onSign}
              title={dialogTitle}
              onClose={() => {
                setShowSignOff(false);
                setTest(undefined);
              }}
              buttonText="Save"
              {...props}
            >
              <Grid item xs>
                <Form>
                  {canEditOutcome && (
                    <FormikRadioGroup
                      label="Outcome"
                      name="outcome"
                      options={[
                        { label: 'Pass', value: 'Pass' },
                        { label: 'Fail', value: 'Fail' },
                      ]}
                    />
                  )}
                  <FormikTextField label="Comment" name="comment" />
                </Form>
              </Grid>
            </SignOffDialog>
          );
        }}
      </Formik>
    </>
  );
}
