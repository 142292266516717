export enum BatchRecordLoadingActions {
  INITIALISE = 'pilot/batch-records/INITIALISE',
  INITIALISED = 'pilot/batch-records/INITIALISED',
}

// eslint-disable-next-line import/prefer-default-export
export const initialiseBatchRecord = () => {
  return {
    type: BatchRecordLoadingActions.INITIALISE,
  };
};

export const batchRecordInitialised = () => {
  return {
    type: BatchRecordLoadingActions.INITIALISED,
  };
};
