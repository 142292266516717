import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BatchRecord, DepositionMethod, Sections } from 'modules/Pilot/BatchRecord/domain';
import {
  getDepositionAssets,
  getChemistryDefinitions,
} from 'modules/Pilot/BatchRecord/reducers/selectors';
import { getItemsBeingEdited } from 'modules/Core/reducers';
import { getUserPermissions } from 'modules/Core/reducers/selectors';
import { PartStatus, RecordStatus, SectionStatus } from 'modules/RecordManagement';
import RecordTable from '../RecordTable/RecordTable';
import pouchPreparationDefinition from './pouchPreparationDefinition';
import useReagentDefinition from './reagentDefinition';
import useBlockAidDefinition from './blockAidDefinition';
import useDepositionDefinition from './depositionDefintion';
import useLineClearanceDefinition from './lineClearanceDefinition';
import CommentsTable from '../CommentsTable';
import SignOffTable from '../SignOffTable';

type DepositionPanelProps = {
  batchRecord: BatchRecord;
  readOnly?: boolean;
};

const DepositionPanel = ({ batchRecord, readOnly = false }: DepositionPanelProps) => {
  const itemsBeingEdited = useSelector(getItemsBeingEdited);

  const hasHamilton =
    batchRecord.batchRequest.depositionMethod === DepositionMethod.Hamilton ||
    batchRecord.batchRequest.depositionMethod === DepositionMethod.Both;
  const hasFisnar =
    batchRecord.batchRequest.depositionMethod === DepositionMethod.Fisnar ||
    batchRecord.batchRequest.depositionMethod === DepositionMethod.Both;

  const hamiltonAssets = useSelector(store =>
    getDepositionAssets(store, DepositionMethod.Hamilton)
  );
  const fisnarAssets = useSelector(store => getDepositionAssets(store, DepositionMethod.Fisnar));
  const userPermissions = useSelector(getUserPermissions);
  const chemistryOptions = useSelector(getChemistryDefinitions);

  const lineClearanceDefinition = useLineClearanceDefinition(batchRecord, userPermissions);
  const lineClearanceStatus = () => {
    if (
      batchRecord.status === RecordStatus.Complete ||
      batchRecord.status === RecordStatus.Rejected
    )
      return PartStatus.ReadOnly;

    if (batchRecord.deposition.status === SectionStatus.Locked) return PartStatus.Locked;

    return batchRecord.lineClearance ? PartStatus.Complete : PartStatus.Incomplete;
  };

  const reagentDefintion = useReagentDefinition(batchRecord, itemsBeingEdited, userPermissions);
  const blockAidDefinition = useBlockAidDefinition(batchRecord, itemsBeingEdited, userPermissions);
  const hamiltonDeposition = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDepositionDefinition(
        batchRecord,
        DepositionMethod.Hamilton,
        hamiltonAssets,
        chemistryOptions,
        itemsBeingEdited,
        userPermissions
      ),
    [batchRecord, hamiltonAssets, chemistryOptions, itemsBeingEdited, userPermissions]
  );

  const fisnarDeposition = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDepositionDefinition(
        batchRecord,
        DepositionMethod.Fisnar,
        fisnarAssets,
        chemistryOptions,
        itemsBeingEdited,
        userPermissions
      ),
    [batchRecord, fisnarAssets, chemistryOptions, itemsBeingEdited, userPermissions]
  );

  const pouchPreparation = useMemo(
    () => pouchPreparationDefinition(batchRecord, itemsBeingEdited, userPermissions),
    [batchRecord, itemsBeingEdited, userPermissions]
  );

  return (
    <>
      <RecordTable
        definition={pouchPreparation}
        status={batchRecord.deposition.foilPouches.status}
        xs={6}
      />
      <SignOffTable
        definition={lineClearanceDefinition}
        status={lineClearanceStatus()}
        xs={12}
        withTime
      />
      <RecordTable
        definition={reagentDefintion}
        status={batchRecord.deposition.reagent.status}
        xs={6}
      />
      <RecordTable
        definition={blockAidDefinition}
        status={batchRecord.deposition.blockAid?.status}
        xs={6}
      />
      {hasHamilton && (
        <RecordTable
          definition={hamiltonDeposition}
          status={batchRecord.deposition.depositions.status}
          xs={12}
        />
      )}
      {hasFisnar && (
        <RecordTable
          definition={fisnarDeposition}
          status={batchRecord.deposition.depositions.status}
          xs={12}
        />
      )}
      <CommentsTable
        recordId={batchRecord.id}
        section={Sections.Deposition}
        comments={batchRecord.comments}
        readOnly={readOnly}
      />
    </>
  );
};

export default DepositionPanel;
