import { UserPermission } from 'modules/Core/types';
import { recordBaseAssembly, RecordBaseAssemblyActions } from 'modules/Pilot/BatchRecord/actions';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { PartStatus } from 'modules/RecordManagement';
import { SignOffDefinition } from '../SignOffTable/SignOffTable';

export default (
  batchRecord: BatchRecord,
  userId: string,
  userPermissions: UserPermission[]
): SignOffDefinition => {
  const {
    id,
    postDrying: {
      baseAssembly: { status, requiredPermissions, signOffs },
    },
  } = batchRecord;

  const isAllowedToSign = () => {
    if (
      status === PartStatus.Locked ||
      status === PartStatus.ReadOnly ||
      status === PartStatus.Blocked
    )
      return false;
    if (signOffs.find(so => so.user.id === userId)) return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  return {
    signOff: signOffs,
    title: 'Base Assembly',
    dialogName: 'pilot/batch-records/BASE_ASSEMBLY',
    reviewText: 'Have you taken part in Base Assembly?',
    allowsMultiple: true,
    action: recordBaseAssembly(id),
    api: RecordBaseAssemblyActions.CALL,
    canSign: isAllowedToSign(),
  };
};
