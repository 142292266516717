import React from 'react';
import { WidgetProps, utils } from '@rjsf/core';
import { ImageMap } from '@qiuz/react-image-map';
import { FieldArray, useField } from 'formik';

import image from './base.png';

const { getUiOptions } = utils;

export default ({ id, uiSchema }: WidgetProps) => {
  const [field, , helpers] = useField(id);
  const options = getUiOptions(uiSchema);

  const cursor = options?.readOnly ? '' : 'pointer';

  const mapArea = [
    {
      width: '14.44104803493449%',
      height: '18.18374558303887%',
      left: '10.043668122270741%',
      top: '24.72791519434629%',
      cursor,
    },
    {
      width: '14.44104803493449%',
      height: '18.183745583038867%',
      left: '71.2882096069869%',
      top: '24.551236749116608%',
      cursor,
    },
    {
      width: '14.44104803493449%',
      height: '18.183745583038867%',
      left: '10.480349344978166%',
      top: '58.650176678445234%',
      cursor,
    },
    {
      width: '14.44104803493449%',
      height: '18.183745583038867%',
      left: '10.480349344978166%',
      top: '58.650176678445234%',
      cursor,
    },
    {
      width: '14.44104803493449%',
      height: '18.183745583038867%',
      left: '70.52401746724891%',
      top: '59.71024734982332%',
      cursor,
    },
  ].map((value, index) => {
    if (field.value?.includes(index?.toString())) {
      return {
        ...value,
        opacity: 0.33,
        background: 'red',
      };
    }

    return value;
  });

  return (
    <div>
      <FieldArray name={id}>
        {({ remove, push }) => (
          <ImageMap
            className="usage-map"
            src={image}
            map={mapArea}
            onMapClick={(_area, index) => {
              if (options?.readOnly) return;

              helpers.setTouched(true);
              if (field.value?.includes(index?.toString())) {
                const i = field.value.indexOf(index?.toString());
                remove(i);
              } else {
                push(index?.toString());
              }
            }}
          />
        )}
      </FieldArray>
    </div>
  );
};
