export default theme => ({
  root: {
    flexGrow: 1,

    '& #requestTitle': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '& .MuiTypography-h5': {
        lineHeight: '1.8',
      },
    },

    '& .MuiCardHeader-root': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
      '& .MuiTypography-h6': {
        lineHeight: '1.0',
      },
    },

    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },

    '& .MuiCardContent-root': {
      whiteSpace: 'pre-line',
    },
  },
  stats: {
    '& .MuiGrid-container .MuiGrid-item': {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        '&:not(:last-of-type)': {
          borderBottom: '1px solid #eee',
        },
      },
      [theme.breakpoints.up('md')]: {
        '&:not(:last-of-type)': {
          borderRight: '1px solid #eee',
        },
      },
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  instrumentDetails: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },

  stackedList: {
    padding: theme.spacing(2),
    margin: 0,

    '& .MuiGrid-item': {
      '&:nth-last-of-type(n+3)': {
        borderBottom: '1px solid #eee',
      },
    },
  },

  stackedListHeader: {
    backgroundColor: '#eee',
    textAlign: 'right',
  },

  detailList: {
    '& .MuiTypography-subtitle2': {
      fontWeight: 'bold',
    },
  },

  tabBar: {
    '& .MuiTab-root': {
      '&.Mui-selected': {
        backgroundColor: 'rgb(28, 184, 220);',
      },

      '&:hover': {
        backgroundColor: 'rgb(28, 184, 220);',
      },
    },
  },

  affectedComponents: {
    '& .MuiGrid-item': {
      '&:nth-last-of-type(n+3)': {
        borderBottom: '0px none',
      },
    },
  },

  testCategory: {
    '&.MuiGrid-item': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: 0,
    },
  },

  testResult: {
    borderBottom: '1px solid #eee',
  },

  testPass: {
    color: '#4caf50',
  },

  testFail: {
    color: '#f44336',
  },

  testNa: {
    color: '#000',
  },
});
