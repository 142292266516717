import { apiPost, apiPatch } from 'modules/Core/actions/apiActions';

export type AdhesivePayload = {
  lotNumber: string;
};

export type AmendAdhesivePayload = { subRecordId?: string; comment?: string } & AdhesivePayload;

export enum AddAdhesiveActions {
  CALL = 'pilot/batch-records/ADD_ADHESIVE',
  REQUEST = 'pilot/batch-records/ADD_ADHESIVE_REQUEST',
  SUCCESS = 'pilot/batch-records/ADD_ADHESIVE_SUCCESS',
  FAILURE = 'pilot/batch-records/ADD_ADHESIVE_FAILURE',
}

export enum AmendAdhesiveActions {
  CALL = 'pilot/batch-records/AMEND_ADHESIVE',
  REQUEST = 'pilot/batch-records/AMEND_ADHESIVE_REQUEST',
  SUCCESS = 'pilot/batch-records/AMEND_ADHESIVE_SUCCESS',
  FAILURE = 'pilot/batch-records/AMEND_ADHESIVE_FAILURE',
}

export const addAdhesive = (recordId: string, payload: AdhesivePayload) =>
  apiPost(`/pilot/batch-records/${recordId}/preparation/adhesive`, AddAdhesiveActions.CALL, {
    ...payload,
  });

export const amendAdhesive = (recordId: string, payload: AmendAdhesivePayload) =>
  apiPatch(`/pilot/batch-records/${recordId}/preparation/adhesive`, AmendAdhesiveActions.CALL, {
    ...payload,
  });
