import { apiGet } from 'modules/Core/actions';

export enum FetchBatchRecordActivityActions {
  CALL = 'pilot/batch-records/FETCH_BATCH_RECORD_ACTIVITY',
  REQUEST = 'pilot/batch-records/FETCH_BATCH_RECORD_ACTIVITY_REQUEST',
  SUCCESS = 'pilot/batch-records/FETCH_BATCH_RECORD_ACTIVITY_SUCCESS',
  FAILURE = 'pilot/batch-records/FETCH_BATCH_RECORD_ACTIVITY_FAILURE',
}

export const fetchBatchRecordActivity = (recordId: string) =>
  apiGet(`/pilot/batch-records/${recordId}/activity`, FetchBatchRecordActivityActions.CALL);
