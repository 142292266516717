/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { TestSessionInstrumentDto } from 'modules/InstrumentsQualityControl/models';
import {
  TestSessionInstrumentTestForm,
  TestSessionInstrumentTestFormProps,
} from '../TestSessionsInstrumentsTests/TestSessionInstrumentTestForm';

export type InstrumentTestProps<T> = {
  instrument: TestSessionInstrumentDto | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any> &
  TestSessionInstrumentTestFormProps<T>;

export default function InstrumentTest<T>({ instrument, ...props }: InstrumentTestProps<T>) {
  return <TestSessionInstrumentTestForm key={instrument?.id} instrument={instrument} {...props} />;
}
