import { UserPermission } from 'modules/Core/types';
import {
  signOffManufacturing,
  SignOffManufacturingActions,
} from 'modules/Pilot/BatchRecord/actions';
import { BatchRecord } from 'modules/Pilot/BatchRecord/domain';
import { PartStatus } from 'modules/RecordManagement';
import { SignOffDefinition } from '../SignOffTable/SignOffTable';

export default (batchRecord: BatchRecord, userPermissions: UserPermission[]): SignOffDefinition => {
  const {
    id,
    signOff: {
      manufacturing: { status, requiredPermissions, signOff },
    },
  } = batchRecord;

  const isAllowedToSign = () => {
    if (batchRecord.signOff.batchCompletion.status !== PartStatus.Complete) return false;
    if (status !== PartStatus.Incomplete) return false;

    let result = true;
    requiredPermissions?.forEach(req => {
      if (userPermissions.find(perm => perm.name === req) === undefined) result = false;
    });

    return result;
  };

  return {
    signOff,
    title: 'Manufacturing Sign Off',
    dialogName: 'pilot/batch-records/MANUFACTURING_SIGN_OFF',
    reviewText: 'Is the batch record ready to be signed off and made read-only?',
    allowsMultiple: false,
    action: signOffManufacturing(id),
    api: SignOffManufacturingActions.CALL,
    canSign: isAllowedToSign(),
  };
};
